import styled from "styled-components";
import { LG, SM } from "../../../../utils/constants";

export const Wrapper = styled.div`
  margin-top: 48px;
  > p {
    opacity: 0.5;
  }
  @media (max-width: ${SM}) {
    margin-top: 200px;

   
  }
`;

export const ServicesResults = styled.div<{ empty: boolean }>`
  margin-top: 48px;

  ${props =>
    !props.empty &&
    `
        max-width: 716px;
    `};
  > div:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: ${LG}) {
    max-width: unset;
    > div {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${SM}) {
    margin-top: 28px;
  }
`;
