import React, { FC, useEffect, useState } from "react";

import "./Animate.css";

//components

interface Props {
  show?: any;
  children?: React.ReactChild | React.ReactChildren;
  opacity?: boolean;
}

const Animate: FC<Props> = ({ show, children, opacity }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <>
        {opacity ? (
          <div
            style={{
              animation: `${
                show === true ? "opacityIn" : "opacityOut"
              } 0.4s ease-in-out`
            }}
            onAnimationEnd={onAnimationEnd}
            className={"animated-div"}
          >
            <>{children}</>
          </div>
        ) : (
          <div
            style={{
              animation: `${show === true ? "fadeIn" : "fadeOut"} 0.4s ease`
            }}
            onAnimationEnd={onAnimationEnd}
            className={"animated-div"}
          >
            <>{children}</>
          </div>
        )}
      </>
    )
  );
};

export default Animate;
