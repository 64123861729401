import styled from "styled-components";
import checkboxIcon from "../../assets/icons/check-icon.svg";

export const Wrapper = styled.label`
    display: inline-flex;
    cursor: pointer;
    justify-content: center;
    font-size: 14px;
    line-height: 21px;
`;

export const CheckBox = styled.span`
    height: 18px;
    width: 18px;
    border: 1px solid var(--black);
    box-sizing: border-box;
    margin-right: 11px;
    transition: all 0.2s linear;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &::after {
        content: "";
        width: 10px;
        height: 12px;
        mask: url(${checkboxIcon}) center / contain no-repeat;
        background: var(--white);
        opacity: 0;
        transition: all 0.2s linear 0.3s;
    }
`;

export const Input = styled.input`
    display: none;
    &:checked + {
        ${CheckBox} {
            background: var(--primary-color);
            border: 1px solid transparent;
            &::after {
                opacity: 1;
            }
        }
    }
`;