import styled from "styled-components";
import { SM } from "../../../utils/constants";

const LabelColor = (status: any) => {
  if (status.toLowerCase() === "confirmada" || status === "realizada") {
    return "rgba(0, 189, 138, 0.8)";
  }
  if (status.toLowerCase() === "solicitada") {
    return "rgba(8, 197, 221, 0.8)";
  }
  if (status.toLowerCase() === "falta info") {
    return "rgba(255, 162, 0, 0.8)";
  }

  if (status.toLowerCase() === "pendiente de pago") {
    return "rgba(161, 116, 205, 0.8)";
  } else {
    return "rgba(242, 59, 49, 0.8)";
  }
};

export const Wrapper = styled.div``;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  a {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000 !important;

    vertical-align: middle;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }
`;

export const TablaMainWrap = styled.div``;

export const Searcher = styled.div`
  position: relative;

  img {
    position: absolute;
    opacity: 0.5;
    top: 50%;
    transform: translate(10px, -50%);
  }

  input {
    border: 1px solid #00000040;
    outline: 0;
    padding: 15px 10px 15px 40px;
    width: 240px;
    transition: ${props => props.theme.defaultTransition()};

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    &:focus {
      border-color: ${props => props.theme.mainBlue};

      img {
        opacity: 1;
      }
    }
  }

  @media (max-width: ${SM}) {
    input {
      width: calc(100% - 40px);
      padding-right: 0;
    }
  }
`;

export const TabsButtons = styled.ul`
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: ${SM}) {
    display: flex;
    /*    grid-template-columns: auto auto auto auto auto auto auto; */
    overflow-x: scroll;
    width: 100%;

    padding-bottom: 20px;
    margin-bottom: 10px;
  }
`;

export const ButtonLi = styled.div<{
  activeLi?: boolean | number;
  index?: number;
}>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${props =>
    props.activeLi === props.index ? "white" : "rgba(18, 18, 201, 0.3)"};
  background-color: ${props =>
    props.activeLi === props.index ? "#1212C9" : "transparent"};
  border: 1px solid
    ${props =>
      props.activeLi === props.index ? "#1212C9" : "rgba(18, 18, 201, 0.3)"};
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;

  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    border-color: #1212c9;
  }

  @media (max-width: ${SM}) {
    display: flex;
    span {
      display: flex;
      white-space: pre;
    }
  }
`;

export const Listado = styled.div`
  /* display: grid;
  grid-template-columns: 12% 12% 8% 12% 12% 14% 12% 12% ; */
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const ListadoHead = styled.div`
  display: grid;
 /*  grid-template-columns: 10% 12% 10% 14% 12% 17% 9% 16%; */
 //grid-template-columns:10% 12% 7% 10% 12% 9% 13% 10% 0%;
  grid-template-columns:10% 12% 7% 10% 12% 7% 13% 7% 10% 0%;
  margin-bottom: 12px;
`;

export const ListadoCat = styled.h4<{
  index?: number;
  cursorPointer?: boolean;
}>`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  opacity: ${props => (props.index === 1 ? 1 : 0.6)};
  cursor: ${props => (props.cursorPointer ? "pointer" : "inherit")};
`;

export const CatImg = styled.img<{ cursorFecha?: string }>`
  margin-left: 10px;
  position: relative;
  top: -1px;

  transform: ${props =>
    props.cursorFecha === "up" ? "rotate(0)" : "rotate(180deg)"};
`;

export const ListadoItemWrap = styled.div<{ activeItem?: number }>`
  display: grid;
  /* grid-template-columns: 10% 12% 10% 14% 12% 17% 9% 16%; */
  //grid-template-columns:10% 12% 7% 10% 12% 9% 13% 10% 0%;
  grid-template-columns:10% 12% 7% 10% 12% 7% 13% 7% 10% 0%;
  background-color: #ffffff;
  position: relative;

  &:before {
    content: "";
    width: 30px;
    height: 100%;
   /*  background-color: ${props =>
      props.activeItem === 2 ? "#F6F9FA" : "#ffffff"}; */
    position: absolute;
    bottom: 0;
    top: 0;
    left: -30px;

    &:hover {
      background-color: #f6f9fa;
    }
  }

  &:hover {
    background-color: #f6f9fa;

    &:before{
      background-color: #f6f9fa;
    }
  }
`;

export const ListadoItem = styled.div`
  padding: 20px 0 20px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  &:last-child {
    padding: 20px 0 0 0;
  }
`;

export const Label = styled.div<{ bg?: string }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.03em;
  text-transform: uppercase;
  background-color: ${props => LabelColor(props.bg)};
  color: #ffffff;
  padding: 8px 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: -14px;

  @media (max-width: ${SM}) {
    padding: 6px 10px;
  }
`;

export const Paginator = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
      width: 27px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    img {
      cursor: pointer;
    }
  }
`;

export const Li = styled.li<{ index?: number; current?: number }>`
  display: inline-block;
  margin: 0 5px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background-color: ${props =>
    props.index === props.current ? "var(--primary-color)" : "white"};
  transition: ${props => props.theme.defaultTransition()};
  color: ${props => (props.index === props.current ? "white" : "#00000")};

  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

export const LinkWrapMobile = styled.div`
  a {
    text-decoration: none;
  }

  > div,
  button,
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

   button{
    margin-bottom:50px;
  }
`;

export const TablaCardMobile = styled.div<{ activeItem?: number }>`
  width: 100%;
`;

export const ListMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  span {
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    opacity: 0.6;
  }

  h5 {
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #000000;
    font-weight: 400;
  }
`;
