import styled from "styled-components";

import { SM, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.div`
  padding: 24px 120px 128px;
  position: relative;
  span[aria-label="Jarvis logo"] {
    z-index: -1;
    top: 74px;
    right: 0;
    clip: rect(0, 303px, 367px, 0);
    width: 303px;
    background-position: left;
    background-size: cover;
  }
  h3 + p {
    opacity: 0.5;
  }

  @media (max-width: ${SM}) {
    padding: 24px 20px 128px;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const CodeContainer = styled.div`
  padding-top: 16px;

  @media (max-width: ${SM}) {
    margin-bottom: -30px;
  }
`;

export const AirportCode = styled.div<{ reserType: string }>`
  background: ${props =>
    props.reserType === "Arribo"
      ? "rgba(20, 167, 250, 0.1)"
      : props.reserType === "Partida"
      ? "rgba(255, 189, 0, 0.1)"
      : "rgb(4, 189, 138, 0.1)"};
  display: inline-flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  border-radius: 4px;

  span:first-child {
    margin-right: 4px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: ${SM}) {
    margin-bottom: 8px;
  }
`;

export const ReservationsContainer = styled.div`
  padding-top: 16px;

  & + div {
    margin-top: 56px;
  }

  @media (max-width: ${SM}) {
    > div {
      > div:nth-child(3) {
        > div:nth-child(2) {
          display: block;
          p:last-child span {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const ReservationsItem = styled.div`
  margin-top: 40px;
  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 1px solid var(--secondary-gray-light);
  }

  @media (max-width: ${SM}) {
    &:not(:last-child) {
      border: 0;
      padding-bottom: 0;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  h3 {
    margin: 4px 0;
  }
  > span {
    margin-right: 16px;
  }

  @media (max-width: ${SM}) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;

    > div {
      &:first-child {
        margin-left: -40px;
      }
    }

    span {
      margin-right: 16px;
      display: block;
      margin: 0 auto 0 0;
      margin-left: -9px;
    }

    p {
      font-size: 12px;
      opacity: 0.8;
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  column-gap: 56px;
  grid-template-columns: 1.5fr 1fr;

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 10px;
  button {
    font-weight: 600;
    color: #fd0000;
    font-size: 15px;
    &:first-child {
      margin-right: 32px;
    }
  }
`;

export const PaymentContainer = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  row-gap: 16px;
  border-bottom: 1px solid var(--secondary-gray-light);
  padding-bottom: 16px;
  margin-bottom: 16px;

  p:nth-child(even) {
    opacity: 1;
    text-align: right;
    margin-top: 0;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1.3fr 1fr;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 32px;

  & + label {
    margin-bottom: 40px;
    span {
      opacity: 1;
    }
  }
`;

export const DropdownsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  > div {
    &:first-child > div {
      padding-left: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid var(--secondary-gray-light);
    }
  }
  & + div {
    pointer-events: none;
  }

  @media (max-width: ${SM}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 0;
    margin-left: -10px;

    > div > div {
      padding-right: 20px;

      &:nth-child(1) {
        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 1px;
          height: 100%;
          background-color: var(--secondary-gray-light);
          left: -7px;
        }
      }
    }

    > div {
      &:first-child > div {
        padding-left: 24px;
        border-left: 0;

        &:after {
          display: none;
        }
      }
      &:nth-child(3) > div {
        border-left: 0;
        &:after {
          display: none;
        }
      }
      &:not(:last-child) {
        border-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteModalContainer = styled(FlexCenterContainer)`
  padding: 28px 32px 32px;
  flex: 1;
  flex-direction: column;

  > h2 + p {
    margin-bottom: 40px;
    opacity: 0.5;
    margin-top: 16px;
  }

  > p:first-child {
    width: 100%;
    margin-top: 0;
  }

  button:first-child {
    margin-right: 2px;
  }

  @media (max-width: ${SM}) {
    padding: 28px 10px 32px;
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin-top: 35px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #000000;

      opacity: 1 !important;
      margin-top: 5px !important;
      margin-bottom: 20px !important;
    }
    > div {
      flex-direction: column-reverse;

      width: 100%;

      button {
        width: 100%;
        display: block;
      }
    }
  }
`;

export const ServicePackageContainer = styled.div<{ modalPack?: boolean }>`
  display: grid;
  row-gap: 8px;
  padding: 28px 0 32px;
  width: 628px;

  > div {
    cursor: pointer;
  }
  > div > div:last-child {
    padding-bottom: 0;
    border: 0;
    padding-top: 8px;
    h3 + div + p {
      margin-top: 6px;
    }
  }

  @media (max-width: ${SM}) {
    > div {
      padding: 16px 16px 0;

      > div {
        padding-bottom: 0;

        div:last-child {
          margin-top: -30px !important;
          margin-bottom: -10px !important;
        }
      }
    }
  }
`;

export const DetalleBtnMobile = styled.div`
  margin-top: 30px !important;
  text-align: center;
  button {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

export const CambiarPaqueteTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #000000;
`;

export const BasesBtn = styled.span`
  text-decoration: underline;
  cursor: pointer;

  font-size: 14px;
  line-height: 21px;
  color: var(--black);
`;
