import styled from "styled-components";
import { SM } from "../../../../utils/constants";

export const Wrapper = styled.div`
  @media (max-width: ${SM}) {
    margin-bottom:-100px;
    overflow: auto;
    button {
      width: 100%;
      display: flex;
      justify-content: center;

      margin-top: 20px;
 
    }
  }
`;

export const ReservationInformation = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  padding-bottom: 8px;
  &:first-child {
    border-bottom: 1px solid var(--secondary-gray-light);
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 16px;
    margin-top: 0;
    &:nth-child(even) {
      text-align: right;
    }
  }

  @media (max-width: ${SM}) {
    border-bottom: 1px solid #d1dfe6;
    margin-bottom: 20px;

    p {
      &:nth-child(odd) {
        opacity: 0.8;
      }
      &:nth-child(even) {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: right;

        color: #000000;
      }
    }
  }
`;

export const PackageInformation = styled(ReservationInformation)`
  padding-bottom: 16px;

  @media (max-width: ${SM}) {
    border-bottom: 1px solid transparent;
  }
`;

export const MobileHeadline = styled.div`
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;

    margin-bottom: 30px;
  }
`;

export const SinCargo = styled.span`

font-size:12px;
vertical-align: top;

`
