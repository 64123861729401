import React, { Suspense, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Link, useHistory } from "react-router-dom";
import {
  Wrapper,
  BgImage,
  LoginHeader,
  InnerWrap,
  Grid,
  AgenciaLink,
  AgenciaWrap,
  CloseBtn,
  FormlogIn,
  InputWrap,
  LastWrap,
  MobileButtons
} from "./style";
import { Logo, Title, Subtitle } from "../../utils/commonStyle";
import RegularInput from "../../components/RegularInput";
import InputCheckbox from "../../components/InputCheckbox";
import Cta from "../../components/Cta";
import InputError from "../../components/InputError";
import GeneralError from "../../components/GeneralError";
import HelmetMetaData from "../../utils/HelmetMetaData";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

import { login } from "./../../actions/auth/creators";

interface Props {}

const Login: FC<Props> = () => {
  const { t } = useTranslation();

  useWindowSize();
  const dispatch = useDispatch();
  const isLogged = useSelector((state: any) => state.auth.isLogged);

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [valueEmail, setValueEmail] = useState("");
  const [emailName, setEmailName] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [checkValue, setCheckValue] = useState(false);

  const [error, setError] = useState(-1);
  const history = useHistory();

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    handleInputChange(email, 0, "");

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleLogin = () => {
    dispatch(
      login({ username: formData.email, password: formData.password, setError })
    );
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    setValidateEmail("empty");
  }, []);

  if (error === 1) {
    history.push("/agencia");
  } else {
  }

  return (
    <Suspense fallback={<div />}>
      <HelmetMetaData
        title={"Jarvis | Log In"}
        quote={""}
        description={"Iniciá sesión como agencia"}
      ></HelmetMetaData>

      <Wrapper>
        {ShowWindowDimensions() > SM && <BgImage />}
        <Grid>
          <LoginHeader>
            <Link to="/">
              <Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <AgenciaWrap>
              {ShowWindowDimensions() > SM && (
                <>
                  <Link to="/registro">
                    <AgenciaLink>{t('pantalla_login.registrate_como_agencia')}</AgenciaLink>
                  </Link>
                  <Link to="/registro-final" style={{'marginLeft': '1em'}}>
                    <AgenciaLink>{t('pantalla_login.registrate_como_final')}</AgenciaLink>
                  </Link>
                </>
              )}
              <Link to="">
                <CloseBtn />
              </Link>
            </AgenciaWrap>
          </LoginHeader>
          <InnerWrap>
            <Title>{t('pantalla_login.titulo')}</Title>
            <Subtitle>{t('pantalla_login.subtitulo')}</Subtitle>
            <FormlogIn>
              <InputWrap>
                <RegularInput
                  label={t('pantalla_login.formulario_email') + " *"}
                  type={"text"}
                  placeholder={"nombre@email.com"}
                  onChange={handleValidMail}
                  errorMail={validateEmail}
                  name={"email"}
                  emailName={emailName}
                  filled={formData["email"] !== "" ? "filled" : "empty"}
                />
                {!validateEmail && (
                  <InputError text={t('generales.error_email')} />
                )}
              </InputWrap>

              <InputWrap>
                <RegularInput
                  label={t('pantalla_login.formulario_password') + " *"}
                  type={"password"}
                  value={formData["password"]}
                  passwordInput={true}
                  placeholder={"********"}
                  onChange={handleInputChange}
                  name={"password"}
                  filled={formData["password"] !== "" ? "filled" : "empty"}
                />
              </InputWrap>

              <LastWrap>
                <Link to={"/reestablecer-password"}>
                  <AgenciaLink>{t('pantalla_login.olvide_password')}</AgenciaLink>
                </Link>
                <InputCheckbox onClick={setCheckValue} value={checkValue}>
                  <span>{t('pantalla_login.recordar_datos')}</span>
                </InputCheckbox>
                <GeneralError
                  visible={error === 0}
                  text={t('generales.datos_incorrectos')}
                />
                {ShowWindowDimensions() > SM ? (
                  <>
                    <Cta
                      onClick={handleLogin}
                      isRoute={false}
                      toRoute={"/agencia"}
                      theme="blueDarkTheme"
                      inactive={
                        formData.email === "" || formData.password === "" || validateEmail === false
                      }
                    >
                      {t('pantalla_login.iniciar_sesion')}
                    </Cta>
                  </>
                ) : (
                  <MobileButtons>
                    <Cta
                      onClick={handleLogin}
                      isRoute={false}
                      toRoute={"/agencia"}
                      theme="blueDarkTheme"
                      inactive={
                        formData.email === "" || formData.password === "" || validateEmail === false
                      }
                    >
                      {t('pantalla_login.iniciar_sesion')}
                    </Cta>

                    <Link to="/registro">
                      <AgenciaLink>{t('pantalla_login.registrate_como_agencia')}</AgenciaLink>
                    </Link>
                    <Link to="/registro-final">
                      <AgenciaLink>{t('pantalla_login.registrate_como_final')}</AgenciaLink>
                    </Link>
                  </MobileButtons>
                )}
              </LastWrap>
            </FormlogIn>
          </InnerWrap>
        </Grid>
      </Wrapper>
    </Suspense>
  );
};

export default Login;
