import GoogleMapReact from "google-map-react";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getPacksByAirport } from "../../../actions/packs/creators";
import { Pack } from "../../../actions/packs/types";
import airport from "../../../assets/images/airport-eze.jpg";
import * as CSC from "../../../utils/commonStyle";
import {
  ShowWindowDimensions,
  SM,
  useWindowSize
} from "../../../utils/mobileQueries";
import Cta from "../../Cta";
import SearchBar from "../../SearchBar";
import Tooltip from "../../Tooltip";
import * as SC from "./style";
import Modal from "../../Modal";

import { GOOGLE_MAPS_API_KEY } from "../../../utils/constants";

interface Props {
  bookingHook: {
    booking: any;
    setBooking: any;
  };
}

interface IResultInfo {
  image: string;
  location: string;
  airport: string;
  services: IService[];
}

const TEMP_RESULT: IResultInfo = {
  image: "",
  location: "Ezeiza, buenos aires, argentina",
  airport: "EZE, Aeropuerto Internacional Ministro Pistarini",
  services: []
};

const ResultsDetailPreview: React.FC<Props> = ({
  bookingHook: { booking, setBooking }
}) => {
  const { t } = useTranslation();

  useWindowSize();

  const isEng = localStorage.getItem("lang") === "EN";

  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState<string | undefined>("");
  const [showInfoMobile, setShowInfoMobile] = useState<any>({
    title: "",
    desc: ""
  });
  const [showVuelta, setShowVuelta] = useState(false);
  const [center, setCenter] = useState({
    lat: -34.8150044,
    lng: -58.5348284
  });

  // MAPS OPTIONS
  const defaultMapOptions = {
    fullscreenControl: false
  };
  const mapProperties = {
    zoom: 10
  };

  const addMarker = (maps: any) => {
    new maps.maps.Marker({
      position: {
        lat: center.lat,
        lng: center.lng
      },
      map: maps.map
    });
  };

  const [packs, setPacks] = useState<any>([]);

  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("airport") || "{}");

    const fetchData = async () => {
      const __packs: Pack[] = await getPacksByAirport(_id);

      setPacks(
        __packs.map(({ packName, description, descriptionEN }) => ({
          title: packName.toUpperCase(),
          descriptionEN: descriptionEN || "No description",
          description: description || "Sin descripción"
        }))
      );
    };

    fetchData();
  }, []);



  useEffect(() => {
    const {
      airportCode,
      airportName,
      city,
      state,
      country,
      image,
      location
    } = JSON.parse(localStorage.getItem("airport") || "{}");
    TEMP_RESULT.airport = `${airportCode} - ${airportName}`;
    TEMP_RESULT.location = [city, state, country].join(", ");
    TEMP_RESULT.image = image;

    setCenter({
      lat: location ? parseFloat(location.split(",")[0]) : 0,
      lng: location ? parseFloat(location.split(",")[1]) : 0
    });

    setTimeout(() => {
      setBooking({
        ...booking,
        airport: JSON.parse(localStorage.getItem("airport") || "{}")
      });
    }, 800);
  }, []);

  //TODO mostrar mensaje de vuelta

  /*   useEffect(()=>{
      setShowVuelta(false)
    },[]) */

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const currentLang = localStorage.getItem("lang") || "ES";

  const [inputFocus, setInputFocus] = useState(false)

  return (
    <SC.Wrapper>
      {ShowWindowDimensions() > SM && (
        <SC.ResultSearchBar>
          <SearchBar setInputFocus={setInputFocus} />
        </SC.ResultSearchBar>
      )}

      {showVuelta && (
        <SC.DescuentoWrap>
          <SC.DescuentoHead>
            <CSC.LittleTitle>
              {t("resultados.descuentos.titulo")}
            </CSC.LittleTitle>
            <CSC.Title>
              {t("resultados.descuentos.descuento", { percentage: "10%" })}
            </CSC.Title>
            <CSC.Paragraph>
              {t("resultados.descuentos.subtitle", { percentage: "10%" })}
            </CSC.Paragraph>
          </SC.DescuentoHead>
          <Cta
            onClick={() => {}}
            isRoute={true}
            toRoute={"/mis-reservas"}
            theme="lightTheme"
          >
            {" " + t("resultados.detalle_reserva.ver_mis_reservas") + " "}
          </Cta>
        </SC.DescuentoWrap>
      )}

      {ShowWindowDimensions() > SM ? (
        <SC.Container>
          <SC.Map>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY
              }}
              defaultZoom={mapProperties.zoom}
              defaultCenter={center}
              options={defaultMapOptions}
              onGoogleApiLoaded={maps => {
                addMarker(maps);
              }}
            ></GoogleMapReact>
          </SC.Map>

          <div>
            <SC.TitleContainer>
              <CSC.LittleTitle>{TEMP_RESULT.location}</CSC.LittleTitle>
              <CSC.BigTitle>{TEMP_RESULT.airport}</CSC.BigTitle>
            </SC.TitleContainer>

            <SC.ServiceList>
              {packs.length > 0 &&
                packs.map((el: any, i: number) => (
                  <Tooltip
                    title={el.title}
                    description={
                      isEng
                        ? el.descriptionEN || el.description
                        : el.description
                    }
                    key={i}
                  >
                    <SC.Service>{el.title}</SC.Service>
                  </Tooltip>
                ))}
            </SC.ServiceList>
          </div>
          {/*     <SC.Image src={airport} width={400} height={290} /> */}
          <SC.ImageAirport bg={TEMP_RESULT.image} />
        </SC.Container>
      ) : (
        <Fragment>
          <SC.Container>
            <SC.Map>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_MAPS_API_KEY
                }}
                defaultZoom={mapProperties.zoom}
                defaultCenter={center}
                options={defaultMapOptions}
                onGoogleApiLoaded={maps => {
                  addMarker(maps);
                }}
              ></GoogleMapReact>
            </SC.Map>
            <SC.ImageAirport bg={TEMP_RESULT.image} />
          </SC.Container>
          <div>
            <SC.TitleContainer>
              <CSC.LittleTitle>{TEMP_RESULT.location}</CSC.LittleTitle>
              <CSC.BigTitle>{TEMP_RESULT.airport}</CSC.BigTitle>
            </SC.TitleContainer>

            <SC.ServiceList hideMobile={true}>
              {packs.length > 0 &&
                packs.map((el: any, i: number) => (
                  <SC.Service key={i}>{el.title}</SC.Service>
                ))}
            </SC.ServiceList>

            <SC.ServiceList hideMobile={false}>
              {packs.length > 0 &&
                packs.map((el: any, i: number) => (
                  <SC.Service
                    onClick={() => {
                      setIsOpen(true);

                      setShowInfoMobile({
                        title: currentLang === "ES" ? el.title : el.title,
                        desc:
                          currentLang === "ES"
                            ? el.description
                            : el.descriptionEN
                      });
                    }}
                    key={i}
                  >
                    {el.title}
                  </SC.Service>
                ))}
            </SC.ServiceList>
            <Modal
              isOpen={isOpen}
              closeModal={() => {
                setIsOpen(false);
              }}
              fullscreen={false}
            >
              <SC.ShowModalTitle>
                <h3>{showInfoMobile.title}</h3>
                <p>{showInfoMobile.desc}</p>
              </SC.ShowModalTitle>
            </Modal>
          </div>
        </Fragment>
      )}
    </SC.Wrapper>
  );
};

export default ResultsDetailPreview;
