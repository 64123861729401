import React from 'react';

import * as SC from './style';

import Cta from '../Cta';
import { Link } from 'react-router-dom';

interface Props {
	title: string;
	textBtn: string;
	goTo?: string;
	btnLink?: boolean;
	inactiveReservation?:boolean;
	onClick?: () => void;
}

const DetailWhiteCard: React.FC<Props> = ({ children, title, textBtn, goTo, btnLink, inactiveReservation, onClick }) => {
	return (
		<SC.Wrapper>
			<SC.Title>{title}</SC.Title>

			{!btnLink ? (
				<SC.Container>
					{children}
					<Cta onClick={() => {}}

					inactive={inactiveReservation}

					goTo={!inactiveReservation ? goTo : ""}>
						{textBtn}
					</Cta>
				</SC.Container>
			) : (
				<SC.Container>
					{children}

					<Cta onClick={() => onClick ? onClick() : {}}
							inactive={inactiveReservation}
							goTo={!inactiveReservation ? goTo : ""}>
						{textBtn}
					</Cta>
				</SC.Container>
			)}
		</SC.Wrapper>
	);
};

export default DetailWhiteCard;
