import React, { Suspense, FC } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, Name, Text, Code } from "./style";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import { Booking, Passenger } from "../../pages/MyReservations/MyReservations";
import moment from "moment";

interface Props {
  booking?: Booking;
  pasajero: any;
  editButtons?: any;
  deleteModal?: any;
  setOpenModal?: any;
  removePassenger?: any;
  index?: number;
  SetOpenModifyModal?: any;
  parsedDate?: boolean;
}

const CardPasajeroSmall: FC<Props> = ({
  booking,
  pasajero,
  editButtons,
  deleteModal,
  removePassenger,
  index,
  SetOpenModifyModal,
  parsedDate
}) => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div />}>
      <div>
        <Wrapper>
          <Name>{pasajero.name}</Name>
          {parsedDate ? (
            <Text>{moment(pasajero.dob).format("DD/MM/YYYY")} </Text>
          ) : (
            <Text>{pasajero.dob} </Text>
          )}

          <Text>{pasajero.phone}</Text>
          <Text>
            <a href={`mailto:${pasajero.email}`}>{pasajero.email}</a>
          </Text>
          <Code>{pasajero.passport}</Code>
        </Wrapper>
        {editButtons && (
          <SC.ButtonContainer>
            <CSC.Link
              as="button"
              type="button"
              onClick={() => {
                SetOpenModifyModal(booking, {
                  show: true,
                  index: index
                });
              }}
            >
              {t("checkout.formulario.editar_informacion")}
            </CSC.Link>
            <CSC.Link
              as="button"
              type="button"
              onClick={() => {
                deleteModal(true);
                removePassenger(booking, index, pasajero.name);
              }}
            >
              {t("checkout.formulario.remover")}
            </CSC.Link>
          </SC.ButtonContainer>
        )}
      </div>
    </Suspense>
  );
};

export default CardPasajeroSmall;
