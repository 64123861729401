import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SocialWrap = styled.div<{logo: string, height?: number, hoverLogo?:string}>`


background: url(${props => props.logo}) center / contain no-repeat;
    width: 16px;
    height: ${props => props.height || 16}px;
    transition:${props => props.theme.defaultTransition('all', 0.1)};

    &:hover{
        background: url(${props => props.hoverLogo}) center / contain no-repeat;
    }
    &:not(:first-child) {
        margin-left: 24px;
        
    }

    &:last-child{
        width:18px;
        height:18px;
        position:relative;
        top:1px;
    }

`

export const SocialLink = styled.a`
    padding: 5px;

    margin: 0 10px;




`;