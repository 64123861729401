export const tabsData = [
  {
    tab: "ultimos_3_meses"
  },
  {
    tab: "ultimos_6_meses"
  },
  {
    tab: "anual"
  }
];

export const pasajeros = [
  {
    valor: 99
  },
  {
    valor: 111
  },
  {
    valor: 223
  }
];

export const servicios = [
  {
    valor: 22
  },
  {
    valor: 33
  },
  {
    valor: 90
  }
];

export const aeropuertos = [
  [
    {
      nombre: "(MAD) Aeropuerto Adolfo Suárez Madrid-Barajas",
      valor: 100
    },
    {
      nombre: "(FRA) Aeropuerto de Frankfurt",
      valor: 200
    },
    {
      nombre: "(SVO) Internacional de Moscú-Sheremetyevo",
      valor: 100
    }
  ],
  [
    {
      nombre: "(JNB) Aeropuerto de Johannesburgo",
      valor: 200
    },
    {
      nombre: "(CMN) Aeropuerto Internacional Mohámmed V",
      valor: 100
    },
    {
      nombre: "(CPT) Aeropuerto Intl. de la Ciudad del Cabo",
      valor: 200
    }
  ],
  [
    {
      nombre: "(SJO) Aeropuerto Intl. Juan Santamaría",
      valor: 300
    },
    {
      nombre: "(PTY) Aeropuerto Internacional de Tocumen",
      valor: 200
    },
    {
      nombre: "(NAS) Aeropuerto Internacional Lynden Pindling",
      valor: 132
    }
  ]
];
