import { debug } from 'console';

export const hasASurcharge = (hour: number, schedule: number[]) => {
  return schedule[0] < schedule[1]
    ? schedule[0] <= hour && hour < schedule[1]
      ? true
      : false
    : !(schedule[0] > hour && hour >= schedule[1])
    ? true
    : false;
};

export const diffPack = ({ packPrice, packPrice2Pax, packPrice3Pax, packPriceAdditionalPax }: any, pax: number) => {
  let sumPack = packPrice * pax;
  if (packPrice2Pax && pax >= 2) {
    sumPack = packPrice + packPrice2Pax * (pax - 1);
  }
  if (packPrice3Pax && pax >= 3) {
    sumPack = packPrice + packPrice2Pax + packPrice3Pax * (pax - 2);
  }
  if (packPriceAdditionalPax && pax > 3) {
    sumPack = packPrice + packPrice2Pax + packPrice3Pax + packPriceAdditionalPax * (pax - 3);
  }

  return sumPack;
}

export const diffServices = (services: any[], pax: number) => {
  return (services || []).map(({ type, servicePrice, servicePrice2Pax, servicePrice3Pax, servicePriceAdditionalPax }: any) => {
    if (type !== "no multiplica") {
      let sumServices = servicePrice * pax;
      if (servicePrice2Pax && pax >= 2) {
        sumServices = servicePrice + servicePrice2Pax * (pax - 1);
      }
      if (servicePrice3Pax && pax >= 3) {
        sumServices = servicePrice + servicePrice2Pax + servicePrice3Pax * (pax - 2);
      }
      if (servicePriceAdditionalPax && pax > 3) {
        sumServices = servicePrice + servicePrice2Pax + servicePrice3Pax + servicePriceAdditionalPax * (pax - 3);
      }
      return sumServices;
    } else {
      return servicePrice;
    }
  }).reduce((acc: any, item: any) => acc + item, 0);
}

export const calculateDynamicPrice = ({ packPrice, packPrice2Pax, packPrice3Pax, packPriceAdditionalPax }: any, services: any[], pax: number) => {
  const sumServices = services.map(({ type, servicePrice, servicePrice2Pax, servicePrice3Pax, servicePriceAdditionalPax }: any) => {
    if (type !== "no multiplica") {
      let sumServices = servicePrice * pax;
      if (servicePrice2Pax && pax >= 2) {
        sumServices = servicePrice + servicePrice2Pax * (pax - 1);
      }
      if (servicePrice3Pax && pax >= 3) {
        sumServices = servicePrice + servicePrice2Pax + servicePrice3Pax * (pax - 2);
      }
      if (servicePriceAdditionalPax && pax > 3) {
        sumServices = servicePrice + servicePrice2Pax + servicePrice3Pax + servicePriceAdditionalPax * (pax - 3);
      }
      return sumServices;
    } else {
      return servicePrice;
    }
  }).reduce((acc: any, item: any) => acc + item, 0);

  let sumPack = packPrice * pax;
  if (packPrice2Pax && pax >= 2) {
    sumPack = packPrice + packPrice2Pax * (pax - 1);
  }
  if (packPrice3Pax && pax >= 3) {
    sumPack = packPrice + packPrice2Pax + packPrice3Pax * (pax - 2);
  }
  if (packPriceAdditionalPax && pax > 3) {
    sumPack = packPrice + packPrice2Pax + packPrice3Pax + packPriceAdditionalPax * (pax - 3);
  }

  return sumPack + sumServices;
}

export const getTotalPrice = (
  booking: any,
  hourSelected: string,
  surchargePercent: number,
  schedule: number[]
) => {
  const pack = booking.pack;
  const services = booking.pack.services;
  const pax = booking.adultsCount + booking.childsCount;

  let price = calculateDynamicPrice(pack, services, pax);

  let totalPrice = price;
  let surchargeApplied = 0;

  let hour = parseInt(hourSelected?.split(":")[0]);

  if (hour >= 0) {
    if (hasASurcharge(hour, schedule)) {
      surchargeApplied = (price * surchargePercent) / 100;
      totalPrice = price + surchargeApplied;
    }
  }

  const beforeDiscount = totalPrice;

  if (booking.discount) {
    totalPrice -= totalPrice * booking.discount.percentage / 100;
  }

  return {
    totalPrice: Number(totalPrice.toFixed(2)),
    surcharge: surchargeApplied,
    beforeDiscount
  };
};
