import styled from "styled-components";
import passengersIcon from "../../assets/icons/passengers-icon.svg";
import { LG, LargerScreens, SM, XS } from "../../utils/constants";
import search from "../../assets/icons/search-icon.svg";

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  color: var(--black);
  padding-bottom: 150px;

  @media (max-width: ${SM}) {
    padding-bottom: 40px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 17px;

  @media (max-width: ${SM}) {
    justify-content: flex-start;

    a {
      display: block;
      padding-left: 22px;
    }
  }
`;

export const Container = styled.div`
  padding: 0 120px;

  > h3 + p {
    opacity: 0.5;
  }

  @media (max-width: ${SM}) {
    padding: 0 20px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8 !important;
    }
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 60px auto 0;
  }
`;

export const ReservationContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  padding-top: 45px;
  p {
    margin-top: 0px;
  }

  @media (max-width: ${SM}) {
    border-top: 0;
    padding-top: 20px;
  }
`;

export const FixedContainer = styled.div`
  box-shadow: 0px -20px 38px rgba(0, 0, 0, 0.09);
  position: fixed;
  bottom: 0;
  width: 100%;
  > div {
    background: var(--white);
    width: 100%;
    height: 120px;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    column-gap: 16px;
    align-items: center;
    padding: 0 120px;
    box-sizing: border-box;
    > button + p {
      text-align: right;
    }
    button:last-child {
      margin-left: 16px;
      padding: 0 40px;
    }

    p {
      text-align: right;
    }
  }

  @media (min-width: ${LargerScreens}) {
    > div {
      max-width: 1340px;
      margin: 0 auto;
      padding: 0;
    }
  }
`;

export const Reservation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 61px;
  > div:first-child {
    p {
      opacity: 0.5;
    }
  }
`;

export const FlightContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 40px 1fr auto;
  margin-top: 31px;
  column-gap: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
  margin-bottom: 24px;
  p {
    opacity: 0.5;
  }
  span {
    margin-right: 16px;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
    border-bottom: 0;
  }
`;

export const PassengersCount = styled.div<{ full: boolean }>`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-left: 32px;
  &::before {
    content: "";
    background: url(${passengersIcon}) center / contain no-repeat;
    width: 21px;
    height: 21px;
    margin-right: 11px;
  }
  ${props =>
    !props.full &&
    `
        opacity: 0.3;
    `};
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    padding: 0 45px;
    margin-left: 16px;
  }
`;

export const PassengersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-bottom: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media (max-width: ${SM}) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`;

export const PassengerBox = styled.div`
  width: 280px;
  background: var(--white);
  border: 1px solid var(--secondary-gray-light);
  padding: 16px;
  box-sizing: border-box;
  p:first-child {
    margin-bottom: 8px;
  }
  p:last-child {
    margin-top: 16px;
  }
  p:not(:first-child):not(:last-child) {
    line-height: 29px;
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 16px;
  button {
    outline: 0;
    font-weight: 500;
  }

  button:first-child {
    margin-right: 32px;
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;

export const InputContainer = styled.div`
  margin-left: 32px;
  position: relative;
  display: flex;
  align-items: center;

  > div:first-child {
    width: 200px;
    input {
      padding-right: 40px;

      ::placeholder {
        color: #666666;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: #666666;
      }

      ::-ms-input-placeholder {
        color: #666666;
      }
    }
  }

  @media (max-width: ${SM}) {
    div:last-child {
      position: initial;
    }
  }
`;

export const HelpIcon = styled.span<{ icon?: any }>`
  background: url(${props => props.icon}) center / contain no-repeat;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteModalContainer = styled(FlexCenterContainer)`
  padding: 28px 32px 32px;
  flex: 1;
  flex-direction: column;

  > h2 + p {
    margin-bottom: 40px;
    opacity: 1;
    margin-top: 16px;
  }

  > p:first-child {
    width: 100%;
    margin-top: 0;
  }

  button:first-child {
    margin-right: 2px;
  }

  span {
    opacity: 0.5;
  }

  @media (max-width: ${SM}) {
    padding: 28px 32px 0;
    > div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      button {
        width: 100%;
        display: block;
      }
    }

    > h2 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #000000;
    }

    > p {
      margin-bottom: 15px !important;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
`;

export const InputsModal = styled.div`
  padding: 30px;

  > h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
    margin-bottom: 20px;
  }

  input, textarea {
    box-sizing: border-box;
    width: 100%;
  }

  @media (max-width: ${SM}) {
    h4 {
      position: relative;
      top: -3px;
      margin-bottom: 40px;
      display: block;
      text-align: center;
    }
  }
`;

export const InputWrap = styled.div`
  margin-bottom: 20px;
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 25px;

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
  }
`;

export const GridButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  button {
    &:first-of-type {
      margin-right: 10px;
    }
  }

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;

    label {
      margin-left: -27px;
      margin-bottom: 30px;
    }

    button {
      width: 100%;
      justify-content: center;
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const MobileInput = styled.div`
  @media (max-width: ${SM}) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: -10px;
    position: relative;

    > div {
      width: 100%;
      margin-left: 0;

      div:first-child {
        width: 100%;
      }
    }
  }
`;

export const AddPassengersMobile = styled.div`
  @media (max-width: ${SM}) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    border-bottom: 1px solid #d1dfe6;
    padding-bottom: 40px;
    margin-bottom: -10px;

    > div {
      margin-left: 20px;
    }
  }
`;

export const ComprarWrapMobile = styled.div`
  /*   border-top: 1px solid #d1dfe6; */

  margin: 0 20px;
  margin-top: -30px;
`;

export const ComprarPrecio = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto;

  padding-top: 40px;
`;

export const TotalText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;

  opacity: 0.8;
`;

export const NominalText = styled.h4`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  color: #000000;
`;

export const ButtonsWrapMobile = styled.div<{inactive?: boolean}>`
  margin-top: 30px;
  width: 100%;
  display: block;

  > button {
    width: 100%;
    display: flex;
    justify-content: center;

    &:first-child {
      margin-bottom: 10px;
    }
  }

   a {
    button {
      margin:0 auto;
    }
  }
  ${props => props.inactive && `
    & > button {
      background: rgba(0, 0, 0, 0.1) !important;
      color: rgba(0, 0, 0, 0.3) !important;
    }
  `};
`;

export const TooltipContainer = styled.div`
  > div > div {
    bottom: calc(100% + 15px);
    right: unset;
    top: unset;
    > div {
      p {
        text-align: center;
      }
      &::before, &::after {
        top: unset;
      }
      &::before {
        bottom: -8px;
      }
      &::after {
        bottom: -8px;
      }
    }
  }
`;

export const ModalTitle = styled.span`
  padding: 50px 30px;
  font-size: 14px;
  font-weight: 400;
  line-height:24px;
`;

export const DatePickerLabel = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  display: block;
  padding-bottom: 15px;
`;

export const SearchWrap = styled.div`
  border-top: 1px solid var(--secondary-gray-light);
  border-bottom: 1px solid var(--secondary-gray-light);
  margin-bottom: 32px;
  padding-left: 99px;
  background: rgba(209, 223, 229, 0.1);
`;

export const SearchIcon = styled.span`
  mask: url(${search}) center / contain no-repeat;
  background: var(--black);
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const Input = styled.input`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  flex: 1;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  &::placeholder {
    color: var(--black);
  }
`;

export const Submenu = styled.ul<{ searchResults?: any[] | undefined }>`
  padding: 0 20px 15px 20px !important;
  overflow: hidden;

  visibility: ${props => (props.searchResults && props.searchResults.length > 0 ? "visible" : "hidden")};
  opacity: ${props => (props.searchResults && props.searchResults.length > 0 ? 1 : 0)};

  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 15px;

    &.hoverable:hover,
    &.unique {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
    &.key-hover {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
  }

  a {
    text-decoration: none;
    color: #000000;
    margin-bottom: 25px;
  }
`;

export const Card = styled.div`
  display: inline-block;
  align-items: center;
  cursor: pointer;

  margin-right: 22px;
  display: flex;

  overflow: hidden;

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    text-align: center;
    display: block;
    margin: 0 auto;
    line-height: 43px;
    margin-bottom: -13px;
    color: rgba(0, 0, 0, 0.5);

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    top: 50%;
  }

  @media (max-width: ${SM}) {
    h5 {
      font-size: 14px;
    }
  }
  @media (max-width: ${XS}) {
    h6 {
      font-weight: 400;
    }
  }
`;

export const BgPic = styled.div<{ bg?: any }>`
  width: 86px;
  min-width: 86px;
  height: 72px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: ${XS}) {
    width: 17%;
  }
`;

export const Headline = styled.div`
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;

  max-width: 380px;

  @media (min-width: ${LargerScreens}) {
    max-width: 450px;
  }

  @media (max-width: ${LG}) {
    max-width: 450px;
  }

  @media (max-width: ${XS}) {
    max-width: 70%;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${XS}) {
      overflow: auto;
      white-space: initial;
    }
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${XS}) {
      overflow: auto;
      white-space: initial;
    }
  }
`;

export const CtaContainer = styled.div`
  margin: 0 0 0 auto;
  margin-right: 20px;
  display: flex;
  justify-content: center;

  button {
    padding: 0 22px;
  }
`;

export const Wrapper2 = styled.div`
  height: 69px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;

  @media (min-width: ${LargerScreens}) {
    max-width: 1427px;
    margin: 0 auto;
  }

  .item {
    border: 1px solid lightblue;
    margin: 5px;
    padding: 5px;
  }

  .item.active,
  .item:hover {
    background: #d3d3d33b;
  }

  .item:hover {
    cursor: pointer;
  }
`;

export const ModalTitle2 = styled.span`
  padding: 30px 30px 0px 50px;
  font-size: 14px;
  font-weight: 400;
  line-height:24px;
`;