import styled from "styled-components";

export const Wrapper = styled.div`
  &:first-child div {
    width: 184px;
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    opacity: 0.3;
  }
`;

export const ChildrenWrap = styled.div`
  text-align: center;
`;

export const Porcentage = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  text-align: center;
  text-transform: uppercase;
`;
