import React, { useState, useEffect, useMemo } from "react";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

import AlertIcon from "../../assets/icons/alert-circle-red.svg";

import { hasASurcharge } from "../../utils/getPriceAndSurcharge";

interface Props {
  title: string;
  placeholder: string;
  setDateValue: any;
  dateValue: any;
  parseDate?: string;
  value?: any;
  onChange: any;
  timeRange?: any;
  toolTip?: {
    title?: string;
    text?: string;
  };
  getHours: any;
}

const TimePickerJarvis: React.FC<Props> = ({
  title,
  onChange,
  value,
  toolTip,
  getHours,
  timeRange
}) => {
  const [open, setOpen] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [focus1, setFocus1] = useState(false);
  const [focus2, setFocus2] = useState(false);

  useEffect(() => {
    if (Number(hours) > 23) {
      setHours("00");
    }

    if (Number(minutes) >= 60) {
      setMinutes("00");
    }
  }, [hours, minutes, timeRange]);

  const handleChange = (evt: {
    target: { validity: { valid: any }; value: any };
  }) => {
    const hoursGoal = evt.target.validity.valid ? evt.target.value : hours;

    setHours(hoursGoal);
    onChange(`${hoursGoal}:${minutes}`);
  };

  const handleChangeMinutes = (evt: {
    target: { validity: { valid: any }; value: any };
  }) => {
    const minutesGoal = evt.target.validity.valid ? evt.target.value : minutes;

    setMinutes(minutesGoal);
    onChange(`${hours}:${minutesGoal}`);
  };

  useEffect(() => {
    if (hours.length === 2) {
      setFocus2(true);
      setFocus1(false);
    }
    if (minutes.length === 0 && hours.length === 2) {
      setFocus1(true);
      setFocus2(true);
    }


    if (hours.length < 2 && minutes.length === 0) {
      setFocus2(false);
      setFocus1(true);
    }

    if (hours.length === 2 && minutes.length === 2) {
      setFocus2(false);
      setFocus1(false);
    }

    if (hours.length === 1 && parseInt(hours) > 2) {
      setFocus2(true);
      setFocus1(false);
    }

    getHours(hours, minutes);
  }, [hours, focus1, focus2, minutes]);

  const isSurcharge = useMemo(() => {
    return hours.length === 2 && minutes.length === 2
      ? hasASurcharge(Number(hours), [timeRange.initial, timeRange.final])
      : false
  }, [hours, minutes, timeRange]);

  return (
    <SC.Wrapper>
      <SC.DatePickerLabel onClick={() => setOpen(!open)}>
        <CSC.LittleTitle>{title}</CSC.LittleTitle>
        {/*   <SC.LabelContainer>
          <SC.Title>{value}</SC.Title>
          <SC.Arrow />
        </SC.LabelContainer> */}
        <SC.TimeWrap>
          {/*   <TimePicker
            onChange={value => onChange(value)}
            value={value}
            disableClock={true}
            maxDetail={"minute"}
            format={"h:m a"}
            className={"react-time-picker"}
            clearIcon={""}
            required={true}
          /> */}

          <SC.TimeInputs>
            {!focus1 && (
              <SC.NumberInput
                type="tel"
                pattern="[0-9]*"
                placeholder={"HH"}
                maxLength={2}
                onChange={handleChange}
                value={hours}
              />
            )}

            {focus1 && (
              <SC.NumberInput
                type="tel"
                pattern="[0-9]*"
                placeholder={"HH"}
                maxLength={2}
                onChange={handleChange}
                value={hours}
              />
            )}

            <SC.Dots colorChange={hours.length !== 0}>:</SC.Dots>

            {!focus2 && (
              <SC.NumberInput
                type="tel"
                pattern="[0-9]*"
                placeholder={"MM"}
                maxLength={2}
                onChange={handleChangeMinutes}
                value={minutes}
                marginCustom={"-4px"}
              />
            )}

            {focus2 && (
              <SC.NumberInput
                type="tel"
                pattern="[0-9]*"
                placeholder={"MM"}
                maxLength={2}
                onChange={handleChangeMinutes}
                value={minutes}
                marginCustom={"-8px"}
                autoFocus
              />
            )}
            {hours !== "" && isSurcharge && (
              <img
                onMouseOver={() => {
                  setShowTool(true);
                }}
                onMouseLeave={() => setShowTool(false)}
                src={AlertIcon}
                alt="alert"
              />
            )}
          </SC.TimeInputs>
        </SC.TimeWrap>
      </SC.DatePickerLabel>

      <SC.Tooltip
        showTool={showTool && isSurcharge}
        showAlert={hours !== "" && isSurcharge}
      >
        <h3>{toolTip && toolTip.title}</h3>
        <p>{toolTip && toolTip.text}</p>
        <em />
      </SC.Tooltip>
    </SC.Wrapper>
  );
};

export default TimePickerJarvis;
