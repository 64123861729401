import React, { FC, useEffect, useState } from "react";
import { Wrapper, RevealWrap, RevealPass, Input } from "./style";

interface Props {
  type?: string;
  placeholder?: string;
  label?: string;
  passwordInput?: boolean;
  onChange?: any;
  name?: any;
  value?: any;
  errorMail?: boolean | string;
  emailName?: string;
  filled?: any;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const RegularInput: FC<Props> = ({
  type,
  placeholder,
  label,
  passwordInput,
  onChange,
  name,
  value,
  errorMail,
  emailName,
  filled,
  disabled = false,
  onFocus = () => {},
  onBlur = () => {}
}) => {
  const [passType, setPassType] = useState("password");

  useEffect(() => { }, [passType]);

  return (
    <>
      {passwordInput ? (
        <Wrapper>
          <label>{label}</label>
          <RevealWrap>
            <RevealPass
              onClick={() => {
                if (passType === "password") {
                  setPassType("text");
                } else {
                  setPassType("password");
                }
              }}
              passType={passType}
            />
            <Input type={passType} filled={filled}
              onChange={(e) => onChange(e)}
              placeholder={placeholder}
              name={name}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              disabled={disabled}
            >
            </Input>
          </RevealWrap>
        </Wrapper>
      ) : (
          <Wrapper>
            <label>{label}</label>
            <Input
              type={type}
              value={value}
              autoComplete={"off"}
              name={name}
              onChange={(e) => onChange(e)}
              placeholder={placeholder}
              errorMail={errorMail}
              emailName={emailName}
              filled={filled}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
              disabled={disabled}
            ></Input>
          </Wrapper>
        )}
    </>
  );
};

export default RegularInput;
