import styled from "styled-components";
import { SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  margin-bottom: 30px;
  > span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    margin-bottom: 12px;
    display: block;
  }
`;

export const Tabs = styled.div`
  width: 75%;
  display: inline-block;

  @media (max-width: ${SM}) {
    width: 100%;
  }
`;

export const TabsButtons = styled.ul`
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: ${SM}) {
    display: grid;
    grid-template-columns: 160px 160px 78px;
    overflow-x: scroll;

    padding-bottom: 20px;
    margin-bottom: 10px;
  }
`;

export const ButtonLi = styled.div<{
  activeLi?: boolean | number;
  index?: number;
}>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: ${props =>
    props.activeLi === props.index ? "white" : "rgba(18, 18, 201, 0.3)"};
  background-color: ${props =>
    props.activeLi === props.index ? "#1212C9" : "transparent"};
  border: 1px solid
    ${props =>
      props.activeLi === props.index ? "#1212C9" : "rgba(18, 18, 201, 0.3)"};
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;

  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    border-color: #1212c9;
  }
`;

export const TabsContent = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
  grid-template-rows: 184px;

  @media (max-width: ${SM}) {
    display: block;
  }
`;

export const Content = styled.div`
  background: #ffffff;
  border: 1px solid #d1dfe5;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /*   max-width: 300px; */

  h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    opacity: 0.5;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 32px;
  }

  li {
    display: flex;
    justify-content: space-between;

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      max-width: 75%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    em {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: ${SM}) {
    padding: 25px 0;
    margin-bottom: 10px;
    h3 {
      margin-top: 70px;
      margin-bottom: 30px;
    }

    h3,
    h6 {
      padding: 0 20px;
    }

    li {
      flex-wrap: wrap;
      span {
        max-width: 60%;
      }
    }
  }

  &:last-child {
    h6 {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
      em {
        padding-right: 20px;
      }
      span {
        padding-left: 20px;
      }
    }
  }
`;

export const GraficoWrap = styled.div`
  display: inline-block;
  position: relative;
  top: 50px;
  left: 70px;

  @media (max-width: ${SM}) {
    top: 32px;
    left: 50%;
    margin-bottom: 70px;
    transform: translate(-50%, 10px);
  }
`;
