import React from "react";
import * as SC from "./style";

interface Props {
  title?: string;
  description?: string;
  viewTool?: string;
}

const Tooltip: React.FC<Props> = ({
  children,
  title = "",
  description = "",
  viewTool
}) => {
  return (
    <SC.Wrapper>

      {viewTool === "checkout" ? (
        <SC.TooltipWrapCheckout>   
          <SC.TooltipInner viewTool={viewTool}>
            <SC.Title>{title}</SC.Title> 
            <SC.Description>{description}</SC.Description>
          </SC.TooltipInner>
        </SC.TooltipWrapCheckout>
      ) : (
        <SC.TooltipWrapper>
          <SC.TooltipInner>
            <SC.Title>{title}</SC.Title>
            <SC.Description>{description}</SC.Description>
          </SC.TooltipInner>
        </SC.TooltipWrapper>
      )}

      {children}
    </SC.Wrapper>
  );
};

export default Tooltip;
