import React, { Suspense, FC, useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  BgImage,
  LoginHeader,
  InnerWrap,
  Grid,
  AgenciaLink,
  AgenciaWrap,
  CloseBtn,
  FormlogIn,
  InputWrap,
  BottomWrap,
  WrapperButton,
  BackButton,
  NextButton,
  BasesBtn} from "./style";
import { Logo, Title, Subtitle } from "../../utils/commonStyle";
import RegularInput from "../../components/RegularInput";
import InputCheckbox from "../../components/InputCheckbox";
import InputError from "../../components/InputError";
import HelmetMetaData from "../../utils/HelmetMetaData";
import GeneralError from "../../components/GeneralError";
import Modal from "../../components/Modal";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

import { signup } from "../../actions/auth/creators";
import BasesYCondiciones from "../../components/BasesYCondiciones";

interface Props {}

const RegistroFinal: FC<Props> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  useWindowSize();

  const [step, setStep] = useState(0);
  const [emptyFields, setEmptyFields] = useState({
    step1: true,
    step2: true
  });

  const [registerError, setRegisterError] = useState(-1);
  const [openBases, setOpenBases] = useState(false);

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [emailName, setEmailName] = useState("");
  const [checkValue, setCheckValue] = useState(false);
  const [formData, setFormData] = useState({
    empresa: "",
    tel: "",
    perfilEmail: "",
    perfilPassword: ""
  });

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailName(email.target.name);

    handleInputChange(email, 0, "");
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  useEffect(() => {
    setValidateEmail("empty");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    setValidateEmail("empty");
  }, []);

  useEffect(() => {
    document.body.style.overflow = "inherit";
    document.documentElement.style.overflow = "inherit";

    handleEmptyFields();
  }, [formData, step, checkValue]);

  const handleEmptyFields = () => {
    if (
      formData.empresa === "" ||
      formData.perfilEmail === "" ||
      formData.perfilPassword === "" ||
      (!validateEmail && emailName === "perfilEmail")
    ) {
      setEmptyFields({
        ...emptyFields,
        step1: true
      });
    } else {
      setEmptyFields({
        ...emptyFields,
        step1: false
      });
    }
  };

  if (registerError === 1) {
    history.push("/agencia");
  }

  const handleSignup = () => {
    const payload = {
      companyName: formData.empresa,
      paymentCondition: "tarjeta",
      phone: formData.tel,
      email: formData.perfilEmail,
      password: formData.perfilPassword,
      type: 'USUARIO_FINAL'
    };
    dispatch(signup({ payload, setRegisterError }));

    return <Redirect to="/agencia" />;
  };

  useEffect(() => {
    if (openBases) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openBases]);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <HelmetMetaData
          title={"Jarvis | Registro"}
          quote={""}
          description={"Registrate como usuario final"}
        ></HelmetMetaData>

        {ShowWindowDimensions() > SM && <BgImage />}

        <Grid>
          <LoginHeader>
            <Link to="/">
              <Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <AgenciaWrap>
              <Link to="/login">
                <AgenciaLink>
                  {t("registro_agencia.boton_inicia_sesion")}
                </AgenciaLink>
              </Link>
              <Link to="">
                <CloseBtn />
              </Link>
            </AgenciaWrap>
          </LoginHeader>

          <InnerWrap>
            <Title>{t("registro_agencia.titulo")}</Title>
            <Subtitle></Subtitle>
            <FormlogIn>
              <InputWrap>
                <RegularInput
                  label="Nombre completo"
                  type={"text"}
                  placeholder="Ingrese nombre"
                  onChange={handleInputChange}
                  name={"empresa"}
                  value={formData["empresa"]}
                  filled={formData["empresa"] !== "" ? "filled" : "empty"}
                />
              </InputWrap>

              <InputWrap>
                <RegularInput
                  label={t("registro_agencia.email")}
                  type={"text"}
                  placeholder={"nombre@mail"}
                  onChange={handleValidMail}
                  errorMail={validateEmail}
                  name={"perfilEmail"}
                  emailName={emailName}
                  filled={
                    formData["perfilEmail"] !== "" ? "filled" : "empty"
                  }
                />
                {!validateEmail && emailName === "perfilEmail" && (
                  <InputError text={t("generales.error_email")} />
                )}
              </InputWrap>

              <InputWrap>
                <RegularInput
                  label={t("registro_agencia.password")}
                  type={"password"}
                  placeholder={"******"}
                  onChange={handleInputChange}
                  name={"perfilPassword"}
                  filled={
                    formData["perfilPassword"] !== "" ? "filled" : "empty"
                  }
                />
              </InputWrap>

              <InputWrap>
                <RegularInput
                  label="Teléfono (opcional)"
                  type={"text"}
                  placeholder={t(
                    "registro_agencia.telefono_emergencia_input"
                  )}
                  onChange={handleInputChange}
                  name={"tel"}
                  filled={formData["tel"] !== "" ? "filled" : "empty"}
                />
              </InputWrap>
            </FormlogIn>

            <BottomWrap>
              <InputCheckbox
                onClick={() => setCheckValue(!checkValue)}
                value={checkValue}
              >
                <span>{t("registro_agencia.Creando_una_cuenta")} </span>
              </InputCheckbox>
              <BasesBtn
                onClick={() => {
                  setOpenBases(true);
                }}
              >
                {""}
                {t("registro_agencia.bases_y_condiciones")}
              </BasesBtn>
              <GeneralError
                visible={registerError === 0}
                text={"Error al crear la cuenta"}
              />
            </BottomWrap>

            <WrapperButton>
              <BackButton onClick={() => setStep(0)}>
                {t("registro_agencia.boton_atras")}
              </BackButton>

              {emptyFields.step1 ? (
                <NextButton inactive={true} onClick={() => {}}>
                  {t("registro_agencia.boton_crear_cuenta")}
                </NextButton>
              ) : (
                <NextButton inactive={false} onClick={handleSignup}>
                  {t("registro_agencia.boton_crear_cuenta")}
                </NextButton>
              )}
            </WrapperButton>
          </InnerWrap>
        </Grid>

        {/* MODAL BASES Y CONDICIONES */}
        <Modal
          isOpen={openBases}
          closeModal={() => {
            setOpenBases(false);
          }}
          fullscreen={true}
        >
          <BasesYCondiciones />
        </Modal>
      </Wrapper>
    </Suspense>
  );
};

export default RegistroFinal;
