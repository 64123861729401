import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const getAll = async () => {
  const {
    data: { airports }
  } = await axios.get(`${API_URL}/airport`);
  return airports.filter(({ visible }: { visible: boolean }) => visible);
};
