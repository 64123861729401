import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const verify = async (code: string, airport: string, pack: string, date: string) => {
  date = `${date.split(", ")[0].split("/")[2]}-${
    date.split(", ")[0].split("/")[1]
  }-${date.split(", ")[0].split("/")[0]}T${date.split(", ")[1]}:00.000Z`;

  code = code.toUpperCase();
  const { data } = await axios.get(`${API_URL}/discount/${code}?airport=${airport}&pack=${pack}&date=${date}`);
  return data;
};