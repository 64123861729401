import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import NumberPicker from "../../../NumberPicker";

import * as SC from "./style";
import * as CSC from "../../../../utils/commonStyle";

interface Props {
  setToShowData?: any;
  toShowData: Object;
  bookingHook: {
    booking: any;
    setBooking: any;
  };
}

interface IPassengerBaggage {
  id: number;
  title: string;
}

const ReservationCharacteristics: React.FC<Props> = ({
  setToShowData,
  toShowData,
  bookingHook: { booking, setBooking }
}) => {
  const { t } = useTranslation();

  const info: IPassengerBaggage[] = [
    {
      id: 1,
      title: t('resultados.caracteristicas.adultos')
    },
    {
      id: 2,
      title: t('resultados.caracteristicas.ninos') + ' (3-12)'
    },
    {
      id: 3,
      title: t('resultados.caracteristicas.infantes') + ' (0 - 2)'
    },
    {
      id: 4,
      title: t('resultados.caracteristicas.Equipaje')
    }
  ];

  const [reservationInfo, setReservationInfo] = useState([0, 0, 0, 0]);

  const onChange = (index: number, option: "increment" | "decrement") => {
    const newArray = [...reservationInfo];
    newArray[index] =
      option === "increment"
        ? newArray[index] + 1
        : newArray[index] > 0
        ? newArray[index] - 1
        : newArray[index];
    setReservationInfo(newArray);

    setToShowData((toShowData: Object) => ({
      ...toShowData,
      pasajeros: [
        {
          nombre: t('resultados.caracteristicas.adultos'),
          cantidad: newArray[0]
        },
        {
          nombre: t('resultados.caracteristicas.ninos'),
          cantidad: newArray[1]
        },
        {
          nombre: t('resultados.caracteristicas.infantes'),
          cantidad: newArray[2]
        },
        {
          nombre: t('resultados.caracteristicas.Equipaje'),
          cantidad: newArray[3]
        }
      ]
    }));

    setBooking({
      ...booking,
      adultsCount: newArray[0],
      childsCount: newArray[1],
      infantsCount: newArray[2],
      bagsCount: newArray[3]
    });
  };

  useEffect(() => {
    const bookingStorage = localStorage.getItem("more_offers_booking");

    if(!bookingStorage) {
      return;
    }

    const {
      adultsCount,
      childsCount,
      infantsCount,
      bagsCount
    } = JSON.parse(bookingStorage);

    const newArray = [
      adultsCount,
      childsCount,
      infantsCount,
      bagsCount
    ];
    setReservationInfo(newArray);

    setToShowData((toShowData: Object) => ({
      ...toShowData,
      pasajeros: [
        {
          nombre: t('resultados.caracteristicas.adultos'),
          cantidad: newArray[0]
        },
        {
          nombre: t('resultados.caracteristicas.ninos'),
          cantidad: newArray[1]
        },
        {
          nombre: t('resultados.caracteristicas.infantes'),
          cantidad: newArray[2]
        },
        {
          nombre: t('resultados.caracteristicas.Equipaje'),
          cantidad: newArray[3]
        }
      ]
    }));

    setBooking({
      ...booking,
      adultsCount: newArray[0],
      childsCount: newArray[1],
      infantsCount: newArray[2],
      bagsCount: newArray[3]
    });
  }, []);

  return (
    <SC.Wrapper>
      <CSC.MediumTitle>{t('resultados.caracteristicas.titulo')}</CSC.MediumTitle>
      <CSC.Paragraph>{t('resultados.caracteristicas.subtitulo')}</CSC.Paragraph>
      <SC.CountersContainer>
        {info.map((item, index) => (
          <NumberPicker
            title={item.title}
            number={reservationInfo[index]}
            key={index}
            onDecrement={() => onChange(index, "decrement")}
            onIncrement={() => onChange(index, "increment")}
          />
        ))}
      </SC.CountersContainer>
    </SC.Wrapper>
  );
};

export default ReservationCharacteristics;
