import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const getProfile = async (id: string) => {
  const { data: user } = await axios.get(`${API_URL}/user/${id}`);
  return user;
};

export const update = async (id: string, data: any) => {
  await axios.put(`${API_URL}/user/${id}`, data);
};

export const getBookings = async (id: string) => {
  const { data: { bookings } } = await axios.get(`${API_URL}/user/${id}/bookings`);
  return bookings;
};

export const resetPasswordEmail = async (email: string) => {
  await axios.post(`${API_URL}/user/password/email`, { email });
};

export const resetPasswordNew = async (token: string, password: string) => {
  await axios.post(`${API_URL}/user/password/new`, { token, password });
};
