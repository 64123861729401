import styled from "styled-components";
import { SM } from "../../utils/constants";

export const Wrapper = styled.div``;

export const Container = styled.div`
  box-shadow: var(--shadow);
  background: var(--white);
  padding: 16px;

  button {
    width: 100%;
    justify-content: center;
  }

  a {
    text-decoration: none !important;
  }

  label {
    a {
      text-decoration: underline !important;
    }
  }

  @media (max-width: ${SM}) {
    box-shadow: none;
  }
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 16px;

  @media (max-width: ${SM}) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    text-align: center;

    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
