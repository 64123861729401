import React, { FC } from "react";
import { Wrapper } from "./style";

interface Props {
  text?: string;
}

const RegularInput: FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <p>{text}</p>
    </Wrapper>
  );
};

export default RegularInput;
