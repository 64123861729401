import React, { FC, Suspense, useEffect, useState } from "react";
import { getBookings, getProfile } from "../../actions/users/creators";
import { User } from "../../actions/users/types";
import SearchBar from "../../components/SearchBar";
//HELMET
import HelmetMetaData from "../../utils/HelmetMetaData";
import {
  ShowWindowDimensions,
  SM,
  useWindowSize
} from "../../utils/mobileQueries";
import Balance from "./balance";
// COMPONENTS
import Perfil from "./perfil";
import { Wrapper, ResultSearchBar } from "./style";
import Transacciones from "./transacciones";

interface Props {}

const Agencia: FC<Props> = () => {
  const [user, setUser] = useState<User>();
  const [bookings, setBookings] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const _bookings = await getBookings(localStorage.getItem("userId") || "");
      const _filteredBookings =_bookings.filter((book: { airport: any; }) =>{
        if (book.airport !== null)
          return book
      })

      setBookings(_filteredBookings);
    };

    fetchData();
  }, []);

  useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("userId");
      const _user = await getProfile(userId!);
      setUser(_user);
    };

    fetchData();

    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  return (
    <Suspense fallback={<div />}>
      <HelmetMetaData
        quote={""}
        /*    description={"Soluciones a medida, tanto en arribos como en partidas"} */
      ></HelmetMetaData>

      {ShowWindowDimensions() > SM && (
        <ResultSearchBar>
          <SearchBar setInputFocus={setInputFocus} />
        </ResultSearchBar>
      )}

      <Wrapper>
        <Perfil user={user} />
        <Balance bookings={bookings} />
        <Transacciones bookings={bookings} />
      </Wrapper>
    </Suspense>
  );
};

export default Agencia;
