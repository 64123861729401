import styled from "styled-components";
import * as CSC from "../../utils/commonStyle";
import { XS } from "../../utils/constants";

export const Wrapper = styled.div<{ bg?: string }>`
  height: 400px;
  text-align: center;
  background: url(${props => props.bg}) no-repeat center center / cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 128px;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;

  @media (max-width: ${XS}) {
    height: auto;
    margin: 0;
    padding: 30px 20px;

    > div, a, button{
          display: block;
          width:100%;
    }
  }
`;

export const SupTitle = styled(CSC.LittleTitle)``;

export const Title = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;

  margin-bottom: 10px;

  @media (max-width: ${XS}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  margin-bottom: 35px;

  @media (max-width: ${XS}) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 25px;
    padding: 0 15px;
  }
`;
