import styled from "styled-components";
import check from "../../assets/icons/check-icon.svg";

import { SM, LG } from "../../utils/constants";


export const Wrapper = styled.div<{ selected: boolean }>`
  width: 100%;
  background: var(--white);
  border: 1px solid var(--secondary-gray-light);
  box-sizing: border-box;
  padding: 16px;
  color: var(--black);
  position: relative;
  transition: all 0.2s linear;

  h3 {
    color: var(--black);
    margin: 0;
  }
  ${props =>
    props.selected &&
    `
      pointer-events: auto;
        border: 1px solid var(--primary-color);
        box-shadow: var(--shadow);
        &::before {
            content: "";
            position: absolute;
            top: -13px;
            right: -13px;
            width: 26px;
            height: 26px;
            background: var(--primary-color);
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        &::after {
            content: "";
            background: url(${check}) center / contain no-repeat;
            width: 12px;
            height: 10px;
            position: absolute;
            top: -5px;
            right: -6px;
            display: inline-block;
        }

        @media(max-width:${SM}){

          &:before{
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
          }

          &:after{
          width: 15px;
          height: 14px;
          top: 18px;
          right: 17px;
          }
        
        }
    `};

  @media (max-width: ${SM}) {
    h3 {
      margin: 0 0 10px 0;
    }
    > div {
      display: block;

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;

        /* identical to box height */

        color: #000000;
      }
    }
  }
`;

export const PackageHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: max-content;
  align-items: center;
  column-gap: 16px;

  span {
    width: max-content;
  }
`;

export const vvipDiv = styled.div`
  margin-bottom: 2rem;
  text-decoration: underline;
`;

export const PackageInfo = styled.div<{
  borderBottom?: boolean;
  noService?: number;
}>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  width: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: ${props =>
    props.noService !== 0 ? "1px solid var(--secondary-gray-light)" : "0"};
  color: ${props => props.noService};

  h3 {
    font-weight: 700;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
    border-bottom: ${props =>
      props.borderBottom ? "1px solid rgba(0,0,0,0.1)" : 0};

    > div:first-of-type {
      display: flex;
      align-items: baseline;
    }
  }
`;

export const ServicesList = styled.ul<{ inline: boolean }>`
  list-style: none;
  ${props =>
    props.inline &&
    `
        grid-area: 2 / 1 / 3 / 3;
        margin-top: 16px;
        p {
            display: inline-block;
            margin-bottom: 0;
        }
    `};
  & + button {
    align-self: end;
  }

  @media (max-width: ${LG}) {
    max-width: 500px;
  }
`;

export const ServicesItem = styled.li`
  p {
    margin: 0;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const PriceContainer = styled.div`
  text-align: right;

  @media (max-width: ${SM}) {
    text-align: left;

    p {
      display: inline-block;
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
`;

export const Price = styled.p`
  font-size: 20px;
  line-height: 30px;
  & + p {
    color: var(--black);
    opacity: 0.5;
    margin: 0;
  }
`;

export const ExtraServices = styled.div`
  padding-top: 24px;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;
  > div {
    flex: 1;
    p:first-child {
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  p {
    margin-top: 0;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const MobileExtra = styled.div`
  /*   display: flex;
  align-items: center; */
  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p:first-child {
      font-weight: 500;
      margin-bottom: 0;

      font-size: 14px;
    }

    p:last-child {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.8;
      color: #000000;

      margin-bottom: 5px;
    }
  }
  p {
    margin-top: 0;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  label {
    margin-top: 25px;
  }
`;

export const SmallPrice = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 24px;
`;

export const CheckIcon = styled.span`
  width: 20px;
  height: 20px;
  background: var(--white);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  &::after {
    content: "";
    mask: url(${check}) center / contain no-repeat;
    background: var(--primary-color);
    width: 12px;
    height: 10px;
  }
`;

export const MobileButtonsWrap = styled.div`
  button {
    width: 100%;
    margin-top: 30px;

    display: flex;
    justify-content: center;
  }
`;

export const MobilePWrap = styled.div`
  @media (max-width: ${SM}) {
    p {
      text-align: left !important;
      opacity: 1 !important;
      font-size: 14px !important;
      line-height: 21px;

      span {
        opacity: 1 !important;
      }
    }
  }
`;

export const Agetitle = styled.div`
  p {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    opacity: 0.5 !important;

    letter-spacing: 0.15em !important;
    text-transform: uppercase !important;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;

  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  color: var(--black);
`;
