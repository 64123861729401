import React, { Suspense, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

// STYLES & IMAGES
import { Wrapper, Item } from "./style";
import PartidasIcon from "../../../assets/servicios-images/partidas.svg";
import ArribosIcon from "../../../assets/servicios-images/arribos.svg";
import ConexionesIcon from "../../../assets/servicios-images/conexion.svg";
import TransladosIcon from "../../../assets/servicios-images/translados.svg";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../../utils/AOSconfig";

interface Props {}

const Categorias: FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <div data-aos="fade-up">
          <Item>
            <img src={PartidasIcon} alt="Partidas imagen" />
            <h3>{t('nuestros_servicios.hero.tipo_de_reserva_partidas.partidas_titulo')}</h3>
            <h4>{t('nuestros_servicios.hero.tipo_de_reserva_partidas.partidas_subtitulo')}</h4>
            <p>{t('nuestros_servicios.hero.tipo_de_reserva_partidas.partidas_texto')}</p>
          </Item>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <Item>
            <img src={ArribosIcon} alt="Arribos imagen" />
            <h3>{t('nuestros_servicios.hero.tipo_de_reserva_arribos.arribos_titulo')}</h3>
            <h4>{t('nuestros_servicios.hero.tipo_de_reserva_arribos.arribos_subtitulo')}</h4>
            <p>{t('nuestros_servicios.hero.tipo_de_reserva_arribos.arribos_texto')}</p>
          </Item>
        </div>
        <div data-aos="fade-up">
          <Item>
            <img src={ConexionesIcon} alt="Conexiones imagen" />
            <h3>{t('nuestros_servicios.hero.tipo_de_reserva_conexiones.conexiones_titulo')}</h3>
            <h4>{t('nuestros_servicios.hero.tipo_de_reserva_conexiones.conexiones_subtitulo')}</h4>
            <p>{t('nuestros_servicios.hero.tipo_de_reserva_conexiones.conexiones_texto')}</p>
          </Item>
        </div>

        <div data-aos="fade-up" data-aos-delay="300">
          <Item>
            <img src={TransladosIcon} alt="Translados imagen" />
            <h3>{t('nuestros_servicios.hero.tipo_de_reserva_translados.conexiones_titulo')}</h3>
            <h4>{t('nuestros_servicios.hero.tipo_de_reserva_translados.conexiones_subtitulo')}</h4>
            <p>{t('nuestros_servicios.hero.tipo_de_reserva_translados.conexiones_texto')}</p>
          </Item>
        </div>
      </Wrapper>
    </Suspense>
  );
};

export default Categorias;
