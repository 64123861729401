import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {}

const HomepageCounters: React.FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    require("./purecounter.js");
  }, []);

  return (
    <section id="facts">
      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h3 className="h32">{ t("homepage.nuestros_numeros") }</h3>
          <p></p>
        </header>

        <div className="row counters">
          <div className="col-lg-4 col-6 text-center">
            <span data-purecounter-start="0" data-purecounter-end="60" data-purecounter-duration="1" className="purecounter"></span>
            <p>{ t("homepage.destinations") }</p>
          </div>

          <div className="col-lg-4 col-6 text-center">
            <span data-purecounter-start="0" data-purecounter-end="102" data-purecounter-duration="1" className="purecounter"></span>
            <p>{ t("homepage.products") }</p>
          </div>

          <div className="col-lg-4 col-12 text-center">
            <span data-purecounter-start="10700" data-purecounter-end="14527" data-purecounter-duration="1" className="purecounter"></span>
            <p>{ t("homepage.passengers") }</p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default HomepageCounters;
