import styled from "styled-components";
import { SM } from "../../utils/constants";

export const Wrapper = styled.div`
  height: 200px;
  background: rgba(209, 223, 229, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: rgba(0, 0, 0, 0.5);
    max-width: 460px;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${SM}) {
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
      max-width: 90%;
      line-height: 22px;
    }
  }
`;
