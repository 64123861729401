import React, { Suspense, FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Wrapper, BgImage, Content, Headline, ButtonWrap } from "./style";
import { Logo, LittleTitle, Title, Paragraph } from "../../utils/commonStyle";

import Cta from "../../components/Cta";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

//components

interface Props {}

const ConfirmacionReserva: FC<Props> = () => {
  useWindowSize();

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  const [agencia, setAgencia] = useState(false);

  return (
    <Suspense fallback={<div />}>
      {" "}
      {ShowWindowDimensions() > SM ? (
        <>
          <Wrapper>
            <BgImage />
            <Content>
              <Link to="/">
                <Logo role="img" aria-label="Jarvis logo" />
              </Link>
              <Headline>
                <LittleTitle>confirmar reserva</LittleTitle>
                <Title>Por favor confirme la reserva #193131</Title>
                <Paragraph>
                  Te enviaremos por mail una confirmación y un comprobante
                </Paragraph>
                <ButtonWrap>
                  <Cta
                    isRoute={true}
                    toRoute={"/"}
                    onClick={() => null}
                    theme="simpleTheme"
                  >
                    Cancelar Reserva
                  </Cta>
                  <Cta
                    onClick={() => null}
                    isRoute={true}
                    toRoute={"/"}
                    theme="blueDarkTheme"
                  >
                    Confirmar Reserva
                  </Cta>
                </ButtonWrap>
              </Headline>
            </Content>
          </Wrapper>
        </>
      ) : (
        <div>
          <Content>
            <Link to="/">
              <Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <Headline>
              <LittleTitle>confirmar reserva</LittleTitle>
              <Title>Por favor confirme la reserva #193131</Title>
              <Paragraph>
                Te enviaremos por mail una confirmación y un comprobante
              </Paragraph>
              <ButtonWrap>
                <Cta
                  isRoute={true}
                  toRoute={"/"}
                  onClick={() => null}
                  theme="simpleTheme"
                >
                  Cancelar Reserva
                </Cta>
                <Cta
                  isRoute={true}
                  toRoute={"/"}
                  onClick={() => null}
                  theme="blueDarkTheme"
                >
                  Confirmar Reserva
                </Cta>
              </ButtonWrap>
            </Headline>
          </Content>
        </div>
      )}
    </Suspense>
  );
};

export default ConfirmacionReserva;
