import axios from 'axios';
import { setLogin, setLogout } from '.';

const { REACT_APP_API_URL: API_URL } = process.env;

export const login = ({ username, password, setError }: any) => {
  return (dispatch: any) => {
    return axios
      .post(`${API_URL}/auth/login`, {
        email: username,
        password
      })
      .then((res) => {
        localStorage.setItem('userId', res.data.id);
        localStorage.setItem('token', res.data.token);
        dispatch(setLogin(true));
        setError(1);
      })
      .catch((error) => {
        setError(0);
        throw error;
      });
  };
};

export const logout = () => (dispatch: any) => {
  localStorage.removeItem('userId');
  localStorage.removeItem('token');
  dispatch(setLogout());
};

export const signup = (data: any) => {
  return (dispatch: any) => {
    return axios
      .post(`${API_URL}/auth/register`, data.payload)
      .then((res) => {
        localStorage.setItem('userId', res.data.id);
        localStorage.setItem('token', res.data.token);
        dispatch(setLogin(true));
        data.setRegisterError(1);
      })
      .catch((error) => {
        data.setRegisterError(0);
        throw error;
      });
  };
};
