import { keyframes } from "styled-components";


export const Pop = keyframes`
  0% {
/*     opacity: 0; */
    transform:scale(0)
  }
  60% {
/*     opacity: 0; */
    transform:scale(1.1)
  }
  100% {
 /*    opacity: 1; */
    transform:scale(0.9);

  }
`;

export const fadeIn = keyframes`
  0% {
/*     opacity: 0; */
    transform:scale(0)
  }
  80% {
/*     opacity: 0; */
    transform:scale(1.2)
  }
  100% {
 /*    opacity: 1; */
    transform:scale(0.9);

  }
`;

export const OpacityAnimation = keyframes`

0% {
    opacity:1;
    left: -100%
  }
  100% {
    opacity: 1;

    left: 0%;
  }


`;

export const FadeIn = keyframes`

0% {
    opacity:0;

  }
  100% {
    opacity: 1;
  
  }


`;

export const FadeOut = keyframes`

0% {
    opacity:1;

  }
  100% {
    opacity: 0;


  }


`;
