import React, { useEffect, Suspense, useState } from "react";

import ResultsDetailInfo from "../../components/Results/ResultsDetailInfo";
import BookingSearch from "../../components/Results/BookingSearch";

//HELMET
import HelmetMetaData from "../../utils/HelmetMetaData";
import { getAll } from "../../actions/airports/creators";
import { Setting } from "../../actions/settings/types";

interface Props {}

const ResultsDetail: React.FC<Props> = () => {
  useEffect(() => {
    const airport = localStorage.getItem("airport");
    if (!airport) {
      window.location.href = "/";
    }

    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const bookingStorage = localStorage.getItem("more_offers_booking") ? JSON.parse(localStorage.getItem("more_offers_booking")!) : undefined;

  //TODO api booking objecto
  const [booking, setBooking] = useState({
    airport: localStorage.getItem("airport"),
    airline: bookingStorage ? bookingStorage.airline : "-",
    airlineName: bookingStorage ? bookingStorage.airlineName : "",
    bookingType: bookingStorage ? 'Arribo' : "Partida",
    flightType: "-",
    date: "-",
    adultsCount: bookingStorage ? bookingStorage.adultsCount : 0,
    childsCount: bookingStorage ? bookingStorage.childsCount : 0,
    infantsCount: bookingStorage ? bookingStorage.infantsCount : 0,
    bagsCount: bookingStorage ? bookingStorage.bagsCount : 0,
    discount: undefined,
    pack: {
      packName: "",
      packPrice: "",
      packCurrency: "",
      packItems: [],
      services: [
        {
          selected: false,
          serviceName: "",
          serviceDescription: "",
          servicePrice: "",
          serviceCurrency: ""
        }
      ]
    },
    passengers: bookingStorage ? bookingStorage.passengers : []
  });

  return (
    <Suspense fallback={<div />}>
      <HelmetMetaData
        quote={""}
        description={
          "Los servicios dependerán del destino, terminal, horario y aerolínea"
        }
      ></HelmetMetaData>

      <ResultsDetailInfo bookingHook={{ booking, setBooking }} />
      <BookingSearch bookingHook={{ booking, setBooking }} />
    </Suspense>
  );
};

export default ResultsDetail;
