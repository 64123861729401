import styled from "styled-components";
import { SM, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.footer<{ menuMobile?: boolean }>`
  padding: 24px 120px;
  display: flex;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, auto);
  row-gap: 22px;
  grid-template-areas: "logo contact social" "copyright copyright copyright";
  border-top: 2px solid var(--gray-light);

  > span {
    grid-area: logo;
  }

  @media (max-width: ${SM}) {
    display: block;

    margin-top: ${props => (props.menuMobile ? "0" : "20px")};
    padding: ${props => (props.menuMobile ? "0 40px" : "24px 40px")};
    border-top: ${props => (props.menuMobile ? "0" : `2px solid #f6f6f6`)};
    > span {
      display: block;
      margin: 30px auto 30px auto;
    }
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1440px;
    margin: 0 auto;
    border-top: 0;
  }
`;

export const ContactContainer = styled.div`
  grid-area: contact;
  display: flex;
  align-items: center;
  justify-content: center;

  + div {
    grid-area: social;
  }

  @media (max-width: ${SM}) {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;

    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 20px;
      margin-right: 0 !important;
    }

    + div {
      width: 80%;
      margin: 30px auto 20px auto;
      display: block;
      text-align: center;

      a {
        display: inline-block;
      }
    }
  }
`;

export const Copyright = styled.p`
  grid-area: copyright;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  @media (max-width: ${SM}) {
    max-width: 190px;
    margin: 30px auto 20px auto;
    display: block;
    text-align: center;
  }
`;
