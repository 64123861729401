import styled from "styled-components";

import arrivalIcon from "../../../../assets/icons/arrival-icon.svg";
import departureIcon from "../../../../assets/icons/departure-icon.svg";
import { LG, SM } from "../../../../utils/constants";

const LabelColor = (status: any) => {
  if (status === "confirmada" || status === "realizada") {
    return "rgba(0, 189, 138, 0.8)";
  }
  if (status === "solicitada") {
    return "rgba(8, 197, 221, 0.8)";
  }
  if (status === "falta info") {
    return "rgba(255, 162, 0, 0.8)";
  }

  if (status === "pendiente de pago") {
    return "rgba(161, 116, 205, 0.8)";
  } else {
    return "rgba(242, 59, 49, 0.8)";
  }
};

export const Wrapper = styled.div`
  margin: 56px 120px;

  @media (max-width: ${LG}) {
    margin: 56px 20px;
  }
`;

export const Headline = styled.div<{ mobile?: boolean }>`
  display: flex;
  align-items: center;

  justify-content: ${props => (props.mobile ? "center" : "space-between")};

  a {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000 !important;

    vertical-align: middle;
    display: flex;
    align-items: center;
    text-decoration: underline !important;

    img {
      margin-right: 15px;
    }
  }
`;

export const NoUnderline = styled.div`
  a {
    text-decoration: none !important;
  }
`;

export const ReservaTabla = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  margin-top: 40px;

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.15em;
    text-transform: uppercase;

    color: #000000;

    opacity: 0.5;

    margin-bottom: 8px;
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    color: #000000;
  }
`;

export const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr;

  @media (max-width: ${SM}) {
    display: block;

    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }
`;

export const Head = styled.div`
  display: grid;
  /* repeat(8,1fr), repeat falla en safari*/
  grid-template-columns: 1.2fr 1.2fr 0.8fr 1fr 1fr 0.8fr 1.2fr 0.6fr 1fr 1fr;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 0.8fr 1fr 1fr 0.8fr 1.2fr 0.6fr 1fr 1fr;
`;

export const Label = styled.div<{ bg?: string; status?: string }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  height: ${props => (props.status === "pendiente de pago" ? "50px" : "20px")};

  letter-spacing: 0.03em;
  text-transform: uppercase;
  background-color: ${props => LabelColor(props.bg)};
  color: #ffffff;
  padding: 6px 8px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
`;

export const DetalleGrid = styled.div``;

export const InfoPasajerosWrap = styled.div``;

export const CodeContainer = styled.div`
  padding-top: 16px;
`;

export const ReservationIcon = styled.span<{ type: string }>`
  width: 40px;
  height: 40px;
  background: url(${props =>
      props.type === "arrival" ? arrivalIcon : departureIcon})
    center / contain no-repeat;
`;

export const AirportCode = styled.div<{ reserType: string }>`
  background: ${props =>
    props.reserType === "arrival"
      ? "rgba(20, 167, 250, 0.1)"
      : "rgba(255, 189, 0, 0.1)"};
  display: inline-flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  border-radius: 4px;
  span:first-child {
    margin-right: 4px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const ReservationsContainer = styled.div`
  padding-top: 16px;
  & + div {
    margin-top: 56px;
  }
`;

export const ReservationsItem = styled.div`
  margin-top: 40px;
  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 1px solid var(--secondary-gray-light);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  > div {
    max-width: 600px;
  }

  h3 {
    margin: 4px 0;
  }
  > span {
    margin-right: 16px;
  }

  @media (max-width: ${SM}) {
    display: block;

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 7px;
    }

    span {
      display: block;
      margin-left: -10px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 716px 1fr;
  column-gap: 56px;
`;

export const ButtonsContainer = styled.div`
  padding-top: 24px;
  button {
    font-weight: 500;
    &:first-child {
      margin-right: 32px;
    }
  }
`;

export const PaymentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 16px;
  border-bottom: 1px solid var(--secondary-gray-light);
  padding-bottom: 16px;
  margin-bottom: 16px;

  p:nth-child(even) {
    opacity: 1;
    text-align: right;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 32px;
  & + label {
    margin-bottom: 40px;
    span {
      opacity: 1;
    }
  }
`;

export const DropdownsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  > div {
    &:first-child > div {
      padding-left: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid var(--secondary-gray-light);
    }
  }

  @media (max-width: ${SM}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 0;
    margin-left: -10px;
    margin-top: 35px;
    margin-bottom: 35px;

    > div {
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(2) {
        border-right: 0;
      }
      &:nth-child(3) {
        margin-right: 10px;
      }
    }
  }
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteModalContainer = styled(FlexCenterContainer)`
  padding: 28px 32px 32px;
  flex: 1;
  flex-direction: column;

  > h2 + p {
    margin-bottom: 40px;
    opacity: 0.5;
    margin-top: 16px;
  }

  > p:first-child {
    width: 100%;
    margin-top: 0;
  }

  button:first-child {
    margin-right: 2px;
  }
`;

export const ServicePackageContainer = styled.div`
  display: grid;
  row-gap: 8px;
  padding: 28px 0 32px;
  width: 628px;
  > div {
    cursor: pointer;
  }
  > div > div:last-child {
    padding-bottom: 0;
    border: 0;
    padding-top: 8px;
    h3 + div + p {
      margin-top: 6px;
    }
  }
`;

export const DropWrap = styled.div`
  padding-left: 24px;
  padding-right: 32px;
  height: 100%;
  cursor: pointer;
  display: flex;

  flex-direction: column;
  justify-content: center;
  z-index: 0;

  &:first-child {
    padding-left: 12px;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.5;
  }
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  @media (max-width: ${SM}) {
    padding-left: 24px !important;

    padding-right: 10px !important;
  }
`;

export const TitleDetalle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: -10px;

  color: #000000;

  @media (max-width: ${SM}) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }
`;

export const PasajerosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${SM}) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
`;

export const PasajerosWrap = styled.div`
  margin-top: 45px;

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 30px;
  }

  @media (max-width: ${SM}) {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    > div {
      margin-bottom: 20px;
      > div {
        margin-right: 10px;
      }
    }
  }
`;

export const InfoAdicionalWrap = styled.div<{ contactoExist?: boolean }>`
  margin-left: 50px;

  > h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
  }

  @media (max-width: ${SM}) {
    margin-left: ${props => (props.contactoExist ? "0" : "-20px")};
    margin-right: ${props => (props.contactoExist ? "0" : "-20px")};
  }
`;

export const InfoBox = styled.div`
  background: #f6f9fa;
  padding: 0 15px 15px;
  overflow: hidden;

  button {
    width: 100%;
    text-align: center;
    display: inline;

    @media (max-width: ${SM}) {
      margin: -20px 0 20px;
    }
  }
`;

export const InfoTitle = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  padding: 60px 0 5px 0;

  @media (max-width: ${SM}) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    text-align: center;
  }
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.5;

  padding-bottom: 55px;

  @media (max-width: ${SM}) {
    opacity: 0.8;
    margin: 0 10px;
  }
`;

export const InputsModal = styled.div`
  padding: 30px;

  > h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
    margin-bottom: 20px;
  }

  @media (max-width: ${SM}) {
    h4 {
      text-align: center;
      position: relative;
      top: -1px;
      margin-bottom: 30px;
    }
  }
`;

export const InputWrap = styled.div`
  @media (max-width: ${SM}) {
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 25px;

  input {
    width: 94%;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
  }
`;

export const GridButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    margin-top: 40px;

    button {
      display: flex;
      justify-content: center;
    }
  }
`;

export const DatosContactoWrap = styled.div`
  padding: 15px 10px;
  overflow: hidden;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const DatosGrid = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    h6 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      color: #000000;

      opacity: 0.5;
    }

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      color: #000000;
    }
  }
`;

export const EditButtons = styled.div`
  display: flex;
  align-items: center;

  button {
    background: transparent;
    border: 0;
    cursor: pointer;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #000000;

    margin-right: 15px;
    margin-top: 15px;
  }
`;

export const ComentariosWrap = styled.div`
  h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 5px;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    overflow-wrap: break-word;
    color: #000000;
    max-width: 25vw;
  }

  @media (max-width: ${SM}) {
    p {
      max-width: 100%;
    }
  }
`;

export const LinkWrapMobile = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  a {
    text-decoration: none;
  }

  > div,
  button,
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    margin-bottom: 50px;
  }
`;

export const TablaCardMobile = styled.div<{ activeItem?: number }>`
  width: 100%;

  flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
`;

export const ListMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  span {
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    opacity: 0.6;
  }

  h5 {
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #000000;
    font-weight: 400;
  }
`;

export const Package = styled.div`
  width: 100%;
  background: var(--white);
  border: 1px solid var(--secondary-gray-light);
  box-sizing: border-box;
  padding: 16px;
  color: var(--black);
  position: relative;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;

  > .flex {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--secondary-gray-light);

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: var(--black);
    }
  }
`;

export const PriceWrap = styled.div`
  text-align: right;

  font-size: 20px;
  line-height: 30px;
`;

export const PacksWrap = styled.div``;

export const PackItem = styled.div`
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    margin-top: 8px;
  }
`;

export const ServicesWrap = styled.div``;

export const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  > div {
    max-width: 500px;
    margin-right: 10px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    margin-top: 8px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    color: var(--black);
    opacity: 0.5;
    margin: 0;
  }
`;

export const ServPrice = styled.div`
  font-weight: 500;
`;

export const Procedimiento = styled.div`
font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 15px;
    position: relative;
    color: var(--black)



`