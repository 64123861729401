import styled from "styled-components";
import {
  LG,
  SM,
  CustomDesktop,
  LargerScreens
} from "../../../../utils/constants";

export const Wrapper = styled.div`
  p {
    opacity: 0.5;
  }

  @media (max-width: ${SM}) {
    > p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;

      color: #000000;

      opacity: 0.8;
    }

    > h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      color: #000000;

      margin-top: -30px;
    }
  }
`;

export const SearchContainer = styled.div`
  background: var(--white);
  border: 1px solid rgba(209, 223, 229, 0.5);
  box-sizing: border-box;
  box-shadow: var(--shadow);
  height: 98px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding-right: 24px;
  width: calc(100% + 128px);
  margin-left: -64px;

  > div {
    &:not(:last-child):not(:nth-last-child(2)) {
      &::after {
        content: "";
        position: absolute;
        top: 24px;
        right: 0;
        background: var(--secondary-gray-light);
        height: calc(100% - 48px);
        width: 1px;
      }
    }
  }

  @media (max-width: ${LG}) {
    width: auto;
    margin: 0 auto;

    div {
      padding: 0 2px;
    }

    > div {
      span {
        font-size: 13px;
      }
    }
  }
`;

export const CtaContainer = styled.div`
  margin: 0 0 0 auto;
  margin-right: 20px;

  button {
    padding: 0 22px;
  }
`;

export const MobileWrap = styled.div``;

export const CaracterMobile = styled.ul`
  margin-top: 30px;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 15px;

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;

      color: #000000;

      opacity: 0.8;
    }
  }

  button {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    margin-top: 30px;
  }
`;

export const ModalTitle = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #000000;

  position: absolute;
  top: 29px;
  width: 100%;
  z-index: -1;
`;

export const TabWrapper = styled.div`
  margin-top: 60px;
  padding: 20px;
  /*  overflow:scroll; */

  > button {
    margin: 20vh auto 100px;
  }

  > div {
    &:first-child {
      padding-bottom: -5px !important;
    }
  }
`;

export const Tabs = styled.div`
  border-bottom: 1px solid #d1dfe678;

  padding-top: 20px;
`;

export const TabsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${SM}) {
    &:first-child {
      &:first-child {
        div {
          &:first-child {
            h4 {
              &:nth-child(2) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  > div > div {
    display: flex;
    align-items: center;
    margin-left: -10px;
  }

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 0.15em;
    text-transform: uppercase;

    color: #000000;

    opacity: 0.5;
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    padding-bottom: 15px;
  }
`;

export const TabsContent = styled.div``;

export const TabUl = styled.ul<{
  openTab: boolean;
  height?: number;
  overflow?: string;
}>`
  height: ${props => (props.openTab ? props.height + "px" : "0")};
  overflow: ${props => (props.overflow ? props.overflow : "hidden")};

  transition: ${props => props.theme.defaultTransition()};
  margin-bottom: ${props => (props.openTab ? "20px" : "0")};
`;

export const Li = styled.li<{ active?: number; index?: number }>`
  background-color: ${props =>
    props.active === props.index ? "rgba(209, 223, 229, 0.2);" : "transparent"};

  padding: 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const TabsIcon = styled.div`
  margin-bottom: 10px;
`;

export const TabsCaret = styled.div<{ openTab: boolean }>`
  transform: ${props => (props.openTab ? "rotate(180deg)" : "rotate(0)")};
  transition: ${props => props.theme.defaultTransition()};
  img {
    width: 15px;
  }
`;

export const TitleNoIcon = styled.div`
  margin-left: 10px;

  h4 {
    padding-top: 5px;
  }
`;

export const DateMobileWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 20px;

  > div > div {
    padding: 0;
    width: 85vw;

    > div > div {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const TimeMobileWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 20px;

  > div > div {
    padding: 0;
    width: 85vw;
  }
`;

export const SearchBarCustom = styled.div`
  margin-top: 40px;

  display: grid;
  grid-template-columns:/*  15vw 20vw 15vw 18vw 12vw 12vw; */ 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: ${CustomDesktop}) {
    grid-template-columns: /* 16vw 23vw 16vw 15vw 14vw 11vw; */ 1fr 1fr 1fr 1fr 1fr;

    > div > div {
      padding-left: 15px;
      padding-right: 10px;
    }
  }

  @media (min-width: ${LargerScreens}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 1377px;
    margin: 60px auto 0;
    padding: 30px 10px;
  }
  grid-template-rows: 63px;

  margin-left: -5vw;
  margin-right: -5vw;

  height: 120px;
  padding: 30px 5vw;

  background: #ffffff;
  border: 1px solid rgba(209, 223, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 20px 38px rgba(0, 0, 0, 0.09);

  > div {
    &:nth-child(1) {
      h5 {
      /*   width: 130px; */
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: ${CustomDesktop}) {
        /*   width: 120px; */
        }

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: -10px;
        }
      }
    }

    &:nth-child(2) {
      > div {
        span {
          top: 0;
        }
      }
      h5 {
        width: 210px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: -10px;
        }
      }
      h3 {
        margin-bottom: 4px;
      }
    }

    &:nth-child(3) {
      > div {
        h3 {
          margin-bottom: 4px;
        }

        span {
          /*       top: -2px !important; */
        }
      }
      h5 {
        width: 130px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: -10px;
        }
      }
    }

    &:nth-child(4) {
      > div {
        h3 {
          margin-bottom: 4px;
        }

        span {
          top: 0px !important;

          > div {
            position: relative;
            top: 3px;
          }
        }
      }
      h5 {
        width: 180px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: -10px;
        }
      }
    }

    &:nth-child(5) {
      > div {
        &:after {
          display: none !important;
          visibility: hidden !important;
          opacity: 0 !important;
        }

        h3 {
          margin-bottom: 4px;
        }

        span {
          top: 0px !important;
        }
      }
      h5 {
        width: 180px;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: -10px;
        }
      }
    }

    @media (max-width: ${CustomDesktop}) {
      > div {
        &:after {
          display: none !important;
          visibility: hidden !important;
          opacity: 0 !important;
        }
      }
    }
  }

  > div > div {
    &:first-of-type {
      display: block;
      white-space: pre;
      overflow: hidden;

      height: auto;

      &:after {
        content: "";
        width: 1px;
        height: 80%;
        position: absolute;
        top: 0;
        right: 17px;
        left: unset;
        bottom: 0;
        display: block;
        background-color: #d1dfe6;
        top: 50%;
        transform: translate(10px, -50%);
      }

      > div {
        /* 	display: block; */
        height: auto;
        display: flex;
      }

      > div > span {
        display: inline-block;

        position: relative;
        /* 		top: -11px; */
      }
    }
  }
`;

export const HeadlineTitle = styled.div`
  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;


export const AutocompleInput = styled.div`
  width: 90%;
  border: 1px solid red;
  display: flex;
  align-items: center;
  margin: 0 10px 10px 10px;
  padding:10px;

  border: 1px solid var(--secondary-gray-light);

  input {
    width: 100%;

    border: 0;
  }

  img {
    height: 15px;
    padding-right:5px;
  }
`;

export const LoadingAirlines = styled.div`

padding:10px 0 0 10px;


`

export const NotFound = styled.div`
  padding: 15px 20px 10px;

  span{
    line-height:23px;
    font-size:16px;
    display:inline-block;
  }

  a {
    display: block;
    font-size:16px;
    margin-top:5px;
    font-weight: 500;
    color: #000000;
  }
`;