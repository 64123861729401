import React, { Suspense, FC, useEffect, useState } from "react";
import { UserProvider } from "../../utils/userContext";
import * as SC from "./style";
import { useTranslation } from "react-i18next";

// COMPONENTS
import HomeAgencias from "../../components/HomeAgencias";
import HomepageHero from "../../components/HomepageHero";
import HomepageServices from "../../components/HomepageServices";
import HomepageAbout from "../../components/HomepageAbout";

//HELMET
import HelmetMetaData from "../../utils/HelmetMetaData";
import HomepageTestimonials from '../../components/HomepageTestimonials';
import HomepageCounters from '../../components/HomepageCounters';

interface Props {}

const Homepage: FC<Props> = () => {
  const [scrollActive, setScrollActive] = useState(0);

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }, [scrollActive]);

  window.onbeforeunload = function() {
    window.scrollTo(0, 0);
  };

  if (scrollActive === 1) {
    setTimeout(() => {
      setScrollActive(0);
    }, 50);
  }

  const [inputFocus, setInputFocus] = useState(false)





  const { t, i18n } = useTranslation();

  return (
    <Suspense fallback={<div />}>
      <HelmetMetaData
        description={
          "Estamos acá para mejorar la experiencia en cada uno de tus viajes y darte acceso a un servicio de primera clase antes y después de volar."
        }
        image={"../../assets/images/home-images/hero-desktop.jpg"}
      ></HelmetMetaData>
      <UserProvider value={scrollActive}>
        <SC.FullWidthWrapper>
          <SC.Wrapper>
            <HomepageHero value={scrollActive} inputFocus={inputFocus} setInputFocus={setInputFocus}/>
          </SC.Wrapper>
          <HomepageServices setScrollActive={setScrollActive} setInputFocus={setInputFocus} />
          <SC.Wrapper>
            <HomepageAbout />
          </SC.Wrapper>
          <HomepageCounters />
          <HomepageTestimonials />
          <HomeAgencias />
        </SC.FullWidthWrapper>
      </UserProvider>
    </Suspense>
  );
};

export default Homepage;
