import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const createBooking = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/booking`, payload);
  return data;
};

export const confirmBooking = async (bookingId: string, token: string, status: string) => {
  const { data } = await axios.put(`${API_URL}/booking/confirmation/${bookingId}?token=${token}&status=${status}`);
  return data;
};