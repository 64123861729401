import React, { FC, useEffect, useState } from "react";
import { Wrapper, Input } from "./style";
import './styles.css';

interface Props {
  suggestions: any[];
  suggestionKey: string;
  placeholder?: string;
  label?: string;
  value?: string;
  name?: any;
  onChange2?: any;
  filled?: any;
  onClick2?: any;
}

const AutocompleteInput: FC<Props> = ({
  suggestions,
  suggestionKey,
  placeholder,
  label,
  name,
  value,
  onChange2,
  onClick2,
  filled,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    setUserInput(value || '');
  }, [value]);

  const onChange = (e: any) => {
    const _userInput = e.currentTarget.value;

    const _filteredSuggestions = suggestions.filter((suggestion: any) =>
      suggestion[suggestionKey].toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setFilteredSuggestions(_filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(_userInput);

    if (onChange2) {
      onChange2(e);
    }
  }

  const onClick = (suggestion: any) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion[suggestionKey]);

    if (onClick2) {
      onClick2(suggestion);
    }
  }

  const onBlur = () => {
    setShowSuggestions(false);
  }

  return (
    <>
      <Wrapper onBlur={onBlur}>
        <label>{label}</label>
        <Input
          autoComplete={"off"}
          name={name}
          onChange={onChange}
          value={userInput}
          placeholder={placeholder}
          filled={filled}
        ></Input>
      {showSuggestions && userInput && filteredSuggestions.length ?
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <li key={index} onMouseDown={() => onClick(suggestion)}>
                {suggestion[suggestionKey]}
              </li>
            );
          })}
        </ul>
      : ''}
      </Wrapper>
    </>
  );
};

export default AutocompleteInput;
