import React from "react";
import * as SC from "./style";

interface Props {
    checked?: boolean;
    onClick?: () => void;
}

const Toogle: React.FC<Props> = ({checked = false, onClick}) => {
  return (
    <SC.Wrapper onClick={onClick}>
      <SC.ToggleContent checked={checked} />
      <SC.Switch />
    </SC.Wrapper>
  );
};

export default Toogle;
