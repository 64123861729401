import styled from "styled-components";
import { SM } from "../../../../utils/constants";

export const Wrapper = styled.div`
  background: #f6f9fa;
  padding: 60px;
  margin-top: 60px;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000000;

    margin-bottom: 20px;

    border:1px solid red;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    opacity: 0.8;

    max-width: 460px;
    margin: 0 auto;

    @media (max-width: ${SM}) {
      max-width: unset;
      
      font-size: 14px;
      line-height: 21px;
    }
  }
  @media (max-width: ${SM}) {
    padding: 60px 20px;
    margin-bottom:-100px;
    margin-top: 30px;
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom:5px;
    }
  }
`;
