import React from "react";
import * as SC from "./style";

interface Props {
  name: string;
  value: string;
  onChange: any;
  placeholder: string;
  autoComplete?:string;
  error?: boolean;
}

const InputText: React.FC<Props> = ({ name, value, onChange, placeholder, autoComplete, error }) => {


  return (
    <SC.Wrapper>
      <SC.Input
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={e => onChange(e.target.value)}
        autoComplete={autoComplete}
        style={{ color: error ? 'red' : 'inherit', borderColor: error ? 'red' : 'inherit' }}
      />
    </SC.Wrapper>
  );
};

export default InputText;
