import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, SupTitle, Title, Text } from "./style";
import Cta from "../Cta";

import Bg from "../../assets/images/home-images/home-agencia.jpg";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../utils/AOSconfig";

interface Props {}

const HomeAgencias: React.FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-delay="200">
      <Wrapper bg={Bg}>
        <SupTitle>{t('homepage.agencia_de_viaje.volanta')}</SupTitle>
        <Title>{t('homepage.agencia_de_viaje.titulo')}</Title>
        <Text>{t('homepage.agencia_de_viaje.parrafo')}</Text>
        <Cta
          isRoute={true}
          toRoute={"/registro"}
          onClick={() => null}
          theme="blueDarkTheme"
        >
          {t('homepage.agencia_de_viaje.boton')}
        </Cta>
      </Wrapper>{" "}
    </div>
  );
};

export default HomeAgencias;
