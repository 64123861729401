import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const createPassenger = (payload: any) => {
  return axios.post(`${API_URL}/passanger`, payload);
};

export const getPassengers = async (userId: string) => {
  const { data } = await axios.get(`${API_URL}/passanger/${userId}`)
  return data;
};
