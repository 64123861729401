import styled from "styled-components";
import { LargerScreens, SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  margin: 56px 120px 65px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px 50px;
  margin-top: -70px;

  @media(max-width:${SM}){
    margin:0 20px;
    margin-top:70px;
    grid-template-columns: 1fr;
    grid-gap: 50px;
 
  }

  @media(min-width:${LargerScreens}){
    margin:100px auto 65px auto;
    max-width:1270px;
  }
`;

export const Item = styled.div`
  max-width: 500px;

  @media (min-width: ${LargerScreens}) {
    max-width: 700px;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    margin: 20px 0 5px 0;
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.8;
    margin: 0 0 20px 0;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    opacity: 0.8;
  }
`;
