import React, { Suspense, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, ChildrenWrap, Porcentage } from "./style";

import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// COMPONENTS

interface Props {
  icon?: any;
  percentageProps?: number;
}

const Porcentaje: FC<Props> = ({ icon, percentageProps }) => {
  const { t } = useTranslation();

  const [percentage, setPercentage] = useState(0);

  


  useEffect(() => {
    setTimeout(() => {
      if (percentage !== percentageProps) {
        setPercentage(percentage + 1);
      }
    }, 30);
  }, [percentage]);

  

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth={7}
          styles={buildStyles({
            strokeLinecap: "round",
            pathTransitionDuration: 1,
            pathColor: `#FFBD00`,
            textColor: "#f88",
            trailColor: "#14A7FA",
            backgroundColor: "#3e98c7"
          })}
        >
          <ChildrenWrap>
            <img src={icon} alt="partidas" />
            <h4>{t('pantalla_agencia.balance.Partidas')}</h4>
            <Porcentage>{percentage}%</Porcentage>
          </ChildrenWrap>
        </CircularProgressbarWithChildren>
      </Wrapper>
    </Suspense>
  );
};

export default Porcentaje;
