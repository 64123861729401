import React, { useEffect } from "react";

import "../../assets/swiper/swiper-bundle.min.css";
import "../../assets/style.css";
import { useTranslation } from 'react-i18next';

interface Props {}

const HomepageTestimonials: React.FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    require("./main.js");
  }, []);

  return (
    <section id="testimonials" className="section-bg">
      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h3>{ t("homepage.testimonios") }</h3>
        </header>

        <div className="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="testimonial-initials">
                  DR
                </div>
                <h3>Diego Rodríguez</h3>
                <h4>Argentina</h4>
                <p>
                  <img src="/img/quote-sign-left.png" className="quote-sign-left" alt="" />
                  Contrate el servicio para que reciban a mis padres en el aeropuerto de Ezeiza, los asistieron en todo momento y salieron más rápido de lo esperado
                  <img src="/img/quote-sign-right.png" className="quote-sign-right" alt="" />
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="testimonial-initials">
                  EB
                </div>
                <h3>Emily Brown</h3>
                <h4>UK</h4>
                <p>
                  <img src="/img/quote-sign-left.png" className="quote-sign-left" alt="" />
                  I allways include Meet & Greet service for my VIP paxs, it's a MUST
                  <img src="/img/quote-sign-right.png" className="quote-sign-right" alt="" />
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="testimonial-initials">
                  SD
                </div>
                <h3>Sara Dallmann</h3>
                <h4>Germany</h4>
                <p>
                  <img src="/img/quote-sign-left.png" className="quote-sign-left" alt="" />
                  I had very little time between two flights; I couldn't have done it without your help. Great service!
                  <img src="/img/quote-sign-right.png" className="quote-sign-right" alt="" />
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="testimonial-initials">
                  KG
                </div>
                <h3>Keith G.</h3>
                <h4>United States</h4>
                <p>
                  <img src="/img/quote-sign-left.png" className="quote-sign-left" alt="" />
                  Amazing service, thank you Marcos for helping us
                  <img src="/img/quote-sign-right.png" className="quote-sign-right" alt="" />
                </p>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="testimonial-initials">
                  PR
                </div>
                <h3>Paolo Ramos</h3>
                <h4>Perú</h4>
                <p>
                  <img src="/img/quote-sign-left.png" className="quote-sign-left" alt="" />
                  Nuestras maletas no llegaron en el vuelo, David nos asistió con el reclamo y el equipaje fue enviado a nuestro hotel al día siguiente. Muy buena atención
                  <img src="/img/quote-sign-right.png" className="quote-sign-right" alt="" />
                </p>
              </div>
            </div>

          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  );
};

export default HomepageTestimonials;
