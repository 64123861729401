import React, { useContext, useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../utils/userContext";

// ASSETTS

import HeroImage from "../../assets/images/home-images/hero-desktop.jpg";
import HeroImageXs from "../../assets/images/home-images/mobile-hero-home.jpg";

// COMPONENTS
import SearchBar from "../SearchBar";
import Cta from "../Cta";
import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";
import Modal from "../Modal";

// STYLES
import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../utils/AOSconfig";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { addEmailToNewsletter } from '../../actions/newsletter/creators';
import moment from "moment";

interface Props {
  value?: number;
  inputFocus?:boolean;
  setInputFocus?:any;
}

const HomepageHero: React.FC<Props> = ({ value , inputFocus, setInputFocus}) => {
  const [modalShow, setModalShow] = React.useState(false);

  const { t } = useTranslation();

  useWindowSize();

  const myRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
 ;

  if (value === 1) {
    window.scrollTo({ behavior: "smooth", top: myRef.current.offsetTop });
  }

  useEffect(() => {
    const newsletter = localStorage.getItem('NEWLETTER_MODAL');
    const newsletterDate = localStorage.getItem('NEWLETTER_MODAL_DATE');

    try {
      if (newsletterDate && moment(newsletterDate).add(15, 'd').isSameOrBefore(new Date(), 'day')) {
        setModalShow(true);
      } else if (!newsletter || newsletter !== 'false') {
        setModalShow(true);
      }
    } catch (_) {}
  }, [])

  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [validNewsletterEmail, setValidNewsletterEmail] = useState(false);

  const handleChangeNewsletterEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setValidNewsletterEmail(re.test(email));

    setNewsletterEmail(email);
  }

  const handleOnClickNewsletter = async () => {
    await addEmailToNewsletter(newsletterEmail);
    setModalShow(false);
    localStorage.setItem('NEWLETTER_MODAL_DATE', new Date().toISOString());
    localStorage.setItem('NEWLETTER_MODAL', 'false');
  }


  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <>
      <Modal
        maxWidth="850px"
        isOpen={modalShow}
        noBackground
        closeModal={() => {
          setModalShow(false);
          localStorage.setItem('NEWLETTER_MODAL_DATE', new Date().toISOString());
          localStorage.setItem('NEWLETTER_MODAL', 'false');
        }}
      >
        <SC.NewsletterLogo />
        <SC.NewsletterBanner>
          <SC.NewsletterTitle>{t('homepage.newsletter.title')}</SC.NewsletterTitle>
          <SC.NewsletterSubtitle>{t('homepage.newsletter.subtitle')}</SC.NewsletterSubtitle>
        </SC.NewsletterBanner>
        <SC.NewsletterWrapper>
          <SC.NewsletterInput type="email" value={newsletterEmail} placeholder={t('homepage.newsletter.input')} onChange={(e) => handleChangeNewsletterEmail(e.target.value)} />
          <SC.NewsletterButton disabled={!validNewsletterEmail} onClick={handleOnClickNewsletter}>{t('homepage.newsletter.button')}</SC.NewsletterButton>
        </SC.NewsletterWrapper>
      </Modal>
      <SC.Wrapper>
        <SC.Container>
          <SC.TitleContainer>
            <div data-aos="fade-up">
              <CSC.LittleTitle>{t("homepage.servicios_vip")}</CSC.LittleTitle>
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              <CSC.BigTitle>
                {/*      Soy Jarvis, tu asistente personal, y puedo mejorar tu experiencia en muchos aeropuertos! */}
                {t("homepage.conoce_jarvis")}
              </CSC.BigTitle>
            </div>
          </SC.TitleContainer>
          <SC.SearchBarContainer>
            <SearchBar inputFocus={inputFocus} setInputFocus={setInputFocus} />
            {/*    <Cta onClick={() => {}}>
              Buscar
              <CSC.RightArrow />
            </Cta> */}
          </SC.SearchBarContainer>
          <SC.ImageHero
            bg={ShowWindowDimensions() > SM ? HeroImage : HeroImageXs}
            role="img"
            aria-label="Hero"
          />
          {ShowWindowDimensions() > SM && (
            <CSC.LogoFull role="img" aria-label="Jarvis logo" />
          )}

          <div ref={myRef}></div>
        </SC.Container>
      </SC.Wrapper>
      <CookieConsent
        location="bottom"
        buttonText={t('homepage.cookies_accept')}
        cookieName="jarvis_cookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        {t('homepage.cookies')}
      </CookieConsent>
    </>
  );
};

export default HomepageHero;
