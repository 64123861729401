import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

//COMPONENTS
import EmptyResult from "../../../EmptyResult";
import ServicePackage from "../../../ServicePackage";

//STYLES
import * as SC from "./style";
import * as CSC from "../../../../utils/commonStyle";

import { Pack } from "../../../../actions/packs/types";
import { getPacksByAirport } from "../../../../actions/packs/creators";
import BookingSearch from "../BookingSearch";

interface Props {
  servicio?: any;
  setServicio?: any;
  bookingHook: {
    booking: any;
    setBooking: any;
  };
  isAepVvipAvailable?: boolean;
}

export interface IPackageHandled extends Pack {
  selected: boolean;
}

const Services: React.FC<Props> = ({
  servicio,
  setServicio,
  isAepVvipAvailable,
  bookingHook: { booking, setBooking }
}) => {
  const { t } = useTranslation();

  const [packs, setPacks] = useState<IPackageHandled[]>([]);
  const [filteredPacks, setFilteredPacks] = useState<IPackageHandled[]>([]);

  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("airport") || "{}");

    const fetchData = async () => {
      const __packs: Pack[] = await getPacksByAirport(_id);

      setPacks(
        __packs.map(item => ({
          ...item,
          selected: false,
          services: item.services.map(extra => ({ ...extra, selected: false }))
        }))
      );
      // setServicio(__packs[0]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredPacks(
      packs.filter(({ bookingTypes }: { bookingTypes: string[] }) =>
        bookingTypes.includes(booking.bookingType)
      )
    );
  }, [packs, booking]);

  const onClick = (id: string) => {
    let newPacks = [...packs];
    newPacks = newPacks.map(item => ({
      ...item,
      selected: item._id === id ? true : false,
      services: item.services.map(service => ({ ...service, selected: false }))
    }));

    let newServicio = newPacks.find(item => item._id === id);

    setPacks(newPacks);
    setServicio(newServicio);
    setBooking({ ...booking, pack: { ...newServicio, services: [] } });
  };

  const onToggle = (index: number, serviceId: string) => {
    let newServices = servicio;
    if (!servicio || (servicio && servicio._id !== serviceId)) {
      let newServicio = packs.find(item => item._id === serviceId);
      newServices = { ...newServicio, selected: true };
    }

    newServices = {
      ...newServices,
      services: newServices.services
        ? newServices.services.map((item: any, i: number) => {
            return {
              ...item,
              selected: i === index ? !item.selected : item.selected
            };
          })
        : []
    };

    let newPacks = [...packs].map((item: any) => {
      return {
        ...(item._id === newServices._id ? newServices : item),
        selected: item._id === newServices._id ? true : false,
        services:
          item._id !== newServices._id
            ? item.services.map((service: any) => ({
                ...service,
                selected: false
              }))
            : newServices.services
      };
    });

    setServicio(newServices);
    setPacks(newPacks);

    let newBooking = {
      ...booking,
      pack: {
        ...newServices,
        services: newServices.services
          .filter((item: any) => item.selected)
          .map((el: any) => {
            const aux = { ...el };
            return aux;
          })
      }
    };
    setBooking(newBooking);
  };

  return (
    <SC.Wrapper>
      {filteredPacks.length > 0 && (
        <>
          <CSC.MediumTitle>{t("resultados.productos.titulo")}</CSC.MediumTitle>
          <CSC.Paragraph>{t("resultados.productos.subtitulo")}</CSC.Paragraph>
        </>
      )}

      <SC.ServicesResults empty={filteredPacks?.length ? false : true}>
        {filteredPacks?.length ? (
          filteredPacks.map((item, index) => (
            <Fragment key={item._id}>
              <ServicePackage
                packName={item.packName}
                isAepVvipAvailable={isAepVvipAvailable}              
                pack={item}
                onClick={() => [onClick(item._id)]}
                selected={item.selected || false}
                packageNumber={index + 1}
                withBtn
                setToggle={index => {
                  onToggle(index, item._id);
                }}
              />
            </Fragment>
          ))
        ) : (
          <EmptyResult
            title={t("resultados.productos.sin_servicio_titulo")}
            subtitle={t("resultados.productos.sin_servicio_subtitulo")}
            jarvisLink={true}
          />
        )}
      </SC.ServicesResults>
    </SC.Wrapper>
  );
};

export default Services;
