import styled from "styled-components";

export const Wrapper = styled.div<{ visible?: boolean }>`
  margin: 0 0 20px;
  opacity: ${props => (props.visible ? 1 : 0)};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  transition: ${props => props.theme.defaultTransition()};
  line-height: 24px;
  font-size: 14px;

  text-align: left;
`;
