import styled from "styled-components";
import { XS, LargerScreens } from "../../utils/constants";

export const FullWidthWrapper = styled.div`
  @media (max-width: ${XS}) {
    overflow: hidden;
  }
`;

export const Wrapper = styled.div`
  padding: 9px 120px 0;

  @media (max-width: ${XS}) {
    padding: 9px 20px 0;
    overflow: initial;

    width: calc(100% - 30px);
    margin: 0 auto;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;
