import React, { Suspense, FC, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  Headline,
  Lista,
  Grid,
  Titles,
  CUIT,
  Address,
  InputsModal,
  InputWrap,
  GridButtons,
  SelectWrap,
  FormlogIn,
  UploadPhoto,
  PhotoShow,
  PhotoHolder,
  MobileWrap,
  FormDisplayMobile,
  VerMas,
  ListaForm,
  ImageErrorMsg
} from "./style";
import Cta from "../../../components/Cta";
import Modal from "../../../components/Modal";
import RegularInput from "../../../components/RegularInput";
import SimpleSelect from "../../../components/SimpleSelect";
import CameraIcon from "../../../assets/images/camera.svg";
import InputError from "../../../components/InputError";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";
import { User } from "../../../actions/users/types";
import { update } from '../../../actions/users/creators';

interface Props {
  user: User | undefined;
}

const Perfil: FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation();
  useWindowSize();

  const [openModal, setOpenModal] = useState(false);

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [emailName, setEmailName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [showList, setShowList] = useState(false);
  const [height, setHeight] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [updated, setUpdated] = useState(false);

  //select value
  const [selectedValue, setSelectedValue] = useState("al contado");
  const handleChange = (e: { value: any }) => {
    setSelectedValue(e.value);
  };

  useEffect(() => {
    setSelectedValue(user?.paymentCondition!);

    setInfoAgencia({
      nombre: user?.companyName,
      cuit: user?.taxId,
      direccion: user?.address,
      operaciones: user?.operationContact,
      administrativo: user?.adminContact,
      tel: user?.phone,
      pago: selectedValue
    });

    setFormData({
      nombre: user?.companyName,
      cuit: user?.taxId,
      direccion: user?.address,
      operaciones: user?.operationContact,
      administrativo: user?.adminContact,
      tel: user?.phone,
      pago: selectedValue
    });

    setFileImage(user?.picture);
  }, [user]);

  const [infoAgencia, setInfoAgencia] = useState<any>({
    nombre: user?.companyName,
    cuit: user?.taxId,
    direccion: user?.address,
    operaciones: user?.operationContact,
    administrativo: user?.adminContact,
    tel: user?.phone,
    pago: user?.paymentCondition
  });

  const [formData, setFormData] = useState({
    nombre: user?.companyName,
    cuit: user?.taxId,
    direccion: user?.address,
    operaciones: user?.operationContact,
    administrativo: user?.adminContact,
    tel: user?.phone,
    pago: user?.paymentCondition
  });

  const [fileImage, setFileImage] = useState();
  const [triggerImage, setTriggerImage] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const userId = localStorage.getItem('userId')!;
      const data = {
        companyName: infoAgencia.nombre,
        taxId: infoAgencia.cuit,
        address: infoAgencia.direccion,
        operationContact: infoAgencia.operaciones,
        adminContact: infoAgencia.administrativo,
        phone: infoAgencia.tel,
      }
      await update(userId, data);

      if(updated) {
        window.location.reload();
      }
    };

    fetch();
  }, [infoAgencia]);

  const imageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files && e.target.files[0];



      if (file.size > 1048576) {
        setImageError(true);
      } else {
        getBase64(file).then((picture: any) => {
          setTimeout(async () => {
            const userId = localStorage.getItem('userId')!;
            await update(userId, { picture });
            setFileImage(picture!);
          }, 400);
        });
        setImageError(false);
      }
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    handleInputChange(email, 0, "");
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  const myRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (myRef && myRef.current) {
      setHeight(myRef && myRef.current && myRef.current.offsetHeight);
    }
  }, [height, showList]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openModal]);

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);



  return (
    <Suspense fallback={<div />}>
      {ShowWindowDimensions() >= SM ? (
        <Wrapper>
          <Headline>
            <Grid>
              <div>
                <UploadPhoto>
                  {fileImage ? (
                    <>
                      <PhotoShow
                        style={{ background: `url(${fileImage})` }}
                      ></PhotoShow>
                      <span>
                        <input
                          type={"file"}
                          accept="image/*"
                          onChange={e => {
                            imageUpload(e);
                            setTriggerImage(!triggerImage);
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <PhotoHolder>
                        <input
                          type={"file"}
                          accept="image/*"
                          onChange={e => {
                            imageUpload(e);
                            setTriggerImage(!triggerImage);
                          }}
                        />
                        <img src={CameraIcon} alt="change pic" />
                      </PhotoHolder>
                      <span>
                        <input
                          type={"file"}
                          accept="image/*"
                          onChange={e => {
                            imageUpload(e);
                            setTriggerImage(!triggerImage);
                          }}
                        />
                      </span>
                    </>
                  )}
                </UploadPhoto>
                {/*          <ProfilePic /> */}
                <Titles>
                  <h3>{infoAgencia.nombre}</h3>
                  <CUIT>
                    {t("registro_agencia.CUIT")}
                    {infoAgencia.cuit}
                  </CUIT>
                  <Address>{infoAgencia.direccion}</Address>
                </Titles>
              </div>
              <div>
                <Cta
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  isRoute={false}
                  toRoute={"inicio-sesion"}
                  theme="lightTheme"
                >
                  {t("pantalla_agencia.boton_editar_perfil")}
                </Cta>
              </div>
            </Grid>
          </Headline>

          <ImageErrorMsg show={imageError}>
            {lang === "EN" ? "Please upload an image that is 1MB or less" : "Por favor subí una imagen que pese 1MB o menos"}

          </ImageErrorMsg>

          <Lista>
            {user?.type !== 'USUARIO_FINAL' && <><li>
              <h5>{t("pantalla_agencia.operaciones")}</h5>
              <h6>
                <a href={`mailto:${infoAgencia.operaciones}`}>
                  {infoAgencia.operaciones}
                </a>
              </h6>
            </li>
            <li>
              <h5>{t("pantalla_agencia.Administrativo")}</h5>
              <h6>
                <a href={`mailto:${infoAgencia.administrativo}`}>
                  {infoAgencia.administrativo}
                </a>
              </h6>
            </li></>}
            <li>
              <h5>{t("pantalla_agencia.telefono")}</h5>
              <h6>
                <a href={`tel:${infoAgencia.tel}`}>{infoAgencia.tel}</a>
              </h6>
            </li>
            <li>
              <h5>{t("pantalla_agencia.pago")}</h5>
              <h6>{selectedValue}</h6>
            </li>
          </Lista>
        </Wrapper>
      ) : (
        <MobileWrap>
          <UploadPhoto>
            {fileImage ? (
              <>
                <PhotoShow
                  style={{ background: `url(${fileImage})` }}
                ></PhotoShow>
                <span>
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={e => {
                      imageUpload(e);
                      setTriggerImage(!triggerImage);
                    }}
                  />
                </span>
              </>
            ) : (
              <>
                <PhotoHolder>
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={e => {
                      imageUpload(e);
                      setTriggerImage(!triggerImage);
                    }}
                  />
                  <img src={CameraIcon} alt="change pic" />
                </PhotoHolder>
                <span>
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={e => {
                      imageUpload(e);
                      setTriggerImage(!triggerImage);
                    }}
                  />
                </span>
              </>
            )}
          </UploadPhoto>
          <ImageErrorMsg show={imageError}>
            {lang === "EN" ? "Please upload an image that is 1MB or less" : "Por favor subí una imagen que pese 1MB o menos"}

          </ImageErrorMsg>
          <FormDisplayMobile>
            <div>
              <h3>{infoAgencia.nombre}</h3>
              <p>
                {t("registro_agencia.CUIT")}
                {infoAgencia.cuit}
              </p>
              <p>{infoAgencia.direccion}</p>
            </div>

            <ListaForm height={height} showList={showList}>
              <div ref={myRef}>
                <li>
                  <h5>{t("pantalla_agencia.operaciones")}</h5>
                  <h6>
                    <a href={`mailto:${infoAgencia.operaciones}`}>
                      {infoAgencia.operaciones}
                    </a>
                  </h6>
                </li>
                <li>
                  <h5>{t("pantalla_agencia.Administrativo")}</h5>
                  <h6>
                    <a href={`mailto:${infoAgencia.administrativo}`}>
                      {infoAgencia.administrativo}
                    </a>
                  </h6>
                </li>
                <li>
                  <h5>{t("pantalla_agencia.telefono")}</h5>
                  <h6>
                    <a href={`tel:${infoAgencia.tel}`}>{infoAgencia.tel}</a>
                  </h6>
                </li>
                <li>
                  <h5>{t("pantalla_agencia.pago")}</h5>
                  <h6>{selectedValue}</h6>
                </li>
              </div>
            </ListaForm>

          </FormDisplayMobile>


          <VerMas showList={showList} onClick={() => setShowList(!showList)}>
            {!showList
              ? t("pantalla_agencia.mis_trasacciones.mobile_ver_mas_info")
              : t("pantalla_agencia.mis_trasacciones.mobile_ver_menos_info")}
          </VerMas>
          <Cta
            onClick={() => {
              setOpenModal(true);
            }}
            isRoute={false}
            theme="lightTheme"
          >
            {t("pantalla_agencia.boton_editar_perfil")}
          </Cta>
        </MobileWrap>
      )}

      <Modal
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        maxWidth={"700px"}
        fullscreen={true}
      >
        <InputsModal>
          <h4>{t("pantalla_agencia.datos_de_perfil")}</h4>
          <FormlogIn>
            <InputWrap>
              <RegularInput
                label={t("registro_agencia.nombre_de_la_empresa")}
                type={"text"}
                placeholder={t("registro_agencia.nombre_de_la_empresa_input")}
                onChange={handleInputChange}
                name={"nombre"}
                value={formData["nombre"]}
                filled={formData["nombre"] !== "" ? "filled" : "empty"}
              />
            </InputWrap>

            <InputWrap>
              <RegularInput
                label={t("registro_agencia.CUIT")}
                type={"text"}
                placeholder={t("registro_agencia.CUIT_input")}
                name={"cuit"}
                onChange={handleInputChange}
                value={formData["cuit"]}
                filled={formData["cuit"] !== "" ? "filled" : "empty"}
              />
            </InputWrap>

            <InputWrap>
              <RegularInput
                label={t("registro_agencia.direccion")}
                type={"text"}
                placeholder={t("registro_agencia.direccion")}
                name={"direccion"}
                onChange={handleInputChange}
                value={formData["direccion"]}
                filled={formData["direccion"] !== "" ? "filled" : "empty"}
              />
            </InputWrap>

            <InputWrap>
              <RegularInput
                label={t('registro_agencia.telefono_emergencia')}
                type={"text"}
                placeholder={t('registro_agencia.telefono_emergencia_input')}
                name={"tel"}
                onChange={handleInputChange}
                value={formData["tel"]}
                filled={formData["tel"] !== "" ? "filled" : "empty"}
              />
            </InputWrap>

            {user?.type !== 'USUARIO_FINAL' && <InputWrap>
              <RegularInput
                label={t("registro_agencia.contacto_operaciones")}
                type={"text"}
                name={"operaciones"}
                onChange={handleValidMail}
                errorMail={validateEmail}
                value={formData.operaciones}
                placeholder={t("registro_agencia.contacto_operaciones_input")}
                emailName={emailName}
                filled={formData["operaciones"] !== "" ? "filled" : "empty"}
              />
              {!validateEmail && emailName === "operaciones" && (
                <InputError text={t("generales.error_email")} />
              )}
            </InputWrap>}

            {user?.type !== 'USUARIO_FINAL' && <InputWrap>
              <RegularInput
                label={t("registro_agencia.contacto_administrativo")}
                type={"text"}
                placeholder={t(
                  "registro_agencia.contacto_administrativo_input"
                )}
                name={"administrativo"}
                onChange={handleValidMail}
                errorMail={validateEmail}
                value={formData.administrativo}
                emailName={emailName}
                filled={formData["administrativo"] !== "" ? "filled" : "empty"}
              />
              {!validateEmail && emailName === "administrativo" && (
                <InputError text={t("generales.error_email")} />
              )}
            </InputWrap>}

            <SelectWrap>
              <SimpleSelect
                label={t('registro_agencia.condicion_de_pago')}
                options={[
                  { value: "al contado", label: "al contado" },
                  { value: "tarjeta", label: "tarjeta" },
                  { value: "depósito", label: "depósito" }
                ]}
                placeholder={t('registro_agencia.seleccione_una_opcion')}
                selectedValue={selectedValue}
                disabled
                handleChange={handleChange}
              />
            </SelectWrap>

            {user?.type !== 'USUARIO_FINAL' && <SelectWrap>
              <SimpleSelect
                label={t('registro_agencia.condicion_comercial')}
                options={[
                  { value: "al contado", label: "al contado" },
                  { value: "tarjeta", label: "tarjeta" },
                  { value: "depósito", label: "depósito" }
                ]}
                placeholder={t('registro_agencia.seleccione_una_opcion')}
                selectedValue={selectedValue} // IBISDEV SET COMMERCIAL CONDITION
                disabled
                handleChange={handleChange}
              />
            </SelectWrap>}
          </FormlogIn>

          <GridButtons>
            <Cta
              onClick={() => {
                setOpenModal(false);
              }}
              theme="simpleTheme"
            >
              {t("mis_reservas.cancelar")}
            </Cta>{" "}
            <Cta
              onClick={() => {
                setOpenModal(false);
                setUpdated(true);
                setInfoAgencia({
                  nombre: formData.nombre,
                  cuit: formData.cuit,
                  direccion: formData.direccion,
                  operaciones: formData.operaciones,
                  administrativo: formData.administrativo,
                  tel: formData.tel,
                  pago: formData.pago
                });
              }}
              theme="blueDarkTheme"
            >
              {t("pantalla_agencia.guardar_cambios")}
            </Cta>{" "}
          </GridButtons>
        </InputsModal>
      </Modal>
    </Suspense>
  );
};

export default Perfil;
