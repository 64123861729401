import React from "react";
import { useTranslation } from "react-i18next";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

interface Props {
  title: string;
  subtitle?: string;
  jarvisLink?: boolean;
}

const EmptyResult: React.FC<Props> = ({ title, subtitle, jarvisLink }) => {
  const { t } = useTranslation();

  return (
    <SC.Wrapper>
      <CSC.Title>{title}</CSC.Title>
      <CSC.Paragraph>
        {subtitle}

        {jarvisLink && (
          <a href={"mailto:hello@jarvisvip.com"}>hello@jarvisvip.com</a>
        )}
      </CSC.Paragraph>
    </SC.Wrapper>
  );
};

export default EmptyResult;
