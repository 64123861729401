import React, {
  Fragment,
  SetStateAction,
  useEffect,
  useState,
  useRef
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAll } from "../../actions/airports/creators";
import { Airport } from "../../actions/airports/types";
import { SM, ShowWindowDimensions } from "../../utils/mobileQueries";
import { compareTwoStrings } from 'string-similarity';

import * as SC from "./style";

interface Props {
  mobileMenu?: boolean;
  setMenuOpen?: any;
  inputFocus?: boolean;
  setInputFocus?: any;
}

const SearchBar: React.FC<Props> = ({
  mobileMenu,
  setMenuOpen,
  inputFocus,
  setInputFocus
}) => {
  let location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const [airports, setAirports] = useState<Airport[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState(false);
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [airportSelected, setAirportSelected] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const _airports = await getAll();
      setAirports(_airports);
    };

    fetchData();
  }, []);

  const handleRedirect = () => {
    if (
      location.pathname.split("/")[1] === "/resultados" ||
      location.pathname.split("/")[1] === "resultados"
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      history.push(`/resultados/${airportSelected}`);
    }
  };

  const normalize = (value: string) =>
    value.toLowerCase()
    .replace("á", "a")
    .replace("é", "e")
    .replace("í", "i")
    .replace("ó", "o")
    .replace("ú", "u");

  useEffect(() => {
    const results = [...airports].filter((a) => {
      const a1 = normalize(`${a.airportCode} ${a.airportName} ${a.city} ${a.state} ${a.country}`);
      const s = normalize(searchTerm);

      return a1.includes(s);
    })

    const results2 = [...airports].filter((a) => !results.includes(a)).sort((a, b) => {
      const a1 = normalize(`${a.airportCode} ${a.airportName} ${a.city} ${a.state} ${a.country}`);
      const b1 = normalize(`${b.airportCode} ${b.airportName} ${b.city} ${b.state} ${b.country}`);
      const s = normalize(searchTerm);

      return compareTwoStrings(b1, s) - compareTwoStrings(a1, s);
    }).slice(0, 6 - results.length);

    setSearchResults([...results, ...results2]);
  }, [searchTerm]);

  const storeAirport = (airport: Airport) => {
    localStorage.setItem("airport", JSON.stringify(airport));
  };

  const keyControlEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchTerm === "") return;
    const ul = document.getElementById("menu") as HTMLUListElement;
    const { key } = event;
    if (key !== "ArrowDown" && key !== "ArrowUp" && key !== "Enter") return;
    event.preventDefault();
    const lis: HTMLLIElement[] | undefined = Array.from(
      ul!.querySelectorAll("li.hoverable")
    );
    if (key === "ArrowDown") {
      if ((window as any).currentFocusIndex === null) {
        (window as any).currentFocusIndex = 0;
      } else {
        if ((window as any).currentFocusIndex < lis.length - 1) {
          (window as any).currentFocusIndex++;
        }
      }
    }
    if (key === "ArrowUp") {
      if ((window as any).currentFocusIndex! > 0) {
        (window as any).currentFocusIndex!--;
      } else {
        (window as any).currentFocusIndex = null;
      }
    }
    lis.forEach(li => {
      li.classList.remove("key-hover");
    });

    if (
      (window as any).currentFocusIndex !== null &&
      lis[(window as any).currentFocusIndex]
    ) {
      lis[(window as any).currentFocusIndex].classList.add("key-hover");
      lis[(window as any).currentFocusIndex].focus();
      if (key === "Enter") {
        storeAirport(searchResults[(window as any).currentFocusIndex]);

      /*   handleRedirect(); */

        if (
          location.pathname.split("/")[1] === "/resultados" ||
          location.pathname.split("/")[1] === "resultados"
        ) {
          history.push(`/resultados/${searchResults[(window as any).currentFocusIndex].airportCode}`);
          window.location.reload();
        } else {
          history.push(`/resultados/${searchResults[(window as any).currentFocusIndex].airportCode}`);
        }
      }
    }
  };

  useEffect(() => {
    (window as any).currentFocusIndex = null;
  }, []);

  useEffect(() => {
    function handleKeyDown(e: any) {
      if (e.key === "Enter" && searchResults.length === 1) {
        if (
          location.pathname.split("/")[1] === "/resultados" ||
          location.pathname.split("/")[1] === "resultados"
        ) {
          history.push(`/resultados/${searchResults[0].airportCode}`);
          window.location.reload();
        } else {
          history.push(`/resultados/${searchResults[0].airportCode}`);
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchResults]);

  return (
    <SC.RelativeWrap>
      {!mobileMenu ? (
        <Fragment>
          <SC.SearchWrap>
            <SC.Wrapper>
              <SC.SearchIcon />

              {inputFocus && (
                <SC.Input
                  id="search-input"
                  onKeyDown={keyControlEvent}
                  type="text"
                  placeholder={
                    ShowWindowDimensions() >= SM
                      ? t("homepage.buscador")
                      : t("homepage.buscador_mobile")
                  }
                  value={searchTerm}
                  onChange={handleChange}
                  autoComplete="off"
                  autoFocus={true}
                  onBlur={() => {
                    setInputFocus(false);
                  }}
                />
              )}

              {!inputFocus && (
                <SC.Input
                  id="search-input"
                  onKeyDown={keyControlEvent}
                  type="text"
                  placeholder={
                    ShowWindowDimensions() >= SM
                      ? t("homepage.buscador")
                      : t("homepage.buscador_mobile")
                  }
                  value={searchTerm}
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={() => {
                    setInputFocus(false);
                  }}
                />
              )}
            </SC.Wrapper>
          </SC.SearchWrap>
          <SC.Submenu id="menu" searchTerm={searchTerm}>
            {searchResults.length !== 0 ? (
              <>
                {searchResults
                  .map((item, i) => {
                    if (searchTerm !== "" && searchResults.length > 1) {
                      return (
                        <li key={i} className="hoverable">
                          <Link
                            onClick={() => {
                              storeAirport(item);
                              setAirportSelected(item.airportCode);

                              if (typeof window !== "undefined") {
                                handleRedirect();
                                /*   history.push(`/${item.airportCode}`); */
                              }
                            }}
                            key={i}
                            to={
                              location.pathname.split("/")[1] ===
                                "/resultados" ||
                              location.pathname.split("/")[1] === "resultados"
                                ? `/resultados/${item.airportCode}`
                                : `/resultados/${item.airportCode}`
                            }
                          >
                            {}
                            <SC.Card>
                              <SC.BgPic bg={item.image} />
                              <SC.Headline>
                                <h3>
                                  {item.airportCode} - {item.airportName}
                                </h3>
                                <h4>
                                  {[item.city, item.state, item.country].join(
                                    ", "
                                  )}
                                </h4>
                              </SC.Headline>
                            </SC.Card>
                          </Link>
                        </li>
                      );
                    } else {
                      storeAirport(item);

                      return (
                        <li key={i} className="unique" tabIndex={0}>
                          <Link
                            onClick={() => {
                              storeAirport(item);
                              setAirportSelected(item.airportCode);

                              if (typeof window !== "undefined") {
                                handleRedirect();
                                /*    history.push(`/${item.airportCode}`); */
                              }
                            }}
                            key={i}
                            to={
                              location.pathname.split("/")[1] ===
                                "/resultados" ||
                              location.pathname.split("/")[1] === "resultados"
                                ? `/resultados/${item.airportCode}`
                                : `/resultados/${item.airportCode}`
                            }
                          >
                            <SC.Card>
                              <SC.BgPic bg={item.image} />
                              <SC.Headline>
                                <h3>
                                  {item.airportCode} - {item.airportName}
                                </h3>
                                <h4>
                                  {[item.city, item.state, item.country].join(
                                    ", "
                                  )}
                                </h4>
                              </SC.Headline>
                            </SC.Card>
                          </Link>
                        </li>
                      );
                    }
                  })}
              </>
            ) : (
              <>
                <SC.Card>
                  <h5>{t("generales.ningun_aeropuerto")}</h5>
                </SC.Card>
              </>
            )}
          </SC.Submenu>
        </Fragment>
      ) : (
        <Fragment>
          <SC.MenuSearchWrap active={active} onClick={() => setActive(!active)}>
            <SC.Wrapper>
              <SC.MenuSearchIcon />
              <SC.MenuInput
                type="text"
                placeholder={t("homepage.buscador_mobile")}
                value={searchTerm}
                onChange={handleChange}
              />
            </SC.Wrapper>
          </SC.MenuSearchWrap>

          <SC.MenuSubmenu searchTerm={searchTerm}>
            {searchResults.length !== 0 ? (
              <>
                {searchResults
                  .map((item, i) => {
                    if (searchTerm !== "") {
                      return (
                        <Link
                          onClick={() => {
                            storeAirport(item);
                            setAirportSelected(item.airportCode);
                            if (typeof window !== "undefined") {
                              handleRedirect();
                            }
                            setMenuOpen(false);
                          }}
                          key={i}
                          to={
                            location.pathname.split("/")[1] === "/resultados" ||
                            location.pathname.split("/")[1] === "resultados"
                              ? `/resultados/${item.airportCode}`
                              : `/resultados/${item.airportCode}`
                          }
                        >
                          <SC.Card>
                            <SC.BgPic bg={item.image} />
                            <SC.Headline>
                              <h3>
                                {item.airportCode} - {item.airportName}
                              </h3>
                              <h4>
                                {[item.city, item.state, item.country].join(
                                  ", "
                                )}
                              </h4>
                            </SC.Headline>
                          </SC.Card>
                        </Link>
                      );
                    }
                  })}
              </>
            ) : (
              <>
                <SC.Card>
                  <h6>{t("generales.ningun_aeropuerto")}</h6>
                </SC.Card>
              </>
            )}
          </SC.MenuSubmenu>
        </Fragment>
      )}
    </SC.RelativeWrap>
  );
};

export default SearchBar;
