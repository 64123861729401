import React from "react";
import * as SC from "./style";

interface Props {
  onClick?: any;
  value?: boolean;
  name?: string;
  checked?:boolean
}

const InputCheckbox: React.FC<Props> = ({ children, onClick, value, name, checked }) => {
  return (
    <SC.Wrapper>
      <SC.Input type="checkbox" name={name} onClick={() => onClick(!value)} checked={checked} />
      <SC.CheckBox />


      {children}
    </SC.Wrapper>
  );
};

export default InputCheckbox;
