import styled from "styled-components";
import airPlaneIcon from "../../assets/icons/airplane-icon.svg";
import { SM, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.header`
  color: var(--primary-text);
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 120px 32px;
  position: relative;

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const NavBar = styled.ul`
  display: flex;
  align-items: center;
  flex: 1;
  > a {
    margin-right: 44px;
  }
`;

export const NavItem = styled.li`
  &:not(:last-child) {
    margin-right: 40px;
  }

  a,
  span {
    text-decoration: none;
    color: var(--primary-text);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  span {
    cursor: pointer;
  }

  a:hover,
  span:hover {
    text-decoration: underline;
  }
`;

export const NavLink = styled.a`
  font-weight: 500;
`;

export const Login = styled.div`
  margin-right: 32px;
  a {
    text-decoration: none;
    color: #000000 !important;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AirPlane = styled.span`
  mask: url(${airPlaneIcon}) center / contain no-repeat;
  background: var(--primary-color);
  width: 20px;
  height: 20px;
  margin-right: 16px;
`;

export const StickyContact = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
  > div {
    height: 48px;
    display: flex;

    padding: 0 120px;
    margin-bottom: 7px;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${LargerScreens}) {
    > div {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
`;

export const InputsModal = styled.div`
  padding: 30px;

  > h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 10px;
  }

  > p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    opacity: 0.8;

    margin-bottom: 30px;
  }

  @media (max-width: ${SM}) {
    padding: 20px;
    margin-top: 50px;

    > h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    > p {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #000000;
      opacity: 0.8;
      margin-top: -5px;
    }
  }
`;

export const InputWrap = styled.div`
  @media (max-width: ${SM}) {
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 25px;

  input {
    width: 94%;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
  }
`;

export const GridButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    margin-top: 80px;

    button {
      display: flex;
      justify-content: center;
    }
  }
`;

export const DatosContactoWrap = styled.div`
  padding: 15px 10px;
  overflow: hidden;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const ReservasWrap = styled.div`
  position: relative;
`;

export const ReservasLink = styled.div<{ qty: number }>`
  ${props =>
    props.qty === 0 &&
    `
    pointer-events: none;
    button {
      background: rgba(0,0,0,0.1);
      color: #bdbdbd;
      > span {
        background: #bdbdbd;
      }
    }
  `};
  ${props =>
    props.qty > 0 &&
    `
    &:after{
      content:"${props.qty}";
      position:absolute;
      top:-12px;
      right:-13px;
      background-color:${props.theme.mainBlue};
      color:#ffffff;
      width:22px;
      height:22px;
      border-radius: 50%;
      display: flex;
      align-items:center;
      justify-content:center;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      border: 3px solid #ffffff;
    }  
  `}
`;

export const Tooltip = styled.div<{ showTooltip?: boolean }>`
  background: #ffffff;
  border: 1px solid rgba(209, 223, 229, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 20px 38px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  padding: 15px 0 15px 20px;
  position: absolute;
  z-index: 10;
  right: 120px;
  top: 91px;
  border-bottom: 4px solid #f6f6f6;

  > div {
    &:first-child {
      padding-right: 40px;
    }

    &:last-child {
      a {
        display: block;
        padding: 20px;
      }
    }
  }

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    opacity: 0.5;
    padding-bottom: 5px;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 65px;
    height: 4px;
    background-color: #13e272;
  }
`;

export const LogoJarvisC = styled.img`
  margin: 10px auto -10px auto;
  display: block;
`;

export const EnvioMensaje = styled.span`
  display: block;
  text-align:center;

  font-size:13px;
  margin:0 auto;


`;
