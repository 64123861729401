import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const getAll = async () => {
  const {
    data: { settings }
  } = await axios.get(`${API_URL}/setting`);
  return settings;
};
