import styled from "styled-components";

import jarvisLogo from "../assets/icons/jarvis-icon.svg";
import arrowIcon from "../assets/icons/right-arrow.svg";
import jarvisLogoFull from "../assets/icons/jarvis-logo-full.svg";
import arrivalIcon from "../assets/icons/arrival-icon.svg";
import departureIcon from "../assets/icons/departure-icon.svg";
import conecctionIcon from "../assets/icons/connections-icon.svg";
import warningCircle from "../assets/icons/alert-circle-red.svg";

export const Logo = styled.span`
  background: url(${jarvisLogo}) center / contain no-repeat;
  width: 120px;
  height: 55px;
  display: inline-block;
`;

export const LogoFull = styled.span`
  background: url(${jarvisLogoFull}) center / contain no-repeat;
  width: 366px;
  height: 366px;
  position: absolute;
`;

export const BigTitle = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 53.33px;
  margin: 0;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
`;

export const MediumTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
`;

export const MediumTitleBold = styled(MediumTitle)`
  font-weight: bold;
`;

export const LittleTitle = styled.h3`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0;
  color: var(--secondary-color);
  margin-bottom: 8px;
`;

export const Subtitle = styled.h5`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  opacity: 0.5;
`;

export const AlternativeTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  margin-top: 8px;
`;

export const Paragraph2 = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: var(--secondary-green);
  margin-top: 8px;
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: bold;
  margin: 0;
`;

export const Paragraph600 = styled(Paragraph)`
  font-weight: 600;
  margin: 0;
`;

export const MediumParagraph = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: var(--black);
  opacity: 0.5;
  margin: 0;
`;

export const SmallParagraph = styled.p`
  font-size: 10px;
  line-height: 15px;
  color: var(--black);
  opacity: 0.6;
  margin: 0;
`;

export const MediumParagraph2 = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  color: var(--secondary-green);
  opacity: 1 !important;
  margin: 0;
`;

export const ContactLink = styled.a<{
  icon: string;
  color?: string;
  customWidth?: string;
  customHeight?: string;
}>`
  text-decoration: none;
  color: ${props => (props.color ? props.color : "#000")};
  font-size: 12px;
  line-height: 18px;
  display: inline-flex;
  cursor: pointer;
  &::before {
    content: "";
    mask: url(${props => props.icon}) center / contain no-repeat;
    background: url(${props => props.icon}) center / contain no-repeat;
    width: ${props => (props.customWidth ? props.customWidth : "16px")};
    height: ${props => (props.customHeight ? props.customHeight : "16px")};
    margin-right: 10px;
  }
  &:not(:last-child) {
    margin-right: 50px;
  }

  &:hover {
    text-decoration: underline;
  }

  span {
    line-height: 22px;
  }
`;

export const RightArrow = styled.span`
  background: url(${arrowIcon}) center / contain no-repeat;
  width: 11px;
  height: 11px;
  margin-left: 16px;
`;

export const GreenTag = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: var(--secondary-color);
  padding: 0 16px;
  height: 34px;
  color: var(--black);
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: var(--black);
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const DefaultWrapper = styled.div`
  padding: 24px 120px !important;

  font-size: 14px;
  line-height: 21px;
  /*  text-decoration-line: underline; */
  color: var(--black);
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const ReservationIcon = styled.span<{ type: string }>`
  width: 40px;
  height: 40px;
  background: url(${props =>
      props.type === "Arribo"
        ? arrivalIcon
        : props => (props.type === "Partida" ? departureIcon : conecctionIcon)})
    center / contain no-repeat;
`;

export const NightSurchargeContainer = styled.div`
  display: flex;
  > p {
    margin-bottom: 0;
    margin-right: 5px;
  }
`;

export const WarningIcon = styled.span`
  width: 18px;
  height: 18px;
  background: url(${warningCircle}) center / contain no-repeat;
`;
