/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, FC, useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import {
  Wrapper,
  Headline,
  TablaMainWrap,
  Searcher,
  TabsButtons,
  ButtonLi,
  Listado,
  ListadoHead,
  ListadoItem,
  ListadoCat,
  ListadoItemWrap,
  Label,
  CatImg,
  Paginator,
  Li,
  TablaCardMobile,
  ListMobile,
  LinkWrapMobile
} from "./style";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";

import DownloadIcon from "../../../assets/icons/download.svg";
import Lupa from "../../../assets/icons/search-icon.svg";
import { filtros, headlines } from "./data";
import Caret from "../../../assets/icons/caret.svg";

import ArrowLeft from "../../../assets/icons/arrow_prev.svg";
import ArrowRight from "../../../assets/icons/arrow_next.svg";
import Cta from "../../../components/Cta";

interface Props {
  bookings: any[];
}

const Transacciones: FC<Props> = ({ bookings }) => {
  const { t } = useTranslation();

  useWindowSize();

  const [active, setActive] = useState(0);
  const [data, setData] = useState<any>([]);
  const [categoria, setCategoria] = useState("todas");
  const [dateFilter, setDateFilter] = useState("up");
  const [searchTerm, setSearchTerm] = useState("");
  let [savedIndex, setSavedIndex] = useState(0);
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };

  const [searchResults, setSearchResults] = useState<any[]>([]);
  let [currentPage, setCurrentPage] = useState(0);

  const [arrayToFilter, setArrayToFilter] = useState<any>([]);

  const cantidadDeElementos = 5;

  const [resultConfirmada, setResultConfirmada] = useState<any[]>([]);
  const [resultSolicitada, setResultSolicitada] = useState<any[]>([]);
  const [resultIFaltainfo, setResultIFaltainfo] = useState<any[]>([]);
  const [resultPendiente, setResultPendiente] = useState<any[]>([]);
  const [resultCancelada, setResultCancelada] = useState<any[]>([]);
  const [resultRealizada, setResultRealizada] = useState<any[]>([]);

  const headers = [
    { label: "Nº de reserva", key: "numeroReserva" },
    { label: "Fecha de vuelo", key: "fecha" },
    { label: "Hora", key: "hora" },
    { label: "Tipo de reserva", key: "tipoReserva" },
    { label: "Aeropuerto", key: "aeropuerto" },
    { label: "Pasajero", key: "pasajero" },
    { label: "Pasajeros", key: "pasajeros" },
    { label: "Pack", key: "pack" },
    { label: "Estado", key: "estado" }
  ];

  useEffect(() => {
    const __bookings = bookings.map((booking: any) => ({
      numeroReserva: booking.id,
      fecha: moment.utc(booking.date).format("DD/MM/YYYY"),
      hora: moment.utc(booking.date).format("HH:mm"),
      tipoReserva: booking.bookingType,
      aeropuerto: booking.airport.airportCode,
      pasajero: booking.passengers[0].name,
      pasajeros: booking.passengers.length,
      pack: booking.pack?.packName,
      estado: booking.status,
      flightNumber: booking.flightNumber
    }));

    setData(__bookings);
    setSearchResults(__bookings);

    if (__bookings) {
      setResultConfirmada(
        __bookings.filter(
          ({ estado }: { estado: any }) => estado.toLowerCase() === "confirmada"
        )
      );
      setResultSolicitada(
        __bookings.filter(
          ({ estado }: { estado: any }) => estado.toLowerCase() === "solicitada"
        )
      );
      setResultIFaltainfo(
        __bookings.filter(
          ({ estado }: { estado: any }) => estado.toLowerCase() === "falta_info"
        )
      );
      setResultPendiente(
        __bookings.filter(
          ({ estado }: { estado: any }) =>
            estado.toLowerCase() === "pendiente_de_pago"
        )
      );
      setResultCancelada(
        __bookings.filter(
          ({ estado }: { estado: any }) => estado.toLowerCase() === "cancelada"
        )
      );
      setResultRealizada(
        __bookings.filter(
          ({ estado }: { estado: any }) => estado.toLowerCase() === "realizada"
        )
      );
    }

    setActive(0);

    setCurrentPage(0);
    setSavedIndex(0);
    setCategoria("todas");
    setArrayToFilter(data);
  }, [bookings]);

  useEffect(() => {
    let results = [] as any;

    if (arrayToFilter.length > 0) {
      results = arrayToFilter.filter((single: { pasajero: string }) => {
        return single.pasajero.toLowerCase().includes(searchTerm.toLowerCase());
      });
    } else {
      results = data.filter((single: { pasajero: string }) => {
        return single.pasajero.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setSearchResults(results);

    if (searchTerm !== "") {
      setCurrentPage(0);
    }
  }, [searchTerm, arrayToFilter]);

  const limitPerPage = 5;

  let pages: any = 0;

  if (searchResults.length > 0) {
    pages = searchResults.length / limitPerPage;
    pages = pages.toString();
    pages = parseInt(pages);
  }

 
 
  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <Headline>
          <h4>{t("pantalla_agencia.mis_trasacciones.titulo")}</h4>
          <CSVLink
            data={data}
            headers={headers}
            filename={"listado-de-reservas.csv"}
          >
            <img src={DownloadIcon} alt="" />
            {t("pantalla_agencia.mis_trasacciones.exportar_listado")}
          </CSVLink>
        </Headline>
        <TablaMainWrap>
          <Searcher>
            <img src={Lupa} alt={""} />
            <input
              type="text"
              placeholder={t("pantalla_agencia.mis_trasacciones.buscar")}
              onChange={handleChange}
            />
          </Searcher>
          <TabsButtons>
            {filtros.map((tab, i) => {
              return (
                <ButtonLi
                  key={i}
                  index={i}
                  onClick={() => {
                    setActive(i);

                    setCurrentPage(0);
                    setSavedIndex(0);

                    if (tab.tab === "todas") {
                      setCategoria("todas");
                      setArrayToFilter(data);
                    } else {
                      setCategoria(
                        tab.tab !== "solicitadas"
                          ? tab.tab.replace("s", "")
                          : "solicitada"
                      );
                    }
                    if (tab.tab === "confirmadas") {
                      setArrayToFilter(resultConfirmada);
                    }
                    if (tab.tab === "realizadas") {
                      setArrayToFilter(resultRealizada);
                    }
                    if (tab.tab === "solicitadas") {
                      setArrayToFilter(resultSolicitada);
                    }
                    if (tab.tab === "falta_info") {
                      setArrayToFilter(resultIFaltainfo);
                    }
                    if (tab.tab === "pendiente_de_pago") {
                      setArrayToFilter(resultPendiente);
                    }
                    if (tab.tab === "canceladas") {
                      setArrayToFilter(resultCancelada);
                    }
                  }}
                  activeLi={active}
                >
                  <span>
                    {t("pantalla_agencia.mis_trasacciones." + tab.tab)}
                  </span>

                  <span>
                    {tab.tab === "todas" ? ` (${data.length})` : ""}
                    {tab.tab === "confirmadas"
                      ? resultConfirmada && ` (${resultConfirmada.length})`
                      : ""}
                    {tab.tab === "realizadas"
                      ? resultRealizada && ` (${resultRealizada.length})`
                      : ""}
                    {tab.tab === "solicitadas"
                      ? resultSolicitada && ` (${resultSolicitada.length})`
                      : ""}
                    {tab.tab === "falta_info"
                      ? resultIFaltainfo && ` (${resultIFaltainfo.length})`
                      : ""}{" "}
                    {tab.tab === "pendiente_de_pago"
                      ? resultPendiente && ` (${resultPendiente.length})`
                      : ""}{" "}
                    {tab.tab === "canceladas"
                      ? resultCancelada && ` (${resultCancelada.length})`
                      : ""}
                  </span>
                </ButtonLi>
              );
            })}
          </TabsButtons>

          {ShowWindowDimensions() > SM ? (
            <Listado>
              <ListadoHead>
                {headlines.map((cat, j) => {
                  return (
                    <ListadoCat
                      key={j}
                      index={j}
                      onClick={() => {
                        if (
                          cat.cat === "fecha_de_vuelo" &&
                          dateFilter === "up"
                        ) {
                          setDateFilter("down");
                        }
                        if (
                          cat.cat === "fecha_de_vuelo" &&
                          dateFilter !== "up"
                        ) {
                          setDateFilter("up");
                        }
                      }}
                      cursorPointer={cat.cat === "fecha_de_vuelo"}
                    >
                      {t("pantalla_agencia.mis_trasacciones." + cat.cat)}
                      {cat.cat === "fecha_de_vuelo" ? (
                        <CatImg src={Caret} alt="" cursorFecha={dateFilter} />
                      ) : (
                        ""
                      )}
                    </ListadoCat>
                  );
                })}
              </ListadoHead>

              <>
                {data && (
                  <>
                    {searchResults

                      .sort(
                        (
                          a: { fecha: moment.MomentInput },
                          b: { fecha: moment.MomentInput }
                        ) =>
                          Number(
                            moment
                              .utc(
                                dateFilter === "up" ? a.fecha : b.fecha,
                                "DD/MM/YYYY"
                              )
                              .format("x")
                          ) -
                          Number(
                            moment
                              .utc(
                                dateFilter === "up" ? b.fecha : a.fecha,
                                "DD/MM/YYYY"
                              )
                              .format("x")
                          )
                      )
                      .slice(currentPage, currentPage + 5)
                      .map(
                        (
                          item: {
                            estado: string;
                            numeroReserva: React.ReactNode;
                            fecha: React.ReactNode;
                            hora: React.ReactNode;
                            tipoReserva: React.ReactNode;
                            aeropuerto: React.ReactNode;
                            pasajero: React.ReactNode;
                            pasajeros: React.ReactNode;
                            pack: React.ReactNode;
                            flightNumber: string
                          },
                          k: number
                        ) => {
                          return (
                            <Fragment key={k}>
                              <>
                                {item.estado.toLowerCase() === categoria && (
                                  <>
                                    <Link to={`/agencia/detalle/${item.numeroReserva}`}>
                                      <ListadoItemWrap activeItem={k}>
                                        <ListadoItem>
                                          <span>{item.numeroReserva}</span>
                                        </ListadoItem>

                                        <ListadoItem>
                                          <span>{item.fecha}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.hora}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.flightNumber && item.flightNumber.toUpperCase()}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.tipoReserva}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.aeropuerto}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.pasajero}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.pasajeros}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <span>{item.pack}</span>
                                        </ListadoItem>
                                        <ListadoItem>
                                          <Label bg={item.estado}>
                                            {item.estado}
                                          </Label>
                                        </ListadoItem>
                                      </ListadoItemWrap>
                                    </Link>
                                  </>
                                )}
                              </>
                              <>
                                {categoria === "todas" && (
                                  <Link
                                    to={`/agencia/detalle/${item.numeroReserva}`}
                                  >
                                    <ListadoItemWrap activeItem={k}>
                                      <ListadoItem>
                                        <span>{item.numeroReserva}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.fecha}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.hora}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.flightNumber && item.flightNumber.toUpperCase()}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.tipoReserva}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.aeropuerto}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.pasajero}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.pasajeros}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <span>{item.pack}</span>
                                      </ListadoItem>
                                      <ListadoItem>
                                        <Label bg={item.estado}>
                                          {item.estado}
                                        </Label>
                                      </ListadoItem>
                                    </ListadoItemWrap>
                                  </Link>
                                )}
                              </>
                            </Fragment>
                          );
                        }
                      )}
                  </>
                )}
              </>
            </Listado>
          ) : (
            // MOBILE
            <>
              {data && (
                <>
                  {searchResults

                    .sort(
                      (
                        a: { fecha: moment.MomentInput },
                        b: { fecha: moment.MomentInput }
                      ) =>
                        Number(
                          moment
                            .utc(
                              dateFilter === "up" ? a.fecha : b.fecha,
                              "DD/MM/YYYY"
                            )
                            .format("x")
                        ) -
                        Number(
                          moment
                            .utc(
                              dateFilter === "up" ? b.fecha : a.fecha,
                              "DD/MM/YYYY"
                            )
                            .format("x")
                        )
                    )
                    .slice(currentPage, currentPage + 5)
                    .map(
                      (
                        item: {
                          estado: string;
                          numeroReserva: React.ReactNode;
                          fecha: React.ReactNode;
                          hora: React.ReactNode;
                          tipoReserva: React.ReactNode;
                          aeropuerto: React.ReactNode;
                          pasajero: React.ReactNode;
                          pasajeros: React.ReactNode;
                          pack: React.ReactNode;
                          flightNumber: string
                        },
                        k: number
                      ) => {
                        return (
                          <Fragment key={k}>
                            <>
                              {item.estado.toLowerCase() === categoria && (
                                <LinkWrapMobile>
                                  <Link
                                    to={`/agencia/detalle/${item.numeroReserva}`}
                                  >
                                    <TablaCardMobile activeItem={k}>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.numero_de_reserva"
                                          )}
                                        </span>
                                        <h5>{item.numeroReserva}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.fecha_de_vuelo"
                                          )}
                                        </span>
                                        <h5>{item.fecha}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.hora"
                                          )}
                                        </span>
                                        <h5>{item.hora}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.numero_de_vuelo"
                                          )}
                                        </span>
                                        <h5>{item.flightNumber && item.flightNumber.toUpperCase()}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.tipo_de_reserva"
                                          )}
                                        </span>
                                        <h5>{item.tipoReserva}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Aeropuerto"
                                          )}
                                        </span>
                                        <h5>{item.aeropuerto}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Pasajero"
                                          )}
                                        </span>
                                        <h5>{item.pasajero}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Pasajeros"
                                          )}
                                        </span>
                                        <h5>{item.pasajeros}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                              "pantalla_agencia.mis_trasacciones.Pack"
                                          )}
                                        </span>
                                        <h5>{item.pack}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Estado"
                                          )}
                                        </span>
                                        <Label bg={item.estado}>
                                          {item.estado}
                                        </Label>
                                      </ListMobile>
                                    </TablaCardMobile>
                                  </Link>
                                  <Cta
                                    onClick={() => {}}
                                    isRoute={true}
                                    toRoute={`/agencia/detalle/${item.numeroReserva}`}
                                    theme="lightTheme"
                                  >
                                    {t("pantalla_agencia.boton_ver_detalle")}
                                  </Cta>
                                </LinkWrapMobile>
                              )}
                            </>
                            <>
                              {categoria === "todas" && (
                                <LinkWrapMobile>
                                  <Link
                                    to={`/agencia/detalle/${item.numeroReserva}`}
                                  >
                                    <TablaCardMobile activeItem={k}>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.numero_de_reserva"
                                          )}
                                        </span>
                                        <h5>{item.numeroReserva}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.fecha_de_vuelo"
                                          )}
                                        </span>
                                        <h5>{item.fecha}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.hora"
                                          )}
                                        </span>
                                        <h5>{item.hora}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.numero_de_vuelo"
                                          )}
                                        </span>
                                        <h5>{item.flightNumber && item.flightNumber.toUpperCase()}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.tipo_de_reserva"
                                          )}
                                        </span>
                                        <h5>{item.tipoReserva}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Aeropuerto"
                                          )}
                                        </span>
                                        <h5>{item.aeropuerto}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Pasajero"
                                          )}
                                        </span>
                                        <h5>{item.pasajero}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Pasajeros"
                                          )}
                                        </span>
                                        <h5>{item.pasajeros}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                              "pantalla_agencia.mis_trasacciones.Pack"
                                          )}
                                        </span>
                                        <h5>{item.pack}</h5>
                                      </ListMobile>
                                      <ListMobile>
                                        <span>
                                          {t(
                                            "pantalla_agencia.mis_trasacciones.Estado"
                                          )}
                                        </span>
                                        <Label bg={item.estado}>
                                          {item.estado}
                                        </Label>
                                      </ListMobile>
                                    </TablaCardMobile>
                                  </Link>
                                  <Cta
                                    onClick={() => {}}
                                    isRoute={true}
                                    toRoute={`/agencia/detalle/${item.numeroReserva}`}
                                    theme="lightTheme"
                                  >
                                    {t("pantalla_agencia.boton_ver_detalle")}
                                  </Cta>
                                </LinkWrapMobile>
                              )}
                            </>
                          </Fragment>
                        );
                      }
                    )}
                </>
              )}
            </>
          )}
        </TablaMainWrap>{" "}
        {pages + 1 && pages + 1 > 1 && (
          <Paginator>
            <ul>
              {currentPage <= 1 ? (
                <div></div>
              ) : (
                <div
                  onClick={() => {
                    setSavedIndex(--savedIndex);

                    setCurrentPage(currentPage - cantidadDeElementos);
                  }}
                >
                  <img src={ArrowLeft} alt="prev" />
                </div>
              )}

              <>
                {[...Array(pages + 1)].map((e, i) => {
                  return (
                    <Li
                      onClick={() => {
                        setCurrentPage(i * cantidadDeElementos);

                        setSavedIndex(i);
                      }}
                      key={i + 1}
                      index={i * cantidadDeElementos}
                      current={currentPage}
                    >
                      {i + 1}
                    </Li>
                  );
                })}
              </>

              {savedIndex === pages ? (
                <div></div>
              ) : (
                <div
                  onClick={() => {
                    setSavedIndex(++savedIndex);
                    setCurrentPage(currentPage + cantidadDeElementos);
                  }}
                >
                  <img src={ArrowRight} alt="next" />
                </div>
              )}
            </ul>
          </Paginator>
        )}
      </Wrapper>
    </Suspense>
  );
};

export default Transacciones;
