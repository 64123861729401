import styled from "styled-components";
import { SM, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.div`
  padding: 24px 120px;

  @media (max-width: ${SM}) {
    padding: 24px 20px;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto 60px;
  }
`;


export const ResultSearchBar = styled.div`
  ul {
    width: 80%;
    margin: 0 auto;
  }
`;