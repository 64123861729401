import styled from 'styled-components';
import InputRadio from '../../../InputRadio';
import externalLink from '../../../../assets/icons/external-link-icon.svg';
import externalLinkHover from '../../../../assets/icons/hover/external-white.svg';

import { XS, SM, LG, LargerScreens } from '../../../../utils/constants';

export const Wrapper = styled.div`
	margin-top: 48px;

	@media (max-width: ${SM}) {
		overflow: hidden;
		margin-top: 150px;
		> h3 {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 24px;
			color: #000000;
		}
	}

	@media(min-width:${LargerScreens}){
		max-width:1377px;
		margin:60px auto 0;
	}
`;

export const SalonsContainer = styled.div`
	padding-top: 40px;

	@media (max-width: ${LG}) {
		> div {
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
`;

export const AirlineTitle = styled.span`
	color: var(--primary-text);
	font-size: 14px;
	line-height: 21px;
	opacity: 0.8;
`;

export const FlightInfo = styled.span`
	display: inline-flex;
	flex-direction: column;
	padding-left: 34px;

	span{
		max-width:550px;
	}
	span:first-child {
		margin-bottom: 4px;
	}
	span:last-child {
		opacity: 0.5;
	}

	@media(min-width:${LargerScreens}){

		span{
			max-width:800px;
		}
	}

	@media (max-width: ${SM}) {
		padding-left: 0;

		span:first-child {
			opacity: 0.8;
		}
		span:last-child {
			opacity: 0.8;
			width: 80%;
		}
		span:nth-child(2) {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 24px;
			color: #000000;
			max-width: 210px;
		}
	}
`;

export const Image = styled.img`
	width: 72px;
	height: 72px;
	object-fit: cover;
	object-position: center;
`;

export const RadioContainer = styled.span`
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	width: 100%;
	box-sizing: border-box;

	@media (max-width: ${SM}) {
		padding-left: 10px;
	}
`;

export const InputRadioInternal = styled.div`
	transition: all 0.2s linear;
	padding: 16px 0;
	width: 100%;
`;

export const Lounge = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: calc(100% + 48px);
	transform: translateX(-24px);
	box-sizing: border-box;
	margin-bottom: 17px;
	padding-left: 28px;
	transition: all 0.2s linear;
	&::after {
		content: "";
		height: 1px;
		background: var(--secondary-gray-light);
		opacity: 0.5;
		width: calc(100% - 48px);
		position: absolute;
		bottom: -9px;
		left: 24px;
	}
	button {
		position: absolute;
		right: 24px;
		&::after {
			content: "";
			width: 24px;
			height: 24px;
			margin-left: 16px;
			mask: url(${externalLink}) center / contain no-repeat;
			background: var(--black);
		}

		&:hover {
			&::after {
				mask: url(${externalLinkHover}) center / contain no-repeat;
				background: #ffffff;
			}
		}
	}
	&:hover {
		background: rgba(209, 223, 229, 0.2);
	}

	@media (max-width: ${SM}) {
		button {
			border: 0;
			right: 0;
			top: 50%;

			&:hover {
				background-color: transparent;
				&::after {
					mask: url(${externalLink}) center / contain no-repeat;
					background: #000000;
				}
			}
		}
	}
`;

export const VipCompany = styled.div`
	position: absolute;
	z-index: 10;
	background: white;
	top: 226px;
	padding: 10px 20px;
	font-family: Poppins, sans-serif;
	font-size: 18px;
	color: #898989;
	font-weight: 500;

	@media (max-width: ${SM}) {
		top: 146px;
	}
`;

export const LinkSalon = styled.div`
	display: block;
	top: 35px;
	position: absolute;
	width: 100%;
	right: 0;
`;

export const ModalWrapper = styled.div`
	padding-top: 4em;
`

export const WrapperColumn = styled.div`
	margin: 0 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	height: 160px;

	@media (max-width: ${XS}) {
		flex-direction: column;
		height: auto;
		padding-bottom: 1em;
	}
`

export const Column = styled.div`
	flex: 1;
	border-right: 1px solid lightgray;
    margin-right: 20px;
    margin-bottom: 20px;

	@media (max-width: ${XS}) {
		flex: 1;
		margin-bottom: 0px;
		border-right: none;
	}
`

export const Service = styled.div`
	height: 50px;
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
	margin-bottom: 0px;
	div {
		width: 20px;

		img {
			height: 10px;
		}
	}
	div.service {
		margin-left: 1em;
		font-family: 'Poppins';
		font-size: 14px;
		width: 60%;
		line-height: 20px;
	}
	@media (max-width: ${XS}) {
		height: auto;
		margin-bottom: 0px;
		div.service {
			width: 90%;
		}
	}
`
export const EachSlide = styled.div`
	& > img {
		width: 100%;
    	height: 200px !important;
		object-fit: cover;
		object-position: bottom;
	}

	@media (max-width: ${SM}) {
		& > img {
			height: 120px !important;
		}
	}
`;

export const VipInfo = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0 20px;

	@media (max-width: ${SM}) {
		flex-direction: column;
	}
`;
