import AOS from "aos";

export const AosConfig = () =>
  AOS.init({
    duration: 800,
    once: true,
    delay: 0, // values from 0 to 3000, with step 50ms
    easing: "ease-in-out",
    disable: function() {
      var maxWidth = 768;
      return window.innerWidth < maxWidth;
    }
  });
