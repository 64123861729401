import styled from "styled-components";
import { LargerScreens, SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  margin: 56px 120px 200px 120px;

  @media (max-width: ${SM}) {
    margin: -65px 20px 150px 20px;
  }
`;

export const Headline = styled.div`
  text-align: center;
  margin-top: 120px;
  margin-bottom: 80px;

  @media (max-width: ${SM}) {
    text-align: left;
    margin-bottom: 40px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;

  max-width: 1000px;
  margin: 0 auto;
  grid-gap: 20px 70px;
  padding-bottom: 35px;

  @media (min-width: ${LargerScreens}) {
    max-width: 1200px;
  }

  li {
    display: flex;
    align-items: top;

    img {
      position: relative;
      top: -12px;
    }

    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #000000;

      opacity: 0.8;
    }

    &:nth-child(4) {
      position: relative;
      top: -15px;
    }
    &:nth-child(6) {
      position: relative;
      top: -15px;
    }
    &:nth-child(7) {
      position: relative;
      top: -15px;
    }
    &:nth-child(9) {
      position: relative;
      top: -30px;
    }
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
    grid-gap: 25px;

    margin-left: -13px;

    li {
      img {
        top: -10px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }

      &:nth-child(4) {
        top: 0;
      }
      &:nth-child(6) {
        top: 0;
      }
      &:nth-child(7) {
        top: 0;
      }
      &:nth-child(9) {
        top: 0;
      }
    }
  }
`;

export const MobileText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  opacity: 0.8;

  margin-top: 15px;
`;
