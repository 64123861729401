import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// COMPONENTS
import Cta from "../Cta";
import SocialNetworks from "../SocialNetworks";
import RegularInput from "../../components/RegularInput";
import RegularTextArea from "../../components/RegularTextArea";
import Modal from "../../components/Modal";
import MobileMenu from "../../components/MobileMenu";
import InputError from "../../components/InputError";
import Animate from "../MobileMenu/Animate";
import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

// HOOK
import useBookings from "../../utils/useBookings";

// STYLE & IMAGES
import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import mail from "../../assets/icons/mail-icon.svg";
import phone from "../../assets/icons/phone-icon.svg";
import message from "../../assets/icons/message-circle-icon.svg";
import ArrowRight from "../../assets/icons/full-arrow.svg";
import LogoJarvis from "../../assets/icons/jarvis-icon.svg";
import WhatssApp from "../../assets/icons/whatsapp.svg";
import { LogoJarvisC, EnvioMensaje } from "./style";
import { Airport } from "../../actions/airports/types";
import { Pack, Service } from "../../actions/packs/types";
import { logout } from "./../../actions/auth/creators";

import LogoPng from "../../assets/jarvis-logo-x2.png";
import { getProfile } from '../../actions/users/creators';

interface Props {}

export interface Passenger {
  name: string;
  dob: string;
  phone: string;
  email: string;
  passport: string;
  nationality: string;
  notes: string;
}
export interface Booking {
  airport: Airport;
  airline: string;
  bookingType: string;
  flightType: string;
  date: string;
  adultsCount: number;
  childsCount: number;
  infantsCount: number;
  bagsCount: number;
  pack: Pack;
  passengers: Passenger[];
}

const Header: React.FC<Props> = () => {
  useWindowSize();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [openModalMobile, setOpenModalMobile] = useState(false);
  const [userName, setUserName] = useState('');

  const [formData, setFormData] = useState<any>({
    nombre: "",
    email: "",
    comentario: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const { companyName } = await getProfile(userId!);
        setUserName(companyName);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openModal]);

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");

  const [emailName, setEmailName] = useState("");
  const [valueEmail, setValueEmail] = useState("");

  const { bookings } = useBookings({
    onChange: newBookings => {
      setCantidad(newBookings.length);
    }
  });

  const [cantidad, setCantidad] = useState(bookings.length);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    handleInputChange(email, 0, "");
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  // IBIS hook que cambia si estás o no logueado
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    setLogged(!localStorage.getItem("userId"));
  }, []);

  // IBIS animacion cuando agregamos una reserva

  /*     useEffect(() => {

      setTimeout(() => {
        setCantidad(3);
        setShowTooltip(true)

        setTimeout(() => {
          setShowTooltip(false)
        }, 3000)
      }, 1000)
    }, []) */

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  const setLang2 = () => {
    const newLang = lang === "ES" ? "EN" : "ES";
    setLang(newLang);
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  return (
    <SC.Wrapper>
      {ShowWindowDimensions() > SM && (
        <Fragment>
          {window.location.pathname === "/" && (
            <SC.StickyContact>
              <div>
                <SocialNetworks />
                <div>
                  <CSC.ContactLink
                    href="mailto:hello@jarvisvip.com"
                    icon={mail}
                  >
                    hello@jarvisvip.com
                  </CSC.ContactLink>
                  <CSC.ContactLink
                    href="https://api.whatsapp.com/send?phone=5491169592863"
                    icon={WhatssApp}
                    target="_blank"
                    color="#000"
                    customWidth="19px"
                    customHeight="19px"
                  >
                    {/*      (+54) 9 11 6959 2863 */}
                    <span>WhatsApp</span>
                  </CSC.ContactLink>
                </div>
                <CSC.ContactLink icon={message} as="p" onClick={setLang2}>
                  {lang === "EN" ? "Cambiar a Español" : "Change to English"}
                </CSC.ContactLink>
              </div>
            </SC.StickyContact>
          )}
        </Fragment>
      )}

      {ShowWindowDimensions() > SM && (
        <SC.HeaderContainer>
          <SC.NavBar>
            <Link to="/">
              <CSC.Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <SC.NavItem>
              <Link to="/servicios">{t("header.nuestros_servicios")}</Link>
            </SC.NavItem>
            <SC.NavItem>
              <span onClick={() => setOpenModal(true)}>
                {t("header.contacto")}
              </span>
            </SC.NavItem>
          </SC.NavBar>
          {logged ? (
            <SC.Login>
              <Link to="/login">
                <SC.NavLink as={"span"}>{t("header.login")}</SC.NavLink>
              </Link>
            </SC.Login>
          ) : (
            <SC.Login>
              <Link to="/agencia">
                <SC.NavLink as={"span"}>{userName}</SC.NavLink>
              </Link>
              <Link to="/logout" style={{ marginLeft: "2em" }}>
                <SC.NavLink as={"span"}>{t("header.salir")}</SC.NavLink>
              </Link>
            </SC.Login>
          )}

          {/* <SC.Avatar
              ></SC.Avatar> */}

          <SC.ReservasWrap>
            <SC.ReservasLink qty={cantidad}>
              <Cta
                onClick={() => {}}
                toRoute={"/mis-reservas"}
                isRoute={true}
                theme="blueTransparentTheme"
              >
                <SC.AirPlane role="img" aria-label="Airplane icon" />
                {t("header.mis_reservas")}
              </Cta>
            </SC.ReservasLink>
          </SC.ReservasWrap>

          <Animate opacity={true} show={showTooltip}>
            <SC.Tooltip>
              <div>
                <h5>{t("header.animaciones_reserva_agregada_titulo")}</h5>
                <h3>Experiencia VIP - EZE</h3>
                <p>Mie, 14 de Julio 2020, 04:20</p>
              </div>
              <div>
                <Link to={"agencia/detalle/546132"}>
                  <img src={ArrowRight} alt="goTo" />
                </Link>
              </div>
            </SC.Tooltip>
          </Animate>
        </SC.HeaderContainer>
      )}

      {ShowWindowDimensions() <= SM && <MobileMenu userName={userName} onClick={setOpenModal} />}

      <Modal
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
          setFormData(() => ({
            nombre: "",
            email: "",
            comentario: ""
          }));
        }}
        maxWidth={"700px"}
        fullscreen={true}
      >
        <SC.InputsModal>
          <LogoJarvisC src={LogoJarvis} alt="Jarvis" />

          <h3>{t("header.contacto_form.title")}</h3>
          <p>{t("header.contacto_form.subtitle")}</p>
          <SC.InputGrid>
            <SC.InputWrap>
              <RegularInput
                label={t("header.contacto_form.nombre_completo")}
                type={"text"}
                value={formData.nombre}
                placeholder={t("header.contacto_form.nombre_completo")}
                onChange={handleInputChange}
                name={"nombre"}
                filled={formData["nombre"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              {" "}
              <RegularInput
                label={t("header.contacto_form.email")}
                type={"text"}
                placeholder={"nombre@mail"}
                value={formData.email}
                onChange={handleValidMail}
                errorMail={validateEmail}
                name={"email"}
                emailName={emailName}
                filled={formData["email"] !== "" ? "filled" : "empty"}
              />
              {!validateEmail &&
                emailName === "email" &&
                formData.email !== "" && (
                  <InputError text={t("generales.error_email")} />
                )}
            </SC.InputWrap>
          </SC.InputGrid>
          <RegularTextArea
            rows={ShowWindowDimensions() < SM ? 2 : 6}
            value={formData.comentario}
            label={t("header.contacto_form.comentarios")}
            placeholder={t("header.contacto_form.dejar_comentarios")}
            onChange={handleInputChange}
            name={"comentario"}
            filled={formData["comentario"] !== "" ? "filled" : "empty"}
          />
          <SC.GridButtons>
            <Cta
              onClick={() => {
                setFormData((infoAdicional: any) => ({
                  nombre: "",
                  email: "",
                  comentario: ""
                }));

                setOpenModal(false);
              }}
              theme="simpleTheme"
            >
              {t("header.contacto_form.cancelar")}
            </Cta>{" "}
            <Cta
              onClick={() => {
                if (formData.nombre !== "" && formData.email !== "") {
                  setOpenModal(false);
                }
              }}
              theme="blueDarkTheme"
              inactive={
                formData.nombre === "" ||
                formData.email === "" ||
                formData.comentario === "" ||
                !validateEmail === true
              }
            >
              {t("header.contacto_form.enviar_consulta")}
            </Cta>{" "}
          </SC.GridButtons>

          {/** //IBIS acá estan comentados los mensajes de exito y error */}

          {/*     <EnvioMensaje>{t("header.contacto_form.mensaje_exito")}</EnvioMensaje> */}
          {/*    <EnvioMensaje>{t("header.contacto_form.mensaje_error")}</EnvioMensaje> */}
        </SC.InputsModal>
      </Modal>
    </SC.Wrapper>
  );
};

export default Header;
