import styled from "styled-components";

export const Wrapper = styled.div``;

export const Input = styled.input`
    height: 53px;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid rgb(0, 0, 0);
    font-size: 16px;
    line-height: 21px;
    color: var(--black);
    width: 100%;
    &::placeholder {
        opacity: 0.3;
    }
    &[value=""] {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
`;