import React, { Suspense, FC, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import {
  Wrapper,
  BgImage,
  Content,
  Headline,
  ButtonWrap,
  LangWrap,
  LogoYLangWrap,
  MessagesWrap
} from "./style";
import { Logo, LittleTitle, Title, Paragraph } from "../../utils/commonStyle";
import message from "../../assets/icons/message-circle-icon.svg";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";
import { confirmBooking } from "../../actions/bookings/creators";
import Cta from "../../components/Cta";
import * as CSC from "../../utils/commonStyle";

//components

interface Props {}

interface ParamTypes {
  bookingId: string;
}

const SuccessPage: FC<Props> = () => {
  useWindowSize();
  const query = new URLSearchParams(useLocation().search);
  const { bookingId } = useParams<ParamTypes>();

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  const [ready, setReady] = useState(false);
  const [rejected, setRejected] = useState(false);

  const onConfirmBooking = async (status: string) => {
    await confirmBooking(bookingId, query.get("token")!, status);
    /*  alert(`Reserva ${status}`); */

    if (status === "CONFIRMADA") {
      setReady(true);
    } else {
      setRejected(true);
    }
  };

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  const setLang2 = () => {
    const newLang = lang === "ES" ? "EN" : "ES";
    setLang(newLang);
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  return (
    <Suspense fallback={<div />}>
      {" "}
      <Wrapper
        style={{ display: ShowWindowDimensions() > SM ? "grid" : "block" }}
      >
        {ShowWindowDimensions() > SM && <BgImage />}

        <Content>
          <Link to="/">
            <Logo role="img" aria-label="Jarvis logo" />
          </Link>
          <LangWrap>
            <CSC.ContactLink icon={message} as="p" onClick={setLang2}>
              {lang === "EN" ? "Cambiar a Español" : "Change to English"}
            </CSC.ContactLink>
          </LangWrap>

          {lang === "ES" ? (
            <>
              <Headline>
                {/*     <LittleTitle>confirmar reserva</LittleTitle> */}
                <Title>Desea confirmar reserva número {query.get("id")}</Title>
                {/*     <Paragraph>
                Te enviaremos por mail una confirmación y un comprobante
              </Paragraph> */}
                {!ready && !rejected && (
                  <ButtonWrap>
                    <Cta
                      onClick={() => {
                        onConfirmBooking("CANCELADA");

                        setRejected(true);
                      }}
                      theme="simpleTheme"
                    >
                      Rechazar reserva
                    </Cta>
                    <Cta
                      onClick={() => {
                        onConfirmBooking("CONFIRMADA");

                        setReady(true);
                      }}
                      theme="blueDarkTheme"
                    >
                      Confirmar reserva
                    </Cta>
                  </ButtonWrap>
                )}
              </Headline>
              {ready ? (
                <MessagesWrap>
                  <p>La reserva ha sido confirmada</p>
                </MessagesWrap>
              ) : rejected ? (
                <MessagesWrap>
                  <p>La reserva ha sido rechazada</p>
                </MessagesWrap>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <Headline>
                {/*     <LittleTitle>confirm reservation</LittleTitle> */}
                <Title>
                  I would like to confirm reservation number {query.get("id")}
                </Title>
                {/*   <Paragraph>
                We will send you a confirmation and a receipt by email
              </Paragraph> */}
                {!ready && !rejected && (
                  <ButtonWrap>
                    <Cta
                      onClick={() => {
                        {
                          onConfirmBooking("CANCELADA");
                          setRejected(true);
                        }
                      }}
                      theme="simpleTheme"
                    >
                      Reject reservation
                    </Cta>
                    <Cta
                      onClick={() => {
                        onConfirmBooking("CONFIRMADA");
                        setReady(true);
                      }}
                      theme="blueDarkTheme"
                    >
                      Confirm reservation
                    </Cta>
                  </ButtonWrap>
                )}
              </Headline>
              {ready ? (
                <MessagesWrap>
                  <p>The reservation has been confirmed</p>
                </MessagesWrap>
              ) : rejected ? (
                <MessagesWrap>
                  <p>The reservation has been rejected</p>
                </MessagesWrap>
              ) : (
                ""
              )}
            </>
          )}
        </Content>
      </Wrapper>
    </Suspense>
  );
};

export default SuccessPage;
