import React, { Suspense, FC, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Wrapper, BgImage, Content, Headline, CardsContainer, CardLogo } from "./style";
import {
  Logo,
  LittleTitle,
  Title,
} from "../../../utils/commonStyle";


import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";
import { PayPalButton } from 'react-paypal-button-v2';
import { createBooking } from '../../../actions/bookings/creators';
import { createPassenger } from '../../../actions/passengers/creators';
import { Booking } from '../../MyReservations/MyReservations';
import { getProfile } from '../../../actions/users/creators';
import { getAll } from "../../../actions/airports/creators";
import { Setting } from "../../../actions/settings/types";

//components

interface Props {}

const SuccessPage: FC<Props> = () => {
  const { t } = useTranslation();

  useWindowSize();

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [alContado, setAlContado] = useState(true);

  const history = useHistory();

  //IBIS
  const totalToPay = JSON.parse(localStorage.getItem("stored") || "[]")[0].total

  const onCreateBooking = async ({ paymentId }: any) => {
    setLoading(true)

    const user = localStorage.getItem("userId");

    let bookingId_: string [] = [];

    let bookingId: string = "";

    for (const booking of bookings) {
      const date = booking.date;

      const data = {
        user,
        airport: booking.airport._id,
        airline: booking.airline,
        bookingType: booking.bookingType,
        flightType: booking.flightType,
        flightNumber: booking.flightNumber,
        date: `${date.split(", ")[0].split("/")[2]}-${
          date.split(", ")[0].split("/")[1]
        }-${date.split(", ")[0].split("/")[0]}T${date.split(", ")[1]}:00.000Z`,
        adultsCount: booking.adultsCount,
        childsCount: booking.childsCount,
        infantsCount: booking.infantsCount,
        bagsCount: booking.bagsCount,
        pack: booking.pack._id,
        discount: booking.discount?._id || undefined,
        services: booking.pack.services.map(({ _id }) => _id),
        paymentId,
        passengers: booking.passengers.map((passenger) => ({
          ...passenger,
            dob: `${passenger.dob.split('/')[2]}-${passenger.dob.split('/')[1]}-${passenger.dob.split('/')[0]}`
          })
        ),
        totalToPay,
      };

      const { _id } = await createBooking(data);

      bookingId = _id;
      bookingId_.push(bookingId)

    }

    localStorage.removeItem("bookings");
    localStorage.removeItem("more_offers_booking");
    localStorage.removeItem("ALREADY_SHOWED_MODAL");
    

    var paramsUrl = ""

    for (const booking of bookingId_) {
      paramsUrl = paramsUrl + booking + '&' 
    }

    history.push(`/checkout/success/${paramsUrl}`);
  };

  const bookings: Booking[] = JSON.parse(localStorage.getItem("bookings") || "[]")


  


  if (!bookings.length)
    history.push("/");

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem('userId')

      if (userId) {
        const user = await getProfile(userId)

        setAlContado(user.paymentCondition === 'al contado')

        if (user.paymentCondition !== 'tarjeta') {
          onCreateBooking({ paymentId: null }) 
        }
      } else {
        onCreateBooking({ paymentId: null }) 
        setAlContado(false)
      }
    }

    fetchData()
  }, [])



  return (
    <Suspense fallback={<div />}>
      {" "}
      <Wrapper style={{ display: ShowWindowDimensions() > SM ? 'grid' : 'block' }}>
        {ShowWindowDimensions() > SM && <BgImage />}
        <Content>
          <Link to="/">
            <Logo role="img" aria-label="Jarvis logo" />
          </Link>
          {!alContado && <Headline>
            <LittleTitle>{t('checkout.paypal.titulo_pequenio')}</LittleTitle>
            <Title>{t('checkout.paypal.titulo')}</Title>
       {/*      <CardsContainer>
              <CardLogo role="img" aria-label="mastercard" />
              <CardLogo role="img" aria-label="visa" />
              <CardLogo role="img" aria-label="amex" />
            </CardsContainer> */}
            <div style={{ marginTop: "1em" }}></div>
            {!loading && <PayPalButton
              amount={totalToPay}
              onApprove={() => setLoading(true)}
              onSuccess={onCreateBooking}
              options={{
                clientId: "Af_ibNrHqYmEYVG07sQXQoI9tSUz2UUY1diPW70CSaRy61g-fIEjA9drdm_x6x3c0SGwXnVwE2F8NbsV"
              }}
            />}
            {loading && <p>{t('checkout.paypal.procesando_pago')}</p>}
          </Headline>}
        </Content>
      </Wrapper>
    </Suspense>
  );
};

export default SuccessPage;
