import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 700px;
  padding: 30px;
  height: 30vw;

  h4 {
    font-family: Poppins;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;

export const Item = styled.div`
  padding-bottom: 20px;
  h3 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  p {
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  ul {
    list-style: disc;

    padding-left: 40px;

    li {
      font-family: Poppins;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin-top:-10px;
      margin-bottom: 8px;
    }
  }
`;
