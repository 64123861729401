import styled from "styled-components";

import SelectCaret from "../../assets/icons/select-caret.svg";

export const Wrapper = styled.div<{selectedValue?:any}>`
  position: relative;

  label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    display: block;
    padding-bottom: 15px;


  }

  .basic-multi-select {
    width: 100%;
    transition: ${props => props.theme.defaultTransition("all", 0.15)};

    &:focus {
      border: 1px solid #000000;
    }
  }

  .select__control {
    padding: 6px;
    cursor: pointer;
    border-radius: 0;
    border-color: #000000;
    min-height: 52px;
    border-color:rgba(0,0,0,0.1);
    position: relative;
    border-color:${props => props.selectedValue !== "" ? '#000000' :  'rgba(0,0,0,0.1)'};

   

    &:after {
      content: "";
      position: absolute;
      background: url(${SelectCaret}) no-repeat center center / contain;
      width: 15px;
      height: 15px;
      right: 15px;
      opacity: 0.4;
    }
  }

  .select__control--is-focused {
    border-color: transparent !important;
    cursor: pointer;
    border-top: 1px solid #000000;
    border-color: #000000;
  }

  .select__menu {
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    border-radius: 0;
    position: absolute;
    top: 50px;
    box-shadow: none !important;
    width: 100.7%;
    left: -1px;
    margin-top: 0;
    z-index: 10;
    cursor: pointer;
    outline: 0 !important;
  }

  .select__control--menu-is-open {
    box-shadow: 0 0 0 1px #000000;
  }

  .select__control--is-focused {
    box-shadow: 0 0 0 1px #000000;
  }

  .select__single-value {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color:#000000;
  }

  .select__menu-list {
    padding: 0;
  }

  .select__multi-value {
    padding: 5px 10px;
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
  }
  .select__multi-value__label {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: #000000;
    text-transform: uppercase;
  }

  .select__multi-value__remove {
    padding: 0;
    margin: 0 4px;
    &:hover {
      background-color: #000000;
      svg {
        background-color: #000000;
        fill: #fff;
      }
    }

    svg {
      fill: #000000;
      width: 20px;
      height: 20px;
      font-weight: 300;
    }
  }

  

  .select__placeholder,


  .select__option {
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }


  .select__option {
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
  }
  .select__option--is-focused {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  .select__option.select__option--is-focused.select__option--is-selected {
    background-color: #f5f5f5;

    &:focus {
      background-color: #f5f5f5;
    }
  }

  .select__option--is-selected {
    background-color: ${"#ffffff"};
    /* color: #ffffff; */
    font-weight: 600;
    color: #000000 !important;

    &:hover {
      color: #000000 !important;
    }
  }

  .select__option--is-focused.select__option--is-selected {
    color: #000000 !important;
  }
  .select__dropdown-indicator,
  .select__indicator-separator {
    display: none;
  }

  .select__placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0,0,0,0.3) !important;
  }
`;
