import styled from "styled-components";
import { SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  background: #f6f9fa;
  text-align: center;

  h3 {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 40px;

    margin-top: -50px;
  }

  @media (max-width: ${SM}) {
    h3 {
      text-align: left;
      margin-left: 20px;

      font-weight: 500;
      font-size: 24px;
      line-height: 32px;

      margin-top: -184px;
      margin-bottom: 30px;
    }
  }
`;

export const Content = styled.div`
  display: inline-block;
  margin: -110px auto 120px auto;
  width: 800px;

  @media (max-width: ${SM}) {
    margin: 40px auto 120px auto;
    width: auto;
  }
`;

export const Grid = styled.div`
  max-width: 690px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;

  grid-gap: 0 60px;

  @media (max-width: ${SM}) {
    display: block;
  }
`;

export const Card = styled.div`
  margin-top: -5px;

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: left;

    color: #000000;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    opacity: 0.8;
    text-align: left;

    margin-bottom: 10px;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: #000000;
    opacity: 0.8;
  }

  ul {
    margin-top: 25px;
    text-align: left;
    li {
      display: inline-block;
      margin-right: 25px;
    }
  }

  > img {
    max-width: 200px;
    text-align: left;
    margin-left: -32px;
    display: block;
    margin-bottom: -15px;
  }

  @media (max-width: ${SM}) {
    margin-left: 26px;

    &:first-child {
      margin-bottom: 80px;
    }

    p {
      width: 90%;
    }
  }
`;

export const RedesIcon = styled.div<{ logo?: string; logoHover?: string }>`

		background:url(${props => props.logo}) no-repeat center center / contain;
		display: block;
		margin-bottom: -23px;
		width: 32px;
		height: 32px;


		transition:${props => props.theme.defaultTransition("all", 0.2)};

		&:hover{
		background:url(${props => props.logoHover}) no-repeat center center / contain;
		width: 32px;
		height: 32px;
		
		} 

`;

export const Image = styled.div<{ bg: string }>`
  background: url(${props => props.bg}) no-repeat center center / contain;
  height: 330px;
`;
