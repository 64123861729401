import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as SC from "./style";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  maxWidth?: string;
  fullscreen?: boolean;
  noBackground?: boolean;
}

const Modal: React.FC<Props> = ({ children, isOpen, closeModal, maxWidth, fullscreen, noBackground = false }) => {
  useEffect(() => {
    if (isOpen) {
      document.querySelector("html")?.classList.add("scroll-lock");
    } else {
      document.querySelector("html")?.classList.remove("scroll-lock");
    }
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <SC.Wrapper>
          <SC.ModalContent className={noBackground ? 'noBackground' : ''} animation={isOpen} maxWidth={maxWidth} fullscreen={fullscreen}>
            {noBackground && <SC.CloseBtn2 onClick={() => closeModal()} />}
            {!noBackground && <SC.CloseBtn onClick={() => closeModal()} />}
            {children}
          </SC.ModalContent>
          <SC.Overlay onClick={() => closeModal()} />
        </SC.Wrapper>
      ) : (
        <React.Fragment />
      )}
    </>,
    document.body
  );
};

export default Modal;
