import styled from "styled-components";

import EyeIcon from "../../assets/images/login/eye.svg";
import OffEye from "../../assets/images/login/eye-off.svg";

export const Wrapper = styled.div`
  label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    display: block;
    padding-bottom: 15px;
  }
`;

export const Input = styled.input<{
  errorMail?: string | boolean;
  name?: string;
  emailName?: string;
  filled?: any;
}>`
  background-color: transparent;
  border: 1px solid #000000;

  border: ${props =>
    props.errorMail === false && props.emailName === props.name
      ? "1px solid #000000"
      : "1px solid rgba(0, 0, 0, 0.1);"};
  outline: 0 !important;
  padding: 15px 0 15px 15px;
  width: 100%;

  font-family: Poppins;
  font-size: 14px;
  font-size:${props => props.filled};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color:${props =>
    props.errorMail === false && props.emailName === props.name
      ? "#F83939"
      : "#000000"};

  transition: ${props => props.theme.defaultTransition()};

  border-color:${props => props.filled !== "filled" ? 'rgba(0,0,0,0.1)!important' : '#000000'};

  &:focus {
    border-color: ${props =>
    props.errorMail === false && props.emailName === props.name
      ? "#F83939"
      : "#000000"};
/*     border-color: ${props => props.theme.mainBlue}; */
  }

  &::placeholder{
   color:rgba(0, 0, 0, 0.3);
  }
`;

export const RevealPass = styled.div<{ passType: string }>`
  position: absolute;
  height: 26px;
  width: 26px;
  background: url(${props => (props.passType !== "text" ? EyeIcon : OffEye)})
    no-repeat center center / contain;
  top: 15px;
  right: 0;
  cursor: pointer;
`;

export const RevealWrap = styled.div`
  position: relative;
`;
