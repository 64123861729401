import styled from "styled-components";
import { XS } from "../../utils/constants";

import NewsletterLogoImg from "../../assets/vip/logo.png";
import NewsletterBackgroundImg from "../../assets/vip/background.png";

export const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  padding: 30px 60px;

  @media (max-width: ${XS}) {
    padding: 20px;
    flex-direction: column;
  }
`;

export const NewsletterButton = styled.button`
  background: white;
  padding: 10px 20px;
  border: 2px solid #707070;
  font-weight: 500;
  font-size: 18px;
  width: 40%;

  @media (max-width: ${XS}) {
    width: 100%;
  }

  &:hover:enabled {
    cursor: pointer;
    background: lightgrey;
  }
`;

export const NewsletterInput = styled.input`
  background: #F4F4F4;
  padding: 20px 40px;
  border: none;
  margin-right: 20px;
  font-size: 16px;
  width: 60%;

  @media (max-width: ${XS}) {
    width: auto;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const NewsletterTitle = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 28px;
  color: white;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
`;

export const NewsletterSubtitle = styled.div`
  font-family: Poppins;
  font-size: 21px;
  color: white;
  text-align: center;
  font-weight: 400;
`;

export const NewsletterLogo = styled.div`
  background: url(${NewsletterLogoImg});
  height: 120px;
  width: 120px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  position: absolute;
  left: calc(50% - 60px);
`;

export const NewsletterBanner = styled.div`
  background: url(${NewsletterBackgroundImg});
  height: 300px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  margin-top: 60px;
`;

export const Wrapper = styled.div`
  padding-top: 21px;
  padding-bottom: 60px;
  position: relative;
  span[aria-label="Jarvis logo"] {
    top: 59px;
    right: -88px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  max-width: 1000px;
  margin-bottom: 40px;
  h1 {
    margin-top: 16px;
  }
  & + div {
    z-index: 2;
    padding-right: 0;
  }
`;

export const ImageHero = styled.span<{ bg?: string }>`
  width: calc(100% + 176px);
  height: 400px;
  background: var(--gray-light);
  transform: translate(-88px, -36px);
  z-index: 1;
  position: relative;
  background: url(${props => props.bg}) no-repeat center center / cover;

  @media (max-width: ${XS}) {
    height: 245px;
    margin: -30px -20px 20px;
    transform: none;
    width: auto;
  }
`;

export const SearchBarContainer = styled.div`
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  background: transparent;
  padding-right: 1px !important;

  > div:first-child {
    /* flex: 1; */
    border-left: 1px solid var(--secondary-gray-light);
    border-right: 1px solid var(--secondary-gray-light);
    background-color: #ffffff;

    width: calc(100% - 10px);

    > div {
      margin-bottom: 0;
      padding: 0;
      right: 0;
      left: 0;
    }
  }

  button {
    padding: 0 22px;
  }
`;
