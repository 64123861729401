import styled from "styled-components";
import CrossIcon from "../../assets/images/x.svg";
import { SM, LG } from "../../utils/constants";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
`;

export const InnerWrap = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;

  h5 {
    opacity: 1;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 10px;
  }

  @media (max-width: ${SM}) {
    max-width: unset;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    h5 {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      opacity: 0.8;
    }
  }
`;

export const FullLogoWrap = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: ${SM}) {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Close = styled.div`
  width: 30px;
  height: 30px;
  background: url(${CrossIcon}) no-repeat center center / contain;
  cursor: pointer;
  top: 40px;
  right: 40px;
  position: absolute;
`;

export const Candado = styled.div`
  margin-top: 30px;
  margin-bottom: 35px;
`;

export const InputWrap = styled.div`
  padding-bottom: 25px;
  max-width: 300px;
  margin: 0 auto;

  button {
    margin: -10px 0 25px;
    display: inline-block;
    width: calc(100% + 16px);
    font-weight: 500;
  }

  @media (max-width: ${SM}) {
    max-width: unset;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    input {
      box-sizing: border-box;
    }

    button {
      width: 100%;
      margin-top: auto;
    }
  }
`;

export const InputWrapNewPass = styled(InputWrap)`
  @media (max-width: ${SM}) {
    flex: unset;
      label + div > div {
        right: 15px;
      }
    }
`;

export const RecoverForm = styled.div`
  @media (max-width: ${SM}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
`;

export const GoToSesion = styled.span<{ position?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000 !important;
  text-align: center;
  text-decoration-line: underline;
`;

export const View = styled.div`
  @media (max-width: ${SM}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const BottomLink = styled.div`
  margin-top: 40px;
  cursor: pointer;
`;

export const LastStepInputs = styled.div`
  > div {
    padding: 0;
    @media (max-width: ${SM}) {
      &:last-child {
        margin-top: auto;
        padding-bottom: 30px;
        button {
          margin: 0;
          margin-top: 35px;
        }
      }
    }
  }
  button {
    display: inline-block;
    width: 100%;
    max-width: calc(330px - 10px);
    margin: 0 auto;
    margin-left: 17px;
    margin-top: 40px;

    margin-bottom:10px;
  }

  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: column;
    flex: 1;
    > div:last-child {
      button {
        margin-left: 0;
        max-width: unset;
      }
    }
    & + div {
      top: 136px;
      position: absolute;
    }
  }
`;

export const HeadlineWrap = styled.div`
  @media (max-width: ${SM}) {
    display: flex;

    a:last-child div {
      width: 28px;
      height: 28px;
      right: 20px;
    }
  }
`;

export const BottomButtons = styled.div``;


export const ErrorContainer = styled.div`

max-width:300px;
margin:5px auto 0;

@media(max-width:${LG}){
  max-width:unset;
}

`

