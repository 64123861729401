import React, { Suspense, FC, useState } from "react";
import { Wrapper } from "./style";
import Select from "react-select";

interface Props {
  label?: string;
  options?: any;
  placeholder?: string;
  selectedValue?: any;
  handleChange?: any;
  isSearchable?: boolean;
  disabled?: boolean;
}

const SimpleSelect: FC<Props> = ({
  options,
  label,
  placeholder,
  selectedValue,
  handleChange,
  isSearchable = false,
  disabled = false,
}) => {
  return (
    <Suspense fallback={<div />}>
      <Wrapper selectedValue={selectedValue}>
        <label>{label}</label>
        <Select
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={placeholder}
          isSearchable={isSearchable}
          isDisabled={disabled}
          value={options.find(
            (obj: { value: number }) => obj.value === selectedValue
          )} // set selected value
          onChange={e => handleChange(e)} // assign onChange function
        />
      </Wrapper>
    </Suspense>
  );
};

export default SimpleSelect;
