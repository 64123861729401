import { LOGIN, LOGOUT } from './../actions/auth/types'

const initialState = {
    isLogged: false,
};
  
const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLogged: action.value,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;