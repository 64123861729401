import styled from "styled-components";

export const Wrapper = styled.div`
    width: max-content;
    > div {
        display: flex;
        align-items: center;
        width: max-content;
    }
    > h3 {
        color: var(--black);
        opacity: 0.5;
        text-align: center;
    }
`;

export const Number = styled.span`
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: var(--primary-text);
    margin: 0 5px;
    min-width: 55px;
    text-align: center;
    box-sizing: border-box;
`;

const CircleBtn = styled.button`
    height: 26px;
    width: 26px;
    border-radius: 50%;
    border: 2px solid var(--secondary-gray-light);
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    transition: all 0.2s linear;
    &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 2px;
        background: var(--secondary-gray-light);
    }
    &:hover {
        background: var(--primary-color);
        border: 2px solid var(--primary-color);
        &::after, &::before {
            background: var(--white);
        }
    }
`;

export const Decrement = styled(CircleBtn)``;

export const Increment = styled(CircleBtn)`
    &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 2px;
        background: var(--secondary-gray-light);
        transform: rotate(90deg);
    }
`;