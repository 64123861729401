import React, { Suspense, FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  FullLogoWrap,
  InnerWrap,
  Close,
  Candado,
  InputWrap,
  InputWrapNewPass,
  RecoverForm,
  GoToSesion,
  View,
  BottomLink,
  LastStepInputs,
  HeadlineWrap,
  ErrorContainer
} from "./style";
import { Link, useLocation } from "react-router-dom";
import { Logo, Title, Subtitle } from "../../utils/commonStyle";
import BluePrintBg from "../../assets/images/login/blueprint-login.svg";
import CandadoIcon from "../../assets/images/login/candado.svg";
import RegularInput from "../../components/RegularInput";
import InputError from "../../components/InputError";
import Cta from "../../components/Cta";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";
import GeneralError from "../../components/GeneralError";
import { resetPasswordEmail, resetPasswordNew } from '../../actions/users/creators';

interface Props {}

const ReestablecerPassword: FC<Props> = () => {
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation().search);

  useWindowSize();

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [valueEmail, setValueEmail] = useState("");
  const [emailName, setEmailName] = useState("");

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
    email: ""
  });

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const sendEmail = async () => {
    await resetPasswordEmail(valueEmail);
  }

  const sendNewPassword = async () => {
    await resetPasswordNew(query.get('token')!, formData.password1);
  }

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    handleInputChange(email, 0, "");

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  const [step, setStep] = useState(query.get('token') ? 2 : 0);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <InnerWrap>
          <HeadlineWrap>
            <Link to="/">
              <Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <Link to="/">
              <Close />
            </Link>
          </HeadlineWrap>
          {step === 0 && (
            <View>
              <FullLogoWrap>
                <img src={BluePrintBg} alt="" />
              </FullLogoWrap>
              <Candado>
                <img src={CandadoIcon} alt="candado" />
              </Candado>
              <Title>{t('pantalla_olvide_mi_password.titulo')}</Title>
              <Subtitle>{t('pantalla_olvide_mi_password.texto')}</Subtitle>

              <RecoverForm>
                <InputWrap>
                  <RegularInput
                    label={""}
                    type={"text"}
                    placeholder={"nombre@email"}
                    onChange={handleValidMail}
                    errorMail={validateEmail}
                    name={"email"}
                    emailName={emailName}
                    filled={formData["email"] !== "" ? "filled" : "empty"}
                  />
                  {!validateEmail && emailName === "email" && (
                    <InputError text={t('generales.error_email')} />
                  )}

                  {/* CAMBIAR LA VARIABLE VISIBLE POR EL ERROR HANDLING QUE TIENE QUE SER */}

                  <ErrorContainer>
                    <GeneralError
                      visible={false}
                      text={t('generales.datos_incorrectos')}
                    />
                  </ErrorContainer>

                  <Cta
                    onClick={async () => {
                      await sendEmail();
                      setStep(1)
                    }}
                    isRoute={false}
                    toRoute={"inicio-sesion"}
                    theme="blueDarkTheme"
                    inactive={
                      validateEmail && emailName === "email" ? false : true
                    }
                  >
                    {t('pantalla_olvide_mi_password.boton_reestablecer')}
                  </Cta>

                    <Link to={"/login"}>
                      <GoToSesion position={true}>
                        {t('pantalla_olvide_mi_password.boton_volver')}
                      </GoToSesion>
                    </Link>
                </InputWrap>

              </RecoverForm>
            </View>
          )}

          {step === 1 && (
            <View>
              <FullLogoWrap>
                <img src={BluePrintBg} alt="" />
              </FullLogoWrap>
              <Candado>
                <img src={CandadoIcon} alt="candado" />
              </Candado>
              <Title>{t('pantalla_olvide_mi_password.titulo')}?</Title>
              <Subtitle>{t('pantalla_olvide_mi_password.texto_en_breve')}</Subtitle>

              <RecoverForm>
                <BottomLink onClick={async () => {
                  sendEmail();
                  window.location.href = '/';
                }}>
                  <GoToSesion position={false}>
                    {t('pantalla_olvide_mi_password.boton_enviar_de_nuevo')}
                  </GoToSesion>
                </BottomLink>
              </RecoverForm>
            </View>
          )}

          {step === 2 && (
            <View>
              <FullLogoWrap>
                <img src={BluePrintBg} alt="" />
              </FullLogoWrap>
              <Candado>
                <img src={CandadoIcon} alt="candado" />
              </Candado>
              <Title>{t('pantalla_olvide_mi_password.nueva_password')}</Title>
              <Subtitle>{t('pantalla_olvide_mi_password.text_nueva_password')}</Subtitle>

              <RecoverForm>
                <LastStepInputs>
                  <InputWrapNewPass>
                    <RegularInput
                      label={""}
                      type={"password"}
                      passwordInput={true}
                      value={formData["password1"]}
                      placeholder={"********"}
                      onChange={handleInputChange}
                      name={"password1"}
                      filled={formData["password1"] !== "" ? "filled" : "empty"}
                    />
                  </InputWrapNewPass>

                  <InputWrapNewPass>
                    <RegularInput
                      label={""}
                      type={"password"}
                      passwordInput={true}
                      value={formData["password2"]}
                      placeholder={"********"}
                      onChange={handleInputChange}
                      name={"password2"}
                      filled={formData["password2"] !== "" ? "filled" : "empty"}
                    />
                  </InputWrapNewPass>
                  <Cta
                    onClick={async () => {
                      await sendNewPassword();
                      window.location.href = '/';
                    }}
                    isRoute={true}
                    toRoute={"/"}
                    theme="blueDarkTheme"
                    inactive={
                      formData.password1 === "" ||
                      formData.password2 === "" ||
                      formData.password2 !== formData.password1
                        ? true
                        : false
                    }
                  >
                    {t('pantalla_olvide_mi_password.botom_cambiar_password')}
                  </Cta>
                </LastStepInputs>
                <ErrorContainer>
                  <GeneralError
                    visible={
                      formData.password2 !== formData.password1 ? true : false
                    }
                    text={t('pantalla_olvide_mi_password.contrasenia_no_coincide')}
                  />
                </ErrorContainer>
              </RecoverForm>
            </View>
          )}
        </InnerWrap>
      </Wrapper>
    </Suspense>
  );
};

export default ReestablecerPassword;
