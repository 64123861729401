import React, { Suspense, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, Grid, Card, Image, Content, RedesIcon } from "./style";

import JuanPic from "../../../assets/servicios-images/juan.png";
import PabloPic from "../../../assets/servicios-images/pablo.png";
import Linkedin from "../../../assets/icons/linkedin.svg";
import Twitter from "../../../assets/icons/Twitter.svg";

import TwitterHover from "../../../assets/servicios-images/twitter-azul.svg";
import LinkedinHover from "../../../assets/servicios-images/linkedin-azul.svg";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../../utils/AOSconfig";

interface Props {}

const Servicios: FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <Wrapper>
        <Content>
          <div data-aos="fade-up">
            <h3>{t('nuestros_servicios.hero.quienes_somos.titulo')}</h3>
          </div>

          <Grid>
            <div data-aos="fade-up" data-aos-delay="200">
              <Card>
                <img src={JuanPic} alt="Juan Fernando García" />
                {/*           <Image bg={JuanPic} /> */}
                <h4>Juan Fernando García</h4>
                <h6>{t('nuestros_servicios.hero.quienes_somos.puesto')}</h6>

                <p>{t('nuestros_servicios.hero.quienes_somos.bio')}</p>
                <ul>
                  <li>
                    <a
                      href="https://ar.linkedin.com/in/juan-fernando-garcia-31b19847"
                      target="_blank"
                    >
                      {" "}
                      <RedesIcon logo={Linkedin} logoHover={LinkedinHover} />
                      {/*        <img src={Linkedin} alt="Linkedin" /> */}
                    </a>
                  </li>
                </ul>
              </Card>
            </div>

            <div data-aos="fade-up" data-aos-delay="400">
              <Card>
                <img src={PabloPic} alt="Pablo Agustín García" />
                {/*       <Image bg={PabloPic} /> */}
                <h4>Pablo Agustín García</h4>
                <h6>{t('nuestros_servicios.hero.puesto')}</h6>

                <p>{t('nuestros_servicios.hero.bio')}</p>
                <ul>
                  <li>
                    <a
                      href="https://ar.linkedin.com/in/pablo-agust%C3%ADn-garc%C3%ADa-77848027"
                      target="_blank"
                    >
                      {" "}
                      <RedesIcon logo={Linkedin} logoHover={LinkedinHover} />
                      {/*   <img src={Linkedin} alt="Linkedin" /> */}
                    </a>
                  </li>
                </ul>
              </Card>
            </div>
          </Grid>
        </Content>
      </Wrapper>
    </Suspense>
  );
};

export default Servicios;
