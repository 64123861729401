import React from "react";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

interface Props {
    title: string
    number: number;
    onDecrement: () => void;
    onIncrement: () => void;
};

const NumberPicker: React.FC<Props> = ({ title, number, onDecrement, onIncrement }) => {
    return(
        <SC.Wrapper>
            <CSC.LittleTitle>{title}</CSC.LittleTitle>
            <div>
                <SC.Decrement type="button" onClick={onDecrement} />
                <SC.Number>
                    {number}
                </SC.Number>
                <SC.Increment type="button" onClick={onIncrement} />
            </div>
        </SC.Wrapper>
    );
};

export default NumberPicker;