import styled from "styled-components";
import { SM, LargerScreens, LG } from "../../../utils/constants";

export const Wrapper = styled.div``;

export const ServiceList = styled.ul<{ hideMobile?: boolean }>`
  display: ${props => (props.hideMobile !== true ? "flex" : "none")};
  max-width: max-content;
  max-width: 450px;
  flex-wrap: wrap;
  margin-top: 16px;

  @media (max-width: ${SM}) {
    padding-left: 20px;
    max-width: unset;
    width: calc(90% - 20px);
    display: ${props => (props.hideMobile ? "none" : "flex")};
  }
`;

export const TitleContainer = styled.div`
  max-width: 605px;
  h1 {
    font-size: 30px;
    line-height: 37px;
    padding-right: 50px;
  }
  @media (max-width: ${SM}) {
    padding-left: 20px;
    max-width: unset;
    width: 75%;

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      padding: 0;

      color: #000000;
    }
  }
`;

export const Service = styled.li`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 8px;
  background: rgba(209, 223, 229, 0.2);
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
  transition: all 0.2s linear;
  &:hover {
    background: rgba(209, 223, 229, 0.5);
  }
`;

export const Image = styled.img``;

export const ImageAirport = styled.div<{ bg?: string }>`
  max-width: 400px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin-left: 0 !important;

  @media (min-width: ${SM}) {
    height: 210px;
    width: 100px !important;
    box-sizing: border-box;
  }
  @media (max-width: ${SM}) {
    max-width: unset;
  }
`;

export const Container = styled.div`
  display: flex;

  @media (min-width: ${LargerScreens}) {
    max-width: 1280px;
    margin: 0 auto;
  }

  > div {
    flex: 1;
    margin-left: 20px;
    padding-top: 5px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: ${SM}) {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 120px;
    margin-bottom: 40px;
  }
`;

export const Map = styled.div`
  /*  margin-left: -56px !important; */
  padding-top: 0 !important;
  flex: none !important;
  margin-right: 20px;
  margin-left: 0 !important;

  button,
  .gmnoprint {
    display: none !important;
  }

  > div {
    width: 260px !important;
    height: 210px !important;
    display: block !important;
    margin: 0;
    @media (min-width: ${LG}) {
      width: 310px !important;
    }
  }

  @media (max-width: ${SM}) {
    margin-right: 0 !important;
    > div {
      width: 100% !important;
      height: 120px !important;
    }
  }

  @media (min-width: ${LargerScreens}) {
    margin-left: 0 !important;
  }
`;

export const DescuentoWrap = styled.div`
  padding: 33px 120px 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${SM}) {
    flex-direction: column;
    text-align: center;
    display: block;
    padding: 33px 20px 60px;

    a {
      display: block;
      text-align: center;
      margin-top: 30px;

      button {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
`;

export const DescuentoHead = styled.div`
  p {
    opacity: 0.8;
  }

  @media (max-width: ${SM}) {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const ResultSearchBar = styled.div`
  ul {
    width: 80%;
    margin: 0 auto;
  }
`;

export const ShowModalTitle = styled.div`
  padding: 30px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  height: 200px;
  line-height: 24px;
  overflow-y: scroll;

  h3 {
    margin-bottom: 10px;
  }

  p {
  
  }
`;
