import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import moment from "moment";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

import AlertIcon from "../../assets/icons/alert-circle-red.svg";

interface Props {
  title: string;
  placeholder: string;
  setDateValue: any;
  dateValue: any;
  parseDate?: string;
  noLabel?: boolean;
  onChange?: any;
  openCalendar?: boolean;
  minDate?: Date;
  maxDate?: Date;
  withDefaultInput?: boolean;
  toolTip?: {
    title?: string;
    text?: string;
  };
  blockedTime?: boolean
}

const JarvisDatePicker: React.FC<Props> = ({
  title,
  placeholder,
  setDateValue,
  dateValue,
  parseDate,
  noLabel,
  onChange,
  openCalendar = false,
  minDate,
  maxDate,
  withDefaultInput = false,
  toolTip,
  blockedTime
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showTool, setShowTool] = useState(true);

  return (
    <SC.Wrapper
      noLabel={noLabel}
      defaultInput={withDefaultInput}
      isEmpty={dateValue === ""}
    >
      {!noLabel && (
        <SC.DatePickerLabel onClick={() => setOpen(!open)}>
          <CSC.LittleTitle>{title}</CSC.LittleTitle>
          {parseDate === "Invalid date" || parseDate === "" ? (
            <SC.LabelContainer>
              <SC.Title>{t("generales.seleccionar_la_fecha")}</SC.Title>

              <SC.Arrow />
            </SC.LabelContainer>
          ) : (
              <SC.TextContainer>
                <SC.Title style={{ width: "auto" }}>{parseDate}</SC.Title>
                {blockedTime && (
                  <img
                    onMouseOver={() => {
                      setShowTool(true);
                    }}
                    onMouseLeave={() => setShowTool(false)}
                    src={AlertIcon}
                    alt="alert"
                  />
                )}
              </SC.TextContainer>
          )}
        </SC.DatePickerLabel>
      )}

      <DatePicker
        onChange={setDateValue}
        value={
          typeof dateValue === "string" &&
            dateValue !== "" &&
            dateValue !== null

            ? moment.utc(
              moment.utc(dateValue.split(",")[0], "DD/MM/YYYY").format(
                "MM/DD/YYYY"
              )
            ).toDate()
            : dateValue
        }
        isOpen={open}
        onCalendarClose={() => setOpen(false)}
        format={"d/M/y"}
        {...{ minDate: minDate, maxDate: maxDate }}
      />


      <SC.Tooltip
        showTool={showTool && blockedTime!}
        showAlert={blockedTime!}
      >
        <h3>{toolTip && toolTip.title}</h3>
        <p>{toolTip && toolTip.text}</p>
        <em />
      </SC.Tooltip>

    </SC.Wrapper>
  );
};

export default JarvisDatePicker;
