import styled from "styled-components";
import BgPicture from "../../assets/images/login/registro-login.jpg";
import CloseImg from "../../assets/images/x.svg";

import { SM , LargerScreens} from "../../utils/constants";

export const Wrapper = styled.div`
  /*   padding: 9px 120px 0; */

  display: grid;
  grid-template-columns: 25vw 1fr;
  

  @media (max-width: ${SM}) {
    display: block;
  }
  @media(min-width:${LargerScreens}){
    grid-template-columns: 32vw 68vw;
  }
`;

export const BgImage = styled.div`
  height: 100vh;
  background: url(${BgPicture}) no-repeat center center / cover;
`;

export const Grid = styled.div`
  padding: 38px 38px 38px 80px;

  @media (max-width: ${SM}) {
    padding: 30px 20px;
  }
`;

export const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  > a {
    opacity: 0;
    visibility: hidden;
  }

  @media (max-width: ${SM}) {
    > a {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const AgenciaWrap = styled.div`
  position: relative;
  top: -10px;

  > a {
    color: #000000 !important;
  }

  @media (max-width: ${SM}) {
    > a {
      margin-left: -20px;
    }
  }
`;

export const InnerWrap = styled.div`
  padding-top: 0;

  h2 {
    padding-bottom: 5px;
  }

  @media (max-width: ${SM}) {
    margin-top: 30px;

    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    h5 {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
    }
  }
`;

export const AgenciaLink = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
`;

export const CloseBtn = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url(${CloseImg}) no-repeat center center / contain;
  display: inline-block;
  margin-left: 40px;
`;

export const FormlogIn = styled.div`
  padding-top: 35px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 45px;

  @media (max-width: ${SM}) {
    display: block;
  }

  @media(min-width: ${SM}) {
    max-width: 600px;
  }
`;

export const InputWrap = styled.div`
  padding-bottom: 25px;
  @media(min-width: ${SM}) {
    max-width: 600px;
  }

  @media (max-width: ${SM}) {
    margin-right: 20px;
  }
`;

export const LastWrap = styled.div`
  margin-top: -5px;

  > label {
    padding-top: 20px;
    padding-bottom: 80px;
  }
`;

export const SelectWrap = styled.div`
  > div {
    margin-right: -17px;
  }

  @media (max-width: ${SM}) {
    > div {
      margin-right: 3px;
      margin-bottom: 25px;
    }
  }
`;

export const View2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 80px;
  padding-top: 45px;

  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: column-reverse;

    grid-gap: 45px;
  }
`;

export const UploadPhoto = styled.div`
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 15px;
  }

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    cursor: pointer;

    position: relative;
    width: 120px;
    display: block;
    overflow: hidden;
 
    input {
      position: absolute;
      width: 220px;
      left: -102px;
      cursor: pointer;
      opacity: 0;
    }
  }

  @media (max-width: ${SM}) {
    width: auto;
    margin: 0 auto;

    h5 {
      opacity: 0;
      visibility: hidden;
    }

    span {
      text-align: center;
      margin:0 auto;

    }
  }
`;

export const PhotoHolder = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border: 1px solid #00000029;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;

  input {
    border: 1px solid red;
    height: 88px;
    width: 88px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }

  @media (max-width: ${SM}) {
    margin: 0 auto 15px;
  }
`;

export const PhotoShow = styled.div`
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;

  display: flex;
  width: 88px;
  height: 88px;
  border: 1px solid #00000029;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;

  @media (max-width: ${SM}) {
    margin: 0 auto;
    text-align: center;
    margin: 0 auto 15px;
  }
`;

export const BottomWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 110px;

  > div {
    margin-top: 20px;
  }
  span {
    max-width: 240px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #000000;

    a {
      color: #000000;
    }
  }

  @media (max-width: ${SM}) {
    margin-bottom: 50px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  a {
    text-decoration: none !important;
    color: inherit !important;
  }

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const BackButton = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;

  @media (max-width: ${SM}) {
    width: 100%;
  }
`;

export const NextButton = styled.div<{ inactive?: boolean }>`
  width: 209px;
  height: 53px;
  background: ${props => (props.inactive ? "#00000012" : "#1212c9")};
  cursor: ${props => (props.inactive ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${props => props.theme.defaultTransition()};
  margin-left: 30px;
  color: ${props => (props.inactive ? "rgba(0,0,0,0.3)" : "#ffffff")};

  &:hover {
    background-color: ${props => (props.inactive ? "#0000001f" : "#0909AE")};
  }

  span {
    opacity: 0.3;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  @media (max-width: ${SM}) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;


export const BasesBtn = styled.span`

text-decoration: underline;
display:block;
cursor:pointer;

`



export const ImageErrorMsg = styled.h6<{ show?: boolean }>`
  padding-top: 10px;
  display: inline-block;
  font-size: 14px;
  transition: all ease-in-out 0.3s;
  opacity: ${props => (props.show ? 1 : 0)};
  font-weight:400;
  max-width: 200px;
  line-height:18px;

  @media(max-width:${SM}){
    padding-top: 15px;
    opacity:1;
    text-align:center;
    line-height: 20px;
    display:${props => props.show ? 'block' : 'none'};
    margin:0 auto;
  }
`;