import styled from "styled-components";

export const Wrapper = styled.label`
    width: 50px;
    height: 24px;
    display: inline-flex;
`;

export const Switch = styled.span`
    width: 100%;
    height: 100%;
    border-radius: 26px;
    background: var(--secondary-gray-light);
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
    cursor: pointer;
    &::after {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--white);
        position: absolute;
        top: 2px;
        left: 2px;
        transition: all 0.2s linear;
    }
`;

export const ToggleContent = styled.span<{checked: boolean}>`
    display: none;
    ${props => props.checked && `
        & + ${Switch} {
            background: var(--primary-color);
            &::after {
                left: calc(100% - 22px);
            }
        }
    `};
`