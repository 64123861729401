import styled from "styled-components";
import { SM } from "../../../../utils/constants";

export const Wrapper = styled.div`
  p {
    opacity: 0.5;
  }

  

  @media (max-width: ${SM}) {
    > h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      color: #000000;
    }

    > p {
      opacity: 0.8;
    }

    button{
      position:relative;
    }
  }
`;

export const CountersContainer = styled.div`
  padding-top: 40px;
  max-width: 640px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 53px;

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr 1fr;
    gap: 50px 10px;
  }
`;
