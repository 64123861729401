import styled from "styled-components";
import closeIcon from "../../assets/icons/close-button.svg";
import { SM, XS } from "../../utils/constants";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background: rgba(80, 80, 80, 0.6);
`;

export const ModalContent = styled.div<{
  animation: boolean;
  maxWidth?: string;
  fullscreen?: boolean;
}>`
  background: var(--white);
  box-shadow: var(--shadow);
  min-width: 572px;
  min-height: 285px;
  width: ${props => (props.maxWidth ? "100%" : "auto")};
  max-width: ${props =>
    props.maxWidth ? props.maxWidth : props.fullscreen ? "unset" : "auto"};
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  max-height: calc(100% - 10px);
  overflow: auto;
  &.noBackground {
    background: none;
    box-shadow: none;
    padding-right: 30px;
  }
  ${props =>
    props.animation &&
    `
        animation: fadeInModal 0.2s both;
    `};

  & + ${Overlay} {
    opacity: 0;
    ${props =>
      props.animation &&
      `
            animation: fadeInModal 0.2s both;
        `};
  }

  @keyframes fadeInModal {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media (max-width: ${SM}) {
    &.noBackground {
      padding-right: 15px;
    }
  }

  @media (max-width: ${SM}) {
    max-width: ${props => (props.fullscreen ? "100vw" : "95%")};
    width: 95%;
    min-width: unset;
    max-height: ${props => (props.fullscreen ? "unset" : "inherit")};
    height: ${props => (props.fullscreen ? "96vh" : "auto")};
    overflow-y:scroll;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 24px;
  right: 16px;
  background: url(${closeIcon}) center / contain no-repeat;
  width: 32px;
  height: 32px;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index:900;

  @media (max-width: ${SM}) {
    width: 28px;
    height: 28px;
  }
`;

export const CloseBtn2 = styled.button`
  position: absolute;
  background: url(${closeIcon}) center / contain no-repeat;
  width: 32px;
  height: 32px;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index:900;
  background-color: white;
  background-size: 30px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  top: 30px;
  right: 0px;

  @media (max-width: ${XS}) {
		width: 30px;
    height: 30px;
    top: 50px;
    right: 0px;
    background-size: 20px;
	}
`;
