import styled from "styled-components";
import BgPicture from "../../../assets/images/success-bg.jpg";
import { SM, LargerScreens } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 25vw 1fr;



  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.8;
  }

  @media(min-width:${LargerScreens}){
    grid-template-columns: 35vw 65vw;
  }
`;

export const BgImage = styled.div`
  height: 100vh;
  background: url(${BgPicture}) no-repeat center center / cover;
`;

export const Content = styled.div`
  max-width: 610px;

  > a {
    margin-bottom: 20vh;
    display: inline-block;
    margin-left: 80px;
    margin-top: 40px;
  }

  @media (max-width: ${SM}) {
    a {
      margin-left: 20px;
    }
  }
`;

export const Headline = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 80px;
  > p {
    padding-top: 5px;
  }

  @media (max-width: ${SM}) {
    margin-left: 0;
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
    }

    width: 95%;
    margin: 0 auto;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    &:first-child {
      padding-left: 0 !important;
    }
  }

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    width: 90%;

    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);

    button {
      width: 100%;
      display: flex;
      justify-content: center;

      &:first-child {
        padding-left: 30px !important;
      }
    }
  }
`;
