import { SET_BUTTON_DISABLED } from '../actions/buttonActions';

const initialState = {
  buttonIsDisabled: true,
};

const buttonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BUTTON_DISABLED:
      return {
        ...state,
        buttonIsDisabled: action.payload,
      };
    default:
      return state;
  }
};

export default buttonReducer;