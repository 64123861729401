import styled from "styled-components";
import { Paragraph } from "../../utils/commonStyle";
import { LG, SM, XS, LargerScreens } from "../../utils/constants";

export const MainWrapper = styled.div`
  background-color: #f6f9fa;
  margin-top: -250px;

  @media (max-width: ${XS}) {
    margin-top: -300px;
  }
`;

export const Wrapper = styled.div`
  padding: 260px 120px 100px 120px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 0 40px;

  button {
    width: max-content;
  }

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
    button {
      width: 100%;
      margin-top: 35px;
      margin-bottom: -69px;

      span {
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }

  @media (max-width: ${XS}) {
    padding: 260px 20px 100px 20px;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1250px;
    margin: 0 auto;
    padding:260px 0px 100px 0;
  }
`;

export const TitleContainer = styled.div`
  max-width: 420px;

  @media (max-width: ${LG}) {
    max-width: 280px;
  }

  @media (max-width: ${LG}) {
    max-width: unset;
  }

  h2 {
    margin-top: 8px;
  }

  @media (max-width: ${XS}) {
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
    }
  }
`;

export const Services = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 30px 48px;
  margin-top: -10px;

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
  }
`;

export const ServiceIcon = styled.span<{ icon: string }>`
  background: url(${props => props.icon}) center / contain no-repeat;
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: -2px;
  margin-left: -20px;
`;

export const ServiceTitle = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

export const ServiceDescription = styled.p`
  font-size: 14px;
  line-height: 23.8px;
`;

export const AgencyService = styled.div`
  margin-top: 96px;
  transform: translateX(-96px);
  height: 302px;
  width: calc(100% + 184px);
  box-sizing: border-box;
  background: var(--gray-light);
  padding: 0 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 33px;
`;

export const Headline = styled.div``;

export const TextP = styled(Paragraph)`
  padding-top: 5px;
  padding-bottom: 25px;
`;

export const ServiceText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
  color: #000000;
  opacity: 0.8;

  padding-top: 0;
  padding-bottom: 10px;
`;


export const LINK = styled.div`


cursor:pointer;

`
