import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import LayoutGeneral from "./layouts/general";
import Agencia from "./pages/agencia";
import AgenciaDetalle from "./pages/agencia/transacciones/detalle";
import Checkout from "./pages/Checkout";
import CheckoutPage from "./pages/Checkout/Success";
import PaypalPage from "./pages/Checkout/Paypal";
import Homepage from "./pages/homepage";
import ConfirmacionReserva from "./pages/confirmacionReserva";

//VIEWS
import Login from "./pages/login";
import MyReservations from "./pages/MyReservations";
import ReestablecerPassword from "./pages/reestablecerPassword";
import Registro from "./pages/registro";
import RegistroFinal from "./pages/registroFinal";
import ResultsDetail from "./pages/ResultsDetail";
import Servicios from "./pages/servicios";
import styleTheme from "./utils/styleTheme";
import Logout from "./pages/logout";
import ConfirmationPage from "./pages/Confirmation";

function App() {
  const history = useHistory();

  React.useEffect(() => {
    history &&
      history.listen(location => {
        (window as any).dataLayer.push({
          event: "pageview",
          page: { path: location.pathname + location.search }
        });
      });
  }, []);

  return (
    <ThemeProvider theme={styleTheme}>
      <Router>
        <Suspense fallback={null}>
          <Switch>
            <Route
              exact
              path="/"
              children={
                <LayoutGeneral>
                  <Homepage />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/resultados/:id"
              children={
                <LayoutGeneral>
                  <ResultsDetail />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/mis-reservas"
              children={
                <LayoutGeneral>
                  <MyReservations />
                </LayoutGeneral>
              }
            />
            <Route exact path="/login" children={<Login />} />
            <Route exact path="/logout" children={<Logout />} />
            <Route exact path="/registro" children={<Registro />} />
            <Route exact path="/registro-final" children={<RegistroFinal />} />
            <Route
              exact
              path="/reestablecer-password"
              children={<ReestablecerPassword />}
            />
            <Route exact path="/checkout" children={<Checkout />} />
            <Route
              exact
              path="/agencia"
              children={
                <LayoutGeneral>
                  <Agencia />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/servicios"
              children={
                <LayoutGeneral>
                  <Servicios />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/confirmacion-de-reserva"
              children={
                <LayoutGeneral noHeader={true}>
                  <ConfirmacionReserva />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/agencia/detalle/:id"
              children={
                <LayoutGeneral noHeader={true}>
                  <AgenciaDetalle />
                </LayoutGeneral>
              }
            />
            <Route
              exact
              path="/confirmacion/:bookingId"
              children={
                <LayoutGeneral noHeader={true} noFooter={true}>
                  <ConfirmationPage />
                </LayoutGeneral>
              }
            />
            <Route exact path="/checkout/pagar" children={<PaypalPage />} />
            <Route
              exact
              path="/checkout/success/:bookingId"
              children={<CheckoutPage />}
            />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
