import styled from "styled-components";

export const Wrapper = styled.div`
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;

    color: #f83939;
    margin-top:4px;

    text-align: left;
  }
`;
