import styled from "styled-components";
import BgPicture from "../../assets/images/login/login-img.jpg";
import CloseImg from "../../assets/images/x.svg";

import { SM, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.div`
  /*   padding: 9px 120px 0; */

  display: grid;
  grid-template-columns: 25vw 1fr;

  @media (max-width: ${SM}) {
    display: block;
  }

  @media(min-width:${LargerScreens}){
    grid-template-columns: 32vw 68vw;
  }
`;

export const BgImage = styled.div`
  height: 100vh;
  background: url(${BgPicture}) no-repeat center center / cover;
`;

export const Grid = styled.div`
  padding: 38px 38px 38px 80px;

  @media (max-width: ${SM}) {
    padding: 30px 20px;
  }
`;

export const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const AgenciaWrap = styled.div`
  position: relative;
  top: -10px;
  > a {
    color: #000000 !important;
  }
`;

export const InnerWrap = styled.div`
  padding-top: 65px;

  h2 {
    padding-bottom: 5px;
  }

  @media (max-width: ${SM}) {
    padding-top: 20px;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    h5 {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
    }
  }
`;

export const AgenciaLink = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #000000;
`;

export const CloseBtn = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url(${CloseImg}) no-repeat center center / contain;
  display: inline-block;
  margin-left: 40px;
`;

export const FormlogIn = styled.div`
  max-width: 300px;
  padding-top: 35px;

  a {
    color: inherit !important;
  }

  @media (max-width: ${SM}) {
    max-width: unset;
  }
`;

export const InputWrap = styled.div`
  padding-bottom: 25px;

  @media (max-width: ${SM}) {
    margin-right: 20px;
  }
`;

export const LastWrap = styled.div`
  margin-top: -5px;

  > label {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  @media (max-width: ${SM}) {
    > a {
      display: block;
    }
  }
`;

export const MobileButtons = styled.div`
/*   position: absolute;
  width: 89%;
  bottom: 30px; */
  margin-bottom:50px;
  a,
  button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  button {
    margin-bottom: 20px;
  }
`;
