import React, { Suspense, FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { logout } from "../../actions/auth/creators";

interface Props {}

const Logout: FC<Props> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    localStorage.removeItem("bookings");
    window.location.href = "/"
  }, []);

  return (
    <Suspense fallback={<div />}>
    </Suspense>
  );
};

export default Logout;
