import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ServicePackage from "../../components/ServicePackage";
import DetailWhiteCard from "../../components/DetailWhiteCard";
import InputCheckbox from "../../components/InputCheckbox";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/Modal";
import Cta from "../../components/Cta";
import BasesYCondiciones from "../../components/BasesYCondiciones";

import useBookings from "../../utils/useBookings";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import { Airport } from "../../actions/airports/types";
import { Pack, Service } from "../../actions/packs/types";
import { TEMP_PACKAGES } from "../agencia/transacciones/detalle/TEMP_DATA";

import { getTotalPrice, diffPack, diffServices } from "../../utils/getPriceAndSurcharge";
import { Setting } from "../../actions/settings/types";
import { getAll } from "../../actions/settings/creators";

export interface Passenger {
  name: string;
  dob: string;
  phone: string;
  email: string;
  passport: string;
  nationality: string;
  notes: string;
}
export interface Booking {
  airport: Airport;
  airline: string;
  bookingType: string;
  flightType: string;
  date: string;
  flightNumber: string;
  adultsCount: number;
  childsCount: number;
  infantsCount: number;
  bagsCount: number;
  pack: Pack;
  discount?: any;
  passengers: Passenger[];
}

interface Props {}

const MyReservations: React.FC<Props> = () => {
  const { t } = useTranslation();

  useWindowSize();

  const getNumbersList = () => {
    let list = Array.from(Array(10).keys());
    let newOptions = list.map(item => ({
      title: item.toString(),
      value: item.toString()
    }));
    return newOptions;
  };

  const [checkValue, setCheckValue] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const [openBases, setOpenBases] = useState(false);
  const [modalOption, setModalOption] = useState<
    "delete" | "changeReservation"
  >("delete");
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const [servicepack, setServicepack] = useState<IPackageHandled[]>(
    [...TEMP_PACKAGES].map((item, index) => ({
      ...item,
      selected: index === 1 ? true : false
    }))
  );

  const [_surcharge, setSurcharge] = useState<number>(0);
  const [_schedule, setSchedule] = useState<number[]>([0, 0]);

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getAll();

      const _surcharge = parseInt(
        settings.find(({ key }: Setting) => key === "surcharge")!.value
      );
      const _schedule_from = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_from")!.value
      );
      const _schedule_to = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_to")!.value
      );
      const _schedule = [_schedule_from, _schedule_to];

      setSurcharge(_surcharge);
      setSchedule(_schedule);
    };
    fetchSettings();
  }, []);

  const { bookings, updateBookings } = useBookings({});

  useEffect(() => {
    document.body.style.overflow = "inherit";
    document.documentElement.style.overflow = "inherit";
  }, []);

  useEffect(() => {
    if (openModal || openModalDetalle || openBases) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openModal, openModalDetalle, openBases]);

  const onClick = (id: string) => {
    let newServices = [...servicepack];
    newServices = newServices.map(item => ({
      ...item,
      selected: item.id === id ? true : false
    }));
    setServicepack(newServices);
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (bookings.length > 0) {
    } else {
      window.location.href = "/";
    }
  }, []);

  const isEng = localStorage.getItem("lang") === "EN";

  const pax = (booking: any) => booking.adultsCount + booking.childsCount;

  const diffPackPricePax = (booking: any) =>
    diffPack(booking.pack, pax(booking)) - booking.pack.packPrice * (booking.adultsCount + booking.childsCount);

  const diffServicesPricePax = (booking: any) =>
    diffServices(booking.pack.services, pax(booking)) - (booking.pack.services || []).map(({ servicePrice }: any) => servicePrice) * (booking.adultsCount + booking.childsCount);

  const getPriceReservation = (booking: any) => {
    return getTotalPrice(
      booking,
      booking.date.split(",")[1]?.replace(" ", ""),
      _surcharge,
      _schedule
    );
  };

  const removeBooking = (index: number) => {
    let newBookings = [...bookings];
    newBookings.splice(index, 1);
    updateBookings(newBookings);
    setOpenModal(false);
    if (newBookings.length === 0) {
      window.location.href = "/";
    }
  };

  const discountValue = (booking: any) =>
    getPriceReservation(booking).beforeDiscount - getPriceReservation(booking).totalPrice;

  return (
    <SC.Wrapper>
      {ShowWindowDimensions() > SM && (
        <CSC.LogoFull role="img" aria-label="Jarvis logo" />
      )}

      <CSC.MediumTitle>{t("mis_reservas.titulo")}</CSC.MediumTitle>
      <CSC.Paragraph>{t("mis_reservas.subtitulo")}</CSC.Paragraph>
      <SC.CodeContainer>
        {bookings.map((booking: any, index: number) => (
          <React.Fragment key={index}>
            <SC.AirportCode reserType={booking.bookingType}>
              <CSC.ReservationIcon type={booking.bookingType} />
              <CSC.AlternativeTitle as="span">
                {booking.airport.airportCode}
              </CSC.AlternativeTitle>
            </SC.AirportCode>
          </React.Fragment>
        ))}
      </SC.CodeContainer>
      <SC.GridContainer>
        <SC.ReservationsContainer>
          {bookings.map((booking: any, index: number) => (
            <SC.ReservationsItem key={index}>
              <SC.TitleContainer>
                <CSC.ReservationIcon type={booking.bookingType!} />
                <div>
                  <CSC.MediumParagraph>{booking.date}</CSC.MediumParagraph>
                  <CSC.MediumTitle>
                    {booking.airport.airportCode +
                      ", " +
                      booking.airport.airportName}
                  </CSC.MediumTitle>
                  {/* {booking.bagsCount && (
                    <CSC.MediumParagraph>{booking.bagsCount}</CSC.MediumParagraph>
                  )} */}
                </div>
              </SC.TitleContainer>
              <SC.DropdownsContainer>
                <Dropdown
                  title={t("resultados.caracteristicas.adultos")}
                  options={getNumbersList()}
                  placeholder={booking?.adultsCount?.toString()}
                  onChange={() => {}}
                  arrow={false}
                />
                <Dropdown
                  title={t("resultados.caracteristicas.ninos") + " (6-12)"}
                  options={getNumbersList()}
                  placeholder={booking.childsCount?.toString()}
                  onChange={() => {}}
                  arrow={false}
                />
                <Dropdown
                  title={t("resultados.caracteristicas.infantes") + " (1-5)"}
                  options={getNumbersList()}
                  placeholder={booking.infantsCount?.toString()}
                  onChange={() => {}}
                  arrow={false}
                />
                <Dropdown
                  title={t("resultados.caracteristicas.Equipaje")}
                  options={getNumbersList()}
                  placeholder={booking.bagsCount?.toString()}
                  onChange={() => {}}
                  arrow={false}
                />
              </SC.DropdownsContainer>
              <ServicePackage
                pack={booking.pack}
                key={booking.pack && booking.pack._id}
                inlineText
              />
              <SC.ButtonsContainer>
                <CSC.Link
                  type="button"
                  as="button"
                  onClick={() => {
                    setOpenModal(true);
                    setModalOption("delete");
                    setDeleteIndex(index);
                  }}
                >
                  {t("mis_reservas.eliminar")}
                </CSC.Link>
              </SC.ButtonsContainer>
            </SC.ReservationsItem>
          ))}
        </SC.ReservationsContainer>

        {ShowWindowDimensions() > SM ? (
          <DetailWhiteCard
            title={t("mis_reservas.detalle_de_pago")}
            goTo={"/checkout"}
            textBtn={t("mis_reservas.boton_continuar")}
            inactiveReservation={!checkValue}
          >
            <form>
              {bookings.map((booking: any, index: number) => (
                <SC.PaymentContainer key={index}>
                  <CSC.ParagraphBold>
                    {booking.pack && booking.pack.packName} -{" "}
                    {booking.airport.airportCode}
                  </CSC.ParagraphBold>
                  <CSC.MediumParagraph>
                    {booking.pack.packCurrency}{" "}
                    {booking.pack &&
                      parseInt(booking.pack.packPrice) *
                        (booking.adultsCount + booking.childsCount)}
                  </CSC.MediumParagraph>
                  {booking.pack?.services?.map(
                    (extra: Service, index: number) => (
                      <React.Fragment key={index}>
                        <CSC.MediumParagraph>
                          {isEng
                            ? extra.serviceNameEN || extra.serviceName
                            : extra.serviceName}{" "}
                          ({booking.adultsCount + booking.childsCount})
                        </CSC.MediumParagraph>
                        <CSC.MediumParagraph>
                          {extra.serviceCurrency}{" "}
                          {extra.type !== "no multiplica" ? (
                            <>
                              {parseInt(extra.servicePrice) *
                                (booking.adultsCount + booking.childsCount)}
                            </>
                          ) : (
                            <>{parseInt(extra.servicePrice)}</>
                          )}
                        </CSC.MediumParagraph>
                      </React.Fragment>
                    )
                  )}

                  {diffPackPricePax(booking) !== 0 && (
                    <>
                      <CSC.MediumParagraph2>
                        {t("resultados.buscador.diferencia_paquete_pax")}
                      </CSC.MediumParagraph2>
                      <CSC.Paragraph2>USD {diffPackPricePax(booking)}</CSC.Paragraph2>
                    </>
                  )}

                  {diffServicesPricePax(booking) !== 0 && (
                    <>
                      <CSC.MediumParagraph2>
                        {t("resultados.buscador.diferencia_servicios_pax")}
                      </CSC.MediumParagraph2>
                      <CSC.Paragraph2>USD {diffServicesPricePax(booking)}</CSC.Paragraph2>
                    </>
                  )}

                  {!!booking.discount && (
                    <>
                      <CSC.MediumParagraph2>
                        {t("resultados.detalle_reserva.descuento_por_cupon")}
                      </CSC.MediumParagraph2>
                      <CSC.Paragraph2>USD {discountValue(booking) % 1 ? discountValue(booking).toFixed(1) : discountValue(booking)}</CSC.Paragraph2>
                    </>
                  )}

                  {getPriceReservation(booking).surcharge > 0 && (
                    <>
                      <CSC.NightSurchargeContainer>
                        <CSC.MediumParagraph>
                          {t("resultados.buscador.recargo_titulo")}
                        </CSC.MediumParagraph>
                        <CSC.WarningIcon />
                      </CSC.NightSurchargeContainer>
                      <CSC.Paragraph>
                        USD {getPriceReservation(booking).surcharge}
                      </CSC.Paragraph>
                    </>
                  )}

                  <CSC.MediumParagraph>
                    {t("resultados.detalle_reserva.total")}
                    <CSC.SmallParagraph>
                      {t("resultados.detalle_reserva.mas_impuestos")}
                    </CSC.SmallParagraph>
                  </CSC.MediumParagraph>
                  <CSC.ParagraphBold>
                    {booking.pack.packCurrency}{" "}
                    {getPriceReservation(booking).totalPrice}
                  </CSC.ParagraphBold>
                </SC.PaymentContainer>
              ))}

              <SC.TotalContainer>
                <CSC.MediumParagraph>
                  {t("mis_reservas.total_a_pagar")}
                </CSC.MediumParagraph>
                <CSC.MediumTitleBold>
                  {bookings.length && bookings[0].pack.packCurrency}{" "}
                  {Number(
                    bookings.length &&
                      bookings.reduce(
                        (acc: number, booking: any) =>
                          acc + getPriceReservation(booking).totalPrice,
                        0
                      )
                  ).toFixed(2)}
                </CSC.MediumTitleBold>
              </SC.TotalContainer>

              <InputCheckbox onClick={setCheckValue} value={checkValue}>
                <CSC.MediumParagraph as="span">
                  {t("mis_reservas.acepto")}&nbsp;
                </CSC.MediumParagraph>
              </InputCheckbox>
              <SC.BasesBtn
                onClick={() => {
                  setOpenBases(true);
                }}
              >
                {t("mis_reservas.bases_y_condiciones")}
              </SC.BasesBtn>
            </form>
          </DetailWhiteCard>
        ) : (
          <SC.DetalleBtnMobile>
            <Cta
              onClick={() => setOpenModalDetalle(true)}
              theme="blueDarkTheme"
            >
              {t("mis_reservas.boton_mobile_ver_detalle")}
            </Cta>
          </SC.DetalleBtnMobile>
        )}
      </SC.GridContainer>

      <Modal
        isOpen={openModalDetalle}
        closeModal={() => {
          setOpenModalDetalle(false);
        }}
        fullscreen={true}
      >
        <DetailWhiteCard
          title={t("mis_reservas.detalle_de_pago")}
          goTo={"/checkout"}
          textBtn={t("mis_reservas.boton_continuar")}
          inactiveReservation={!checkValue}
        >
          <form>
            {bookings.map((booking: any, index: number) => (
              <SC.PaymentContainer key={index}>
                <CSC.ParagraphBold>
                  {booking.pack && booking.pack.packName} -{" "}
                  {booking.airport.airportCode}
                </CSC.ParagraphBold>
                <CSC.MediumParagraph>
                  {booking.pack.packCurrency}{" "}
                  {booking.pack &&
                    parseInt(booking.pack.packPrice) *
                      (booking.adultsCount + booking.childsCount)}
                </CSC.MediumParagraph>
                {booking.pack?.services?.map(
                  (extra: Service, index: number) => (
                    <React.Fragment key={index}>
                      <CSC.MediumParagraph>
                        {isEng
                          ? extra.serviceNameEN || extra.serviceName
                          : extra.serviceName}{" "}
                        ({booking.adultsCount + booking.childsCount})
                      </CSC.MediumParagraph>
                      <CSC.MediumParagraph>
                        {extra.serviceCurrency}{" "}
                        {extra.type !== "no multiplica" ? (
                          <>
                            {parseInt(extra.servicePrice) *
                              (booking.adultsCount + booking.childsCount)}
                          </>
                        ) : (
                          <>{parseInt(extra.servicePrice)}</>
                        )}
                      </CSC.MediumParagraph>
                    </React.Fragment>
                  )
                )}

                {getPriceReservation(booking).surcharge > 0 && (
                  <>
                    <CSC.NightSurchargeContainer>
                      <CSC.MediumParagraph>
                        {t("resultados.buscador.recargo_titulo")}
                      </CSC.MediumParagraph>
                      <CSC.WarningIcon />
                    </CSC.NightSurchargeContainer>
                    <CSC.Paragraph>
                      USD {getPriceReservation(booking).surcharge}
                    </CSC.Paragraph>
                  </>
                )}

                <CSC.MediumParagraph>
                  {t("resultados.detalle_reserva.total")}
                  <CSC.SmallParagraph>
                    {t("resultados.detalle_reserva.mas_impuestos")}
                  </CSC.SmallParagraph>
                </CSC.MediumParagraph>
                <CSC.ParagraphBold>
                  {booking.pack.packCurrency}{" "}
                  {getPriceReservation(booking).totalPrice}
                </CSC.ParagraphBold>
              </SC.PaymentContainer>
            ))}

            <SC.TotalContainer>
              <CSC.MediumParagraph>
                {t("mis_reservas.total_a_pagar")}
              </CSC.MediumParagraph>
              <CSC.MediumTitleBold>
                {bookings.length && bookings[0].pack.packCurrency}{" "}
                {Number(
                  bookings.length &&
                    bookings.reduce(
                      (acc: number, booking: any) =>
                        acc + getPriceReservation(booking).totalPrice,
                      0
                    )
                ).toFixed(2)}
              </CSC.MediumTitleBold>
            </SC.TotalContainer>

            <InputCheckbox onClick={setCheckValue} value={checkValue}>
              <CSC.MediumParagraph as="span">
                {t("mis_reservas.acepto")}&nbsp;
              </CSC.MediumParagraph>
            </InputCheckbox>
            <SC.BasesBtn
              onClick={() => {
                setOpenBases(true);
              }}
            >
              {t("mis_reservas.bases_y_condiciones")}
            </SC.BasesBtn>
          </form>
        </DetailWhiteCard>
      </Modal>

      <Modal
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        fullscreen={modalOption !== "delete" ? true : false}
      >
        {modalOption === "delete" ? (
          <>
            <SC.DeleteModalContainer>
              <CSC.Title>{t("mis_reservas.eliminar_paquete")}</CSC.Title>
              <CSC.Paragraph>
                {t("mis_reservas.seguro_eliminar_paquete")}
                {deleteIndex > -1 &&
                  bookings[deleteIndex] &&
                  " " + bookings[deleteIndex].pack.packName + "?"}
              </CSC.Paragraph>
              <SC.FlexCenterContainer>
                <Cta
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  theme="simpleTheme"
                >
                  {t("mis_reservas.cancelar")}
                </Cta>
                <Cta
                  onClick={() => {
                    removeBooking(deleteIndex);
                  }}
                >
                  {t("mis_reservas.eliminar_paquete")}
                </Cta>
              </SC.FlexCenterContainer>
            </SC.DeleteModalContainer>
          </>
        ) : (
          <SC.DeleteModalContainer>
            <SC.CambiarPaqueteTitle>
              {t("mis_reservas.cambiar_paquete")}
            </SC.CambiarPaqueteTitle>
            <SC.ServicePackageContainer modalPack={true}>
              {servicepack.map(item => (
                <ServicePackage
                  service={item}
                  key={item.id}
                  inlineText
                  onClick={() => {
                    onClick(item.id);
                  }}
                  selected={item.selected || false}
                />
              ))}
            </SC.ServicePackageContainer>
            <SC.FlexCenterContainer>
              <Cta
                onClick={() => {
                  setOpenModal(false);
                }}
                theme="simpleTheme"
              >
                {t("mis_reservas.cancelar")}
              </Cta>
              <Cta onClick={() => {}}>
                {t("mis_reservas.confirmar_paquete")}
              </Cta>
            </SC.FlexCenterContainer>
          </SC.DeleteModalContainer>
        )}
      </Modal>

      {/* MODAL BASES Y CONDICIONES */}
      <Modal
        isOpen={openBases}
        closeModal={() => {
          setOpenBases(false);
        }}
        fullscreen={true}
      >
        <BasesYCondiciones />
      </Modal>
    </SC.Wrapper>
  );
};

export default MyReservations;
