import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//COMPONENTS
import Cta from "../Cta";
import Toogle from "../Toogle";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

// STYLES
import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import { Pack } from "../../actions/packs/types";
import { useDispatch } from "react-redux";
import { setButtonDisabled } from "../../actions/buttonActions";

interface Props {
  pack?: Pack;
  service?: IPackage;
  selected?: boolean;
  onClick?: () => void;
  packageNumber?: number;
  inlineText?: boolean;
  withBtn?: boolean;
  plain?: boolean;
  borderBottom?: boolean;
  packName?: string;
  setToggle?: (index: number) => void;
  isAepVvipAvailable?: boolean;
}

const ServicePackage: React.FC<Props> = ({
  pack,
  service,
  selected = false,
  onClick = () => {},
  packageNumber,
  inlineText = false,
  withBtn = false,
  plain,
  borderBottom,
  setToggle,
  packName,
  isAepVvipAvailable
}) => {
  const { t } = useTranslation();

  useWindowSize();

  const isEng = localStorage.getItem("lang") === "EN";
  const {airportCode} = JSON.parse(localStorage.getItem("airport") || "{}");
  const [isAEP, setIsAEP] = useState<boolean>(false);
  const history = useHistory();
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    const buttonIsDisabled = !isAepVvipAvailable && currentUrl.includes('resultados/AEP');
    dispatch(setButtonDisabled(buttonIsDisabled));
  }, [packName, isAepVvipAvailable, currentUrl, dispatch]);
  


    useEffect(() => {
      setCurrentUrl(window.location.href);
      setIsAEP(airportCode === "AEP");
    }, []);

  return (
    <SC.Wrapper
      selected={selected}
      onClick={() => (!withBtn && onClick ? onClick() : {})}
    >
      <SC.PackageHeader>
        {/* <CSC.GreenTag>{pack?.packName.toUpperCase()}</CSC.GreenTag> */}
      </SC.PackageHeader>
      <SC.PackageInfo
        borderBottom={borderBottom}
        noService={pack?.services.length}
      >
        <CSC.MediumTitle>{pack?.packName}</CSC.MediumTitle>

        <SC.PriceContainer>
          {!plain && (
            <SC.Price>
              {pack?.packCurrency} {pack?.packPrice}
            </SC.Price>
          )}
        </SC.PriceContainer>

        <SC.MobilePWrap>
          {(pack?.packName.toLowerCase() == 'vvip & meet greet' || pack?.packName.toLowerCase() == 'vvip meet & greet') &&
          <SC.vvipDiv>
            <CSC.Paragraph>
              {isAEP ? (isEng ? 'You can only make a reservation between the hours of 7:00 a.m. and 10:30 p.m.' 
              : 'Sólo se puede hacer una reserva entre las 7:00 y las 22:30 horas.') : ''}
            </CSC.Paragraph>
          </SC.vvipDiv>
          }
          <SC.ServicesList inline={inlineText} as={inlineText ? "span" : "ul"}>
            {pack?.packItems.map(({ serviceNameEN, serviceName }, index) => (
              <React.Fragment key={"pack-" + index}>
                {/*   {inlineText ? (
                    <CSC.MediumParagraph as="span">
                      {(isEng ? serviceNameEN: serviceName) + (index < pack!.services.length - 1 ? "," : "")}
                      &nbsp;
                    </CSC.MediumParagraph>
                  ) : (
                    <SC.ServicesItem>
                      <CSC.Paragraph>{(isEng ? serviceNameEN: serviceName)}</CSC.Paragraph>
                    </SC.ServicesItem>
                  )} */}

                <SC.ServicesItem>
                  <CSC.Paragraph>
                    {isEng ? serviceNameEN : serviceName}
                  </CSC.Paragraph>
                </SC.ServicesItem>
              </React.Fragment>
            ))}
          </SC.ServicesList>
        </SC.MobilePWrap>

        {ShowWindowDimensions() > SM && (
          <Fragment>
            {withBtn && onClick && (
              <Cta
                inactive={(packName?.toLowerCase() == 'vvip & meet greet' || packName?.toLowerCase() == 'vvip meet & greet') && !isAepVvipAvailable && currentUrl.includes('resultados/AEP')}                
                onClick={onClick}
                theme={selected ? "blueDarkTheme" : "lightTheme"}
              >
                {selected ? (
                  <>
                    <span>{t("resultados.productos.boton_seleccionado")}</span>
                    <SC.CheckIcon />
                  </>
                ) : (
                  t("resultados.productos.boton_seleccionar")
                )}
              </Cta>
            )}
          </Fragment>
        )}
      </SC.PackageInfo>

      {ShowWindowDimensions() > SM ? (
        <Fragment>
          {pack?.services.length ? (
            <SC.ExtraServices>
              {pack.services?.map((item, index) => (
                <SC.Extra key={"extra-" + index}>
                  <div>
                    <CSC.Paragraph>
                      {isEng
                        ? item.serviceNameEN || item.serviceName
                        : item.serviceName}{" "}
                    </CSC.Paragraph>
                    <CSC.MediumParagraph>
                      {isEng
                        ? item.serviceDescriptionEN || item.serviceDescription
                        : item.serviceDescription}
                    </CSC.MediumParagraph>
                  </div>

                  {!plain && (
                    <SC.SmallPrice>
                      {item.serviceCurrency} {item.servicePrice}{" "}
                    </SC.SmallPrice>
                  )}

                  {!plain && (
                    <Toogle
                      checked={item.selected}
                      onClick={() => (setToggle ? setToggle(index) : {})}
                    />
                  )}
                </SC.Extra>
              ))}
            </SC.ExtraServices>
          ) : (
            <div></div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {pack?.services?.length ? (
            <SC.ExtraServices>
              {pack.services?.map((item, index) => (
                <SC.MobileExtra key={"extra-" + index}>
                  <div>
                    <div>
                      <CSC.Paragraph>
                        {isEng
                          ? item.serviceNameEN || item.serviceName
                          : item.serviceName}
                      </CSC.Paragraph>
                      <CSC.MediumParagraph>
                        {isEng
                          ? item.serviceDescriptionEN || item.serviceDescription
                          : item.serviceDescription}
                      </CSC.MediumParagraph>
                    </div>
                    <div>
                      {" "}
                      {!plain && (
                        <Toogle
                          checked={item.selected}
                          onClick={() => (setToggle ? setToggle(index) : {})}
                        />
                      )}
                    </div>
                  </div>

                  {!plain && (
                    <SC.SmallPrice>
                      {item.serviceCurrency} {item.servicePrice}{" "}
                    </SC.SmallPrice>
                  )}
                </SC.MobileExtra>
              ))}
            </SC.ExtraServices>
          ) : (
            <div></div>
          )}
        </Fragment>
      )}

      {ShowWindowDimensions() < SM && (
        <SC.MobileButtonsWrap>
          {withBtn && onClick && (
            <Cta
              inactive={(packName?.toLowerCase() == 'vvip & meet greet' || packName?.toLowerCase() == 'vvip meet & greet') && !isAepVvipAvailable && currentUrl.includes('resultados/AEP')}
              onClick={onClick}
              theme={selected ? "blueDarkTheme" : "lightTheme"}
            >
              {selected ? (
                <>
                  <span>{t("resultados.productos.boton_seleccionado")}</span>
                  <SC.CheckIcon />
                </>
              ) : (
                t("resultados.productos.boton_seleccionar")
              )}
            </Cta>
          )}
        </SC.MobileButtonsWrap>
      )}

      {/** /IBIS - si no viene descripcion no tiene que mostrar nada */}

      {history.location.pathname.replace("/", "") === "/mis-reservas" ||
        (history.location.pathname.replace("/", "") === "mis-reservas" && (
          <SC.Description>
            { isEng ? pack?.processEN : pack?.process }
          </SC.Description>
        ))}
    </SC.Wrapper>
  );
};

export default ServicePackage;
