import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, Item } from "./style";

interface Props {}

const BasesYCondiciones: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <h4>{t("bases_y_condiciones.titulo")}</h4>

        <Item>
          <h3>{t("bases_y_condiciones.item_1_titulo")}</h3>
          <p>{t("bases_y_condiciones.item_1_texto_1")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_2")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_3")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_4")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_5")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_6")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_7")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_8")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_9")}</p>
          <p>{t("bases_y_condiciones.item_1_texto_10")}</p>
        </Item>

        <Item>
          <h3>{t("bases_y_condiciones.item_2_titulo")}</h3>
          <p>{t("bases_y_condiciones.item_2_texto_1")}</p>
          <p>{t("bases_y_condiciones.item_2_texto_2")}</p>
          <div>
            <p>{t("bases_y_condiciones.item_2_texto_3")}</p>

            <ul>
              <li>{t("bases_y_condiciones.item_2_list_1")}</li>
              <li>{t("bases_y_condiciones.item_2_list_2")}</li>
              <li>{t("bases_y_condiciones.item_2_list_3")}</li>
              <li>{t("bases_y_condiciones.item_2_list_4")}</li>
              <li>{t("bases_y_condiciones.item_2_list_5")}</li>
              <li>{t("bases_y_condiciones.item_2_list_6")}</li>
            </ul>
          </div>

          <p>{t("bases_y_condiciones.item_2_texto_4")}</p>
        </Item>

        <Item>
          <h3>{t("bases_y_condiciones.item_3_titulo")}</h3>
          <p>{t("bases_y_condiciones.item_3_texto_1")}</p>
          <p>{t("bases_y_condiciones.item_3_texto_2")}</p>
          <p>{t("bases_y_condiciones.item_3_texto_3")}</p>
          <p>{t("bases_y_condiciones.item_3_texto_4")}</p>
          <p>{t("bases_y_condiciones.item_3_texto_5")}</p>
        </Item>
      </Wrapper>
    </Suspense>
  );
};

export default BasesYCondiciones;
