import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Cta from "../../components/Cta";
import InputText from "../../components/InputText";
import CardPasajerosSmall from "../../components/CardPasajerosSmall";
import Modal from "../../components/Modal";
import RegularInput from "../../components/RegularInput";
import RegularTextArea from "../../components/RegularTextArea";
import SimpleSelect from "../../components/SimpleSelect";
import Tooltip from "../../components/Tooltip";
import OkIcon from "../../assets/icons/ok-icon.svg";
import helpCircle from "../../assets/icons/help-circle-red.svg";
import InputError from "../../components/InputError";
import DatePicker from "../../components/Datepicker";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

// HOOK
import useBookings from "../../utils/useBookings";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

import { Booking, Passenger } from "../MyReservations/MyReservations";

import { getTotalPrice } from "../../utils/getPriceAndSurcharge";
import { getAll as getAllAirports } from "../../actions/airports/creators";
import { getPassengers } from '../../actions/passengers/creators';
import AutocompleteInput from '../../components/AutocompleteInput';
import { Airport } from "../../actions/airports/types";

interface Props {}

const Checkout: React.FC<Props> = () => {
  const { t } = useTranslation();
  let location = useLocation();

  useWindowSize();

  // hooks
  const [dateValue, setDateValue] = useState<Date | string>("");
  const [dateModifyValue, setDateModifyValue] = useState<Date | string>("");

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModifyModal, SetOpenModifyModal2] = useState({
    show: false,
    index: -1
  });

  const [] = useState("");
  const [] = useState(false);

  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [emailName, setEmailName] = useState("");
  const [emailModifyName, setEmailModifyName] = useState("");
  const [, setValueEmail] = useState("");

  const history = useHistory();

  const [selectedBooking, setSelectedBooking] = useState<Booking | undefined>();

  //select value
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedModifyValue, setSelectedModifyValue] = useState("");

  const [, setCopiarInfo] = useState(false);

  const [openCalendar, setOpenCalendar] = useState(false);

  const [modalShow, setModalShow] = React.useState(false);

  const [formData, setFormData] = useState<any>({
    name: "",
    dob: "Invalid date",
    passport: "",
    nationality: selectedValue,
    phone: "",
    email: "",
    notes: "",
    copyInfo: false
  });

  const [_surcharge, setSurcharge] = useState<number>(0);
  const [_schedule, setSchedule] = useState<number[]>([0, 0]);

  const [passengers, setPassengers] = useState<any[]>([]);

  const [airports, setAirports] = useState<Airport[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [airportSelected, setAirportSelected] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const _airports = await getAllAirports();
      setAirports(_airports);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let results = [] as any;
    results = airports.filter(
      ({ airportCode, airportName, city, state, country, image }) =>
        `${airportCode}${airportName}${city}${state}${country}${image}`
          .toLowerCase()
          .replace("á", "a")
          .replace("é", "e")
          .replace("í", "i")
          .replace("ó", "o")
          .replace("ú", "u")
          .includes(
            searchTerm
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ú", "u")
          )
    );

    if (!searchTerm) {
      setSearchResults([]);
    } else {
      setSearchResults(results.slice(0, 3));
    }
  }, [searchTerm]);

  const storeAirport = (airport: Airport) => {
    localStorage.setItem("airport", JSON.stringify(airport));
    localStorage.setItem("more_offers_booking", JSON.stringify(bookings[0]));
  };

  const onChangeModal = (event: { target: { value: React.SetStateAction<string> }; }) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const user = localStorage.getItem("userId");

    const fetchPassengers = async () => {
      const _passengers = await getPassengers(user!);
      setPassengers(_passengers);
    };

    if (user) {
      fetchPassengers();
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  // NACIONALITY OPTIONS
  const countries = require("../../utils/nacionality/nationality_es.json");

  const SetOpenModifyModal = (booking: Booking | undefined, payload: any) => {
    setSelectedBooking(booking);
    SetOpenModifyModal2(payload);

    if (payload.show && payload.index !== -1) {
      setModifyPass({
        ...modifyPass,
        name: booking!.passengers[payload.index].name,
        dob: booking!.passengers[payload.index].dob,
        passport: booking!.passengers[payload.index].passport,
        nationality: booking!.passengers[payload.index].nationality,
        phone: booking!.passengers[payload.index].phone,
        email: booking!.passengers[payload.index].email,
        notes: booking!.passengers[payload.index].notes
      });
    }
  };

  const handleDate = () => {
    setFormData({
      ...formData,
      dob: moment.utc(dateValue, "DD MM YYYY hh:mm:ss").format("DD/MM/YYYY")
    });
  };

  const handleModifyDate = () => {
    setModifyPass({
      ...modifyPass,
      dob:
        dateModifyValue !== null
          ? moment.utc(dateModifyValue, "DD MM YYYY hh:mm:ss").format("DD/MM/YYYY")
          : ""
    });
  };

  useEffect(() => {
    handleDate();
  }, [dateValue]);

  useEffect(() => {
    handleModifyDate();
  }, [dateModifyValue]);

  const handleChange = (e: { value: any }) => {
    setSelectedValue(e.value);

    setFormData({
      ...formData,
      nationality: e.value
    });
  };

  const handleModifyChange = (e: { value: any }) => {
    setSelectedModifyValue(e.value);

    setModifyPass({
      ...modifyPass,
      nationality: e.value
    });
  };

  const { bookings, updateBookings } = useBookings({});

  useEffect(() => {
    const again = !!localStorage.getItem('ALREADY_SHOWED_MODAL');
    if (again) {
      return;
    }

    const res = bookings.every((booking: any) => booking.passengers.length === booking.adultsCount + booking.childsCount + booking.infantsCount);
    if (res) {
      localStorage.setItem('ALREADY_SHOWED_MODAL', 'true');
      setModalShow(true);
    }
  }, [bookings]);

  useEffect(() => {
    document.body.style.overflow = "inherit";
    document.documentElement.style.overflow = "inherit";
  }, []);

  useEffect(() => {
    if (openModal || openModalDelete) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openModal, openModalDelete]);

  const handleInputChange = (event: { target: any }) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    handleInputChange(email);
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("bookings", JSON.stringify(bookings));
  }, [bookings]);

  var stored: any = [];

  const onCreateBooking = async () => {
    stored.push({
      total: Number(
        Number(
          bookings.length &&
            bookings.reduce(
              (acc: number, booking: Booking) =>
                acc +
                getTotalPrice(
                  booking,
                  booking.date.split(",")[1]?.replace(" ", ""),
                  _surcharge,
                  _schedule
                ).totalPrice,
              0
            )
        ).toFixed(2)
      )
    });

    /*  if (_surcharge !== 0) {
      stored.map((item: any) => {
        item.total = Number(
          Number(
            bookings.length &&
              bookings.reduce(
                (acc: number, booking: Booking) =>
                  acc +
                  getTotalPrice(
                    booking,
                    booking.date.split(",")[1]?.replace(" ", ""),
                    _surcharge,
                    _schedule
                  ).totalPrice,
                0
              )
          ).toFixed(2)
        );
      });
    } */

    localStorage.setItem("stored", JSON.stringify(stored));

    history.push("/checkout/pagar");
  };

  const [indexPas, setIndexPas] = useState({
    id: -1,
    name: ""
  });

  const removePassenger = (booking: Booking, id: number, name: string) => {
    setSelectedBooking(booking);
    setIndexPas({
      id: id,
      name: name
    });
  };

  const removeModal = () => {
    bookings
      .find((b: Booking) => b === selectedBooking)
      ?.passengers.splice(indexPas.id, 1);
    updateBookings(bookings);
  };

  const [modifyPass, setModifyPass] = useState({
    name: "",
    dob: "",
    passport: "",
    nationality: selectedModifyValue,
    phone: "",
    email: "",
    notes: "",
    copyInfo: false
  });

  const handleModifyInput = (event: { target: any }) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setModifyPass(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidModifyMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailModifyName(email.target.name);
    setValueEmail(email.target.value);

    handleModifyInput(email);
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  const editPassenger = () => {
    bookings.find(
      (booking: Booking) => booking === selectedBooking
    )!.passengers[openModifyModal.index] = modifyPass;
    updateBookings(bookings);
  };

  // https://stackoverflow.com/questions/35688864/regular-expressions-to-match-flight-number
  // Do not use lookahead option because it's not widely supported
  const validFlightNumber = (num: any) => {
    if (num 
      && num.length >= 5 
      && /\b([A-Za-z]{2}|[A-Za-z]\d|\d[A-Za-z])\d{3,4}\b/.test(num)
      ) {
      return true;
    } else {
      return false;
    }
  };

  const updateFieldChanged = (index: any) => (e: any) => {
    const bookings2 = bookings;
    bookings2[index].flightNumber = e;

    updateBookings(JSON.parse(JSON.stringify(bookings2)));
  };

  const checkoutBtnInactive = () => {
    let validFlightsNumbers = bookings.map((book: any) => {
      return validFlightNumber(book.flightNumber);
    });
    if (
      bookings.reduce((a: number, b: Booking) => a + b.passengers.length, 0) !==
        bookings.reduce(
          (a: number, b: Booking) =>
            a + b.adultsCount + b.childsCount + b.infantsCount,
          0
        ) ||
      !validFlightsNumbers.every((v: any) => v === true)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /*  const newdate = new Date("1985-1-1");
  newdate.setDate(newdate.getDate());
  const [birthStartDate, setBirthStartDate] = useState(newdate); */

  useEffect(() => {
    /* setDateValue(birthStartDate); */
  }, []);

  //IBIS numeros tiene todos los numeros de vuelo por si quieren agregarlo a bookings

  const handleOnClick = (suggestion: any) => {
    const dob = moment.utc(suggestion.dob).format("DD/MM/YYYY");

    setFormData({
      name: suggestion.name,
      dob,
      passport: suggestion.passport,
      nationality: suggestion.nationality,
      phone: suggestion.phone,
      email: suggestion.email,
      notes: suggestion.notes,
      copyInfo: false,
    })

    setDateValue(moment(moment.utc(suggestion.dob).format('YYYY-MM-DD')).toDate());
  }

  const onKeyDownModal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchTerm === "") return;
    const ul = document.getElementById("menu") as HTMLUListElement;
    const { key } = event;
    if (key !== "ArrowDown" && key !== "ArrowUp" && key !== "Enter") return;
    event.preventDefault();
    const lis: HTMLLIElement[] | undefined = Array.from(
      ul!.querySelectorAll("li.hoverable")
    );
    if (key === "ArrowDown") {
      if ((window as any).currentFocusIndex === null) {
        (window as any).currentFocusIndex = 0;
      } else {
        if ((window as any).currentFocusIndex < lis.length - 1) {
          (window as any).currentFocusIndex++;
        }
      }
    }
    if (key === "ArrowUp") {
      if ((window as any).currentFocusIndex! > 0) {
        (window as any).currentFocusIndex!--;
      } else {
        (window as any).currentFocusIndex = null;
      }
    }
    lis.forEach(li => {
      li.classList.remove("key-hover");
    });

    if (
      (window as any).currentFocusIndex !== null &&
      lis[(window as any).currentFocusIndex]
    ) {
      lis[(window as any).currentFocusIndex].classList.add("key-hover");
      lis[(window as any).currentFocusIndex].focus();
      if (key === "Enter") {
        storeAirport(searchResults[(window as any).currentFocusIndex]);

      /*   handleRedirect(); */

        if (
          location.pathname.split("/")[1] === "/resultados" ||
          location.pathname.split("/")[1] === "resultados"
        ) {
          history.push(`/resultados/${searchResults[(window as any).currentFocusIndex].airportCode}`);
          window.location.reload();
        } else {
          history.push(`/resultados/${searchResults[(window as any).currentFocusIndex].airportCode}`);
        }
      }
    }
  };

  const handleRedirect = () => {
    if (
      location.pathname.split("/")[1] === "/resultados" ||
      location.pathname.split("/")[1] === "resultados"
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      history.push(`/resultados/${airportSelected}`);
    }
  };

  return (
    <SC.Wrapper>
      <SC.Header>
        <Link to={"/"}>
          <CSC.Logo role="img" aria-label="Jarvis logo" />
        </Link>
      </SC.Header>
      <SC.Container>
        <CSC.MediumTitle>{t("checkout.titulo")}</CSC.MediumTitle>
        <CSC.Paragraph>{t("checkout.subtitulo")}</CSC.Paragraph>
        <SC.ReservationContainer>
          {bookings.map((booking: Booking, index: number) => (
            <SC.Reservation key={index}>
              <div>
                <CSC.Paragraph>
                  {booking.airport.state +
                    ", " +
                    booking.airport.city +
                    ", " +
                    booking.airport.country}
                </CSC.Paragraph>
                <CSC.MediumTitle>
                  {booking.airport.airportCode +
                    ", " +
                    booking.airport.airportName}
                </CSC.MediumTitle>
              </div>
              <SC.FlightContainer>
                <CSC.ReservationIcon type={booking.bookingType} />
                <div>
                  {booking.bookingType == 'Arribo' && <CSC.Paragraph>{t("checkout.arribo")}</CSC.Paragraph>}
                  {booking.bookingType == 'Conexión' && <CSC.Paragraph>{t("checkout.conexión")}</CSC.Paragraph>}
                  {booking.bookingType == 'Partida' && <CSC.Paragraph>{t("checkout.partida")}</CSC.Paragraph>}
                  <CSC.AlternativeTitle>{booking.date}</CSC.AlternativeTitle>
                </div>

                {ShowWindowDimensions() > SM && (
                  <SC.RightContainer>
                    <SC.InputContainer>
                      <InputText
                        name={"flightNumber"}
                        value={booking.flightNumber}
                        onChange={updateFieldChanged(index)}
                        placeholder={t("checkout.numero_de_vuelo")}
                        autoComplete="off"
                        
                      />

                      {!validFlightNumber(booking.flightNumber) ? (
                        <Tooltip
                          title={t("")}
                          description={t("checkout.numero_de_vuelo_desc")}
                        >
                          <SC.HelpIcon icon={helpCircle} />
                        </Tooltip>
                      ) : (
                        <SC.HelpIcon icon={OkIcon} />
                      )}
                    </SC.InputContainer>
                    <Cta
                      onClick={() => {
                        setSelectedBooking(booking);
                        setOpenModal(true);
                      }}
                      inactive={
                        booking.passengers.length ===
                        booking.adultsCount +
                          booking.childsCount +
                          booking.infantsCount
                      }
                    >
                      {t("checkout.boton_agregar_pasajero")}
                    </Cta>
                    <SC.PassengersCount
                      full={
                        booking.passengers.length ===
                        booking.adultsCount +
                          booking.childsCount +
                          booking.infantsCount
                      }
                    >
                      {booking.passengers.length} /{" "}
                      {booking.adultsCount +
                        booking.childsCount +
                        booking.infantsCount}
                    </SC.PassengersCount>
                  </SC.RightContainer>
                )}

                {ShowWindowDimensions() < SM && (
                  <SC.MobileInput>
                    <SC.InputContainer>
                      <InputText
                        name={"flightNumber"}
                        value={booking.flightNumber}
                        onChange={updateFieldChanged(index)}
                        placeholder={t("checkout.numero_de_vuelo")}
                        autoComplete="off"
                      />

                      {!validFlightNumber(booking.flightNumber) ? (
                        <Tooltip
                          title={t("checkout.numero_de_vuelo")}
                          description={t("checkout.numero_de_vuelo_desc")}
                          viewTool={"checkout"}
                        >
                          <SC.HelpIcon icon={helpCircle} />
                        </Tooltip>
                      ) : (
                        <SC.HelpIcon icon={OkIcon} />
                      )}
                    </SC.InputContainer>
                  </SC.MobileInput>
                )}
              </SC.FlightContainer>
              {!!booking.passengers.length && (
                <>
                  <SC.PassengersContainer>
                    {booking.passengers.map(
                      (passenger: Passenger, index: number) => (
                        <CardPasajerosSmall
                          booking={booking}
                          pasajero={passenger}
                          key={index}
                          index={index}
                          editButtons={true}
                          deleteModal={setOpenModalDelete}
                          setOpenModal={setOpenModal}
                          removePassenger={removePassenger}
                          SetOpenModifyModal={SetOpenModifyModal}
                        />
                      )
                    )}
                  </SC.PassengersContainer>
                </>
              )}

              {ShowWindowDimensions() < SM && (
                <SC.AddPassengersMobile>
                  <Cta
                    inactive={
                      booking.passengers.length ===
                      booking.adultsCount +
                        booking.childsCount +
                        booking.infantsCount
                    }
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedBooking(booking);
                    }}
                  >
                    {t("checkout.boton_agregar_pasajero")}
                  </Cta>
                  <SC.PassengersCount
                    full={
                      booking.passengers.length ===
                      booking.adultsCount +
                        booking.childsCount +
                        booking.infantsCount
                    }
                  >
                    {booking.passengers.length} /{" "}
                    {booking.adultsCount +
                      booking.childsCount +
                      booking.infantsCount}
                  </SC.PassengersCount>
                </SC.AddPassengersMobile>
              )}
            </SC.Reservation>
          ))}
        </SC.ReservationContainer>
      </SC.Container>
      {ShowWindowDimensions() > SM ? (
        <SC.FixedContainer>
          <div>
            <Cta
              isRoute={true}
              toRoute={"/mis-reservas"}
              onClick={() => {}}
              theme="lightTheme"
            >
              {t("checkout.boton_volver_a_mis_rerservas")}
            </Cta>
            <CSC.MediumParagraph>
              {t("checkout.Total_a_pagar")}
            </CSC.MediumParagraph>
            <CSC.MediumTitleBold as="p">
              {bookings.length && bookings[0].pack.packCurrency} {" "}
              {Number(
                bookings.length &&
                  bookings.reduce(
                    (acc: number, booking: Booking) =>
                      acc +
                      getTotalPrice(
                        booking,
                        booking.date.split(",")[1]?.replace(" ", ""),
                        _surcharge,
                        _schedule
                      ).totalPrice,
                    0
                  )
              ).toFixed(2)}
            </CSC.MediumTitleBold>
            {checkoutBtnInactive() ? (
              <SC.TooltipContainer>
                <Tooltip description={t("checkout.subtitulo")}>
                  <Cta onClick={() => {}} inactive={true}>
                    {t("checkout.boton_comprar_paquetes")}
                  </Cta>
                </Tooltip>
              </SC.TooltipContainer>
            ) : (
              <Cta onClick={onCreateBooking}>
                {t("checkout.boton_comprar_paquetes")}
              </Cta>
            )}
          </div>
        </SC.FixedContainer>
      ) : (
        <SC.ComprarWrapMobile>
          <SC.ComprarPrecio>
            <SC.TotalText>{t("checkout.Total_a_pagar")}</SC.TotalText>
            <SC.NominalText>
              {bookings.length && bookings[0].pack.packCurrency} {" "}
              {Number(
                bookings.length &&
                  bookings.reduce(
                    (acc: number, booking: Booking) =>
                      acc +
                      getTotalPrice(
                        booking,
                        booking.date.split(",")[1]?.replace(" ", ""),
                        _surcharge,
                        _schedule
                      ).totalPrice,
                    0
                  )
              ).toFixed(2)}
            </SC.NominalText>
          </SC.ComprarPrecio>
          <SC.ButtonsWrapMobile inactive={checkoutBtnInactive()}>
            <Cta
              onClick={() => {
                checkoutBtnInactive() ? setIsOpen(true) : onCreateBooking();
              }}
              theme="blueDarkTheme"
            >
              {t("checkout.boton_comprar_paquetes")}
            </Cta>
            <Cta
              isRoute={true}
              toRoute={"/mis-reservas"}
              onClick={() => {}}
              theme="simpleTheme"
            >
              {t("checkout.boton_volver_a_mis_rerservas")}
            </Cta>
          </SC.ButtonsWrapMobile>
        </SC.ComprarWrapMobile>
      )}

      <Modal
          maxWidth="520px"
          isOpen={modalShow}
          closeModal={() => {
            setModalShow(false);
          }}
        >
          <SC.ModalTitle2>
            <h3>{ t("checkout.mas_ofertas.titulo") }</h3>
          </SC.ModalTitle2>
          <SC.InputsModal>
            <Fragment>
              <SC.SearchWrap style={{'paddingLeft': '0', 'marginLeft': '24px', 'marginRight': '24px'}}>
                <SC.Wrapper2>
                  <SC.SearchIcon />
                    <SC.Input
                      id="search-input"
                      onKeyDown={onKeyDownModal}
                      type="text"
                      placeholder={t("homepage.buscador_mobile")}
                      value={searchTerm}
                      onChange={onChangeModal}
                      autoComplete="off"
                      autoFocus={true}
                    />
                </SC.Wrapper2>
              </SC.SearchWrap>
              <SC.Submenu id="menu" searchResults={searchResults} >
              {searchResults.length !== 0 && (
                <>
                  {searchResults
                    .sort((a, b) => a.airportCode.localeCompare(b.airportCode))
                    .map((item, i) => {
                      if (searchTerm !== "" && searchResults.length > 1) {
                        return (
                          <li key={i} className="hoverable">
                            <Link
                              onClick={() => {
                                storeAirport(item);
                                setAirportSelected(item);

                                if (typeof window !== "undefined") {
                                  handleRedirect();
                                }
                              }}
                              key={i}
                              to={
                                location.pathname.split("/")[1] ===
                                  "/resultados" ||
                                location.pathname.split("/")[1] === "resultados"
                                  ? `/resultados/${item.airportCode}`
                                  : `/resultados/${item.airportCode}`
                              }
                            >
                              {}
                              <SC.Card>
                                <SC.BgPic bg={item.image} />
                                <SC.Headline>
                                  <h3>
                                    {item.airportCode} - {item.airportName}
                                  </h3>
                                  <h4>
                                    {[item.city, item.state, item.country].join(
                                      ", "
                                    )}
                                  </h4>
                                </SC.Headline>
                              </SC.Card>
                            </Link>
                          </li>
                        );
                      } else {
                        storeAirport(item);

                        return (
                          <li key={i} className="unique" tabIndex={0} style={{'marginRight': '40px'}}>
                            <Link
                              onClick={() => {
                                storeAirport(item);
                                setAirportSelected(item);

                                if (typeof window !== "undefined") {
                                  handleRedirect();
                                }
                              }}
                              key={i}
                              to={
                                location.pathname.split("/")[1] ===
                                  "/resultados" ||
                                location.pathname.split("/")[1] === "resultados"
                                  ? `/resultados/${item.airportCode}`
                                  : `/resultados/${item.airportCode}`
                              }
                            >
                              <SC.Card>
                                <SC.BgPic bg={item.image} />
                                <SC.Headline>
                                  <h3>
                                    {item.airportCode} - {item.airportName}
                                  </h3>
                                  <h4>
                                    {[item.city, item.state, item.country].join(
                                      ", "
                                    )}
                                  </h4>
                                </SC.Headline>
                              </SC.Card>
                            </Link>
                          </li>
                        );
                      }
                    })}
                </>
              )}
            </SC.Submenu>
            </Fragment>
            <SC.CtaContainer>
              <Cta onClick={() => setModalShow(false)}>
                { t("checkout.mas_ofertas.omitir") }
              </Cta>
            </SC.CtaContainer>
          </SC.InputsModal>
        </Modal>

      <Modal
        isOpen={openModalDelete}
        closeModal={() => {
          setOpenModalDelete(false);
        }}
      >
        <SC.DeleteModalContainer>
          <CSC.Title>
            {t("checkout.formulario.modal_remover_pasajero_titulo")}
          </CSC.Title>
          <CSC.Paragraph>
            <span>{t("checkout.formulario.remover_a")}&nbsp;</span>
            <b>{indexPas.name}</b>?
          </CSC.Paragraph>
          <SC.FlexCenterContainer>
            <Cta
              onClick={() => {
                setOpenModalDelete(false);
              }}
              theme="simpleTheme"
            >
              {t("checkout.formulario.boton_cancelar")}
            </Cta>
            <Cta
              onClick={() => {
                setOpenModalDelete(false);
                removeModal();
              }}
            >
              {t("checkout.formulario.boton_eliminar_pasajero")}
            </Cta>
          </SC.FlexCenterContainer>
        </SC.DeleteModalContainer>
      </Modal>

      {/**

     MODAL AGREGAR


    */}

      <Modal
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
          setFormData({
            name: "",
            dob: "Invalid date",
            passport: "",
            nationality: "",
            phone: "",
            email: "",
            notes: "",
            copyInfo: false
          });
          setDateValue("");
        }}
        maxWidth={"700px"}
        fullscreen={true}
      >
        <SC.InputsModal>
          <h4>{t("checkout.boton_agregar_pasajero")}</h4>
          <SC.InputGrid>
            <SC.InputWrap>
              <AutocompleteInput
                suggestions={passengers}
                suggestionKey='name'
                label={t("checkout.formulario.nombre_completo") + "*"}
                placeholder={t("checkout.formulario.nombre_completo")}
                onChange2={handleInputChange}
                onClick2={handleOnClick}
                name={"name"}
                value={formData["name"]}
                filled={formData["name"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              <>
                <SC.DatePickerLabel>
                  {t("checkout.formulario.fecha_de_nacimiento") + " *"}
                </SC.DatePickerLabel>
                <DatePicker
                  noLabel={true}
                  title={t("generales.fecha")}
                  placeholder={t("generales.seleccionar_fecha")}
                  dateValue={dateValue}
                  openCalendar={openCalendar}
                  setDateValue={setDateValue}
                  withDefaultInput
                  maxDate={new Date()}
                  parseDate={moment.utc(dateValue, "DD MM YYYY hh:mm:ss").format(
                    "DD/MM/YYYY"
                  )}
                />
              </>
            </SC.InputWrap>

            <SC.InputWrap>
              <AutocompleteInput
                label={t("checkout.formulario.numero_de_pasaporte") + " *"}
                suggestions={passengers}
                suggestionKey='passport'
                placeholder={t("checkout.formulario.numero_de_pasaporte")}
                onChange2={handleInputChange}
                onClick2={handleOnClick}
                name={"passport"}
                value={formData["passport"]}
                filled={formData["passport"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              <SimpleSelect
                label={t("checkout.formulario.Nacionalidad") + "*"}
                options={countries.sort((a: any, b: any) =>
                  a.label.toLowerCase() > b.label.toLowerCase()
                    ? 1
                    : b.label.toLowerCase() > a.label.toLowerCase()
                    ? -1
                    : 0
                )}
                placeholder={t("checkout.formulario.Nacionalidad")}
                selectedValue={formData.nationality}
                handleChange={handleChange}
                isSearchable
              />
            </SC.InputWrap>
            <SC.InputWrap>
              {" "}
              <RegularInput
                label={
                  t("checkout.formulario.telefono") +
                  " " +
                  t("checkout.formulario.opcional")
                }
                type={"text"}
                placeholder={t("checkout.formulario.placeholder_telefono")}
                onChange={handleInputChange}
                name={"phone"}
                value={formData["phone"]}
                filled={formData["phone"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>

            <SC.InputWrap>
              <RegularInput
                label={t("checkout.formulario.email") + "*"}
                type={"text"}
                placeholder={"name@email"}
                onChange={handleValidMail}
                errorMail={validateEmail}
                name={"email"}
                value={formData["email"]}
                emailName={emailName}
                filled={formData["email"] !== "" ? "filled" : "empty"}
              />
              {!validateEmail && emailName === "email" && (
                <InputError text={t("generales.error_email")} />
              )}
            </SC.InputWrap>
          </SC.InputGrid>

          <RegularTextArea
            label={
              t("checkout.formulario.notas_adicionales") +
              " " +
              t("checkout.formulario.opcional")
            }
            placeholder={t("checkout.formulario.notas_adicionales")}
            name="notes"
            value={formData.notes}
            onChange={handleInputChange}
            filled={formData["notes"] !== "" ? "filled" : "empty"}
            rows={ShowWindowDimensions() < SM ? 2 : 6}
          />
          <SC.GridButtons>
            <Cta
              onClick={() => {
                setOpenModal(false);

                setFormData({
                  name: "",
                  dob: "Invalid date",
                  passport: "",
                  nationality: "",
                  phone: "",
                  email: "",
                  notes: "",
                  copyInfo: false
                });

                setDateValue("");
              }}
              theme="simpleTheme"
            >
              {t("checkout.formulario.boton_cancelar")}
            </Cta>{" "}
            <Cta
              onClick={() => {
                setOpenModal(false);

                const bookings2 = bookings.map((booking: Booking) => {
                  if (booking === selectedBooking) {
                    booking.passengers.push(formData);
                  }
                  return booking;
                });

                updateBookings(JSON.parse(JSON.stringify(bookings2)));

                setFormData({
                  name: "",
                  dob: "Invalid date",
                  passport: "",
                  nationality: "",
                  phone: "",
                  email: "",
                  notes: "",
                  copyInfo: false
                });
                setDateValue("");
              }}
              theme="blueDarkTheme"
              inactive={
                formData.name === "" ||
                formData.dob === "" ||
                formData.dob === "Invalid date" ||
                formData.passport === "" ||
                formData.nationality === "" ||
                formData.email === "" ||
                (!validateEmail && emailName === "email")
              }
            >
              {t("checkout.formulario.boton_agregar_pasajero")}
            </Cta>{" "}
          </SC.GridButtons>
        </SC.InputsModal>
      </Modal>

      {/** MODAL EDITAR */}

      <Modal
        isOpen={openModifyModal.show}
        closeModal={() => {
          SetOpenModifyModal(undefined, {
            ...openModifyModal,
            show: false
          });
        }}
        maxWidth={"700px"}
        fullscreen={true}
      >
        <SC.InputsModal>
          <h4>{t("checkout.modificar_pasajero")}</h4>
          <SC.InputGrid>
            <SC.InputWrap>
              <RegularInput
                label={t("checkout.formulario.nombre_completo") + "*"}
                type={"text"}
                placeholder={t("checkout.formulario.nombre_completo")}
                onChange={handleModifyInput}
                name={"name"}
                value={modifyPass["name"]}
                filled={modifyPass["name"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              <>
                <SC.DatePickerLabel>
                  {t("checkout.formulario.fecha_de_nacimiento") + " *"}
                </SC.DatePickerLabel>
                <DatePicker
                  noLabel={true}
                  title={t("generales.fecha")}
                  placeholder={t("generales.seleccionar_fecha")}
                  dateValue={modifyPass["dob"]}
                  openCalendar={openCalendar}
                  setDateValue={setDateModifyValue}
                  withDefaultInput
                  maxDate={new Date()}
                  parseDate={moment.utc(dateValue, "DD MM YYYY hh:mm:ss").format(
                    "DD/MM/YYYY"
                  )}
                />
              </>
            </SC.InputWrap>

            <SC.InputWrap>
              <RegularInput
                label={t("checkout.formulario.numero_de_pasaporte") + " *"}
                type={"text"}
                placeholder={t("checkout.formulario.numero_de_pasaporte")}
                onChange={handleModifyInput}
                name={"passport"}
                value={modifyPass["passport"]}
                filled={modifyPass["passport"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              <SimpleSelect
                label={t("checkout.formulario.Nacionalidad") + " *"}
                options={countries.sort((a: any, b: any) =>
                  a.label.toLowerCase() > b.label.toLowerCase()
                    ? 1
                    : b.label.toLowerCase() > a.label.toLowerCase()
                    ? -1
                    : 0
                )}
                placeholder={t("checkout.formulario.Nacionalidad")}
                selectedValue={modifyPass["nationality"]}
                handleChange={handleModifyChange}
              />
            </SC.InputWrap>
            <SC.InputWrap>
              {" "}
              <RegularInput
                label={
                  t("checkout.formulario.telefono") +
                  " " +
                  t("checkout.formulario.opcional")
                }
                type={"text"}
                placeholder={t("checkout.formulario.placeholder_telefono")}
                onChange={handleModifyInput}
                name={"phone"}
                value={modifyPass["phone"]}
                filled={modifyPass["phone"] !== "" ? "filled" : "empty"}
              />
            </SC.InputWrap>

            <SC.InputWrap>
              <RegularInput
                label={t("checkout.formulario.email") + " *"}
                type={"text"}
                placeholder={"name@email"}
                onChange={handleValidModifyMail}
                errorMail={validateEmail}
                name={"email"}
                emailName={emailModifyName}
                filled={modifyPass["email"] !== "" ? "filled" : "empty"}
                value={modifyPass["email"]}
              />
              {!validateEmail && emailModifyName === "email" && (
                <InputError text={t("generales.error_email")} />
              )}
            </SC.InputWrap>
          </SC.InputGrid>

          <RegularTextArea
            label={
              t("checkout.formulario.notas_adicionales") +
              " " +
              t("checkout.formulario.opcional")
            }
            placeholder={t("checkout.formulario.notas_adicionales")}
            name="notes"
            value={modifyPass.notes}
            onChange={handleModifyInput}
            filled={modifyPass["notes"] !== "" ? "filled" : "empty"}
            rows={ShowWindowDimensions() < SM ? 2 : 6}
          />
          <SC.GridButtons>
            <Cta
              onClick={() => {
                SetOpenModifyModal(undefined, {
                  ...openModifyModal,
                  show: false
                });
              }}
              theme="simpleTheme"
            >
              {t("checkout.formulario.boton_cancelar")}
            </Cta>{" "}
            <Cta
              onClick={() => {
                if (/* formData.name !== "" && formData.phone !== "" */ true) {
                  SetOpenModifyModal(undefined, {
                    ...openModifyModal,
                    show: false
                  });

                  editPassenger();
                }
              }}
              theme="blueDarkTheme"
              inactive={
                modifyPass.name === "" ||
                modifyPass.passport === "" ||
                modifyPass.dob === "" ||
                modifyPass.nationality === "" ||
                modifyPass.email === "" ||
                (!validateEmail && emailModifyName === "email")
              }
            >
              {t("checkout.modificar_pasajero")}
            </Cta>{" "}
          </SC.GridButtons>
        </SC.InputsModal>
      </Modal>

      {/* MODAL MOBILE - CHECKOUT BUTTON */}
      <Modal
        isOpen={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
        fullscreen={false}
      >
        <SC.ModalTitle>{t("checkout.subtitulo")}</SC.ModalTitle>
      </Modal>
    </SC.Wrapper>
  );
};

export default Checkout;
