import React, { FC } from "react";

import Header from "../components/Header"
import Footer from "../components/Footer"

type Props = {
  noHeader?: boolean;
  noFooter?:boolean;
};


const LayoutGeneral: FC<Props> = ({ children, noHeader, noFooter }) => {
  return (
    <div>
      {!noHeader ? <Header /> : <></>}

      {children}

     {!noFooter ? <Footer /> : <></> } 
    </div>
  );
};

export default LayoutGeneral;
