import styled from 'styled-components';
import arrow from '../../assets/icons/dropdown-arrow.svg';
import { Pop } from '../../utils/keyframes';
import { LG } from '../../utils/constants';

export const Wrapper = styled.div`
	position: relative;
	height: 100%;

	.react-time-picker__wrapper {
		font-size: 20px;
		font-weight: 600;
		border: 0;
	}
`;

export const DatePickerLabel = styled.div`
	padding-left: 24px;
	padding-right: 32px;
	height: 100%;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 3;

	> h3 {
		color: var(--black);
		opacity: 0.5;
		margin-bottom: 0px;
		position: relative;
		/*    top: -7px; */
	}
`;

export const Arrow = styled.span`
	width: 12px;
	height: 20px;
	margin-left: 15px;
	background: url(${arrow}) center / contain no-repeat;
`;

export const Title = styled.span`
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	color: var(--black);
	display: inline-flex;
	align-items: center;
`;

export const LabelContainer = styled.div`display: flex;`;

export const TimeWrap = styled.div`
	img {
		animation: 0.3s ${Pop} ease-out forwards;

    position: relative;
    right: -1px;
    top: -1px;
    cursor: pointer
	}
`;

export const Tooltip = styled.div<{ showTool: boolean, showAlert: boolean } >
  `
  background-color: #000000;
  color: #ffffff;

  padding: 20px;

  position: relative;
  top: 20px;
  width:130%;

  

  opacity: ${(props) => (props.showTool ? 1 : 0)};
  visibility: ${(props) => (props.showTool ? 'visible' : 'hidden')};

  transition: ${(props) => props.theme.defaultTransition()};

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    opacity: 1 !important;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    opacity: 1 !important;
    color:#ffffff;
  }

  &:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    top: -7px;
    left: 30px;
    background-color: #000000;
    transform: rotate(45deg);
  }

  em {
    width: 65px;
    height: 5px;
    background-color: #fd0000;
    display: block;
    position: relative;
    top: 20px;
    right: 20px;
  }

  @media (max-width: ${LG}) {
    opacity: ${(props) => (props.showAlert ? 1 : 0)};
    visibility: ${(props) => (props.showAlert ? 'visible' : 'hidden')};
    display: ${(props) => (props.showAlert ? 'block' : 'none')};

    padding: 20px !important;
    width: 75vw !important;

    &:after {
      left: 114px;
    }
  }
`;

export const TimeInputs = styled.div`
	display: flex;
	align-items: center;
`;

export const NumberInput = styled.input <{ marginCustom?: string } >
  `
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
  line-height: 27px;
	color: #000000;
  border: 0;
  width: 100%;
  max-width:35px;
	text-align: center;
margin-left: ${(props) => (props.marginCustom ? props.marginCustom : '-6px')};

padding:0;


`;

export const Dots = styled.div<{ colorChange?: any }>`
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	/* identical to box height */

color: ${props => props.colorChange !== true ? '#757575' : "#000000"};

	position: relative;
	margin-right: 7px;
  top: -1px;
`;
