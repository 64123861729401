import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

export const IconsWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;

  a {
    display: inline-block;
    margin: 0 10px 0 15px;
  }

  button {
    background-color: transparent;
    border: 0;

    outline: 0 !important;

    img {
      position: relative;
      top: 2px;
    }
  }
`;

export const ContentMenu = styled.div<{ show?: boolean }>`
  position: fixed;
  height: 100%;
  background: #ffffff;
  width: 100%;
  top: 0;
  z-index: 3;
  overflow: scroll;

  right: ${props => (props.show ? "0" : "100%")};

  transition: ${props => props.theme.defaultTransition()};
`;

export const CrossIcon = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  right: 10px;
  top: 30px;
  position: absolute;
`;

export const ListaLinks = styled.ul`
  padding: 20px;
`;

export const ListaLi = styled.li<{ index?: number }>`
  margin-bottom: 10px;
  img {
    display: block;
    margin: 0 0 0 auto;

    position: relative;
    top: -17px;
  }

  a,
  button {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: ${props => (props.index === 4 ? props.theme.mainBlue : "#000000")};
  }

  button {
    background: transparent;
    border: 0;
    padding: 0;
  }
`;

export const CrossWrap = styled.div`
  h3 {
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #000000;
  }
`;


export const LangButton = styled.button`

margin-right:15px;
font-size: 16px;

`

