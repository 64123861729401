import React, { Suspense, FC, useEffect } from "react";
import { Wrapper } from "./style";

//components
import Hero from "./Hero";
import Categorias from "./Categorias";
import Beneficios from "./Beneficios/";
import HomeAgencias from "../../components/HomeAgencias";
import QuienesSomos from "./QuienesSomos";

//HELMET
import HelmetMetaData from "../../utils/HelmetMetaData";

interface Props {}

const Servicios: FC<Props> = () => {
  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  return (
    <Suspense fallback={<div />}>
      <HelmetMetaData
        title={"Jarvis | Nuestros Servicios"}
        quote={""}
      ></HelmetMetaData>
      <Wrapper>
        <Hero />
        <Categorias />
        <Beneficios />
        <QuienesSomos />
        <HomeAgencias />
      </Wrapper>
    </Suspense>
  );
};

export default Servicios;
