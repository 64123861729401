import React, { useState, Fragment, Suspense, useEffect } from "react";

import FlightCharacteristics from "./FlightCharacteristics";
import VIPLounges from "./VIPLounges";
import ReservationCharacteristics from "./ReservationCharacteristics";
import Services from "./Services";
import ReservationDetails from "./ReservationDetails";
import ServiciosDisponibles from "./ServiciosDisponibles";

import * as SC from "./style";

interface Props {
  bookingHook: {
    booking: any;
    setBooking: any;
  };
}

const BookingSearch: React.FC<Props> = ({
  bookingHook: { booking, setBooking }
}) => {
  const [data, setData] = useState(true);
  const [blockedTime, setBlockedTime] = useState<boolean>(false)
  const [isAepVvipAvailable, setIsAepVvipAvailable] = useState<boolean>(false)

  const [toShowData, setToShowData] = useState({
    TipoDeReserva: "-",
    Aerolinea: "-",
    TipoDeVuelo: "-",
    Fecha: "-",
    hora: "-",
    pasajeros: [
      {
        nombre: "adultos",
        cantidad: 0
      },
      {
        nombre: "niños",
        cantidad: 0
      },
      {
        nombre: "infantes",
        cantidad: 0
      },
      {
        nombre: "equipaje",
        cantidad: 0
      }
    ],

    equipaje: "-"
  });

  const [servicio, setServicio] = useState(null);



  return (
    <Suspense fallback={<div />}>
      <SC.Wrapper>
        <FlightCharacteristics
          data={data}
          setToShowData={setToShowData}
          toShowData={toShowData}
          bookingHook={{ booking, setBooking }}
          blockedTime={blockedTime}
          setBlockedTime={setBlockedTime}
          setIsAepVvipAvailable={setIsAepVvipAvailable}
        />

        {data ? (
          <Fragment>
            <SC.ReservationSection>
              <ReservationCharacteristics
                setToShowData={setToShowData}
                toShowData={toShowData}
                bookingHook={{ booking, setBooking }}
              />
              <Services
                servicio={servicio}
                setServicio={setServicio}
                bookingHook={{ booking, setBooking }}
                isAepVvipAvailable={isAepVvipAvailable}
              />
              <ReservationDetails toShowData={toShowData}
                servicio={servicio}
                bookingHook={{ booking, setBooking }}
                blockedTime={blockedTime}
              />
            </SC.ReservationSection>
            <VIPLounges />
          </Fragment>
        ) : (
          <ServiciosDisponibles />
        )}
      </SC.Wrapper>
    </Suspense>
  );
};

export default BookingSearch;
