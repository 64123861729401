import React, { Suspense, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, Headline, List, MobileText } from "./style";
import * as CSC from "../../../utils/commonStyle";
import Dot from "../../../assets/servicios-images/dot.svg";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../../utils/AOSconfig";

interface Props {}

const Beneficios: FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AosConfig();
  }, []);

  useWindowSize();
  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <Headline>
          <div data-aos="fade-up">
            <CSC.LittleTitle>{t('nuestros_servicios.hero.beneficios.volanta')}</CSC.LittleTitle>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <CSC.Title>{t('nuestros_servicios.hero.beneficios.titulo')}</CSC.Title>
          </div>

          {ShowWindowDimensions() <= SM && (
            <MobileText>{t('nuestros_servicios.hero.beneficios.beneficios_text_mobile')}</MobileText>
          )}
        </Headline>
        <List>
          <div data-aos="fade-up" data-aos-delay="100">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item1')}</p>
            </li>
          </div>
          <div data-aos="fade-up" data-aos-delay="400" >
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item2')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="100">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item3')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="400">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item4')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="100">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item5')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="400">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item6')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="100">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item7')}</p>
            </li>
          </div>

          <div data-aos="fade-up" data-aos-delay="400">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item8')}</p>
            </li>
          </div>
          
          <div data-aos="fade-up" data-aos-delay="100">
            <li>
              <img src={Dot} alt="item" />
              <p>{t('nuestros_servicios.hero.beneficios.item9')}</p>
            </li>
          </div>
        </List>
      </Wrapper>
    </Suspense>
  );
};

export default Beneficios;
