export const filtros = [
  {
    tab: "todas"
  },
  {
    tab: "realizadas"
  },
  {
    tab: "solicitadas"
  },
  {
    tab: "confirmadas"
  },
  {
    tab: "falta_info"
  },
  {
    tab: "pendiente_de_pago"
  },
  {
    tab: "canceladas"
  }
];

export const headlines = [
  {
    cat: "numero_de_reserva"
  },
  {
    cat: "fecha_de_vuelo"
  },
  {
    cat: "hora"
  },
  {
    cat: "numero_de_vuelo"
  },
  {
    cat: "tipo_de_reserva"
  },
  {
    cat: "Aeropuerto"
  },
  {
    cat: "Pasajero"
  },
  {
    cat: "Pasajeros"
  },
  {
    cat: "Pack"
  },
  {
    cat: "Estado"
  }
];

export const reservations = [
  {
    numeroReserva: 1,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Kathryn Murphy",
    pasajeros: 1,
    estado: "cancelada"
  },
  {
    numeroReserva: 2,
    fecha: "28/06/2020",
    hora: "05:36 pm",
    tipoReserva: "Conexión",
    aeropuerto: "ORE",
    pasajero: "Jacob Jones",
    pasajeros: 3,
    estado: "pendiente de pago"
  },
  {
    numeroReserva: 1,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Kathryn Murphy",
    pasajeros: 1,
    estado: "cancelada"
  },
  {
    numeroReserva: 2,
    fecha: "28/06/2020",
    hora: "05:36 pm",
    tipoReserva: "Conexión",
    aeropuerto: "ORE",
    pasajero: "Jacob Jones",
    pasajeros: 3,
    estado: "pendiente de pago"
  },
  {
    numeroReserva: 1,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Kathryn Murphy",
    pasajeros: 1,
    estado: "cancelada"
  },
  {
    numeroReserva: 3,
    fecha: "12/08/2020",
    hora: "01:08 pm",
    tipoReserva: "Partida",
    aeropuerto: "BRB",
    pasajero: "Leslie Alexander",
    pasajeros: 5,
    estado: "solicitada"
  },
  {
    numeroReserva: 4,
    fecha: "05/08/2020",
    hora: "12:01 pm",
    tipoReserva: "Partida",
    aeropuerto: "CBA",
    pasajero: "Floyd Miles",
    pasajeros: 2,
    estado: "falta info"
  },
  {
    numeroReserva: 5,
    fecha: "28/06/2020",
    hora: "10:32 pm",
    tipoReserva: "Conexión",
    aeropuerto: "ORE",
    pasajero: "Jane Cooper",
    pasajeros: 3,
    estado: "confirmada"
  },
  {
    numeroReserva: 6,
    fecha: "18/06/2020",
    hora: "03:48 am",
    tipoReserva: "Partida",
    aeropuerto: "KPG",
    pasajero: "Cameron Williamson",
    pasajeros: 2,
    estado: "cancelada"
  },
  {
    numeroReserva: 7,
    fecha: "28/06/2020",
    hora: "10:32 pm",
    tipoReserva: "Conexión",
    aeropuerto: "ORE",
    pasajero: "Justine Timer",
    pasajeros: 3,
    estado: "confirmada"
  },
  {
    numeroReserva: 8,
    fecha: "15/06/2020",
    hora: "11:23 pm",
    tipoReserva: "Partida",
    aeropuerto: "CAX",
    pasajero: "Bessie Cooper",
    pasajeros: 1,
    estado: "cancelada"
  },
  {
    numeroReserva: 9,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Conexión",
    aeropuerto: "EZE",
    pasajero: "Joe Toma",
    pasajeros: 1,
    estado: "confirmada"
  },
  {
    numeroReserva: 10,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "James Hetman",
    pasajeros: 1,
    estado: "confirmada"
  },
  {
    numeroReserva: 11,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Serena Johnson",
    pasajeros: 1,
    estado: "cancelada"
  },

  {
    numeroReserva: 12,
    fecha: "25/08/2018",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Savy Regin",
    pasajeros: 1,
    estado: "realizada"
  },
  {
    numeroReserva: 13,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Chandra Nalar",
    pasajeros: 1,
    estado: "pendiente de pago"
  },
  {
    numeroReserva: 14,
    fecha: "18/06/2020",
    hora: "04:02 am",
    tipoReserva: "Conexión",
    aeropuerto: "KPG",
    pasajero: "Darrell Steward",
    pasajeros: 2,
    estado: "realizada"
  },
  {
    numeroReserva: 15,
    fecha: "25/08/2015",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Sarah Smith",
    pasajeros: 1,
    estado: "confirmada"
  },
  {
    numeroReserva: 16,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Amanda Twick",
    pasajeros: 1,
    estado: "pendiente de pago"
  },
  {
    numeroReserva: 17,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Richard Jewll",
    pasajeros: 1,
    estado: "falta info"
  },
  {
    numeroReserva: 18,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Cami Trendon",
    pasajeros: 1,
    estado: "pendiente de pago"
  },
  {
    numeroReserva: 19,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Conexión",
    aeropuerto: "EZE",
    pasajero: "Joe Toma",
    pasajeros: 1,
    estado: "confirmada"
  },
  {
    numeroReserva: 20,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "James Hetman",
    pasajeros: 1,
    estado: "confirmada"
  },
  {
    numeroReserva: 21,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Serena Johnson",
    pasajeros: 1,
    estado: "cancelada"
  },

  {
    numeroReserva: 22,
    fecha: "25/08/2018",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Savy Regin",
    pasajeros: 1,
    estado: "realizada"
  },
  {
    numeroReserva: 23,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Chandra Nalar",
    pasajeros: 1,
    estado: "pendiente de pago"
  },

  {
    numeroReserva: 24,
    fecha: "25/08/2018",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Savy Regin",
    pasajeros: 1,
    estado: "realizada"
  },
  {
    numeroReserva: 25,
    fecha: "25/08/2020",
    hora: "07:59 pm",
    tipoReserva: "Arribo",
    aeropuerto: "EZE",
    pasajero: "Chandra Nalar",
    pasajeros: 1,
    estado: "pendiente de pago"
  }
];
