import React, { Suspense, FC, useState, useEffect, Fragment } from "react";
import {
  Wrapper,
  IconsWrap,
  ContentMenu,
  CrossIcon,
  ListaLinks,
  ListaLi,
  CrossWrap,
  LangButton
} from "./style";
import * as CSC from "../../utils/commonStyle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HamburgerMenu from "react-hamburger-menu";

import AvionLogo from "../../assets/icons/blue-plane.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import CloseIcon from "../../assets/icons/close-button.svg";
import Arrow from "../../assets/icons/blackArrow.svg";
import ArrowBlue from "../../assets/icons/blueArrow.svg";
// HOOK
import useBookings from "../../utils/useBookings";

import SearchBar from "../../components/SearchBar";
import Footer from "../../components/Footer";

import { Airport } from "../../actions/airports/types";
import { Pack } from "../../actions/packs/types";

interface Props {
  onClick?: any;
  userName?: string;
}

export interface Passenger {
  name: string;
  dob: string;
  phone: string;
  email: string;
  passport: string;
  nationality: string;
  notes: string;
}
export interface Booking {
  airport: Airport;
  airline: string;
  bookingType: string;
  flightType: string;
  date: string;
  adultsCount: number;
  childsCount: number;
  infantsCount: number;
  bagsCount: number;
  pack: Pack;
  passengers: Passenger[];
}

const MobileMenu: FC<Props> = ({ onClick, userName }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [onlySearchBar, setOnlySearchBar] = useState(false);
  const [inputFocus, setInputFocus] = useState(false)

  const { t } = useTranslation();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  // IBIS hook que cambia si estás o no logged
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    setLogged(!localStorage.getItem("userId"));
  }, []);

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    const _bookings = localStorage.getItem("bookings") || "{}";
    const __bookings = JSON.parse(_bookings);
    setBookings(__bookings);
  }, []);

  useEffect(() => {
    const _bookings = localStorage.getItem("bookings") || "{}";
    const __bookings = JSON.parse(_bookings);

    if (__bookings.length > 0) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  }, [bookings, showBtn]);

  useBookings({
    onChange: newBookings => {
      setCantidad(newBookings.length);
    }
  });

  const [cantidad, setCantidad] = useState(bookings.length);

  const [lang, setLang] = useState("ES");

	useEffect(() => {
	  const currentLang = localStorage.getItem("lang") || "ES"
	  setLang(currentLang)
	}, [])

	const setLang2 = () => {
	  const newLang = lang === "ES" ? "EN" : "ES"
	  setLang(newLang)
	  localStorage.setItem("lang", newLang)
	  window.location.reload()
	}

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <Link to="/">
          <CSC.Logo role="img" aria-label="Jarvis logo" />
        </Link>

        <IconsWrap>
          <button
            onClick={() => {
              setOnlySearchBar(true);
              setMenuOpen(!menuOpen);
            }}
          >
            <img src={SearchIcon} alt="buscar" />
          </button>

          <Link to="/mis-reservas">
            <img src={AvionLogo} alt="mis-reservas" />
          </Link>

          <LangButton onClick={setLang2}>ES/EN</LangButton>

          <HamburgerMenu
            isOpen={menuOpen}
            menuClicked={() => setMenuOpen(!menuOpen)}
            width={22}
            height={14}
            strokeWidth={2}
            rotate={0}
            color="black"
            borderRadius={0}
            animationDuration={0.3}
          />
        </IconsWrap>
      </Wrapper>

      <Fragment>
        {!onlySearchBar ? (
          <ContentMenu show={menuOpen}>
            <CrossIcon onClick={() => setMenuOpen(false)}>
              <img src={CloseIcon} alt="Cerrar" />
            </CrossIcon>

            <SearchBar mobileMenu={true} setMenuOpen={setMenuOpen} setInputFocus={setInputFocus}/>
            <ListaLinks>
              <ListaLi onClick={() => setMenuOpen(false)}>
                <Link to={"/"}>
                  {t("header.home")}
                  <img src={Arrow} alt="" />
                </Link>
              </ListaLi>
              <ListaLi onClick={() => setMenuOpen(false)}>
                <Link to={"/servicios"}>
                  {t("header.nuestros_servicios")}
                  <img src={Arrow} alt="" />
                </Link>
              </ListaLi>

              <ListaLi>
                <button
                  onClick={() => {
                    onClick(true);
                  }}
                >
                  {t("header.contacto")}
                </button>

                <img src={Arrow} alt="" />
              </ListaLi>
              {cantidad !== 0 && (
                <ListaLi onClick={() => setMenuOpen(false)} index={4}>
                  <Link to={"/mis-reservas"}>
                    {t("header.mis_reservas")}
                    <img src={ArrowBlue} alt="" />
                  </Link>
                </ListaLi>
              )}

              <ListaLi onClick={() => setMenuOpen(false)}>
                {logged ? (
                  <Link to={"/login"}>
                    {t("header.login")}
                    <img src={Arrow} alt="" />
                  </Link>
                ) : (
                  <Link to={"/agencia"}>
                    {userName}
                    <img src={Arrow} alt="" />
                  </Link>
                )}
              </ListaLi>
              {!logged && (
                <ListaLi onClick={() => setMenuOpen(false)}>
                  <Link to={"/logout"}>
                    {t("header.salir")}
                    <img src={Arrow} alt="" />
                  </Link>
                </ListaLi>
              )}
            </ListaLinks>
            <Footer menuMobile={true} />
          </ContentMenu>
        ) : (
          <ContentMenu show={menuOpen}>
            <CrossWrap>
              <h3>{t("header.busca_aeropuertos")}</h3>
              <CrossIcon
                onClick={() => {
                  setMenuOpen(false);
                  setOnlySearchBar(false);
                }}
              >
                <img src={CloseIcon} alt="Cerrar" />
              </CrossIcon>
            </CrossWrap>
            <SearchBar mobileMenu={true} setMenuOpen={setMenuOpen} setInputFocus={setInputFocus} />
          </ContentMenu>
        )}
      </Fragment>
    </Suspense>
  );
};

export default MobileMenu;
