import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useBookings from "../../../../utils/useBookings";

import DetailWhiteCard from "../../../DetailWhiteCard";

import * as SC from "./style";
import * as CSC from "../../../../utils/commonStyle";
import Modal from "../../../Modal";
import Cta from "../../../Cta";
import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../../utils/mobileQueries";

import { getTotalPrice, diffPack, diffServices } from "../../../../utils/getPriceAndSurcharge";
import { getAll } from "../../../../actions/settings/creators";
import { Setting } from "../../../../actions/settings/types";
import CouponIcon from "../../../../assets/icons/tag-solid.svg";
import InputText from '../../../InputText';
import { verify } from '../../../../actions/discounts/creators';
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

interface Props {
  toShowData: any;
  servicio?: any;
  bookingHook: {
    booking: any;
    setBooking: any;
  };
  blockedTime: boolean
}

const ReservationDetails: React.FC<Props> = ({
  toShowData,
  servicio,
  bookingHook: { booking, setBooking },
  blockedTime
}) => {
  const { t } = useTranslation();

  useWindowSize();
  const [openModal, setOpenModal] = useState(false);
  const [total, setTotal] = useState(0);

  const sum: any[] = [];

  const isEng = localStorage.getItem("lang") === "EN";

  const [_surcharge, setSurcharge] = useState<number>(0);
  const [_schedule, setSchedule] = useState<number[]>([0, 0]);

  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState<string>('');
  const [discountError, setDiscountError] = useState<string>('');
  const [discountData, setDiscountData] = useState<any|undefined>(undefined);
  const [discountStatus, setDiscountStatus] = useState<boolean|undefined>(undefined);
  const buttonIsDisabled = useSelector((state: RootState) => state.button.buttonIsDisabled);


  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getAll();
      const _surcharge = parseInt(
        settings.find(({ key }: Setting) => key === "surcharge")!.value
      );
      const _schedule_from = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_from")!.value
      );
      const _schedule_to = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_to")!.value
      );
      const _schedule = [_schedule_from, _schedule_to];

      setSurcharge(_surcharge);
      setSchedule(_schedule);
    };
    fetchSettings();
  }, []);

  const { totalPrice, surcharge, beforeDiscount } = useMemo(
    () => getTotalPrice(booking, toShowData.hora, _surcharge, _schedule),
    [booking, toShowData.hora, _surcharge, _schedule]
  );

  const pax = useMemo(() => booking.adultsCount + booking.childsCount, [booking]);

  const diffPackPricePax = useMemo(() =>
    diffPack(booking.pack, pax) - booking.pack.packPrice * (booking.adultsCount + booking.childsCount),
    [booking]
  );

  const diffServicesPricePax = useMemo(() =>
    diffServices(booking.pack.services, pax) - (booking.pack.services || []).map(({ servicePrice }: any) => servicePrice) * (booking.adultsCount + booking.childsCount),
    [booking]
  );

  const { bookings, updateBookings } = useBookings({});

  useEffect(() => {
    if (booking.pack.packPrice !== "" && sum.length >= 1) {
      let sumReduce = sum.reduce(function(acc, val) {
        return acc + val;
      }, 0);

      setTotal(sumReduce);
    }

    if (booking.pack.services.length === 0) {
      setTotal(0);
    }
  }, [total, sum]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const [disableButton, setDisableButton] = useState(true);

  const handleDisable = () => {
    if (
      booking.airline !== "-" &&
      booking.airline !== undefined &&
      (booking.bookingType !== "" || booking.bookingType !== "-") &&
      (booking.flightType !== "" || booking.flightType !== "-") &&
      booking.pack.packName !== "" &&
      booking.adultsCount > 0 &&
      booking.date.length === 17 &&
      booking.airline !== "Seleccionar" && !blockedTime
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  useEffect(() => {
    handleDisable();
  }, [booking]);

  const verifyDiscount = async () => {
    const code = discount;
    const airport = booking.airport._id;
    const pack = booking.pack._id;
    const date = booking.date;
    const data = await verify(code, airport, pack, date);

    setDiscountStatus(!data.error);
    setDiscountError(data.error);

    if (!data.error) {
      setDiscountData(data);
      setBooking({ ...booking, discount: data });
    }
  }

  useEffect(() => {
    setDiscountData(undefined);
    setDiscountStatus(undefined);
    setDiscountError('');
    setBooking({ ...booking, discount: undefined });
  }, [booking.pack, booking.date])

  const discountValue = beforeDiscount - totalPrice;

  useEffect(() => {
    if((booking?.pack?.packName.toLowerCase() == 'vvip & meet greet') || (booking?.pack?.packName.toLowerCase() == 'vvip meet & greet') && buttonIsDisabled){
      setDisableButton(true)
    }
  }, [booking, buttonIsDisabled]);

  return (
    <SC.Wrapper>
      {ShowWindowDimensions() > SM ? (
        <DetailWhiteCard
          title={t("resultados.detalle_reserva.titulo")}
          textBtn={t("resultados.detalle_reserva.boton")}
          btnLink={true}
          goTo={"/mis-reservas"}
          inactiveReservation={disableButton}
          onClick={() => {
            updateBookings([booking, ...bookings]);
          }}
        >
          <SC.ReservationInformation>
            <CSC.MediumParagraph>
              {t("resultados.buscador.tipo_de_reserva")}
            </CSC.MediumParagraph>
            <CSC.Paragraph> {toShowData.TipoDeReserva}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.aerolinea")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>{booking.airlineName}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.tipo_de_vuelo")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>{toShowData.TipoDeVuelo}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.fecha")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>
              {toShowData.fecha === "Invalid date"
                ? t("resultados.detalle_reserva.fecha_por_seleccionar")
                : toShowData.fecha}

              {toShowData.hora.length > 1 && ", " + toShowData.hora}
            </CSC.Paragraph>
            {toShowData.pasajeros.map(
              (
                tipo: { nombre: React.ReactNode; cantidad: number },
                i: number
              ) => {
                return (
                  <Fragment key={i}>
                    {tipo.cantidad !== 0 && (
                      <>
                        <CSC.MediumParagraph>{tipo.nombre}</CSC.MediumParagraph>
                        {tipo.nombre === "Infantes" ||
                        tipo.nombre === "Babies" ? (
                          <CSC.Paragraph>
                            {tipo.cantidad}{" "}
                            <SC.SinCargo>
                              {t("resultados.caracteristicas.sin_cargo")}
                            </SC.SinCargo>
                          </CSC.Paragraph>
                        ) : (
                          <CSC.Paragraph>{tipo.cantidad}</CSC.Paragraph>
                        )}
                      </>
                    )}
                  </Fragment>
                );
              }
            )}
          </SC.ReservationInformation>

          <SC.PackageInformation>
            {booking.pack.packName !== "" && (
              <CSC.ParagraphBold> {booking.pack.packName}</CSC.ParagraphBold>
            )}

            {booking.pack.packPrice !== "" && (
              <CSC.Paragraph>
                USD{" "}
                {booking.pack.packPrice *
                  (booking.adultsCount + booking.childsCount)}
              </CSC.Paragraph>
            )}

            {booking.pack.services.map(
              (
                item: {
                  serviceName: React.ReactNode;
                  serviceNameEN: React.ReactNode;
                  servicePrice: any;
                  type: string;
                },
                index: string | number | null | undefined
              ) => {
                sum.push(item.servicePrice);

                return (
                  <React.Fragment key={index}>
                    <CSC.MediumParagraph>
                      {(isEng
                        ? item.serviceNameEN || item.serviceName
                        : item.serviceName) !== "" && (
                        <>
                          {booking.adultsCount + booking.childsCount === 0 ? (
                            <>
                              {isEng
                                ? item.serviceNameEN || item.serviceName
                                : item.serviceName}
                            </>
                          ) : (
                            <>
                              {isEng
                                ? item.serviceNameEN || item.serviceName
                                : item.serviceName}{" "}
                              ({booking.adultsCount + booking.childsCount})
                            </>
                          )}
                        </>
                      )}
                    </CSC.MediumParagraph>
                    {(isEng
                      ? item.serviceNameEN || item.serviceName
                      : item.serviceName) !== "" && (
                      <CSC.Paragraph>
                        USD{" "}
                        {item.type !== "no multiplica" ? (
                          <>
                            {parseInt(item.servicePrice) *
                              (booking.adultsCount + booking.childsCount)}
                          </>
                        ) : (
                          <>{parseInt(item.servicePrice)}</>
                        )}
                      </CSC.Paragraph>
                    )}
                  </React.Fragment>
                );
              }
            )}

            {diffPackPricePax !== 0 && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.buscador.diferencia_paquete_pax")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {diffPackPricePax}</CSC.Paragraph2>
              </>
            )}

            {diffServicesPricePax !== 0 && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.buscador.diferencia_servicios_pax")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {diffServicesPricePax}</CSC.Paragraph2>
              </>
            )}

            {!!discountData && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.detalle_reserva.descuento_por_cupon")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {discountValue % 1 ? discountValue.toFixed(1) : discountValue}</CSC.Paragraph2>
              </>
            )}

            {surcharge > 0 && toShowData.hora.length === 5 && (
              <>
                <CSC.NightSurchargeContainer>
                  <CSC.MediumParagraph>
                    {t("resultados.buscador.recargo_titulo")}
                  </CSC.MediumParagraph>
                  <CSC.WarningIcon />
                </CSC.NightSurchargeContainer>
                <CSC.Paragraph>USD {surcharge}</CSC.Paragraph>
              </>
            )}

            {booking.pack.packPrice !== "" && (
              <>
                <CSC.MediumParagraph>
                  {t("resultados.detalle_reserva.total")}
                  <CSC.SmallParagraph>
                    {t("resultados.detalle_reserva.mas_impuestos")}
                  </CSC.SmallParagraph>
                </CSC.MediumParagraph>
                {/* <CSC.ParagraphBold>USD {total}</CSC.ParagraphBold> */}
                <CSC.ParagraphBold>USD {totalPrice}</CSC.ParagraphBold>
              </>
            )}
          </SC.PackageInformation>

          {!discountData && addDiscount &&
            <div style={{ marginBottom: '0.5em' }}>
              <CSC.MediumParagraph style={{ opacity: 1, color: discountStatus === false ? 'red' : 'inherit' }}>
                {t("resultados.detalle_reserva.pregunta_cupon")}
              </CSC.MediumParagraph>
              <InputText
                name={"flightNumber"}
                value={discount}
                onChange={(e: any) => setDiscount(e)}
                placeholder="0000000000"
                autoComplete="off"
                error={discountStatus === false}
              />
              {discountStatus === false &&
                <CSC.ParagraphBold style={{ marginTop: '0.24em', fontSize: '0.8em', color: discountStatus === false ? 'red' : 'inherit' }}>
                  {discountError}
                </CSC.ParagraphBold>
              }
            </div>
          }

          {!discountData && !!totalPrice && booking.date.length === 17 &&
            <div style={{ marginBottom: '1em' }}>
              <Cta onClick={() => addDiscount ? verifyDiscount() : setAddDiscount(true)} theme="blueDarkTheme">
                {t(addDiscount ? "resultados.detalle_reserva.verificar_cupon" : "resultados.detalle_reserva.agregar_cupon")}
                <img src={CouponIcon} alt="" height="20" style={{ marginLeft: '0.5em' }} />
              </Cta>
            </div>
          }
        </DetailWhiteCard>
      ) : (
        <Cta onClick={() => setOpenModal(true)} theme="blueDarkTheme">
          {t("resultados.detalle_reserva.boton_mobile_ver_detalle")}
        </Cta>
      )}

      <Modal
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <DetailWhiteCard
          title=""
          goTo={"/mis-reservas"}
          textBtn={t("resultados.detalle_reserva.boton")}
          inactiveReservation={disableButton}
          btnLink={true}
          onClick={() => {
            updateBookings([booking, ...bookings]);
          }}
        >
          <SC.MobileHeadline>
            <h3>{t("resultados.detalle_reserva.titulo")}</h3>
          </SC.MobileHeadline>
          <SC.ReservationInformation>
            <CSC.MediumParagraph>
              {t("resultados.buscador.tipo_de_reserva")}
            </CSC.MediumParagraph>
            <CSC.Paragraph> {toShowData.TipoDeReserva}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.aerolinea")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>{booking.airlineName}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.tipo_de_vuelo")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>{toShowData.TipoDeVuelo}</CSC.Paragraph>
            <CSC.MediumParagraph>
              {t("resultados.buscador.fecha")}
            </CSC.MediumParagraph>
            <CSC.Paragraph>
              {toShowData.fecha === "Invalid date"
                ? t("resultados.buscador.fecha_a_seleccionar")
                : toShowData.fecha}

              {toShowData.hora.length > 1 && ", " + toShowData.hora}
            </CSC.Paragraph>
            {toShowData.pasajeros.map(
              (
                tipo: { nombre: React.ReactNode; cantidad: number },
                i: number
              ) => {
                return (
                  <Fragment key={i}>
                    {tipo.cantidad !== 0 && (
                      <>
                        <CSC.MediumParagraph>{tipo.nombre}</CSC.MediumParagraph>
                        {tipo.nombre === "Infantes" ||
                        tipo.nombre === "Babies" ? (
                          <CSC.Paragraph>
                            {tipo.cantidad}{" "}
                            <SC.SinCargo>
                              {t("resultados.caracteristicas.sin_cargo")}
                            </SC.SinCargo>
                          </CSC.Paragraph>
                        ) : (
                          <CSC.Paragraph>{tipo.cantidad}</CSC.Paragraph>
                        )}
                      </>
                    )}
                  </Fragment>
                );
              }
            )}
          </SC.ReservationInformation>
          <SC.PackageInformation>
            {booking.pack.packName !== "" && (
              <CSC.ParagraphBold> {booking.pack.packName}</CSC.ParagraphBold>
            )}

            {booking.pack.packPrice !== "" && (
              <CSC.Paragraph>
                USD{" "}
                {booking.pack.packPrice *
                  (booking.adultsCount + booking.childsCount)}
              </CSC.Paragraph>
            )}

            {booking.pack.services.map(
              (
                item: {
                  serviceName: React.ReactNode;
                  serviceNameEN: React.ReactNode;
                  servicePrice: any;
                  type: string;
                },
                index: string | number | null | undefined
              ) => {
                sum.push(item.servicePrice);

                return (
                  <React.Fragment key={index}>
                    <CSC.MediumParagraph>
                      {(isEng
                        ? item.serviceNameEN || item.serviceName
                        : item.serviceName) !== "" && (
                        <>
                          {isEng
                            ? item.serviceNameEN || item.serviceName
                            : item.serviceName}{" "}
                          ({booking.adultsCount + booking.childsCount})
                        </>
                      )}
                    </CSC.MediumParagraph>
                    {(isEng
                      ? item.serviceNameEN || item.serviceName
                      : item.serviceName) !== "" && (
                      <CSC.Paragraph>
                        USD{" "}
                        {item.type !== "no multiplica" ? (
                          <>
                            {parseInt(item.servicePrice) *
                              (booking.adultsCount + booking.childsCount)}
                          </>
                        ) : (
                          <>{parseInt(item.servicePrice)}</>
                        )}
                      </CSC.Paragraph>
                    )}
                  </React.Fragment>
                );
              }
            )}

            {diffPackPricePax !== 0 && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.buscador.diferencia_paquete_pax")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {diffPackPricePax}</CSC.Paragraph2>
              </>
            )}

            {diffServicesPricePax !== 0 && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.buscador.diferencia_servicios_pax")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {diffServicesPricePax}</CSC.Paragraph2>
              </>
            )}

            {!!discountData && (
              <>
                <CSC.MediumParagraph2>
                  {t("resultados.detalle_reserva.descuento_por_cupon")}
                </CSC.MediumParagraph2>
                <CSC.Paragraph2>USD {discountValue % 1 ? discountValue.toFixed(1) : discountValue}</CSC.Paragraph2>
              </>
            )}

            {surcharge > 0 && toShowData.hora.length === 5 && (
              <>
                <CSC.NightSurchargeContainer>
                  <CSC.MediumParagraph>
                    {t("resultados.buscador.recargo_titulo")}
                  </CSC.MediumParagraph>
                  <CSC.WarningIcon />
                </CSC.NightSurchargeContainer>
                <CSC.Paragraph>USD {surcharge}</CSC.Paragraph>
              </>
            )}

            {booking.pack.packPrice !== "" && (
              <>
                <CSC.MediumParagraph>
                  {t("resultados.detalle_reserva.total")}
                  <CSC.SmallParagraph>
                    {t("resultados.detalle_reserva.mas_impuestos")}
                  </CSC.SmallParagraph>
                </CSC.MediumParagraph>
                {/* <CSC.ParagraphBold>USD {total}</CSC.ParagraphBold> */}
                <CSC.ParagraphBold>USD {totalPrice}</CSC.ParagraphBold>
              </>
            )}
          </SC.PackageInformation>

          {!discountData && addDiscount &&
            <div style={{ marginBottom: '0.5em' }}>
              <CSC.MediumParagraph style={{ opacity: 1, color: discountStatus === false ? 'red' : 'inherit' }}>
                {t("resultados.detalle_reserva.pregunta_cupon")}
              </CSC.MediumParagraph>
              <InputText
                name={"flightNumber"}
                value={discount}
                onChange={(e: any) => setDiscount(e)}
                placeholder="0000000000"
                autoComplete="off"
                error={discountStatus === false}
              />
              {discountStatus === false &&
                <CSC.ParagraphBold style={{ marginTop: '0.24em', fontSize: '0.8em', color: discountStatus === false ? 'red' : 'inherit' }}>
                  {t("resultados.detalle_reserva.invalido_cupon")}
                </CSC.ParagraphBold>
              }
            </div>
          }

          {!discountData && !!totalPrice &&
            <div style={{ marginBottom: '1em' }}>
              <Cta onClick={() => addDiscount ? verifyDiscount() : setAddDiscount(true)} theme="blueDarkTheme">
                {t(addDiscount ? "resultados.detalle_reserva.verificar_cupon" : "resultados.detalle_reserva.agregar_cupon")}
                <img src={CouponIcon} alt="" height="20" style={{ marginLeft: '0.5em' }} />
              </Cta>
            </div>
          }
        </DetailWhiteCard>
      </Modal>
    </SC.Wrapper>
  );
};

export default ReservationDetails;
