import styled from "styled-components";
import search from "../../assets/icons/search-icon.svg";
import { LG, LargerScreens, SM, XS } from "../../utils/constants";

export const Wrapper = styled.div`
  height: 69px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;

  @media (min-width: ${LargerScreens}) {
    max-width: 1427px;
    margin: 0 auto;
  }

  .item {
    border: 1px solid lightblue;
    margin: 5px;
    padding: 5px;
  }

  .item.active,
  .item:hover {
    background: #d3d3d33b;
  }

  .item:hover {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  flex: 1;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  &::placeholder {
    color: var(--black);
  }
`;

export const SearchIcon = styled.span`
  mask: url(${search}) center / contain no-repeat;
  background: var(--black);
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const SearchWrap = styled.div`
  border-top: 1px solid var(--secondary-gray-light);
  border-bottom: 1px solid var(--secondary-gray-light);
  margin-bottom: 32px;
  padding-left: 99px;
  background: rgba(209, 223, 229, 0.1);
`;

export const RelativeWrap = styled.div`
  position: relative;
`;

export const Submenu = styled.ul<{ searchTerm?: string }>`
  position: absolute;
  display: block;
  width: auto;
  height: auto !important;
  background-color: white;
  z-index: 10;
  top: 77px;
  right: 0;
  left: 0;
  padding: 25px 20px 15px 20px !important;
  overflow: hidden;

  box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.15);

  visibility: ${props => (props.searchTerm !== "" ? "visible" : "hidden")};
  opacity: ${props => (props.searchTerm !== "" ? 1 : 0)};

  display: grid;
  grid-template-columns: 1fr 1fr;

  li {
    margin-bottom: 15px;

    &.hoverable:hover,
    &.unique {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
    &.key-hover {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
  }

  a {
    text-decoration: none;
    color: #000000;
    margin-bottom: 25px;
  }

  &:focus {
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${XS}) {
    height: auto !important;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export const Card = styled.div`
  display: inline-block;
  align-items: center;
  cursor: pointer;

  margin-right: 22px;
  display: flex;

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    text-align: center;
    display: block;
    margin: 0 auto;
    line-height: 43px;
    margin-bottom: -13px;
    color: rgba(0, 0, 0, 0.5);

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    top: 50%;
  }

  @media (max-width: ${SM}) {
    h5 {
      font-size: 14px;
    }
  }
  @media (max-width: ${XS}) {
    h6 {
      font-weight: 400;
    }
  }
`;

export const BgPic = styled.div<{ bg?: any }>`
  width: 86px;
  height: 72px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: ${XS}) {
    width: 17%;
  }
`;

export const Headline = styled.div`
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;

  max-width: 380px;

  @media (min-width: ${LargerScreens}) {
    max-width: 450px;
  }

  @media (max-width: ${LG}) {
    max-width: 450px;
  }

  @media (max-width: ${XS}) {
    max-width: 70%;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${XS}) {
      overflow: auto;
      white-space: initial;
    }
  }

  h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${XS}) {
      overflow: auto;
      white-space: initial;
    }
  }
`;

export const MenuSearchWrap = styled.div<{ active?: boolean }>`
  border: ${props =>
    props.active ? "1px solid #000000" : "1px solid rgba(0, 0, 0, 0.1)"};

  margin: 100px 15px 15px 15px;
  padding-left: 0;

  background: transparent;

  > div {
    height: 53px;
  }
`;

export const MenuInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  flex: 1;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
  }

  @media (max-width: ${XS}) {
    font-size: 14px;
  }
`;

export const MenuSearchIcon = styled.span`
  mask: url(${search}) center / contain no-repeat;
  background: var(--black);
  width: 24px;
  height: 24px;
  margin-right: 10px;
  opacity: 0.4;
`;

export const MenuSubmenu = styled.div<{ searchTerm?: string }>`
  position: absolute;
  display: block;
  width: auto;
  height: auto !important;

  z-index: 10;
  padding: 25px 20px 15px 20px !important;
  overflow: hidden;

  margin-top: 0;
  background: #ffffff;

  visibility: ${props => (props.searchTerm !== "" ? "visible" : "hidden")};
  opacity: ${props => (props.searchTerm !== "" ? 1 : 0)};

  display: grid;
  grid-template-columns: 1fr 1fr;

  a {
    text-decoration: none;
    color: #000000;
  }

  @media (max-width: ${SM}) {
    a {
      margin-bottom: 12px;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${XS}) {
    height: auto !important;
    /*     width: 100%; */
  }
`;

export const KeyItem = styled.div<{ setSelected?: any; setHovered?: any }>``;
