import React from "react";

import * as SC from "./style";
import twitterIcon from "../../assets/icons/twitter-icon.svg";
import instagramIcon from "../../assets/icons/instagram-icon.svg";
import facebookIcon from "../../assets/icons/facebook-icon.svg";
import linkedinIcon from "../../assets/icons/linkedin-icon.svg";

import TwitterHover from "../../assets/icons/hover/twitter-hover.svg"
import InstagramHover from "../../assets/icons/hover/instagram-hover.svg"
import FacebookHover from "../../assets/icons/hover/facebook-hover.svg"
import LinkedinHover from "../../assets/icons/hover/linkedin-hover.svg"

interface Props { };

const SocialNetworks: React.FC<Props> = () => {
    return (
        <SC.Wrapper>

            {/* <SC.SocialLink href="/twitter" target={'_blank'} >
                <SC.SocialWrap logo={twitterIcon} hoverLogo={TwitterHover} height={13}>
                </SC.SocialWrap>
            </SC.SocialLink> */}

            <SC.SocialLink href="https://www.instagram.com/jarvisvipservices/" target={'_blank'} >
                <SC.SocialWrap logo={instagramIcon} hoverLogo={InstagramHover} >
                </SC.SocialWrap>
            </SC.SocialLink>

            <SC.SocialLink href="https://www.facebook.com/Jarvisvipservices" target={'_blank'} >
                <SC.SocialWrap logo={facebookIcon} hoverLogo={FacebookHover}>
                </SC.SocialWrap>
            </SC.SocialLink>

             <SC.SocialLink href="https://www.linkedin.com/company/jarvis-airport-services" target={'_blank'} >
                <SC.SocialWrap logo={linkedinIcon} hoverLogo={LinkedinHover}>
                </SC.SocialWrap>
            </SC.SocialLink>

        </SC.Wrapper>
    );
};

export default SocialNetworks;