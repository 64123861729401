import React, { Suspense, FC, useState, Fragment, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  BgImage,
  LoginHeader,
  InnerWrap,
  Grid,
  AgenciaLink,
  AgenciaWrap,
  CloseBtn,
  FormlogIn,
  InputWrap,
  View2,
  UploadPhoto,
  PhotoHolder,
  BottomWrap,
  PhotoShow,
  WrapperButton,
  BackButton,
  NextButton,
  BasesBtn,
  ImageErrorMsg
} from "./style";
import { Logo, Title, Subtitle } from "../../utils/commonStyle";
import RegularInput from "../../components/RegularInput";
import CameraIcon from "../../assets/images/camera.svg";
import InputCheckbox from "../../components/InputCheckbox";
import InputError from "../../components/InputError";
import HelmetMetaData from "../../utils/HelmetMetaData";
import GeneralError from "../../components/GeneralError";
import Modal from "../../components/Modal";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

import { signup } from "./../../actions/auth/creators";
import BasesYCondiciones from "../../components/BasesYCondiciones";

interface Props {}

const Registro: FC<Props> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  useWindowSize();

  const [step, setStep] = useState(0);
  const [emptyFields, setEmptyFields] = useState({
    step1: true,
    step2: true
  });

  const [registerError, setRegisterError] = useState(-1);
  const [openBases, setOpenBases] = useState(false);

  const [fileImage, setFileImage] = useState();
  const [triggerImage, setTriggerImage] = useState(false);
  const [validateEmail, setValidateEmail] = useState<string | boolean>("empty");
  const [emailName, setEmailName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [checkValue, setCheckValue] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [formData, setFormData] = useState({
    empresa: "",
    cuit: "",
    direccion: "",
    contacto: "",
    operaciones: "",
    administrativo: "",
    tel: "",
    perfilEmail: "",
    perfilPassword: ""
  });


  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleValidMail = (email: {
    target: React.SetStateAction<any>;
    value: React.SetStateAction<any>;
    name: React.SetStateAction<any>;
  }) => {
    let re = /\S+@\S+\.\S+/;

    setEmailName(email.target.name);
    setValueEmail(email.target.value);

    handleInputChange(email, 0, "");
    if (re.test(email.target.value)) {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  };

  useEffect(() => {
    setValidateEmail("empty");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files && e.target.files[0];
      if (file.size > 1048576) {
        setImageError(true);
      } else {
        getBase64(file).then((base64: any) => {
          localStorage.setItem("fileBase64", base64);
        });
        setImageError(false);
      }
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setFileImage(window.localStorage.getItem("fileBase64"));
    }, 400);
  }, [fileImage, triggerImage]);

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    setValidateEmail("empty");
  }, []);

  useEffect(() => {
    document.body.style.overflow = "inherit";
    document.documentElement.style.overflow = "inherit";

    if (step === 0) {
      handleEmptyFields(0);
    } else {
      handleEmptyFields(1);
    }
  }, [formData, step, checkValue]);

  const handleEmptyFields = (steps: number) => {
    if (steps === 0) {
      if (
        formData.empresa === "" ||
        formData.cuit === "" ||
        formData.direccion === "" ||
        formData.contacto === "" ||
        formData.administrativo === "" ||
        formData.tel === "" ||
        (!validateEmail && emailName === "contacto") ||
        (!validateEmail && emailName) === "administrativo"
      ) {
        setEmptyFields({
          ...emptyFields,
          step1: true
        });
      } else {
        setEmptyFields({
          ...emptyFields,
          step1: false
        });
      }
    }

    if (steps === 1) {
      if (
        formData.perfilEmail === "" ||
        formData.perfilPassword === "" ||
        (!validateEmail && emailName === "perfilEmail") ||
        checkValue === false
      ) {
        setEmptyFields({
          ...emptyFields,
          step2: true
        });
      } else {
        setEmptyFields({
          ...emptyFields,
          step2: false
        });
      }
    }
  };

  if (registerError === 1) {
    history.push("/agencia");
  }

  const handleSignup = () => {
    const payload = {
      companyName: formData.empresa,
      taxId: formData.cuit,
      address: formData.direccion,
      paymentCondition: "Al contado",
      operationContact: formData.contacto,
      adminContact: formData.administrativo,
      phone: formData.tel,
      email: formData.perfilEmail,
      password: formData.perfilPassword
    };
    dispatch(signup({ payload, setRegisterError }));

    return <Redirect to="/agencia" />;
  };

  useEffect(() => {
    if (openBases) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openBases]);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <HelmetMetaData
          title={"Jarvis | Registro"}
          quote={""}
          description={"Registrate como agencia"}
        ></HelmetMetaData>

        {ShowWindowDimensions() > SM && <BgImage />}

        <Grid>
          <LoginHeader>
            <Link to="/">
              <Logo role="img" aria-label="Jarvis logo" />
            </Link>
            <AgenciaWrap>
              <Link to="/login">
                <AgenciaLink>
                  {t("registro_agencia.boton_inicia_sesion")}
                </AgenciaLink>
              </Link>
              <Link to="">
                <CloseBtn />
              </Link>
            </AgenciaWrap>
          </LoginHeader>

          {step === 0 && (
            <InnerWrap>
              <Title>{t("registro_agencia.titulo")}</Title>
              <Subtitle></Subtitle>
              <FormlogIn>
                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.nombre_de_la_empresa")}
                    type={"text"}
                    placeholder={t(
                      "registro_agencia.nombre_de_la_empresa_input"
                    )}
                    onChange={handleInputChange}
                    name={"empresa"}
                    value={formData["empresa"]}
                    filled={formData["empresa"] !== "" ? "filled" : "empty"}
                  />
                </InputWrap>

                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.CUIT")}
                    type={"text"}
                    placeholder={t("registro_agencia.CUIT_input")}
                    onChange={handleInputChange}
                    name={"cuit"}
                    filled={formData["cuit"] !== "" ? "filled" : "empty"}
                  />
                </InputWrap>

                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.direccion")}
                    type={"text"}
                    placeholder={t("registro_agencia.direccion_input")}
                    onChange={handleInputChange}
                    name={"direccion"}
                    filled={formData["direccion"] !== "" ? "filled" : "empty"}
                  />
                </InputWrap>

                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.contacto_operaciones")}
                    type={"text"}
                    placeholder={t(
                      "registro_agencia.contacto_operaciones_input"
                    )}
                    onChange={handleValidMail}
                    errorMail={validateEmail}
                    name={"contacto"}
                    emailName={emailName}
                    filled={formData["contacto"] !== "" ? "filled" : "empty"}
                  />
                  {!validateEmail && emailName === "contacto" && (
                    <InputError text={t("generales.error_email")} />
                  )}
                </InputWrap>

                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.contacto_administrativo")}
                    type={"text"}
                    placeholder={t(
                      "registro_agencia.contacto_administrativo_input"
                    )}
                    onChange={handleValidMail}
                    errorMail={validateEmail}
                    name={"administrativo"}
                    emailName={emailName}
                    filled={
                      formData["administrativo"] !== "" ? "filled" : "empty"
                    }
                  />
                  {!validateEmail && emailName === "administrativo" && (
                    <InputError text={t("generales.error_email")} />
                  )}
                </InputWrap>

                <InputWrap>
                  <RegularInput
                    label={t("registro_agencia.telefono_emergencia")}
                    type={"text"}
                    placeholder={t(
                      "registro_agencia.telefono_emergencia_input"
                    )}
                    onChange={handleInputChange}
                    name={"tel"}
                    filled={formData["tel"] !== "" ? "filled" : "empty"}
                  />
                </InputWrap>
              </FormlogIn>

              <WrapperButton>
                <BackButton onClick={() => history.push("/login")}>
                  {t("registro_agencia.boton_atras")}
                </BackButton>
                {emptyFields.step1 ? (
                  <NextButton inactive={true} onClick={() => {}}>
                    {t("registro_agencia.boton_siguiente")}
                  </NextButton>
                ) : (
                  <NextButton inactive={false} onClick={() => setStep(1)}>
                    {t("registro_agencia.boton_siguiente")}
                  </NextButton>
                )}
              </WrapperButton>
            </InnerWrap>
          )}

          {step === 1 && (
            <Fragment>
              <InnerWrap>
                <Title>{t("registro_agencia.titulo")}</Title>
                <Subtitle>{t("registro_agencia.text")}</Subtitle>
                <View2>
                  <div>
                    <InputWrap>
                      <RegularInput
                        label={t("registro_agencia.email")}
                        type={"text"}
                        placeholder={"nombre@mail"}
                        onChange={handleValidMail}
                        errorMail={validateEmail}
                        name={"perfilEmail"}
                        emailName={emailName}
                        filled={
                          formData["perfilEmail"] !== "" ? "filled" : "empty"
                        }
                      />
                      {!validateEmail && emailName === "perfilEmail" && (
                        <InputError text={t("generales.error_email")} />
                      )}
                    </InputWrap>

                    <InputWrap>
                      <RegularInput
                        label={t("registro_agencia.password")}
                        type={"password"}
                        placeholder={"******"}
                        onChange={handleInputChange}
                        name={"perfilPassword"}
                        filled={
                          formData["perfilPassword"] !== "" ? "filled" : "empty"
                        }
                      />
                    </InputWrap>
                  </div>
                  <UploadPhoto>
                    <h5>{t("registro_agencia.foto_perfil")}</h5>

                    {fileImage ? (
                      <>
                        <PhotoShow
                          style={{ background: `url(${fileImage})` }}
                        ></PhotoShow>
                        <span>
                          {t("registro_agencia.cambiar_foto")}
                          <input
                            type={"file"}
                            accept="image/*"
                            onChange={e => {
                              imageUpload(e);
                              setTriggerImage(!triggerImage);
                            }}
                          />
                        </span>
                        <ImageErrorMsg show={imageError}>
                    {lang === "EN"
                      ? "Please upload an image that is 1MB or less"
                      : "Por favor subí una imagen que pese 1MB o menos"}
                  </ImageErrorMsg>
                      </>
                    ) : (
                      <>
                        <PhotoHolder>
                          <input
                            type={"file"}
                            accept="image/*"
                            onChange={e => {
                              imageUpload(e);
                              setTriggerImage(!triggerImage);
                            }}
                          />
                          <img src={CameraIcon} alt="change pic" />
                        </PhotoHolder>
                        <span>
                          {t("registro_agencia.seleccionar_foto")}
                          <input
                            type={"file"}
                            accept="image/*"
                            onChange={e => {
                              imageUpload(e);
                              setTriggerImage(!triggerImage);
                            }}
                          />
                        </span>
                        <ImageErrorMsg show={imageError}>
                    {lang === "EN"
                      ? "Please upload an image that is 1MB or less"
                      : "Por favor subí una imagen que pese 1MB o menos"}
                  </ImageErrorMsg>
                      </>
                    )}
                  </UploadPhoto>
                
                </View2>
              </InnerWrap>
              <BottomWrap>
                <InputCheckbox
                  onClick={() => setCheckValue(!checkValue)}
                  value={checkValue}
                >
                  <span>{t("registro_agencia.Creando_una_cuenta")} </span>
                </InputCheckbox>
                <BasesBtn
                  onClick={() => {
                    setOpenBases(true);
                  }}
                >
                  {""}
                  {t("registro_agencia.bases_y_condiciones")}
                </BasesBtn>
                <GeneralError
                  visible={registerError === 0}
                  text={"Error al crear la cuenta"}
                />
              </BottomWrap>
              <WrapperButton>
                <BackButton onClick={() => setStep(0)}>
                  {t("registro_agencia.boton_atras")}
                </BackButton>

                {emptyFields.step2 ? (
                  <NextButton inactive={true} onClick={() => {}}>
                    {t("registro_agencia.boton_crear_cuenta")}
                  </NextButton>
                ) : (
                  <NextButton inactive={false} onClick={handleSignup}>
                    {t("registro_agencia.boton_crear_cuenta")}
                  </NextButton>
                )}
              </WrapperButton>
            </Fragment>
          )}
        </Grid>

        {/* MODAL BASES Y CONDICIONES */}
        <Modal
          isOpen={openBases}
          closeModal={() => {
            setOpenBases(false);
          }}
          fullscreen={true}
        >
          <BasesYCondiciones />
        </Modal>
      </Wrapper>
    </Suspense>
  );
};

export default Registro;
