import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const getPacksByAirport = async (id: string) => {
  const { data: { packs } } = await axios.get(`${API_URL}/pack/${id}`,{headers:{'x-access-token':localStorage.getItem('token')}});
  return packs.filter(({ visible }: { visible: boolean }) => visible)
    .map((pack: any) => {
      const services = pack.services.filter(({ visible }: { visible: boolean }) => visible)
      const packItems = pack.packItems.filter(({ visible }: { visible: boolean }) => visible)
      return { ...pack, services, packItems }
    });
};