import React, { Suspense, FC, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Wrapper, BgImage, Content, Headline, ButtonWrap } from "./style";
import {
  Logo,
  LittleTitle,
  Title,
  Paragraph
} from "../../../utils/commonStyle";

import Cta from "../../../components/Cta";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";

//components

interface Props {}

interface ParamTypes {
  bookingId: string;
}

const SuccessPage: FC<Props> = () => {
  const { bookingId} = useParams<ParamTypes>();
  const { t } = useTranslation();
  const [paramsUrl, setParamsUrl] = useState<string[]>([])

  useWindowSize();

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    setParamsUrl(bookingId.split('&'))
    window.scrollTo(0, 0);

  }, []);

  const [agencia, setAgencia] = useState(false);

  return (
    <Suspense fallback={<div />}>
      {" "}
      {ShowWindowDimensions() > SM ? (
        <>
          {!agencia ? (
            <Wrapper>
              <BgImage />
              <Content>
                <Link to="/">
                  <Logo role="img" aria-label="Jarvis logo" />
                </Link>
                <Headline>
                  <LittleTitle>{t('success_page.volanta')}</LittleTitle>
                  <Title>
                    {t('success_page.titulo')} 🙌🏼
                  </Title>
                  <Paragraph>
                    {t('success_page.texto')}
                  </Paragraph>
                  <ButtonWrap>
                    <Cta
                      isRoute={true}
                      toRoute={"/"}
                      onClick={() => null}
                      theme="simpleTheme"
                    >
                      {t('success_page.boton_home')}
                    </Cta>
                    <Cta onClick={() => window.open(`${process.env.REACT_APP_API_URL}/booking/comprobante/${paramsUrl}`, '_blank')} theme="blueDarkTheme">
                      {t('success_page.boton_descargar_comprobantes')}
                    </Cta>
                  </ButtonWrap>
                </Headline>
              </Content>
            </Wrapper>
          ) : (
            <Wrapper>
              <BgImage />
              <Content>
                <Link to="/">
                  <Logo role="img" aria-label="Jarvis logo" />
                </Link>
                <Headline>
                  <LittleTitle>{t('success_page.volanta')}</LittleTitle>
                  <Title>{t('success_page.reserva_exito')}</Title>
                  <Paragraph>
                    {t('success_page.reserva_exito_text')}
                  </Paragraph>
                  <ButtonWrap>
                    <Cta
                      isRoute={true}
                      toRoute={"/"}
                      onClick={() => null}
                      theme="simpleTheme"
                    >
                      {t('success_page.boton_home')}
                    </Cta>
                    <Cta onClick={() => null} theme="blueDarkTheme">
                      {t('success_page.boton_completar_reserva')}
                    </Cta>
                  </ButtonWrap>
                </Headline>
              </Content>
            </Wrapper>
          )}
        </>
      ) : (
        <div>
          {!agencia ? (
            <Content>
              <Link to="/">
                <Logo role="img" aria-label="Jarvis logo" />
              </Link>
              <Headline>
                <LittleTitle>{t('success_page.volanta')}</LittleTitle>
                <Title>{t('success_page.titulo')} 🙌🏼</Title>
                <Paragraph>
                  {t('success_page.texto')}
                </Paragraph>
                <ButtonWrap>
                  <Cta
                    isRoute={true}
                    toRoute={"/"}
                    onClick={() => null}
                    theme="simpleTheme"
                  >
                    {t('success_page.boton_home')}
                  </Cta>
                  <Cta onClick={() => window.open(`${process.env.REACT_APP_API_URL}/booking/comprobante/${paramsUrl}`, '_blank')} theme="blueDarkTheme">
                    {t('success_page.boton_descargar_comprobantes')}
                  </Cta>
                </ButtonWrap>
              </Headline>
            </Content>
          ) : (
            <Content>
              <Link to="/">
                <Logo role="img" aria-label="Jarvis logo" />
              </Link>
              <Headline>
                <LittleTitle>{t('success_page.volanta')}</LittleTitle>
                <Title>{t('success_page.reserva_exito')}</Title>
                <Paragraph>
                  {t('success_page.reserva_exito_text')}
                </Paragraph>
                <ButtonWrap>
                  <Cta
                    isRoute={true}
                    toRoute={"/"}
                    onClick={() => null}
                    theme="simpleTheme"
                  >
                    {t('success_page.boton_home')}
                  </Cta>
                  <Cta onClick={() => null} theme="blueDarkTheme">
                    {t('success_page.boton_completar_reserva')}
                  </Cta>
                </ButtonWrap>
              </Headline>
            </Content>
          )}{" "}
        </div>
      )}
    </Suspense>
  );
};

export default SuccessPage;
