import styled from "styled-components";
import { LargerScreens, SM } from "../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  overflow: hidden;
  padding: 80px 0 150px 0;
  grid-gap: 0 40px;

  > h2 {
    margin-top: 8px;
  }

  @media (max-width: ${SM}) {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 0 60px 0;
  }
`;

export const Paragraph = styled.p`
  max-width: 574px;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const Headline = styled.div`
  max-width: 491px;

  @media (max-width: ${SM}) {
    h2 {
      font-size: 22px;
      line-height: 32px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
    }
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const PicWrapper = styled.div`
  @media (max-width: ${SM}) {
    width: 100%;
    height: 100%;
    margin-bottom: 70px;
  }
`;

export const Pic = styled.div<{ bg?: string }>`
  background: url(${props => props.bg}) no-repeat center center / cover;
  max-width: 448px;
  min-height: 400px;
  margin: 0 0 0 auto;
  position: relative;

  @media (max-width: ${SM}) {
    max-width: unset;
    min-height: 250px;
  }

  @media (min-width: ${LargerScreens}) {
    max-width: 800px;
    min-height: 500px;
  }
`;

export const Icon = styled.img`
  position: absolute;
  bottom: -111px;
  left: -100px;

  @media (max-width: ${SM}) {
    bottom: -87px;
    left: 50%;
    margin-left: -80px;
    width: 160px;
  }
`;
