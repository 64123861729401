export const TEMP_DATA: IReservation[] = [
  {
      airportCode: "EZE",
      airport: "COR, Aeropuerto Internacional Ingeniero Aeronáutico Ambrosio Taravella",
      lounge: "Salón Cóndor Aerolíneas Argentinas",
      date: "Miércoles 14 de Julio de 2020, 04:20",
      reservationType:  "departure",
      adultsNumber: 2,
      childrensNumber: 0,
      infantsNumber: 0,
      baggageNumber: 4,
      package: {
          id: "1",
          type: "pack vip in",
          title: "Experiencia VIP",
          services: ["Vuelos Internacionales, Acceso Área VIP / Lounge, Migraciones Fast Track, Traslado de Equipaje"],
          price: 50.00,
          extras: [
              {
                  id: "1",
                  title: "Traslado",
                  description: "Descripción corta sobre el servicio",
                  price: 20.00,
              },
          ],
      },
  },
];

export const TEMP_PACKAGES: IPackage[] = [
  {   
      id: "1",
      title: "Fast Pass",
      price: 50.00,
      type: "pack fast pass",
      services: ["Vuelos Internacionales", "Migraciones Fast Track", "Traslado de Equipaje (3 max)"]
  },
  {   
      id: "2",
      title: "Experiencia VIP",
      price: 120.00,
      type: "pack vip in",
      services: [
          "Vuelos Internacionales",
          "Acceso Área VIP / Lounge",
          "Migraciones Fast Track",
          "Traslado de Equipaje (3 max)"
      ]
  },
  {   
      id: "3",
      title: "Experiencia Premium VIP In",
      price: 180.00,
      type: "pack vip in premium",
      services: [
          "Vuelos Internacionales",
          "Acceso Área VIP / Lounge",
          "Asistencia Personal",
          "Migraciones Fast Track",
          "Control de Seguridad Fast Track",
          "Traslado de Equipaje (3 max)",
          "Asistencia en Check-In"
      ]
  },
]
  