import styled from "styled-components";
import { SM } from "../../utils/constants";

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d1dfe5;
  box-sizing: border-box;
  min-width: 300px;
  padding: 15px 15px 15px 15px;
  margin-right: 20px;
  margin-bottom: 15px;

  @media (max-width: ${SM}) {
    margin-right: 3px;
  }

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    overflow-wrap: break-word;
    max-width: 20vw;
  }
`;

export const Name = styled.h4`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 5px;
`;

export const Text = styled.h6`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;

  overflow-wrap: break-word;
  max-width: 20vw;

  @media (max-width: ${SM}) {
    max-width: unset;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Code = styled.h6`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #000000;
  margin-top: 10px;

  overflow-wrap: break-word;
  max-width: 20vw;

  @media (max-width: ${SM}) {
    max-width: unset;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 35px;

  button {
    outline: 0;
    font-weight: 500;
  }

  button:first-child {
    margin-right: 32px;
  }
`;
