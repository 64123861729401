import React, { useState, useEffect } from "react";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import { AutocompleInput } from "./style";
import LupaIcon from "../../assets/icons/search-icon.svg";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

interface Props {
  title: string;
  options: IDropdownOptions[];
  selectedValue?: IDropdownOptions;
  placeholder?: string;
  onChange: (value: IDropdownOptions) => void;
  arrow?: boolean;
  dropdownType?: string;
  icon?: string;
}

const Dropdown: React.FC<Props> = ({
  title,
  options,
  selectedValue,
  placeholder,
  onChange,
  arrow,
  dropdownType,
  icon
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState(false);
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    let results = [] as any;

    results = options.filter(({ title }) => {
      if (searchTerm.length > 2) {
        return title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return title
          .toLowerCase()
          .substring(0, 2)
          .includes(searchTerm.toLowerCase().substring(0, 2));
      }
    });



    setSearchResults(results);
  }, [searchTerm]);

  return (
    <SC.Wrapper>
      <SC.DropdownToggle arrow={arrow} onClick={() => setOpen(!open)}>
        <CSC.LittleTitle>{title}</CSC.LittleTitle>
        <SC.OptionSelected>
          {selectedValue ? (
            <SC.Title
              dropdownType={dropdownType}
              icon={icon ? icon : selectedValue.icon}
            >
              {selectedValue.title}
            </SC.Title>
          ) : (
            <SC.Title>{placeholder}</SC.Title>
          )}
          {arrow === false ? <></> : <SC.DropdownArrow />}
        </SC.OptionSelected>
      </SC.DropdownToggle>

      <SC.DropdownMenu
        open={arrow === false ? false : open}
        dropdownType={dropdownType}
      >
        {dropdownType === "Airlines" && (
          <AutocompleInput>
            <img src={LupaIcon} alt="" />
            <input
              type="text"
              placeholder={t("generales.buscar_aerolinea")}
              onChange={handleChange}
            />
          </AutocompleInput>
        )}

        {dropdownType === "Airlines" ? (
          <>
            {searchResults.length === 0 && searchTerm.length > 0 && (
              <SC.NotFound>
                <span>{t("generales.contactar")}</span>
                <a href="mailto:hello@jarvisvip.com">
                  {""} hello@jarvisvip.com
                </a>
              </SC.NotFound>
            )}
            {searchTerm.length !== 0 ? (
              <>
                {searchResults
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((item, index) => {
                    return (
                      <SC.DropdownItem
                        key={index}
                        onClick={() => {
                          onChange(item);
                          setOpen(false);
                        }}
                      >
                        <SC.IconAirlinesWrap>
                          <img src={icon} alt="" />
                          <SC.TitleHide dropdownType={dropdownType} icon={""}>
                            {item.title}
                          </SC.TitleHide>
                        </SC.IconAirlinesWrap>
                      </SC.DropdownItem>
                    );
                  })}
              </>
            ) : (
              <>
                {options.length > 0 ? (
                  <>
                    {options.map((item, index) => {
                      return (
                        <SC.DropdownItem
                          key={index}
                          onClick={() => {
                            onChange(item);
                            setOpen(false);
                          }}
                        >
                          <SC.IconAirlinesWrap>
                            <img src={icon} alt="" />
                            <SC.TitleHide dropdownType={dropdownType} icon={""}>
                              {item.title}
                            </SC.TitleHide>
                          </SC.IconAirlinesWrap>
                        </SC.DropdownItem>
                      );
                    })}
                  </>
                ) : (
                  <SC.LoaderWrap>
                    <Loader
                      type="Oval"
                      color="#1212c9"
                      secondaryColor="rgba(18,18,201,.1)"
                      height={64}
                      width={64}
                      timeout={0}
                    />
                    <h6>{t("generales.cargando_aerolineas")}</h6>
                  </SC.LoaderWrap>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {options.map((item, index) => (
              <SC.DropdownItem
                key={index}
                onClick={() => {
                  onChange(item);
                  setOpen(false);
                }}
              >
                <SC.TitleHide
                  dropdownType={dropdownType}
                  icon={icon ? icon : item.icon}
                >
                  {item.title}
                </SC.TitleHide>
              </SC.DropdownItem>
            ))}
          </>
        )}
      </SC.DropdownMenu>

      <SC.Overlay active={open} onClick={() => setOpen(false)} />
    </SC.Wrapper>
  );
};

export default Dropdown;
