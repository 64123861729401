import styled from "styled-components";
import { LG } from "../../utils/constants";

export const Wrapper = styled.div`
  margin-top: 20px;
  label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    display: block;
    padding-bottom: 15px;
  }
`;

export const TextArea = styled.textarea<{ filled?: string }>`
  border-color: rgba(0, 0, 0, 0.1);
  width: 96.2%;
  resize: none;
  padding: 20px 0 0 20px;
  transition: ${props => props.theme.defaultTransition()};

  &:focus {
    outline: 0;
    border-color: #000000;
  }

  border-color: ${props =>
    props.filled !== "filled" ? "rgba(0,0,0,0.1)!important" : "#000000"};

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: ${LG}) {
    width: 93%;
  }
`;
