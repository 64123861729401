import styled from "styled-components";

export const LinkWrap = styled.div`
  a {
    text-decoration: none !important;
  }
`;

export const Cta = styled.button<{ inactive: boolean, hoverColor?: string, colorHover?:string }>`
  padding: 0 30px;
  background: ${props =>
    props.inactive ? "rgba(0, 0, 0, 0.1)" : props.theme.bg};
  border: ${props =>
    (!props.inactive && props.theme.border) || "2px solid transparent"};
  color: ${props =>
    props.inactive
      ? "rgba(0, 0, 0, 0.3)"
      : props => props.theme.color || "var(--black)"};
  height: ${props => props.theme.height || 53}px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;


  &:hover{
    background-color:${props => props.theme.hover ? props.theme.hover : props.theme.bg };
    color:${props => props.theme.colorHover ? props.theme.colorHover : props.theme.color};

 } 

  &:focus {
    outline: unset;
  }
  ${props =>
    props.inactive &&
    `
        pointer-events: none;
    `}
`; 
