import styled from "styled-components";
import arrow from "../../assets/icons/dropdown-arrow.svg";
import { LG } from '../../utils/constants';

export const Wrapper = styled.div<{ noLabel?: boolean, defaultInput: boolean, isEmpty: boolean }>`
  position: relative;
  .react-calendar {
    background: #ffffff;
    border: 1px solid rgba(209, 223, 229, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 20px 38px rgba(0, 0, 0, 0.09);

    padding-bottom: 20px;
    padding-top: 10px;

    abbr {
      text-decoration: none;
    }

    .react-calendar__month-view__weekdays__weekday {
      abbr {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .react-calendar__month-view {
    margin: 0 15px;

    border-bottom: 1px solid white;
    position: relative;

    &:after {
      content: "";
      bottom: 0;
      left: 0;

      height: 2px;
      background-color: #ffffff;
      position: absolute;
      width: 100%;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(0, 0, 0, 0.1);
  }

  .react-date-picker {
    ${props => props.defaultInput && `
      width: 100%;
    `}
    ${props => !props.noLabel && `
      position: absolute;
      top: 100%;
    `};
    &.react-date-picker--open {
      .react-date-picker__wrapper {
        outline: 0;
        border-color: #000000;
      }
    }
    .react-date-picker__calendar {
      top: ${props => !props.noLabel ? "calc(100% + 27px)" : "100%"} !important;
      bottom: unset !important;
    }
  }
  .react-calendar__tile {
    border-bottom: 1px solid rgba(209, 223, 229, 0.5);

    &:last-child {
      border-bottom: 0;
    }
  }
  .react-date-picker__inputGroup {
    padding: 0 15px !important;
    ${props => !props.defaultInput && `
      display: none;
    `}
  }

  .react-date-picker__wrapper {
    transition: all ease-out 0.3s;
    ${props => !props.defaultInput && `
      display: none;
    `}
    ${props => props.defaultInput && `
      height: 53px;
      border: 1px solid ${props.isEmpty ? "rgba(0,0,0,0.1)" : "#000000"};
    `};
    .react-date-picker__button {
      display: none;
    }
  }

  .react-calendar__tile--now {
  }

  .react-calendar__tile--active {
    background-color: ${props => props.theme.mainBlue};
    color:#ffffff!important;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    position: relative;
    right: 40px;
    top: 8px;

    &:before {
      content: "";
      background: url(${arrow}) no-repeat center center / contain;

      transform: rotate(-90deg);
      position: absolute;
      top: 14px;
      width: 14px;
      height: 15px;
      right: 17px;
    }
  }

  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    position: relative;
    left: 40px;
    top: 8px;

    &:before {
      content: "";
      background: url(${arrow}) no-repeat center center / contain;

      transform: rotate(90deg);
      position: absolute;
      top: 14px;
      width: 14px;
      height: 15px;
      right: 14px;
    }
  }

  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;

    color: #000000;
  }

  .react-calendar__navigation__label {
    margin-top: 15px;
  }

  .react-calendar__tile--now:not(.react-calendar__tile--active){
    background: #2073f478 !important;
    color: var(--white);
  }

  .react-date-picker__inputGroup__input{
    margin:0 2px 0 0;
    padding:0;
  }
`;

export const Tooltip = styled.div<{ showTool: boolean, showAlert: boolean } >
  `
  background-color: #000000;
  color: #ffffff;

  padding: 20px;

  position: relative;
  top: 20px;
  width:130%;



  opacity: ${(props) => (props.showTool ? 1 : 0)};
  visibility: ${(props) => (props.showTool ? 'visible' : 'hidden')};

  transition: ${(props) => props.theme.defaultTransition()};

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    opacity: 1 !important;
  }

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    opacity: 1 !important;
    color:#ffffff;
  }

  &:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    top: -7px;
    left: 30px;
    background-color: #000000;
    transform: rotate(45deg);
  }

  em {
    width: 65px;
    height: 5px;
    background-color: #fd0000;
    display: block;
    position: relative;
    top: 20px;
    right: 20px;
  }

  @media (max-width: ${LG}) {
    opacity: ${(props) => (props.showAlert ? 1 : 0)};
    visibility: ${(props) => (props.showAlert ? 'visible' : 'hidden')};
    display: ${(props) => (props.showAlert ? 'block' : 'none')};

    padding: 20px !important;
    width: 75vw !important;

    &:after {
      left: 114px;
    }
  }
`;

export const DatePickerLabel = styled.div`
  padding-left: 24px;
  padding-right: 32px;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  opacity: 1;
  position: initial;
  width: auto;
  top: 0;
  > h3 {
    color: var(--black);
    opacity: 0.5;
    margin-bottom: 0px;
    position: relative;
  }
`;

export const Arrow = styled.div`
  width: 12px;
  height: 20px;
  margin-left: 15px;
  background: url(${arrow}) center / contain no-repeat;
`;

export const Title = styled.h5`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  display: inline-block;
  align-items: center;
`;

export const LabelContainer = styled.span`
  display: flex;
`;


export const DateContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 180px;

  > * {
    margin-right: 8px;
  }
`