import styled from "styled-components";
import arrow from "../../assets/icons/dropdown-arrow.svg";
import { SM } from "../../utils/constants";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const DropdownToggle = styled.div<{ arrow?: boolean }>`
  padding-left: 24px;
  padding-right: 32px;
  height: 100%;
  cursor: ${props => (props.arrow === false ? "auto" : "pointer")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  > h3 {
    color: var(--black);
    opacity: 0.5;
    margin-bottom: 0px;
  }
`;

export const DropdownMenu = styled.ul<{
  open: boolean;
  dropdownType?: string;
}>`
  height: ${props => (props.dropdownType === "Airlines" ? "250px" : "auto")};
  overflow-y: scroll;

  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: var(--white);
  border: 1px solid rgba(209, 223, 229, 0.5);
  box-shadow: var(--shadow);
  width: 330px;
  padding: 13px 8px;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: all 0.2s linear;
  opacity: ${props => (props.open ? "1" : "0")};
  pointer-events: ${props => (props.open ? "auto" : "none")};

  @media (max-width: ${SM}) {
    width: auto;
    right: 0;
  }

  li {
    &.hoverable:hover {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
    &.key-hover {
      cursor: pointer;
      background: #1212c91c;
      color: white;
    }
  }
`;

export const DropdownItem = styled.li`
  /*   height: 47px; */
  width: 100%;
  background: transparent;
  padding: 0 8px;
  border: 0;
  transition: all 0.2s linear;
  cursor: pointer;
  box-sizing: border-box;

  padding-bottom: 7px;
  &:not(:last-child) {
    margin-bottom: 9px;
  }
  &:hover {
    background: rgba(209, 223, 229, 0.2);
  }
`;

export const IconAirlinesWrap = styled.div`
  display: grid;
  grid-template-columns: 44px 1fr;
  img {
    width: 28px;
  }
`;

export const LoaderWrap = styled.div`
  text-align: center;
  padding-top: 40px;

  h6 {
    font-weight: 300;
    margin-top: 15px;
  }
`;

export const Overlay = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: ${props => (props.active ? "block" : "none")};
`;

export const Title = styled.h5<{ icon?: string; dropdownType?: string }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  display: inline-flex;
  align-items: center;

  ${props =>
    props.icon &&
    `
        &::before {
            content: "";
            background: url(${props.icon}) center / contain no-repeat;
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
    `};

  ${props =>
    props.icon &&
    `   &::before {
        content: "";
        width: ${props.dropdownType === "Airlines" ? "26px" : "40px"};
        height: ${props.dropdownType === "Airlines" ? "26px" : "40px"};
        margin-right: 16px;
        margin-left: ${
          props.dropdownType === "Airlines" ? "0 !important" : "inherit"
        };
      }
    `};
`;

export const TitleHide = styled.h6<{ icon?: string; dropdownType?: string }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
  display: inline-flex;
  align-items: center;
  ${props =>
    props.icon &&
    `
        &::before {
            content: "";
            background: url(${props.icon}) center / contain no-repeat;
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
    `};
  ${props =>
    props.icon &&
    `   &::before {
        content: "";
        width: ${props.dropdownType === "Airlines" ? "26px" : "40px"};
        height: ${props.dropdownType === "Airlines" ? "26px" : "40px"};
        margin-right: 16px;
        margin-left: ${
          props.dropdownType === "Airlines" ? "0 !important" : "inherit"
        };
      }
    `};
`;

export const DropdownArrow = styled.span`
  width: 12px;
  height: 20px;
  margin-left: 15px;
  background: url(${arrow}) center / contain no-repeat;
`;

export const OptionSelected = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  ${Title} {
    &::before {
      margin-right: 8px;
    }
  }
`;

export const AutocompleInput = styled.div`
  width: 90%;
  border: 1px solid red;
  display: flex;
  align-items: center;
  margin: 0 10px 10px 10px;
  padding: 10px;

  border: 1px solid var(--secondary-gray-light);

  input {
    width: 100%;

    border: 0;
  }

  img {
    height: 15px;
    padding-right: 5px;
  }
`;

export const NotFound = styled.div`
  padding: 15px 20px 10px;

  span{
    line-height:23px;
    font-size:16px;
    display:inline-block;
  }

  a {
    display: block;
    font-size:16px;
    margin-top:5px;
    font-weight: 500;
    color: #000000;
  }
`;
