import { useLayoutEffect, useState } from "react";



export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const ShowWindowDimensions = props => {
  const [width, height] = useWindowSize();

  return width;
};

export const LargerScreens = 1441;
export const LG = 1199;
export const SM = 992;
export const XS = 767;
export const CustomDesktop = 1366;
