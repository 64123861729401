import styled from "styled-components";
import BgImage from "../../../assets/images/img-agencia.svg";

import { SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

export const ProfilePic = styled.div`
  width: 68px;
  height: 68px;
  background: url(${BgImage}) no-repeat center center / cover;
  margin-right: 25px;
  margin-top: -3px;
`;

export const Headline = styled.div``;

export const Lista = styled.ul`
  li {
    display: inline-block;
    text-align: left;
    margin-right: 45px;
    margin-top: 40px;
    h5 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;

      letter-spacing: 0.15em;
      text-transform: uppercase;
      opacity: 0.3;
      margin-bottom: 3px;
    }

    h6,
    a {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-decoration: none;
      color: #000000;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    &:first-child {
      display: flex;
      align-items: center;
    }
  }
`;

export const Titles = styled.div`
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 133.33%;
    margin-bottom: 2.5px;
  }
`;

export const CUIT = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
`;

export const Address = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
  display: inline-block;
  margin-left: 8px;

  &:before {
    content: "";
    width: 3px;
    height: 3px;
    background-color: black;
    display: inline-block;
    opacity: 0.8;
    position: relative;
    top: -4px;
    margin-right: 8px;
  }
`;

export const InputsModal = styled.div`
  padding: 30px;

  > h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
    margin-bottom: 20px;
  }

  @media (max-width: ${SM}) {
    h4 {
      text-align: center;
      position: relative;
      top: -1px;
    }
  }
`;

export const InputWrap = styled.div`
  input {
    margin-bottom: 0;
    box-sizing: border-box;
  }
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 25px;
`;

export const GridButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;

    button {
      display: flex;
      justify-content: center;
    }
  }
`;

export const SelectWrap = styled.div`
  > div {
    width: 100%;
  }
`;

export const FormlogIn = styled.div`
  padding-top: 35px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px 45px;

  @media (min-width: ${SM}) {
    grid-template-columns: 1fr 1fr;
    max-width: 638px;
  }
`;

export const PhotoHolder = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border: 1px solid #00000029;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;

  input {
    position: absolute;
    width: 110px;
    left: -102px;
    cursor: pointer;
    opacity: 0;
    background: red;
    top: -40px;
    height: 100px;
  }

  @media (max-width: ${SM}) {
    margin: 0 auto;
    input {
      left: -50px !important;
      top: -96px !important;
    }
  }
`;

export const PhotoShow = styled.div`
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;

  display: flex;
  width: 88px;
  height: 88px;
  border: 1px solid #00000029;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;

  @media (max-width: ${SM}) {
    margin: 0 auto;
  }
`;

export const UploadPhoto = styled.div`
  margin-right: 30px;
  position: relative;
  top: 5px;
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 15px;
  }

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    cursor: pointer;

    position: relative;

    input {
      position: absolute;
      width: 100px;
      left: -94px;
      cursor: pointer;
      opacity: 0;
      height: 100px;
      top: -56px;

      border: 1px solid red;
    }
  }

  @media (max-width: ${SM}) {
    margin-right: 0;

    input {
      left: -50px !important;
      top: -96px !important;
    }
  }
`;

export const MobileWrap = styled.div`
  text-align: center;
  button:last-child {
    margin: 0 auto;
  }

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
  margin-bottom: 35px;
`;

export const FormDisplayMobile = styled.div`
margin-top: 30px;

  > div:first-child {
    margin-bottom: 15px;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;

    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    opacity: 0.8;
    padding-top: 2px;
  }
`;

export const VerMas = styled.button<{ showList?: boolean }>`
  border: 0;
  background-color: transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  text-align: center;
  text-decoration-line: underline;

  color: #000000;

  margin-top: ${props => (props.showList ? "15px" : "0")};
  margin-bottom: 30px;

  transition: ${props => props.theme.defaultTransition()};
`;

export const ListaForm = styled.ul<{ height?: number; showList?: boolean }>`
  height: ${props => (props.showList ? props.height + "px" : 0)};
  overflow: hidden;
  transition: ${props => props.theme.defaultTransition()};

  li {
    margin-bottom: 10px;
  }

  h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.3;
  }

  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    text-align: center;
    margin-top: 7px;

    a {
      color: #000000;
      text-decoration: none;
    }
  }
`;

export const ImageErrorMsg = styled.span<{ show?: boolean }>`
  padding-top: 10px;
  display: inline-block;
  font-size: 14px;
  transition: all ease-in-out 0.3s;
  opacity: ${props => (props.show ? 1 : 0)};

  @media(max-width:${SM}){
    padding-top: 15px;
    opacity:1;
    max-width: 250px;
    line-height: 20px;
    display:${props => props.show ? 'block' : 'none'};
    margin:0 auto;
  }
`;
