import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Cta from "../Cta";
import Picture from "../../assets/images/home-images/home-nosotros.jpg";
import NosIcon from "../../assets/images/home-images/nosotros-icon.svg";

import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../utils/AOSconfig";

interface Props {}

const HomepageAbout: React.FC<Props> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <SC.Wrapper>
      <SC.Headline>
        <div data-aos="fade-up">
          <CSC.LittleTitle>{t('homepage.nosotros.volanta')}</CSC.LittleTitle>
        </div>

        <div data-aos="fade-up" data-aos-delay="100">
          <CSC.Title>{t('homepage.nosotros.titulo')}</CSC.Title>
        </div>
        <div data-aos="fade-up" data-aos-delay="200">
          <SC.Paragraph>{t('homepage.nosotros.texto')}</SC.Paragraph>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <Cta
            onClick={() => {}}
            isRoute={true}
            toRoute={"/servicios"}
            theme="lightTheme"
          >
            {t('homepage.nosotros.boton')}
          </Cta>
        </div>
      </SC.Headline>
      <SC.PicWrapper>
        <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1200">
          <SC.Pic bg={Picture}>
            <SC.Icon src={NosIcon} alt={"icono vip"}></SC.Icon>
          </SC.Pic>
        </div>
      </SC.PicWrapper>
    </SC.Wrapper>
  );
};

export default HomepageAbout;
