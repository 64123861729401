import styled from "styled-components";

import { LG, LargerScreens } from "../../../utils/constants";

export const Wrapper = styled.div`
  padding: 64px 120px 128px;

  @media (max-width: ${LG}) {
    padding: 64px 20px 40px;
  }
`;

export const ReservationSection = styled.div`
  display: grid;
  grid-template-columns: 716px 1fr;
  grid-template-rows: 202px 1fr;
  margin-top: 64px;
  column-gap: 56px;

@media(min-width:${LargerScreens}){
  max-width:1377px;
  margin:60px auto 0;
  grid-template-columns: 1fr 0.6fr;
}

  > div {
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`;
