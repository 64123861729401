import React, { Suspense, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  Tabs,
  TabsButtons,
  TabsContent,
  ButtonLi,
  Content,
  GraficoWrap
} from "./style";

import Porcentaje from "../../../components/Porcentaje";
import { tabsData } from "./balanceData";
import IconPercentage from "../../../assets/icons/departure-icon.svg";
import moment from "moment";

interface Props {
  bookings: any[];
}

const Balance: FC<Props> = ({ bookings }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(0);
  const [percentageProps, setPercentage] = useState(0);
  const [pasajeros, setPasajeros] = useState<any[]>([]);
  const [servicios, setServicios] = useState<any[]>([]);
  const [aeropuertos, setAeropuertos] = useState<any[]>([[]]);

  useEffect(() => {
    setPasajeros([
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(3, "month"))
          )
          .reduce((a, { passengers }) => a + passengers.length, 0)
      },
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(6, "month"))
          )
          .reduce((a, { passengers }) => a + passengers.length, 0)
      },
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(12, "month"))
          )
          .reduce((a, { passengers }) => a + passengers.length, 0)
      }
    ]);
    setServicios([
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(3, "month"))
          ).length
      },
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(6, "month"))
          ).length
      },
      {
        valor: bookings
          .filter(({ status }: { status: string }) => status !== 'CANCELADA')
          .filter(({ date }) =>
            moment.utc(date).isAfter(moment.utc().subtract(12, "month"))
          ).length
      }
    ]);

    const distAirports = [
      ...new Set(
        bookings.map(
          ({ airport: { airportCode, airportName } }) =>
            `(${airportCode}) ${airportName}`
        )
      )
    ];
    setAeropuertos([
      distAirports
        .map(nombre => ({
          nombre,
          valor: bookings
            .filter(
              ({ date, airport: { airportCode, airportName } }) =>
                `(${airportCode}) ${airportName}` &&
                moment.utc(date).isAfter(moment.utc().subtract(3, "month"))
            )
            .reduce(a => a + 1, 0)
        }))
        .filter(({ valor }) => valor)
        .slice(0, 3),
      distAirports
        .map(nombre => ({
          nombre,
          valor: bookings
            .filter(
              ({ date, airport: { airportCode, airportName } }) =>
                `(${airportCode}) ${airportName}` &&
                moment.utc(date).isAfter(moment.utc().subtract(6, "month"))
            )
            .reduce(a => a + 1, 0)
        }))
        .filter(({ valor }) => valor)
        .slice(0, 3),
      distAirports
        .map(nombre => ({
          nombre,
          valor: bookings
            .filter(
              ({ date, airport: { airportCode, airportName } }) =>
                `(${airportCode}) ${airportName}` &&
                moment.utc(date).isAfter(moment.utc().subtract(12, "month"))
            )
            .reduce(a => a + 1, 0)
        }))
        .filter(({ valor }) => valor)
        .slice(0, 3)
    ]);
    setPercentage(
      (100 *
        bookings.filter(({ bookingType }) => bookingType === "Partida")
          .length) /
        bookings.length
    );
  }, [bookings]);

  const partidaArray = bookings.filter(
    booking => booking.bookingType === "Partida"
  );

  const finalPercentage = (partidaArray.length * 100) / bookings.length;

  const parsePercentage = Math.round(Number(finalPercentage));

  const [percentageHook, setPercentageHook] = useState(0);

  useEffect(() => {
    setPercentageHook(parsePercentage);
  }, [percentageHook, parsePercentage]);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <span>{t("pantalla_agencia.balance.titulo")}</span>
        <Tabs>
          {tabsData && (
            <TabsButtons>
              {tabsData.map((data: any, i: any | boolean | undefined) => {
                return (
                  <ButtonLi
                    key={i}
                    activeLi={i}
                    index={active}
                    onClick={() => setActive(i)}
                  >
                    {t("pantalla_agencia.balance." + data.tab)}
                  </ButtonLi>
                );
              })}
            </TabsButtons>
          )}

          <TabsContent>
            {pasajeros.map((pasajero, j) => {
              if (active === j) {
                return (
                  <Content key={j}>
                    <h6>{t("pantalla_agencia.balance.pasajeros")}</h6>
                    <h3>{pasajero.valor}</h3>
                  </Content>
                );
              }
            })}
            {servicios.map((servicio, k) => {
              if (active === k) {
                return (
                  <Content key={k}>
                    <h6>
                      {t("pantalla_agencia.balance.servicios_contratados")}
                    </h6>
                    <h3>{servicio.valor}</h3>
                  </Content>
                );
              }
            })}
            {aeropuertos && (
              <Content>
                <h6>{t("pantalla_agencia.balance.top_aeropuertos")}</h6>
                {aeropuertos[active].map((lugar: any, n: any) => {
                  return (
                    <li key={n}>
                      <span>{lugar.nombre}</span>
                      <em>{lugar.valor}</em>
                    </li>
                  );
                })}
              </Content>
            )}
          </TabsContent>
        </Tabs>
        <GraficoWrap>
          {Number.isFinite(parsePercentage) &&
            parsePercentage > 0 &&
            percentageHook !== NaN &&
            percentageHook > 0 && (
              <Porcentaje
                icon={IconPercentage}
                percentageProps={percentageHook}
              ></Porcentaje>
            )}
        </GraficoWrap>
      </Wrapper>
    </Suspense>
  );
};

export default Balance;
