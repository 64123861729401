import React from "react";
import { useTranslation } from "react-i18next";

import { Wrapper } from "./style";

interface Props {}

const ServiciosDisponibles: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h3>{t("resultados.productos.sin_servicio_titulo")}</h3>
      <p>
        {t("resultados.productos.sin_servicio_subtitulo")}

       
      </p>
 
    </Wrapper>
  );
};

export default ServiciosDisponibles;
