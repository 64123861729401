import React, { Suspense, FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Wrapper,
  Headline,
  ReservaTabla,
  DetalleGrid,
  InfoPasajerosWrap,
  Head,
  Body,
  Label,
  TitleDetalle,
  MainGrid,
  PasajerosWrap,
  PasajerosGrid,
  InfoAdicionalWrap,
  InfoBox,
  InfoTitle,
  Subtitle,
  InputsModal,
  InputWrap,
  InputGrid,
  GridButtons,
  DatosContactoWrap,
  DatosGrid,
  EditButtons,
  ComentariosWrap,
  LinkWrapMobile,
  TablaCardMobile,
  ListMobile,
  NoUnderline,
  Package,
  PriceWrap,
  PacksWrap,
  PackItem,
  ServicesWrap,
  ServiceItem,
  ServPrice,
  Procedimiento
} from "./style";
import Cta from "../../../../components/Cta";
import * as SC from "./style";
import * as CSC from "../../../../utils/commonStyle";
import ServicePackage from "../../../../components/ServicePackage";
import CardPasajerosSmall from "../../../../components/CardPasajerosSmall";
import Modal from "../../../../components/Modal";
import moment from "moment";
import "moment-timezone";

import DownloadIcon from "../../../../assets/icons/download.svg";
import Dropdown from "../../../../components/Dropdown";

import RegularInput from "../../../../components/RegularInput";
import RegularTextArea from "../../../../components/RegularTextArea";

import { TEMP_DATA, TEMP_PACKAGES } from "./TEMP_DATA";
import { getBookings } from "../../../../actions/users/creators";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../../utils/mobileQueries";

interface Props {}

const AgenciaDetalle: FC<Props> = () => {
  const { t } = useTranslation();
  let id = useParams<{ id: string }>();
  const numeroDeReserva = id.id;



  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const _bookings = await getBookings(localStorage.getItem("userId") || "");
      setBookings(_bookings);
    };

    fetchData();
  }, []);

  const selectedBooking: any = bookings.filter(
    (booking: { id: string }) => booking.id === numeroDeReserva
  );



  useWindowSize();

  const getNumbersList = () => {
    let list = Array.from(Array(10).keys());
    let newOptions = list.map(item => ({
      title: item.toString(),
      value: item.toString()
    }));
    return newOptions;
  };

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [infoAdicional, setInfoAdicional] = useState<any>({
    nombre: "",
    tel: "",
    comentario: ""
  });

  const [formData, setFormData] = useState({
    nombre: "",
    tel: "",
    comentario: ""
  });

  const [dataToShow, setDataToShow] = useState({
    nombre: "",
    tel: "",
    comentario: ""
  });

  const [contactoExist, setContactoExist] = useState(false);

  const handleInputChange = (
    event: { target: any },
    index: any,
    formType: any
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
  }, [openModal]);

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  //IBIS borrar esto para mapear los datos reales
  const packItems2 = [
    {
      deleted: false,
      emailProvider: "undefined",
      serviceCurrency: "USD",
      serviceDescription: "Maletero personal hasta 3 bultos",
      serviceDescriptionEN: "Personal baggage porter up to 3 suitcases",
      serviceName: "Servicio de maletero (hasta 3 bultos)",
      serviceNameEN: "Baggage porter (up to 3 suitcases)",
      servicePrice: 20,
      type: "no multiplica",
      visible: true
    },
    {
      deleted: false,
      emailProvider: "undefined",
      serviceCurrency: "USD",
      serviceDescription: "Espacio VIP con servicio de bar y wifi",
      serviceDescriptionEN: "VIP area with free wi-fi and bar services",
      serviceName: "Acceso sala VIP - Aeropuertos Vipclub ",
      serviceNameEN: "Aeropuertos Vipclub - VIP Lounge access",
      servicePrice: 60,
      type: "por pasajeros",
      visible: true
    },
    {
      deleted: false,
      emailProvider: "undefined",
      serviceCurrency: "USD",
      serviceDescription:
        "Se despide al pasajero luego del encuentro con su contacto",
      serviceDescriptionEN:
        "Escort to designated meeting point with your contact",
      serviceName: "Encuentro con guía, chofer o medio de transporte",
      serviceNameEN: "Meet with guide, driver or chosen transportation ",
      servicePrice: 0,
      type: "no multiplica",
      visible: true
    }
  ];

  const services2 = [
    {
      deleted: false,
      emailProvider: "undefined",
      selected: true,
      serviceCurrency: "USD",
      serviceDescription:
        "Equipaje envuelto en film con cobertura de seguro en caso de daño, robo o pérdida total del equipaje (1 bulto)",
      serviceDescriptionEN:
        "Luggage wrapped in film with insurance coverage in the event of damage, theft or total loss of baggage (1 suitcase)",
      serviceName: "Embalaje y protección de equipaje",
      serviceNameEN: "Luggage wraping and protection service",
      servicePrice: 12,
      type: "por maletas",
      visible: true
    },
    {
      deleted: false,
      emailProvider: "undefined",
      selected: true,
      serviceCurrency: "USD",
      serviceDescription: "Maletero personal hasta 3 bultos",
      serviceDescriptionEN: "Personal baggage porter up to 3 suitcases",
      serviceName: "Servicio de maletero adicional (hasta 3 bultos)",
      serviceNameEN: "Additional baggage porter (up to 3 suitcases)",
      servicePrice: 20,
      type: "no multiplica",
      visible: true
    }
  ];

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <Headline>
          <NoUnderline>
            <Cta
              onClick={() => {}}
              isRoute={true}
              toRoute={"/agencia"}
              theme="lightTheme"
            >
              {t("pantalla_agencia.mis_trasacciones.volver_al_perfil")}
            </Cta>{" "}
          </NoUnderline>
          {ShowWindowDimensions() >= SM && (
            <a href="#" target="_blank">
              <img src={DownloadIcon} alt="" />
              {t("pantalla_agencia.mis_trasacciones.exportar_transaccion")}
            </a>
          )}
        </Headline>

        {ShowWindowDimensions() >= SM ? (
          <ReservaTabla>
            <DetalleGrid>
              <Head>
                <h5>
                  {t("pantalla_agencia.mis_trasacciones.numero_de_reserva")}
                </h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.fecha_de_vuelo")}</h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.hora")}</h5>
                <h5>
                  {t("pantalla_agencia.mis_trasacciones.numero_de_vuelo")}
                </h5>
                <h5>
                  {t("pantalla_agencia.mis_trasacciones.tipo_de_reserva")}
                </h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.Aeropuerto")}</h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.Pasajero")}</h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.Pasajeros")}</h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.Pack")}</h5>
                <h5>{t("pantalla_agencia.mis_trasacciones.Estado")}</h5>
              </Head>
              <Body>
                <h4>{selectedBooking[0] && selectedBooking[0].id}</h4>
                <h4>
                  {selectedBooking[0] &&
                    moment(selectedBooking[0].date).format("DD/MM/YYYY")}
                </h4>
                <h4>
                  {selectedBooking[0] &&
                    moment.utc(selectedBooking[0].date).format("hh:mm A")}
                </h4>
                <h4>
                  {selectedBooking[0] &&
                    selectedBooking[0].flightNumber.toUpperCase()}
                </h4>
                <h4>{selectedBooking[0] && selectedBooking[0].bookingType}</h4>
                <h4>
                  {selectedBooking[0] && selectedBooking[0].airport.airportCode}
                </h4>
                <h4>
                  {selectedBooking[0] && selectedBooking[0].passengers[0].name}
                </h4>
                <h4>
                  {" "}
                  {selectedBooking[0] && selectedBooking[0].passengers.length}
                </h4>
                <h4>
                  {selectedBooking[0] && selectedBooking[0].pack.packName}
                </h4>
                <Label
                  status={
                    selectedBooking[0] &&
                    selectedBooking[0].status.toLowerCase()
                  }
                  bg={
                    selectedBooking[0] &&
                    selectedBooking[0].status.toLowerCase()
                  }
                >
                  {selectedBooking[0] && selectedBooking[0].status}
                </Label>
              </Body>
            </DetalleGrid>
          </ReservaTabla>
        ) : (
          <LinkWrapMobile>
            <TablaCardMobile>
              <ListMobile>
                <span>
                  {t("pantalla_agencia.mis_trasacciones.numero_de_reserva")}
                </span>
                <h5>{selectedBooking[0] && selectedBooking[0].id}</h5>
              </ListMobile>
              <ListMobile>
                <span>
                  {t("pantalla_agencia.mis_trasacciones.fecha_de_vuelo")}
                </span>
                <h5>
                  {" "}
                  {selectedBooking[0] &&
                    moment(selectedBooking[0].date).format("DD/MM/YYYY")}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.hora")}</span>
                <h5>
                  {" "}
                  {selectedBooking[0] &&
                    moment.utc(selectedBooking[0].date).format("hh:mm A")}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>
                  {t("pantalla_agencia.mis_trasacciones.numero_de_vuelo")}
                </span>
                <h5>
                  {selectedBooking[0] &&
                    selectedBooking[0].flightNumber.toUpperCase()}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>
                  {t("pantalla_agencia.mis_trasacciones.tipo_de_reserva")}
                </span>
                <h5>{selectedBooking[0] && selectedBooking[0].bookingType}</h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.Aeropuerto")}</span>
                <h5>
                  {" "}
                  {selectedBooking[0] && selectedBooking[0].airport.airportCode}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.Pasajero")}</span>
                <h5>
                  {selectedBooking[0] && selectedBooking[0].passengers.length}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.Pasajeros")}</span>
                <h5>
                  {selectedBooking[0] && selectedBooking[0].passengers.length}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.Pack")}</span>
                <h5>
                  {selectedBooking[0] && selectedBooking[0].pack.packName}
                </h5>
              </ListMobile>
              <ListMobile>
                <span>{t("pantalla_agencia.mis_trasacciones.Estado")}</span>
                <Label
                  bg={
                    selectedBooking[0] &&
                    selectedBooking[0].status.toLowerCase()
                  }
                >
                  {selectedBooking[0] && selectedBooking[0].status}
                </Label>
              </ListMobile>
            </TablaCardMobile>
          </LinkWrapMobile>
        )}

        {ShowWindowDimensions() < SM && (
          <Headline mobile={true}>
            <a href="#" target="_blank">
              <img src={DownloadIcon} alt="" />
              {t("pantalla_agencia.mis_trasacciones.exportar_transaccion")}
            </a>
          </Headline>
        )}



        <MainGrid>
          <SC.ReservationsContainer>
            <TitleDetalle>
              {t("pantalla_agencia.mis_trasacciones.detalle_de_reserva")}
            </TitleDetalle>
            {selectedBooking.length > 0 &&
              selectedBooking.map((item: any, index: number) => (
                <SC.ReservationsItem key={index}>
                  <SC.TitleContainer>
                    <SC.ReservationIcon type={item.bookingType!} />
                    <div>
                      <CSC.MediumParagraph>
                        {moment(item.date).format("DD/MM/YYYY")}{" "}
                        {moment.utc(item.date).format("hh:mm A")}
                      </CSC.MediumParagraph>
                      <CSC.MediumTitle>
                        {item.airport.airportCode +
                          ", " +
                          item.airport.airportName}
                      </CSC.MediumTitle>
                      <CSC.MediumParagraph>{""}</CSC.MediumParagraph>
                    </div>
                  </SC.TitleContainer>
                  <SC.DropdownsContainer>
                    <SC.DropWrap>
                      <CSC.LittleTitle>
                        {t("resultados.caracteristicas.adultos")}
                      </CSC.LittleTitle>
                      <h5>{item.adultsCount}</h5>
                    </SC.DropWrap>
                    <SC.DropWrap>
                      <CSC.LittleTitle>
                        {t("resultados.caracteristicas.ninos") + " (6-12)"}
                      </CSC.LittleTitle>
                      <h5>{item.childsCount}</h5>
                    </SC.DropWrap>
                    <SC.DropWrap>
                      <CSC.LittleTitle>
                        {t("resultados.caracteristicas.infantes") + " (1-5)"}
                      </CSC.LittleTitle>
                      <h5>{item.infantsCount}</h5>
                    </SC.DropWrap>
                    <SC.DropWrap>
                      <CSC.LittleTitle>
                        {t("resultados.caracteristicas.Equipaje")}
                      </CSC.LittleTitle>
                      <h5>{item.bagsCount}</h5>
                    </SC.DropWrap>
                  </SC.DropdownsContainer>
                  <Package>
                    <div className="flex">
                      <h3>{item.pack.packName}</h3>
                      <PriceWrap>
                        <span>
                          {item.pack.packCurrency} {item.totalToPay}
                        </span>
                      </PriceWrap>
                      <PacksWrap>
                        {selectedBooking[0].pack.packItems.map((item: any, i: any) => {
                          return (
                            <PackItem key={i}>
                              {lang === "ES" ? (
                                <p>{item.serviceDescription}</p>
                              ) : (
                                <p>{item.serviceDescriptionEN}</p>
                              )}
                            </PackItem>
                          );
                        })}
                      </PacksWrap>
                    </div>
                    <ServicesWrap>
                      {selectedBooking[0].services.map((service: any, i: any) => {

                        return (
                          <ServiceItem key={i}>
                            {lang === "ES" ? (
                              <div>
                                <h5>{service.serviceName}</h5>
                                <p>{service.serviceDescription}</p>
                              </div>
                            ) : (
                              <div>
                                <h5>{service.serviceNameEN}</h5>
                                <p>{service.serviceDescriptionEN}</p>
                              </div>
                            )}
                            <ServPrice>USD {service.servicePrice}</ServPrice>
                          </ServiceItem>
                        );
                      })}
                    </ServicesWrap>

                    <Procedimiento>
                      {lang === "ES" ? (
                        <p>{item.pack.process}</p>
                      ) : (
                        <p>{item.pack.processEN}</p>
                      )}
                    </Procedimiento>
                  </Package>
                  {/*  <ServicePackage
                    pack={item.pack}
                    service={item.pack.services}
                    key={item.package && item.package.id}
                    inlineText
                    plain={true}
                    borderBottom={true}
                  /> */}
                </SC.ReservationsItem>
              ))}
          </SC.ReservationsContainer>

          {/*    {ShowWindowDimensions() > SM && (
            <InfoAdicionalWrap>
              {!contactoExist ? (
                <>
                  <h4>
                    {t(
                      "pantalla_agencia.mis_trasacciones.informacion_adicional"
                    )}
                  </h4>
                  <InfoBox>
                    <InfoTitle>
                      {t(
                        "pantalla_agencia.mis_trasacciones.agregar_datos_de_contacto"
                      )}
                    </InfoTitle>
                    <Subtitle>
                      {t(
                        "pantalla_agencia.mis_trasacciones.servicio_acompaniamiento"
                      )}
                    </Subtitle>
                    <Cta
                      onClick={() => setOpenModal(true)}
                      theme="blueDarkTheme"
                    >
                      {t("pantalla_agencia.mis_trasacciones.agregar_contacto")}
                    </Cta>{" "}
                  </InfoBox>
                </>
              ) : (
                <>
                  <h4>
                    {t(
                      "pantalla_agencia.mis_trasacciones.informacion_adicional"
                    )}
                  </h4>
                  <InfoBox>
                    <DatosContactoWrap>
                      <h3>
                        {t(
                          "pantalla_agencia.mis_trasacciones.datos_de_contacto"
                        )}
                      </h3>
                      <DatosGrid>
                        <li>
                          <h6>
                            {t(
                              "pantalla_agencia.mis_trasacciones.nombre_label"
                            )}
                            :
                          </h6>
                          <span>{dataToShow.nombre}</span>
                        </li>
                        <li>
                          <h6>{t("checkout.formulario.telefono")}:</h6>
                          <span>{dataToShow.tel}</span>
                        </li>
                      </DatosGrid>
                      {dataToShow.comentario !== "" && (
                        <ComentariosWrap>
                          <h6>
                            {t(
                              "pantalla_agencia.mis_trasacciones.comentario_adicional"
                            )}
                            :
                          </h6>
                          <p>{dataToShow.comentario}</p>
                        </ComentariosWrap>
                      )}
                    </DatosContactoWrap>
                  </InfoBox>
                  <EditButtons>
                    <button
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      {t("checkout.formulario.editar_informacion")}
                    </button>
                    <button
                      onClick={() => {
                        setOpenModalDelete(true);
                        setFormData({
                          nombre: "",
                          tel: "",
                          comentario: ""
                        });
                      }}
                    >
                      {t("mis_reservas.eliminar")}
                    </button>
                  </EditButtons>
                </>
              )}
            </InfoAdicionalWrap>
          )} */}
        </MainGrid>
        <PasajerosWrap>
          <h3>{t("pantalla_agencia.mis_trasacciones.informacion_pasajero")}</h3>

          <PasajerosGrid>
            {selectedBooking.map((booking: any, i: number) => {
              return booking.passengers.map((pasajero: any, j: number) => {
                return (
                  <CardPasajerosSmall
                    key={j}
                    pasajero={pasajero}
                    parsedDate={true}
                  />
                );
              });
            })}
          </PasajerosGrid>
        </PasajerosWrap>

        {ShowWindowDimensions() < SM && (
          <InfoAdicionalWrap contactoExist={contactoExist}>
            {!contactoExist ? (
              <>
                <InfoBox>
                  <InfoTitle>
                    {t(
                      "pantalla_agencia.mis_trasacciones.agregar_datos_de_contacto"
                    )}
                  </InfoTitle>
                  <Subtitle>
                    {t(
                      "pantalla_agencia.mis_trasacciones.servicio_acompaniamiento"
                    )}
                  </Subtitle>
                  <Cta onClick={() => setOpenModal(true)} theme="blueDarkTheme">
                    {t("pantalla_agencia.mis_trasacciones.agregar_contacto")}
                  </Cta>{" "}
                </InfoBox>
              </>
            ) : (
              <>
                <InfoBox>
                  <DatosContactoWrap>
                    <h3>
                      {t("pantalla_agencia.mis_trasacciones.datos_de_contacto")}
                    </h3>
                    <DatosGrid>
                      <li>
                        <h6>
                          {t("pantalla_agencia.mis_trasacciones.nombre_label")}:
                        </h6>
                        <span>{dataToShow.nombre}</span>
                      </li>
                      <li>
                        <h6>{t("checkout.formulario.telefono")}:</h6>
                        <span>{dataToShow.tel}</span>
                      </li>
                    </DatosGrid>
                    {dataToShow.comentario !== "" && (
                      <ComentariosWrap>
                        <h6>
                          {t(
                            "pantalla_agencia.mis_trasacciones.comentario_adicional"
                          )}
                          :
                        </h6>

                        <p>{dataToShow.comentario}</p>
                      </ComentariosWrap>
                    )}
                  </DatosContactoWrap>
                </InfoBox>
                <EditButtons>
                  <button
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    {t("checkout.formulario.editar_informacion")}
                  </button>
                  <button
                    onClick={() => {
                      setOpenModalDelete(true);
                      setFormData({
                        nombre: "",
                        tel: "",
                        comentario: ""
                      });
                    }}
                  >
                    {t("mis_reservas.eliminar")}
                  </button>
                </EditButtons>
              </>
            )}
          </InfoAdicionalWrap>
        )}

        <Modal
          isOpen={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
          maxWidth={"700px"}
        >
          <InputsModal>
            <h4>{t("pantalla_agencia.mis_trasacciones.datos_de_contacto")}</h4>
            <InputGrid>
              <InputWrap>
                <RegularInput
                  label={
                    t("pantalla_agencia.mis_trasacciones.nombre_label") + " *"
                  }
                  type={"text"}
                  placeholder={t(
                    "pantalla_agencia.mis_trasacciones.nombre_label"
                  )}
                  onChange={handleInputChange}
                  name={"nombre"}
                  value={formData["nombre"]}
                  filled={formData["nombre"] !== "" ? "filled" : "empty"}
                />
              </InputWrap>
              <InputWrap>
                {" "}
                <RegularInput
                  label={t("checkout.formulario.telefono") + " *"}
                  type={"text"}
                  placeholder={"+54 11667 73812"}
                  onChange={handleInputChange}
                  name={"tel"}
                  value={formData["tel"]}
                  filled={formData["tel"] !== "" ? "filled" : "empty"}
                />
              </InputWrap>
            </InputGrid>
            <RegularTextArea
              label={
                t("checkout.formulario.notas_adicionales") +
                t("checkout.formulario.opcional")
              }
              placeholder={t("checkout.formulario.notas_adicionales")}
              name="comentario"
              value={formData.comentario}
              onChange={handleInputChange}
              filled={formData["comentario"] !== "" ? "filled" : "empty"}
              rows={ShowWindowDimensions() < SM ? 2 : 6}
            />
            <GridButtons>
              <Cta
                onClick={() => {
                  setOpenModal(false);
                }}
                theme="simpleTheme"
              >
                {t("mis_reservas.cancelar")}
              </Cta>{" "}
              <Cta
                onClick={() => {
                  if (formData.nombre !== "" && formData.tel !== "") {
                    setContactoExist(true);
                    setOpenModal(false);

                    setDataToShow({
                      nombre: formData.nombre,
                      tel: formData.tel,
                      comentario: formData.comentario
                    });
                  }
                }}
                theme="blueDarkTheme"
              >
                {t("pantalla_agencia.mis_trasacciones.agregar_contacto")}
              </Cta>{" "}
            </GridButtons>
          </InputsModal>
        </Modal>
        <Modal
          isOpen={openModalDelete}
          closeModal={() => {
            setOpenModalDelete(false);
          }}
        >
          <SC.DeleteModalContainer>
            <CSC.Title>{t("mis_reservas.eliminar_paquete")}</CSC.Title>
            <CSC.Paragraph>
              {t("pantalla_agencia.mis_trasacciones.seguro_eliminar_contacto")}
            </CSC.Paragraph>
            <SC.FlexCenterContainer>
              <Cta
                onClick={() => {
                  setOpenModalDelete(false);
                }}
                theme="simpleTheme"
              >
                {t("mis_reservas.cancelar")}
              </Cta>
              <Cta
                onClick={() => {
                  setContactoExist(false);
                  setInfoAdicional((infoAdicional: any) => ({
                    nombre: "",
                    tel: "",
                    comentario: ""
                  }));
                  setOpenModalDelete(false);
                }}
              >
                {t("pantalla_agencia.mis_trasacciones.eliminar_contacto")}
              </Cta>
            </SC.FlexCenterContainer>
          </SC.DeleteModalContainer>
        </Modal>
      </Wrapper>
    </Suspense>
  );
};

export default AgenciaDetalle;
