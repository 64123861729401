import styled from "styled-components";
import { Paragraph } from "../../../utils/commonStyle";
import BgNueva from "../../../assets/servicios-images/mapa-nuevo.svg"
import { LargerScreens, SM } from "../../../utils/constants";

export const Wrapper = styled.div`
  margin: 56px 0 65px 120px;
  display: grid;
  grid-template-columns: 0.58fr 1fr;

  @media (max-width: ${SM}) {
    grid-template-columns: 1fr;
    margin: 56px 20px 20px 20px;
  }

  @media(min-width:${LargerScreens}){
    max-width:1270px;
    margin:80px auto 40px auto;
    grid-template-columns: 1fr 1fr;
  } 
`;

export const HeadlineWrap = styled.div`
  @media (max-width: ${SM}) {
    margin-right: 12px;
    h2 {
      font-weight: 500;
      font-size: 23px;
      line-height: 32px;
    }
  }
`;

export const TextP = styled(Paragraph)`
  padding-top: 5px;
  padding-bottom: 25px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;

  color: #000000;
  opacity: 0.8;
`;

export const FeaturedList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  > div {
    &:nth-child(3) {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }

  li {
    background: #ffffff;
    border: 1px solid rgba(209, 223, 229, 0.4);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: center;

    display: inline-block;
    margin-right: 15px;
    margin-bottom: 20px;

    position: relative;

    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      vertical-align: middle;
      line-height: 0.8;

      color: #000000;

      opacity: 0.8;
    }

    img {
      margin-right: 15px;
      vertical-align: middle;
    }

    &:after {
      content: "";
      width: 24px;
      height: 3px;
      background: var(--secondary-color);
      border-radius: 15px;
      position: absolute;
      bottom: 0;
      left: 18px;
    }
  }

  @media (max-width: ${SM}) {
    display: grid;
    grid-template-columns: 0.7fr 1fr;

    li {
      padding: 20px 12px 20px 10px;
      margin-right: 8px;

      img {
        margin-right: 8px;
      }

      &:after {
        left: 8px;
      }
    }
  }
`;

export const HeroBg = styled.div`
  background: url(${BgNueva}) no-repeat center center / contain;

  height: 56vw;
  margin-top: -250px;
  margin: -250px 0 0 auto;
  width: 90%;

  @media (min-width: ${LargerScreens}) {
    height: 38vw;
    width: 100%;
  }
`;

export const HeroHs = styled.div`
  margin-top: -130px;
  margin-left: -20px;
  @media (max-width: 374.98px) {
    max-width: 100%;
    overflow: hidden;
    transform: translateX(20px);
    margin-left: 0;
  }
`;
