import React from "react";

import { Wrapper } from "./style";

interface Props {
  text?: string;
  visible?: boolean;
}

const GeneralError: React.FC<Props> = ({ text, visible }) => {
  return <Wrapper visible={visible}>{text}</Wrapper>;
};

export default GeneralError;
