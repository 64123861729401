import React, { Suspense, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  TextP,
  HeadlineWrap,
  FeaturedList,
  HeroBg,
  HeroHs
} from "./style";

import * as CSC from "../../../utils/commonStyle";

//assets
import EjecutivosIcon from "../../../assets/servicios-images/ejecutivos.svg";
import FamiliasIcon from "../../../assets/servicios-images/familias.svg";
import AdultosMayoresIcon from "../../../assets/servicios-images/adultos-mayores.svg";
import MovilidadReducidaIcon from "../../../assets/servicios-images/movilidad-reducida.svg";
import HeroXs from "../../../assets/servicios-images/mapa-nuevo-xs.svg";


import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../../utils/mobileQueries";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../../utils/AOSconfig";

interface Props {}

const Hero: FC<Props> = () => {
  const { t } = useTranslation();
  useWindowSize();

  useEffect(() => {
    AosConfig();
  }, []);

  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <HeadlineWrap>
          <div data-aos="fade-up">
            <CSC.LittleTitle>
              {t('nuestros_servicios.hero.volanta')}
            </CSC.LittleTitle>
          </div>

          <div data-aos="fade-up" data-aos-delay="100">
            <CSC.Title>
              {t('nuestros_servicios.hero.titulo')}
            </CSC.Title>
          </div>

          <div data-aos="fade-up" data-aos-delay="200">
            <TextP>
              {t('nuestros_servicios.hero.texto')}
            </TextP>
          </div>

          <FeaturedList>
            <div data-aos="fade-up" data-aos-delay="300">
              <li>
                <img src={EjecutivosIcon} alt="" />
                <span>
                  {t('nuestros_servicios.hero.ejecutivos_tag')}
                </span>
              </li>
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
              <li>
                <img src={FamiliasIcon} alt="" />
                <span>
                  {t('nuestros_servicios.hero.familias_tag')}
                </span>
              </li>
            </div>
            <div data-aos="fade-up" data-aos-delay="500">
              <li>
                <img src={AdultosMayoresIcon} alt="" />
                <span>
                  {t('nuestros_servicios.hero.adultos_mayores')}
                </span>
              </li>
            </div>

            <div data-aos="fade-up" data-aos-delay="600">
              <li>
                <img src={MovilidadReducidaIcon} alt="" />
                <span>
                  {t('nuestros_servicios.hero.movilidad_reducida')}
                </span>
              </li>
            </div>
          </FeaturedList>
        </HeadlineWrap>
        {ShowWindowDimensions() >= SM ? (
          <HeroBg />
        ) : (
          <HeroHs>
            <img src={HeroXs} alt="mapa" />
          </HeroHs>
        )}
      </Wrapper>
    </Suspense>
  );
};

export default Hero;
