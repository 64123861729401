import styled from "styled-components";
import { SM } from "../../utils/constants";

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 100%;
  pointer-events: none;
  z-index: 1;

  opacity: 1;
  visibility: visible;
  right: -110px;
  top: 20px;
`;

export const TooltipWrapCheckout = styled.div`
  position: absolute;
  top: 100%;
  pointer-events: none;
  z-index: 1;

  opacity: 1;
  visibility: visible;
  right: -110px;
  top: 20px;

  @media (max-width: ${SM}) {
    left: inherit;
    max-width: 280px;
    top: 50px;
    right: 0;
  }
`;

export const TooltipInner = styled.div<{ viewTool?: string }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 272px;
  background: var(--white);
  color: var(--black);
  box-shadow: var(--shadow);
  border: 1px solid rgba(209, 223, 229, 0.5);
  box-sizing: border-box;
  position: relative;
  opacity: 0;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%) rotate(-45deg);
  }

  &::before {
    border: 1px solid rgba(209, 223, 229, 0.5);
    top: -8px;
    width: 13px;
    height: 13px;
  }

  &::after {
    background: var(--white);
    top: -6px;
    width: 17px;
    height: 17px;
  }

  @media (max-width: ${SM}) {
    &::after,
    &::before {
      content: "";
      position: absolute;
      left: ${props => (props.viewTool === "checkout" ? "unset" : "50%")};
      right: ${props => (props.viewTool === "checkout" ? "0" : "initial")};
      transform: translate(-50%) rotate(-45deg);
    }

    &:before {
      right: ${props =>
        props.viewTool === "checkout" ? "12px !important" : "initial"};
      top: ${props =>
        props.viewTool === "checkout" ? "3px !important" : "initial"};
    }

    &:after {
      right: ${props =>
        props.viewTool === "checkout" ? "10px !important" : "initial"};
      top: ${props =>
        props.viewTool === "checkout" ? "5px !important" : "initial"};
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    ${TooltipWrapper} {
      opacity: 1;
      pointer-events: auto;
      padding-top: 11px;
      > div {
        opacity: 1;
        transition: opacity 0.3s linear;
      }
    }
  }
  &:hover {
    ${TooltipWrapCheckout} {
      opacity: 1;
      pointer-events: auto;
      padding-top: 11px;
      > div {
        opacity: 1;
        transition: opacity 0.3s linear;
      }
    }
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
`;
