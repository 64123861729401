import React from "react";
import { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom";

import * as SC from "./style";

interface Props {
  theme?:
    | "blueTransparentTheme"
    | "lightTheme"
    | "blueDarkTheme"
    | "simpleTheme"
    | "greyTheme"
  type?: "button" | "submit" | "reset" | undefined;
  onClick: () => void;
  isRoute?: boolean;
  toRoute?: string;
  inactive?: boolean;
  goTo?:string;
  hoverColor?:string;
}


const themes = {
  blueDarkTheme: {
    bg: "var(--primary-color)",
    color: "var(--white)",
    hover:"#0909AE"
  },
  lightTheme: {
    bg: "transparent",
    border: "2px solid var(--black)",
    hover:"#000000",
    colorHover: "#ffffff"
 
  },
  blueTransparentTheme: {
    bg: "rgba(18, 18, 201, .1)",
    height: 55,
    color: "var(--primary-color)",
    hover: 'rgba(18, 18, 202, 0.15)'
  },
  simpleTheme: {
    bg: "transparent",
    color: "var(--black)",
 
  },
  greyTheme:{
    bg:"rgba(0, 0, 0, 0.1)",
    color:"rgba(0, 0, 0, 0.3)",

  }
};

const Cta: React.FC<Props> = ({
  children,
  theme = "blueDarkTheme",
  type = "button",
  onClick,
  isRoute,
  toRoute,
  inactive = false,
  goTo,
  hoverColor
}) => {
  return (
    <ThemeProvider theme={themes[theme]}>

    
      {isRoute || goTo ? (
        <SC.LinkWrap>
          <Link to={toRoute ? toRoute : goTo ? goTo : "/"}>
          <SC.Cta type={type}   hoverColor={hoverColor} onClick={onClick}  inactive={inactive}>
              {children}
            </SC.Cta>
          </Link>
        </SC.LinkWrap>
      ) : (
        <SC.Cta type={type} hoverColor={hoverColor} onClick={onClick} inactive={inactive}>
          {children}
        </SC.Cta>
      )}
    </ThemeProvider>
  );
};

export default Cta;
