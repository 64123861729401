import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import SocialNetworks from "../SocialNetworks";

// STYLES & IMAGES
import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";
import mail from "../../assets/icons/mail-icon.svg";
import phone from "../../assets/icons/phone-icon.svg";
import message from "../../assets/icons/message-circle-icon.svg";
import WhatssApp from "../../assets/icons/whatsapp.svg";

import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

interface Props {
  menuMobile?: boolean;
}

const Footer: React.FC<Props> = ({ menuMobile }) => {
  const { t } = useTranslation();

  useWindowSize();

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  const setLang2 = () => {
    const newLang = lang === "ES" ? "EN" : "ES";
    setLang(newLang);
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  return (
    <SC.Wrapper menuMobile={menuMobile}>
      <CSC.Logo role="img" aria-label="Jarvis logo" />

      <SC.ContactContainer>
        <CSC.ContactLink href="mailto:hello@jarvisvip.com" icon={mail}>
          hello@jarvisvip.com
        </CSC.ContactLink>
        <CSC.ContactLink
          href="https://api.whatsapp.com/send?phone=5491169592863"
          icon={WhatssApp}
          target="_blank"
          color="#000"
          customWidth="19px"
          customHeight="19px"
        >
          {/*      (+54) 9 11 6959 2863 */}
          <span>WhatsApp</span>
        </CSC.ContactLink>
        <CSC.ContactLink icon={message} as="p" onClick={setLang2}>
          {lang === "EN" ? "Cambiar a Español" : "Change to English"}
        </CSC.ContactLink>
      </SC.ContactContainer>
      <SocialNetworks />

      {ShowWindowDimensions() <= SM ? (
        <SC.Copyright>
          {t("footer.copyright", { current_year: new Date().getFullYear() })}
        </SC.Copyright>
      ) : (
        <SC.Copyright>
          {t("footer.copyright", { current_year: new Date().getFullYear() })}
        </SC.Copyright>
      )}
    </SC.Wrapper>
  );
};

export default Footer;
