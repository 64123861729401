import React, { Suspense, FC, useState } from "react";
import { Wrapper, TextArea } from "./style";

interface Props {
  name?: any;
  onChange?: any;
  value?: any;
  label?: string;
  placeholder?: string;
  filled?:string
  rows?:number
}

const RegularTextArea: FC<Props> = ({ name, onChange, value, label, placeholder, filled , rows}) => {
  return (
    <Suspense fallback={<div />}>
      <Wrapper>
        <label>{label}</label>
        <TextArea
          value={value}
          name={name}
          onChange={onChange}
          id=""
          rows={rows}
          placeholder={placeholder}
          filled={filled}
        ></TextArea>
      </Wrapper>
    </Suspense>
  );
};

export default RegularTextArea;
