import styled from "styled-components";
import BgPicture from "../../assets/images/success-bg.jpg";
import mastercardLogo from "../../assets/icons/card-mastercard-logo.svg";
import visaLogo from "../../assets/icons/card-visa-logo.svg";
import amexLogo from "../../assets/icons/card-amex-logo.svg";
import { SM, XS, LargerScreens } from "../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 25vw 1fr;

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.8;
  }

  @media (min-width: ${LargerScreens}) {
    grid-template-columns: 35vw 65vw;
  }
`;

export const BgImage = styled.div`
  height: 100vh;
  background: url(${BgPicture}) no-repeat center center / cover;
`;

export const Content = styled.div`
  max-width: 610px;

  > a {
    margin-bottom: 0;
    display: inline-block;
    margin-left: 80px;
    margin-top: 40px;
    vertical-align: middle;
  }

  @media (max-width: ${SM}) {
    max-width: unset;
    a {
      margin-bottom: 0;
      margin-left: 20px;
    }
  }
`;

export const Headline = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 80px;

  margin-top: 20vh;

  > p {
    padding-top: 5px;
  }

  @media (max-width: ${SM}) {
    margin-left: 0;
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
    }

    width: 95%;
    margin: 0 auto;
    margin-top: 50px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin-top: 40px;

  div {
    &:first-child {
      a {
        margin-left: -30px;
      }
    }
  }

  a {
    display: block;
  }

  @media (max-width: ${SM}) {
    flex-direction: column-reverse;
    margin-top: 100px;

    a {
      display: block;
      margin-left: 0 !important;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const CardLogo = styled.span`
  width: 60px;
  height: 60px;
  &[aria-label="mastercard"] {
    background: url(${mastercardLogo}) no-repeat center center / contain;
  }
  &[aria-label="visa"] {
    background: url(${visaLogo}) no-repeat center center / contain;
  }
  &[aria-label="amex"] {
    width: 90px;
    height: 90px;
    background: url(${amexLogo}) no-repeat center center / contain;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  @media (max-width: ${XS}) {
    justify-content: center;
  }
`;

export const LogoYLangWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LangWrap = styled.div`
  display: inline-block;
  vertical-align: middle;

  width: 60%;
  text-align: right;
  vertical-align: middle;
  p {
    align-items: center;
    font-size: 14px;
    &:before {
      margin-right: 5px;
    }
  }

  @media (max-width: ${SM}) {
    text-align: right;
    margin-top: 20px;
    margin: 15px 0 0 auto;
  }
`;

export const MessagesWrap = styled.div`
  padding-left: 80px;
  padding-top: 60px;

  p{
    font-size:16x;
  }

  @media(max-width:${SM}){
    padding:40px 0;
    text-align:center;
  }
`;
