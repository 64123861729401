import { useEffect, useCallback, useState } from "react";

export default ({
    onChange = (booking) => {},
}) => {
    const [bookings, setBookings] = useState(() => {
        const bookings = localStorage.getItem("bookings");
        return bookings ? JSON.parse(bookings) : [];
    });

    const callListeners = (bookings) => {
        if(window.bookingsListeners) {
            for(var i =0; i < window.bookingsListeners.length; i++) {
                window.bookingsListeners[i](bookings)
            }
        }
    };

    const updateBookings = useCallback(booking => {
        setBookings(booking);
        localStorage.setItem("bookings", JSON.stringify(booking));
        callListeners(booking);
    }, []);

    useEffect(() => {
        if(onChange) {
            if(window.bookingsListeners) {
                window.bookingsListeners.push(onChange);
            } else {
                window.bookingsListeners = [onChange];
            }
        }
    }, []);
    
    return {
        bookings,
        updateBookings,
    }
};