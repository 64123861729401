import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import EmptyResult from '../../../EmptyResult';
import Cta from '../../../Cta';

import * as SC from './style';
import * as CSC from '../../../../utils/commonStyle';

import { TEMP_DATA } from './TEMP_DATA';
import { useWindowSize, ShowWindowDimensions, SM } from '../../../../utils/mobileQueries';
import { getVipsByAirport } from '../../../../actions/vips/creators';
import { Vip } from '../../../../actions/vips/types';
import Modal from "../../../../components/Modal";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import IconBook from "../../../../assets/icons/vip/icon-book.png";
import IconClock from "../../../../assets/icons/vip/icon-clock.png";
import IconCoffee from "../../../../assets/icons/vip/icon-coffee.png";
import IconCreditCard from "../../../../assets/icons/vip/icon-credit-card.png";
import IconInfoCircle from "../../../../assets/icons/vip/icon-info-circle.png";
import IconPets from "../../../../assets/icons/vip/icon-pets.png";
import IconShower from "../../../../assets/icons/vip/icon-shower.png";
import IconUsers from "../../../../assets/icons/vip/icon-users.png";
import IconWifi from "../../../../assets/icons/vip/icon-wifi.png";

interface Props { }

const VIPLounges: React.FC<Props> = () => {
  const { t } = useTranslation();

  useWindowSize();

  const [vips, setVips] = useState<Vip[]>([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedVip, setSelectedVip] = React.useState<any>({});
  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("airport") || "{}");

    const fetchData = async () => {
      const __vips: Vip[] = await getVipsByAirport(_id);
      const trads = {
        '24hrs': '24hrs',
        'Bar y refrigerio': 'Bar and snack',
        'Información de vuelos': 'Flight information',
        'WiFi': 'WiFi',
        'Sala de reuniones': 'Meeting room',
        'Recepción y atención': 'Reception and attention',
        'Material de lectura': 'Reading material',
        'TV Satelital': 'Satellite TV',
        'Duchas': 'Showers',
        'Animales permitidos en su jaula/canil': 'Animals allowed in their cage / kennel',
      }
      setVips(__vips.map((vip) => ({...vip, services: vip.services.map((service: string) => ({service, serviceEN: (trads as any)[service]}))})))
    };

    fetchData();
  }, []);

  function getServices() {
    return selectedVip.services?.filter(({ service }: any) => service !== 'Animales permitidos en su jaula/canil')
  }

  function getLargeService() {
    return selectedVip.services?.find(({ service }: any) => service === 'Animales permitidos en su jaula/canil')
  }

  return (
    <>
      <Modal
        maxWidth="500px"
        isOpen={modalShow}
        noBackground
        closeModal={() => {
          setModalShow(false);
        }}
      >
        <SC.ModalWrapper>

          <div style={{'background': 'white'}}>
            <SC.VipCompany>
              {selectedVip.company}
            </SC.VipCompany>

            {selectedVip.images?.length === 1 &&
              <SC.EachSlide>
                <img src={selectedVip.images![0]} alt="" />
              </SC.EachSlide>
            }
            {selectedVip.images?.length > 1 &&
              <Slide easing="ease">
                {selectedVip.images?.map((image: string) =>
                  <SC.EachSlide>
                    <img src={image} alt="" />
                  </SC.EachSlide>
                )}
              </Slide>
            }

            <SC.VipInfo>
              <div style={{'flex': 1, 'display': 'flex', 'flexDirection': 'column'}}>
                <div style={{'fontFamily': 'Poppins', 'fontWeight': 500, 'fontSize': '20px', 'textTransform': 'uppercase', 'paddingTop': '.3em', 'whiteSpace': 'nowrap'}}>
                  {selectedVip.title}
                </div>
                <div style={{'fontFamily': 'Poppins', 'fontSize': '14px', 'color': '#A5A5A5', 'lineHeight': '16px', 'textAlign': 'right'}}>
                  {selectedVip.location} <br/>
                  {selectedVip.time}
                </div>
              </div>
            </SC.VipInfo>

            <div style={{'fontWeight': 'bold', 'margin': '0 20px', 'marginBottom': '.5em', 'borderBottom': '2px solid #A5A5A5', 'color': '#A5A5A5', 'paddingBottom': '.4em', 'fontFamily': 'Poppins', 'fontSize': '14px'}}>
            { t('resultados.salones_vip.modal2') }
            </div>

            <SC.WrapperColumn>
              <SC.Column>
              {getServices()?.slice(0, 3).map(({ service, serviceEN }: any) =>
                <SC.Service>
                  { service === '24hrs' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Bar y refrigerio' && <div><img src={IconCoffee} alt="" /></div> }
                  { service === 'Información de vuelos' && <div><img src={IconInfoCircle} alt="" /></div> }
                  { service === 'WiFi' && <div><img src={IconWifi} alt="" /></div> }
                  { service === 'Sala de reuniones' && <div><img src={IconUsers} alt="" /></div> }
                  { service === 'Recepción y atención' && <div><img src={IconCreditCard} alt="" /></div> }
                  { service === 'Material de lectura' && <div><img src={IconBook} alt="" /></div> }
                  { service === 'TV Satelital' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Duchas' && <div><img src={IconShower} alt="" /></div> }
                  <div className="service">{ lang === 'ES' ? service : serviceEN}</div>
                </SC.Service>
              )}
              </SC.Column>
              <SC.Column>
              {getServices()?.slice(3, 6).map(({ service, serviceEN }: any) =>
                <SC.Service>
                  { service === '24hrs' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Bar y refrigerio' && <div><img src={IconCoffee} alt="" /></div> }
                  { service === 'Información de vuelos' && <div><img src={IconInfoCircle} alt="" /></div> }
                  { service === 'WiFi' && <div><img src={IconWifi} alt="" /></div> }
                  { service === 'Sala de reuniones' && <div><img src={IconUsers} alt="" /></div> }
                  { service === 'Recepción y atención' && <div><img src={IconCreditCard} alt="" /></div> }
                  { service === 'Material de lectura' && <div><img src={IconBook} alt="" /></div> }
                  { service === 'TV Satelital' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Duchas' && <div><img src={IconShower} alt="" /></div> }
                  <div className="service">{ lang === 'ES' ? service : serviceEN}</div>
                </SC.Service>
              )}
              </SC.Column>
              <SC.Column style={{ 'border': 'none', 'marginRight': 'none' }}>
              {getServices()?.slice(6, 10).map(({ service, serviceEN }: any) =>
                <SC.Service>
                  { service === '24hrs' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Bar y refrigerio' && <div><img src={IconCoffee} alt="" /></div> }
                  { service === 'Información de vuelos' && <div><img src={IconInfoCircle} alt="" /></div> }
                  { service === 'WiFi' && <div><img src={IconWifi} alt="" /></div> }
                  { service === 'Sala de reuniones' && <div><img src={IconUsers} alt="" /></div> }
                  { service === 'Recepción y atención' && <div><img src={IconCreditCard} alt="" /></div> }
                  { service === 'Material de lectura' && <div><img src={IconBook} alt="" /></div> }
                  { service === 'TV Satelital' && <div><img src={IconClock} alt="" /></div> }
                  { service === 'Duchas' && <div><img src={IconShower} alt="" /></div> }
                  <div className="service">{ lang === 'ES' ? service : serviceEN}</div>
                </SC.Service>
              )}
              </SC.Column>
            </SC.WrapperColumn>
            { getLargeService() &&
              <SC.Service style={{ 'paddingLeft': '20px', 'height': '40px' }}>
                <div><img src={IconPets} alt="" /></div>
                <div className="service">{ lang === 'ES' ? getLargeService().service : getLargeService().serviceEN}</div>
              </SC.Service>
            }
          </div>
        </SC.ModalWrapper>
      </Modal>
      <SC.Wrapper>
        {vips.length ? (
          <CSC.MediumTitle>{t('resultados.salones_vip.titulo')}</CSC.MediumTitle>
        ) : <></> }

        {ShowWindowDimensions() > SM ?
          (vips.length ? (
            <SC.SalonsContainer>
              {vips.map((item) => (
                <SC.Lounge key={item._id}>
                  <SC.InputRadioInternal>
                    <SC.RadioContainer>
                      <SC.Image src={item.logo}/>

                      <SC.FlightInfo>
                        <SC.AirlineTitle>{item.company}</SC.AirlineTitle>
                        <CSC.MediumTitle as="span">{item.title}</CSC.MediumTitle>
                        <CSC.Paragraph as="span">
                          {item.location}
                        </CSC.Paragraph>
                      </SC.FlightInfo>
                    </SC.RadioContainer>
                  </SC.InputRadioInternal>

                  <SC.LinkSalon>
                    <Cta onClick={() => {
                      setSelectedVip(item);
                      setModalShow(true);
                     }} theme="lightTheme">
                      {t('resultados.salones_vip.boton')}
                    </Cta>
                  </SC.LinkSalon>
                </SC.Lounge>
              ))}
              </SC.SalonsContainer>
          ) : <div></div>
          )
        :
          (vips.length ? (
            <SC.SalonsContainer>
              {vips.map((item) => (
                <SC.Lounge key={item._id}>
                  <SC.InputRadioInternal>
                    <SC.RadioContainer>
                      <SC.FlightInfo>
                        <SC.AirlineTitle>{item.company}</SC.AirlineTitle>
                        <CSC.MediumTitle as="span">{item.title}</CSC.MediumTitle>
                        <CSC.Paragraph as="span">
                          {item.location}
                        </CSC.Paragraph>
                      </SC.FlightInfo>
                    </SC.RadioContainer>
                  </SC.InputRadioInternal>

                  <SC.LinkSalon>
                    <Cta onClick={() => {
                      setSelectedVip(item);
                      setModalShow(true);
                    }} theme="lightTheme" />
                  </SC.LinkSalon>
                </SC.Lounge>
              ))}
              </SC.SalonsContainer>
          ) : <div></div>)
        }

        {!vips.length && <EmptyResult title={t('resultados.salones_vip.sin-salones_title')}

        subtitle={t("resultados.salones_vip.sin_salones_subtitlo")}


        />}
      </SC.Wrapper>
    </>
  );
};

export default VIPLounges;
