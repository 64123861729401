import React, { useState, useEffect, Fragment, useRef, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { getAll } from "../../../../actions/airlines/creators";
import { getAll as getAllSettings } from "../../../../actions/settings/creators";
import { Airline } from "../../../../actions/airlines/types";
import arrival from "../../../../assets/icons/arrival-icon.svg";
import Caret from "../../../../assets/icons/caret.svg";
import connections from "../../../../assets/icons/connections-icon.svg";
import departure from "../../../../assets/icons/departure-icon.svg";
import * as CSC from "../../../../utils/commonStyle";
import {
  LG,
  ShowWindowDimensions,
  useWindowSize
} from "../../../../utils/mobileQueries";

// COMPONENTS
import Cta from "../../../Cta";
import DatePicker from "../../../Datepicker";
import Dropdown from "../../../Dropdown";
import Modal from "../../../Modal";
import TimePicker from "../../../TimePicker";

import FavAzul from "../../../../assets/icons/fav-azul.png";
import FavBlanco from "../../../../assets/icons/fav-blanco.png";

// STYLES & IMAGES
import * as SC from "./style";

import { addBusinessDays } from "../../../../utils/date";
import { Setting } from "../../../../actions/settings/types";
import { aeropuertos } from "../../../../pages/agencia/balance/balanceData";
import { AutocompleInput } from "./style";
import LupaIcon from "../../../../assets/icons/search-icon.svg";

interface Props {
  data?: any;
  toShowData: any;
  setToShowData?: any;
  bookingHook: {
    booking: any;
    setBooking: any;
  };
  blockedTime: boolean;
  setBlockedTime: (aValue: boolean) => void,
  setIsAepVvipAvailable?: (value: boolean) => void,
}

const FlightCharacteristics: React.FC<Props> = ({
  data,
  toShowData,
  setToShowData,
  bookingHook: { booking, setBooking },
  blockedTime,
  setBlockedTime,
  setIsAepVvipAvailable
}) => {
  const { t } = useTranslation();

  useWindowSize();

  const [dateValue, setDateValue] = useState<Date | string>("");
  /*   const [parseDate, setParseDate] = useState(""); */

  const [value, onChange] = useState("HH:MM");

  const [limitTime, setLimitTime] = useState("")

  useEffect(() => {
    const getHours = new Date().getHours().toString()
    const getMinutes = new Date().getMinutes().toString()
    const hours = getHours.length === 1 ? `0${getHours}` : getHours
    const minutes = getMinutes.length === 1 ? `0${getMinutes}` : getMinutes
    setLimitTime(`${hours}:${minutes}`)
  }, [])

  const [openModal, setOpenModal] = useState(false);

  const [lang, setLang] = useState("ES");

  useEffect(() => {
    const currentLang = localStorage.getItem("lang") || "ES";
    setLang(currentLang);
  }, []);

  const [openTab, setOpenTab] = useState(false);
  const [openTabAerolineas, setOpenTabAerolineas] = useState(false);
  const [openTabVuelos, SetOpenTabVuelos] = useState(false);
  const [mobileTipoChosen, setMobileTipoChosen] = useState(0);
  const [mobileAeroChosen, setMobileAeroChosen] = useState(0);
  const [mobileVuelosChosen, setMobileVuelosChosen] = useState(0);
  const [hourToShow, setHourToShow] = useState("");

  useEffect(() => {
    if (hourToShow.length > 4 && dateValue) {
      const startTime = `${limitTime}:00`;
      const endTime = `${hourToShow}:00`;
      const regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;

      const selectedDate = moment(dateValue)
      const todayPlus3 = moment().add(1, "days");

      if (selectedDate.isSameOrBefore(todayPlus3)) {
        if (parseInt(endTime.replace(regExp, "$1$2$3")) > parseInt(startTime.replace(regExp, "$1$2$3"))) {
          setBlockedTime(false)
        } else {
          setBlockedTime(true)
        }
      } else {
        setBlockedTime(false)
      }
    }
  }, [hourToShow, dateValue])

  const [_airlines, setAirlines] = useState<IDropdownOptions[]>([]);

  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("airport") || "{}");

    const fetchData = async () => {
      const __airlines: Airline[] = await getAll(_id);
      const icons = [arrival, departure, connections];

      setAirlines(
        __airlines.map(({ _id: value, airlineName, airlineCode }, i) => ({
          title: `${airlineCode} - ${airlineName}`,
          value,
          icon: icons[i]
        }))
      );
    };

    fetchData();
  }, []);

  useEffect(() => {
    ƒ(_airlines[0]);
  }, []);

  useEffect(() => {
    setDateValue("");
  }, []);

  const [height, setHeight] = useState(0);
  const [heightAero, setHeightAero] = useState(0);
  const [heightVuelo, setHeightVuelo] = useState(0);

  const myRef = useRef<HTMLInputElement | null>(null);
  const aerolineasRef = useRef<HTMLInputElement | null>(null);
  const vuelosRef = useRef<HTMLInputElement | null>(null);
  const [valueTime, setValueTime] = useState<Date | null>(null);
  const [aepVvipAvailable, setAepVvipAvailable] = useState<boolean>(false);

  const checkTime = (selectedTime: Date | string) => {
    let timeToCheck: Date;
  
    if (typeof selectedTime === 'string') {      
      const [hoursStr, minutesStr] = selectedTime.split(':');
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
  
      timeToCheck = new Date();
      timeToCheck.setHours(hours);
      timeToCheck.setMinutes(minutes);
    } else {
      timeToCheck = selectedTime;
    }
  
    const selectedHours = timeToCheck.getHours();
    const selectedMinutes = timeToCheck.getMinutes();
  
    const isWithinRange =
      (selectedHours > 6 && selectedHours < 22) ||
      (selectedHours === 22 && selectedMinutes <= 30);
  
    return isWithinRange;
  };

  const handleTimeChange = (newTime: Date | null) => {
    setValueTime(newTime);

    if (newTime !== null) {
      setAepVvipAvailable(checkTime(newTime));
    } else {
      setAepVvipAvailable(false);
    }
  };

  useEffect(() => {  
    if(setIsAepVvipAvailable){
      if (valueTime && aepVvipAvailable){
        setIsAepVvipAvailable(true)
      }else{
        setIsAepVvipAvailable(false)
      }
    }  
  }, [valueTime, aepVvipAvailable]);

  useEffect(() => {
    if (myRef && myRef.current) {
      setHeight(myRef && myRef.current && myRef.current.clientHeight);
    }
    if (vuelosRef && vuelosRef.current) {
      setHeightVuelo(
        vuelosRef && vuelosRef.current && vuelosRef.current.clientHeight
      );
    }
    if (aerolineasRef && aerolineasRef.current) {
      setHeightAero(
        aerolineasRef &&
        aerolineasRef.current &&
        aerolineasRef.current.clientHeight
      );
    }
  }, [height, openTab, openModal]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
      document.documentElement.style.overflow = "inherit";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const reservationType: IDropdown = {
    id: "1",
    title: t("resultados.buscador.tipo_de_reserva"),
    options: [
      {
        title: t("resultados.buscador.partida"),
        value: "Partida",
        icon: departure
      },
      {
        title: t("resultados.buscador.arribo"),
        value: "Arribo",
        icon: arrival
      },
      {
        title: t("resultados.buscador.conexión"),
        value: "Conexión",
        icon: connections
      }
    ]
  };

  const airlines: IDropdown = {
    id: "2",
    title: t("resultados.buscador.aerolinea"),
    placeholder: t("generales.boton_seleccionar"),
    options: [
      {
        title: t("generales.boton_seleccionar"),
        value: "Seleccionar"
      }
    ]
  };

  const flightType: IDropdown = {
    id: "3",
    title: t("resultados.buscador.tipo_de_vuelo"),
    placeholder: t("generales.boton_seleccionar"),
    options: [
      {
        title: t("resultados.buscador.tipo_internacional"),
        value: "Internacional"
      },
      {
        title: t("resultados.buscador.tipo_nacional"),
        value: "Doméstico"
      }
    ]
  };

  const [reservationSelected, setReservationSelected] = useState<
    IDropdownOptions
  >(reservationType.options[0]);
  const [flightTypeSelected, setFlightTypeSelected] = useState<
    IDropdownOptions
  >(flightType.options[0]);

  const [airlinesSelected, ƒ] = useState<IDropdownOptions>(airlines.options[0]);

  useEffect(() => {
    setToShowData((toShowData: any) => ({
      ...toShowData,
      TipoDeReserva: reservationSelected && reservationSelected.title,
      Aerolinea: "-",
      TipoDeVuelo: flightTypeSelected && flightTypeSelected.title,
      fecha: moment(dateValue)
        .format("DD-MM-YYYY")
        .replace("-", "/")
        .replace("-", "/"),
      hora: hourToShow
    }));

    setBooking({
      ...booking,
      airline: airlinesSelected && airlinesSelected.value, // Dejar en .value porque rompe los pagos
      airlineName: airlinesSelected && airlinesSelected.title,
      bookingType: reservationSelected && reservationSelected.value,
      flightType: flightTypeSelected && flightTypeSelected.value,
      date:
        moment(dateValue)
          .format("DD-MM-YYYY")
          .replace("-", "/")
          .replace("-", "/") +
        ", " +
        hourToShow
    });
  }, [
    reservationSelected,
    airlinesSelected,
    flightTypeSelected,
    dateValue,
    hourToShow
  ]);

  const [surcharge, setSurcharge] = useState("");
  const [timeRange, setTimeRange] = useState({
    initial: 0,
    final: 0
  });
  const [timeRangeArrival, setTimeRangeArrival] = useState({
    initial: 0,
    final: 0
  });
  const [timeRangeDeparture, setTimeRangeDeparture] = useState({
    initial: 0,
    final: 0
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getAllSettings();
      const _surcharge = settings.find(
        ({ key }: Setting) => key === "surcharge"
      )!.value;
      const _schedule_from = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_from")!.value
      );
      const _schedule_to = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_to")!.value
      );
      const _schedule_from_arrival = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_from_arrival")!.value
      );
      const _schedule_to_arrival = parseInt(
        settings.find(({ key }: Setting) => key === "schedule_to_arrival")!.value
      );

      setSurcharge(_surcharge);
      setTimeRange({
        initial: _schedule_from,
        final: _schedule_to
      });
      setTimeRangeArrival({
        initial: _schedule_from_arrival,
        final: _schedule_to_arrival
      });
      setTimeRangeDeparture({
        initial: _schedule_from,
        final: _schedule_to
      });
    };
    fetchSettings();
  }, []);

  const getHours = (hours: string, minutes: string) => {
    const minutesJoin = minutes.length === 1 ? "0" + minutes : minutes;
    const hoursJoin = hours.length === 1 ? "0" + hours : hours;
    var timeJoin = hoursJoin + ":" + minutesJoin;
    setHourToShow(timeJoin);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    let results = [] as any;

    /*  results = _airlines.filter(({ title }) =>
      title.toLowerCase().includes(searchTerm.toLowerCase())
    ); */

    results = _airlines.filter(({ title }) => {
      if (searchTerm.length > 2) {
        return title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return title
          .toLowerCase()
          .substring(0, 2)
          .includes(searchTerm.toLowerCase().substring(0, 2));
      }
    });
    setSearchResults(results);
  }, [searchTerm]);

  useEffect(() => {
    const bookingStorage = localStorage.getItem("more_offers_booking");

    if (!bookingStorage) {
      return;
    }

    const {
      airline
    } = JSON.parse(bookingStorage);

    const _airline = _airlines.find(({ value }) => value === airline);

    ƒ(_airline!);
    setReservationSelected(reservationType.options[1]);
  }, [_airlines]);

  useEffect(() => {
    console.log("toShowData", toShowData);
    // if TipoDeReserva is arrival set timeRange to arrival, else set timeRange to departure
    if (toShowData.TipoDeReserva === "Arrival") {
      setTimeRange({
        initial: timeRangeArrival.initial,
        final: timeRangeArrival.final
      });
    } else {
      setTimeRange({
        initial: timeRangeDeparture.initial,
        final: timeRangeDeparture.final
      });
    }
  }, [toShowData]);

  return (
    <SC.Wrapper>
      <SC.HeadlineTitle>
        <CSC.MediumTitle>{t("resultados.buscador.titulo")}</CSC.MediumTitle>
        <CSC.Paragraph>{t("resultados.buscador.subtitulo")}</CSC.Paragraph>
      </SC.HeadlineTitle>

      {ShowWindowDimensions() > LG ? (
        <SC.SearchBarCustom>
          <Dropdown
            title={reservationType.title}
            options={reservationType.options}
            selectedValue={reservationSelected}
            onChange={value => {
              setReservationSelected(value);
            }}
          />

          <Dropdown
            title={airlines.title}
            options={_airlines.sort((a, b) => a.title.localeCompare(b.title))}
            placeholder={airlines.placeholder}
            selectedValue={airlinesSelected}
            onChange={value => {
              ƒ(value);
            }}
            dropdownType={"Airlines"}
            icon={FavAzul}
          />
          <Dropdown
            title={flightType.title}
            options={flightType.options}
            placeholder={flightType.placeholder}
            selectedValue={flightTypeSelected}
            onChange={value => {
              setFlightTypeSelected(value);
            }}
          />

          <DatePicker
            title={t("generales.fecha")}
            placeholder={t("generales.seleccionar_fecha")}
            dateValue={dateValue || new Date(addBusinessDays(new Date(), 1))}
            setDateValue={setDateValue}
            parseDate={moment(dateValue, "DD MM YYYY hh:mm:ss")
              .format("DD/MM/YYYY")}
            minDate={new Date(addBusinessDays(new Date(), 1))}
            blockedTime={blockedTime}
            toolTip={{
              title: t("resultados.buscador.hora_blockeada"),
              text:
                t("resultados.buscador.hora_blockeada_text") +
                " " +
                limitTime +
                "hs."
            }}
          />

          <TimePicker
            title={t("resultados.buscador.horario")}
            placeholder={t("resultados.buscador.horario")}
            value={valueTime}
            onChange={handleTimeChange}
            dateValue={valueTime}
            setDateValue={setValueTime}
            timeRange={timeRange}
            toolTip={{
              title: t("resultados.buscador.recargo_titulo"),
              text:
                t("resultados.buscador.recargo_text") + " " + surcharge + "%"
            }}
            getHours={getHours}
          />

          {/*        <SC.CtaContainer>
            <Cta onClick={() => {}}>
              Buscar
              <CSC.RightArrow />
            </Cta>
          </SC.CtaContainer> */}
        </SC.SearchBarCustom>
      ) : (
        <SC.MobileWrap>
          <Fragment>
            {data ? (
              <SC.CaracterMobile>
                <li>
                  <span>{t("resultados.buscador.tipo_de_reserva")}</span>

                  {lang === "EN" ? (
                    <>
                      {booking.bookingType === "Partida" && (
                        <span>Departure</span>
                      )}
                      {booking.bookingType === "Arribo" && <span>Arrival</span>}
                      {booking.bookingType === "Conexión" && (
                        <span>Connection</span>
                      )}
                    </>
                  ) : (
                    <span>{booking.bookingType}</span>
                  )}
                </li>
                <li>
                  <span>{t("resultados.buscador.aerolinea")}</span>
                  <span>{airlinesSelected && airlinesSelected.title}</span>
                </li>
                <li>
                  <span>{t("resultados.buscador.tipo_de_vuelo")}</span>
                  <span>{toShowData.TipoDeVuelo}</span>
                </li>
                <li>
                  <span>{t("resultados.buscador.fecha")}</span>
                  {toShowData.fecha === "Invalid date" ? (
                    "-"
                  ) : (
                    <span>
                      {toShowData.fecha}, {toShowData.hora}
                    </span>
                  )}
                </li>
                <Cta
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  theme="lightTheme"
                >
                  {t("resultados.caracteristicas.boton_mobile_cambiar")}
                </Cta>
              </SC.CaracterMobile>
            ) : (
              <SC.CaracterMobile>
                <Cta
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  theme="blueDarkTheme"
                >
                  {t("resultados.caracteristicas.seleccionar_caracteristicas")}
                </Cta>
              </SC.CaracterMobile>
            )}
          </Fragment>

          <Modal
            isOpen={openModal}
            closeModal={() => {
              setOpenModal(false);
            }}
            fullscreen={true}
          >
            <SC.ModalTitle>{t("resultados.buscador.titulo")}</SC.ModalTitle>
            <SC.TabWrapper>
              <SC.Tabs>
                <SC.TabsHead
                  onClick={() => {
                    setOpenTab(!openTab);
                    setOpenTabAerolineas(false);
                    SetOpenTabVuelos(false);
                  }}
                >
                  <div>
                    <h5>{t("resultados.buscador.tipo_de_reserva")}</h5>
                    <div>
                      <SC.TabsIcon>
                        {airlines.options.length &&
                          airlines.options[mobileTipoChosen] && (
                            <img
                              src={airlines.options[mobileTipoChosen].icon}
                              alt=""
                            />
                          )}
                      </SC.TabsIcon>
                      <SC.TitleNoIcon>
                        <h4>
                          {reservationType.options[mobileTipoChosen].title}
                        </h4>
                      </SC.TitleNoIcon>
                    </div>
                  </div>
                  <SC.TabsCaret openTab={openTab}>
                    <img src={Caret} alt="" />
                  </SC.TabsCaret>
                </SC.TabsHead>
                <SC.TabsContent>
                  <SC.TabUl openTab={openTab} height={height}>
                    <div ref={myRef}>
                      {reservationType.options.map((tipo, i) => {
                        return (
                          <SC.Li
                            key={i}
                            active={mobileTipoChosen}
                            index={i}
                            onClick={() => {
                              setMobileTipoChosen(i);
                              setOpenTab(false);
                              setReservationSelected(tipo);
                            }}
                          >
                            {tipo.title}
                          </SC.Li>
                        );
                      })}
                    </div>
                  </SC.TabUl>
                </SC.TabsContent>
              </SC.Tabs>

              <SC.Tabs>
                <SC.TabsHead
                  onClick={() => {
                    SetOpenTabVuelos(false);
                    setOpenTabAerolineas(!openTabAerolineas);
                    setOpenTab(false);
                  }}
                >
                  <div>
                    <h5>{t("resultados.buscador.aerolinea")}</h5>
                    <div>
                      <SC.TitleNoIcon>
                        <h4>
                          {booking.airline === "-" ||
                            booking.airline === undefined
                            ? lang !== "EN"
                              ? "Seleccionar"
                              : "Select"
                            : airlinesSelected && airlinesSelected.title}
                        </h4>
                      </SC.TitleNoIcon>
                    </div>
                  </div>
                  <SC.TabsCaret openTab={openTabAerolineas}>
                    <img src={Caret} alt="" />
                  </SC.TabsCaret>
                </SC.TabsHead>
                <SC.TabsContent>
                  <SC.TabUl
                    openTab={openTabAerolineas}
                    height={300}
                    overflow={"scroll"}
                  >
                    <AutocompleInput>
                      <img src={LupaIcon} alt="" />
                      <input
                        type="text"
                        placeholder={t("generales.buscar_aerolinea")}
                        onChange={handleChange}
                      />
                    </AutocompleInput>
                    <div ref={aerolineasRef}>
                      {searchTerm.length !== 0 && searchResults.length === 0 && (
                        <SC.NotFound>
                          <span>{t("generales.contactar")} </span>
                          <a href="mailto:hello@jarvisvip.com">
                            hello@jarvisvip.com
                          </a>
                        </SC.NotFound>
                      )}
                      {searchTerm.length !== 0 ? (
                        <>
                          {searchResults
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map((tipo, i) => {
                              return (
                                <SC.Li
                                  key={i}
                                  active={mobileAeroChosen}
                                  index={i}
                                  onClick={() => {
                                    setOpenTabAerolineas(false);
                                    setMobileAeroChosen(i);
                                    ƒ(tipo);
                                  }}
                                >
                                  {tipo.title}
                                </SC.Li>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {_airlines.length > 0 ? (
                            <>
                              {_airlines
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((tipo, i) => {
                                  return (
                                    <SC.Li
                                      key={i}
                                      active={mobileAeroChosen}
                                      index={i}
                                      onClick={() => {
                                        setOpenTabAerolineas(false);
                                        setMobileAeroChosen(i);
                                        ƒ(tipo);
                                      }}
                                    >
                                      {tipo.title}
                                    </SC.Li>
                                  );
                                })}
                            </>
                          ) : (
                            <SC.LoadingAirlines>
                              {t("generales.cargando_aerolineas")}
                            </SC.LoadingAirlines>
                          )}
                        </>
                      )}
                    </div>
                  </SC.TabUl>
                </SC.TabsContent>
              </SC.Tabs>

              <SC.Tabs>
                <SC.TabsHead
                  onClick={() => {
                    setOpenTabAerolineas(false);
                    setOpenTab(false);
                    SetOpenTabVuelos(!openTabVuelos);
                  }}
                >
                  <div>
                    <h5>{t("resultados.buscador.tipo_de_vuelo")}</h5>
                    <div>
                      <SC.TitleNoIcon>
                        <h4>{flightType.options[mobileVuelosChosen].title}</h4>
                      </SC.TitleNoIcon>
                    </div>
                  </div>
                  <SC.TabsCaret openTab={openTabVuelos}>
                    <img src={Caret} alt="" />
                  </SC.TabsCaret>
                </SC.TabsHead>
                <SC.TabsContent>
                  <SC.TabUl openTab={openTabVuelos} height={heightVuelo}>
                    <div ref={vuelosRef}>
                      {flightType.options.map((tipo, i) => {
                        return (
                          <SC.Li
                            key={i}
                            active={mobileVuelosChosen}
                            index={i}
                            onClick={() => {
                              setMobileVuelosChosen(i);
                              setFlightTypeSelected(tipo);
                              SetOpenTabVuelos(false);
                            }}
                          >
                            {tipo.title}
                          </SC.Li>
                        );
                      })}
                    </div>
                  </SC.TabUl>
                </SC.TabsContent>
              </SC.Tabs>

              <SC.Tabs>
                <SC.DateMobileWrap>
                  <DatePicker
                    title={t("generales.fecha")}
                    placeholder={t("generales.seleccionar_fecha")}
                    dateValue={
                      dateValue || addBusinessDays(new Date(), 2)
                    }
                    setDateValue={setDateValue}
                    parseDate={moment(dateValue, "DD MM YYYY hh:mm:ss")
                      .format("DD/MM/YYYY")}
                    minDate={addBusinessDays(new Date(), 2)}
                    blockedTime={blockedTime}
                    toolTip={{
                      title: t("resultados.buscador.hora_blockeada"),
                      text:
                        t("resultados.buscador.hora_blockeada_text") +
                        " " +
                        limitTime +
                        "hs."
                    }}
                  />
                  {/*    <SC.TabsCaret openTab={openTabVuelos}>
                    <img src={Caret} alt="" />
                  </SC.TabsCaret> */}
                </SC.DateMobileWrap>
              </SC.Tabs>

              <SC.Tabs>
                <SC.TimeMobileWrap>
                  <TimePicker
                    title={t("resultados.buscador.horario")}
                    placeholder={t("resultados.buscador.horario")}
                    value={valueTime}
                    onChange={handleTimeChange}
                    dateValue={valueTime}
                    setDateValue={setValueTime}
                    timeRange={timeRange}
                    toolTip={{
                      title: t("resultados.buscador.recargo_titulo"),
                      text:
                        t("resultados.buscador.recargo_text") +
                        " " +
                        surcharge +
                        "%"
                    }}
                    getHours={getHours}
                  />

                  <SC.TabsCaret openTab={openTabVuelos}></SC.TabsCaret>
                </SC.TimeMobileWrap>
              </SC.Tabs>
              <Cta
                onClick={() => {
                  setOpenModal(false);
                }}
                theme="lightTheme"
              >
                {t("resultados.caracteristicas.boton_mobile_guardar")}
              </Cta>
            </SC.TabWrapper>
          </Modal>
        </SC.MobileWrap>
      )}
    </SC.Wrapper>
  );
};

export default FlightCharacteristics;
