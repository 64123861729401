import axios from 'axios';

const { REACT_APP_API_URL: API_URL } = process.env;

export const getAll = async (airport: string) => {
  const {
    data: { airlines }
  } = await axios.get(`${API_URL}/airport/${airport}/airlines`);
  return airlines;
};
