import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// COMPONENTS
import Cta from "../Cta";
import {
  useWindowSize,
  ShowWindowDimensions,
  SM
} from "../../utils/mobileQueries";

// STYLES & IMAGES
import * as SC from "./style";
import * as CSC from "../../utils/commonStyle";

import VipIcon from "../../assets/images/home-images/home-vip.svg";
import TimeIcon from "../../assets/images/home-images/home-time.svg";
import HandsIcon from "../../assets/images/home-images/home-hands.svg";
import CarIcon from "../../assets/images/home-images/home-car.svg";

//AOS
import "aos/dist/aos.css";
import { AosConfig } from "../../utils/AOSconfig";

interface Props {
  setScrollActive?: any;
  setInputFocus?:any;
}

interface IServiceInternal extends IService {
  icon: string;
  subtitle: string;
}

const HomepageServices: React.FC<Props> = ({ setScrollActive , setInputFocus}) => {
  const { t } = useTranslation();

  useWindowSize();

  useEffect(() => {
    AosConfig();
  }, []);

  const servicesInfo: IServiceInternal[] = [
    {
      title: t("homepage.paquetes.salas_vip"),
      subtitle: t("homepage.paquetes.salas_vip_titulo"),
      description: t("homepage.paquetes.salas_vip_parrafo"),
      icon: VipIcon
    },
    {
      title: t("homepage.paquetes.fastpass"),
      subtitle: t("homepage.paquetes.fastpass_titulo"),
      description: t("homepage.paquetes.fastpass_parrafo"),
      icon: TimeIcon
    },
    {
      title: t("homepage.paquetes.meet_and_greet"),
      subtitle: t("homepage.paquetes.meet_and_greet_titulo"),
      description: t("homepage.paquetes.meet_and_greet_parrafo"),
      icon: HandsIcon
    },
    {
      title: t("homepage.paquetes.traslado"),
      subtitle: t("homepage.paquetes.traslado_titulo"),
      description: t("homepage.paquetes.traslado_texto"),
      icon: CarIcon
    }
  ];
  return (
    <SC.MainWrapper>
      <SC.Wrapper>
        <SC.Headline>
          <SC.TitleContainer>
            <div data-aos="fade-up">
              <CSC.LittleTitle>
                {t("homepage.paquetes.nuestros_paquetes")}
              </CSC.LittleTitle>
            </div>

            <div data-aos="fade-up" data-aos-delay="100">
              <CSC.Title>{t("homepage.paquetes.titulo")}</CSC.Title>
            </div>
            <div data-aos="fade-up" data-aos-delay="200">
              <SC.TextP>{t("homepage.paquetes.parrafo")}</SC.TextP>
            </div>

            {ShowWindowDimensions() > SM && (
              <div data-aos="fade-up" data-aos-delay="200">
                <Cta
                  onClick={() => {
                    setScrollActive(1);
                    setTimeout(()=>{
                      setInputFocus(true)
                    }, 600)
                  }}
                  theme="blueDarkTheme"
                >
                  {t("homepage.paquetes.boton")}
                  <CSC.RightArrow />
                </Cta>
              </div>
            )}
          </SC.TitleContainer>
        </SC.Headline>
        <SC.Services>
          {servicesInfo.map((item, i) => (
            <div data-aos="fade-up" data-aos-delay="100" key={i}>
              <SC.LINK
                onClick={() => {
                  setScrollActive(1);
                  setTimeout(()=>{
                    setInputFocus(true)
                  }, 600)
                }}
              >
                <SC.ServiceIcon icon={item.icon} />
                <SC.ServiceTitle>{item.title}</SC.ServiceTitle>
                <SC.ServiceText>{item.subtitle}</SC.ServiceText>
                <SC.ServiceDescription>
                  {item.description}
                </SC.ServiceDescription>
              </SC.LINK>
            </div>
          ))}
        </SC.Services>
        {ShowWindowDimensions() < SM && (
          <Cta
            onClick={() => {
              setScrollActive(1);

              setTimeout(()=>{
                setInputFocus(true)
              }, 600)
          
            }}
            theme="blueDarkTheme"
          >
            {t("homepage.paquetes.boton")}
            <CSC.RightArrow />
          </Cta>
        )}
      </SC.Wrapper>
    </SC.MainWrapper>
  );
};

export default HomepageServices;
